export enum FileType {
  SOURCE = 'SOURCE',
  THUMBNAIL = 'THUMBNAIL',
  CUBEMAP_FACE = 'CUBEMAP_FACE',
  CUBEMAP_FACE_PX = 'CUBEMAP_FACE_PX',
  CUBEMAP_FACE_PY = 'CUBEMAP_FACE_PY',
  CUBEMAP_FACE_PZ = 'CUBEMAP_FACE_PZ',
  CUBEMAP_FACE_NX = 'CUBEMAP_FACE_NX',
  CUBEMAP_FACE_NY = 'CUBEMAP_FACE_NY',
  CUBEMAP_FACE_NZ = 'CUBEMAP_FACE_NZ'
}
