import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputComponent } from '../input.component';

@Component({
  standalone: true,
  selector: 'nrc-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputCheckboxComponent),
      multi: true
    }
  ]
})
export class InputCheckboxComponent extends InputComponent<any> {
  constructor() {
    super();
  }

  /**
   * Handle the input value changes.
   *
   * @param event The event of the input change containing the changed value.
   */
  public override handleInputChange(event: Event): void {
    const value = (event.target as HTMLInputElement).checked;

    this.onChange(value);
    this.onTouched();
  }
}
