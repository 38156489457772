<div class="w-full flex flex-col space-y-2">
  @if (headline) {
    <div class="w-full px-3 py-2 bg-white/5 flex justify-start items-center rounded-md">
      <span class="text-sm text-white/95 font-light">{{ headline }}</span>
    </div>
  }

  @if (fileExplorer && fileExplorer.folders) {
    @for (folder of fileExplorer.folders; track i; let i = $index) {
      <div
        tabindex="0"
        class="w-[95%] ml-[5%] px-3 py-2 flex justify-between items-center rounded-md cursor-pointer"
        [ngClass]="{ 'bg-white/5 ': folder.isSelected}"
        (click)="toggleSelectItem(folder)"
        (keydown)="null"
      >
        <div
          class="w-full flex justify-start items-center space-x-2"
          [ngClass]="{ 'opacity-50': !folder.isSelected}"
        >
          <nrc-icon
            [name]="'folder'"
            class="w-4 h-4"
          />

          <span class="text-sm text-white/95 font-light">
            {{ folder.title }}
          </span>
        </div>

        <nrc-icon
          class="w-4 h-4 transition-transform -rotate-90"
          [ngClass]="{ 'rotate-0': folder.isSelected }"
          [name]="'select'"
        />
      </div>
    }
  }
</div>
