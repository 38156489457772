import { Directive, ElementRef, EventEmitter, HostListener, Output, Input, OnChanges, SimpleChanges, inject } from '@angular/core';

export interface IContentEditableChangeEvent {
  name: string,
  isFocused: boolean,
  saveChanges: boolean
}

@Directive({
  selector: '[nrcContenteditable]',
  standalone: true
})
export class ContenteditableDirective implements OnChanges {
  @Input() public value = new EventEmitter<string>();

  @Output() public valueChanged = new EventEmitter<{name: string, isFocused: boolean, saveChanges: boolean}>();

  private isFocused = false;

  private readonly elementRef = inject(ElementRef);

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes || !changes['value']) {
      return;
    }

    this.elementRef.nativeElement.innerText = changes['value'].currentValue;
  }

  @HostListener('blur')
  public onBlur(): void {
    const value = this.elementRef.nativeElement.innerText;

    this.elementRef.nativeElement.contentEditable = false;

    this.isFocused = false;

    const emitEvent: IContentEditableChangeEvent = { name: value, isFocused: this.isFocused, saveChanges: true };

    this.valueChanged.emit(emitEvent);
  }

  @HostListener('input')
  public onInput(): void {
    const value = this.elementRef.nativeElement.innerText;

    const emitEvent: IContentEditableChangeEvent = { name: value, isFocused: this.isFocused, saveChanges: false };

    this.valueChanged.emit(emitEvent);
  }

  @HostListener('dblclick')
  public onDblClick(): void {
    this.elementRef.nativeElement.contentEditable = true;

    this.elementRef.nativeElement.focus();
    this.isFocused = true;
  }

  @HostListener('keydown.enter', ['$event'])
  public onEnter(event: KeyboardEvent): void {
    // Prevent multiple lines of input and emulate confirmation on enter press.
    event.preventDefault();
    this.elementRef.nativeElement.blur();
  }
}
