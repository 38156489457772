<div class="w-full flex flex-col space-y-4">
  <div
    class="relative w-full min-h-[8rem] p-[4rem] rounded-10 border-1 border-dashed flex flex-col justify-center items-center space-y-2 hover:bg-white/5"
    [ngClass]="{
      'border-cyan bg-white/5': isDraggedOver,
      'border-white/50': !isDraggedOver
    }"
  >
    <input
      #fileDropInput
      id="fileDropInput"
      class="absolute w-full h-full opacity-0 z-10 top-0 left-0 hover:cursor-pointer"
      type="file"
      [multiple]="isMultipleUploadEnabled"
      [accept]="acceptableMimeTypes ? (acceptableMimeTypes | join: ',') : null"
      (change)="handleFiles($event)"
    >

    <nrc-icon class="w-44 h-28" [name]="'file-drop'" />

    <span class="text-base text-white font-normal">
      {{ 'COMPONENTS.FILE_UPLOADER.DRAG_AND_DROP' | translate }}
    </span>

    <label class="text-sm text-white/50 font-light" for="fileDropInput">
      {{ 'COMPONENTS.FILE_UPLOADER.SELECT_A_FILE' | translate }}
    </label>
  </div>

  <div #fileListContainer class="w-full max-h-52 flex flex-col space-y-1 overflow-auto">
    @for (fileListItem of fileList; let i = $index; track i) {
      <div #fileListItems class="flex grow justify-between items-center w-full bg-white/5 rounded p-3 space-x-3">
        <nrc-icon class="w-7 h-7 opacity-30" [name]="'image'" />

        <div class="flex grow flex-col space-y-2">
          <div class="flex justify-between items-center overflow-hidden">
            <span class="text-sm text-white font-light break-all">
              {{ fileListItem.file.name }}
            </span>

            @switch (fileListItem.status()) {
              @case ('in-progress') {
                <span class="text-sm text-white font-light">
                  {{ fileListItem.progress() }}&percnt;
                </span>
              }
              @case ('success') {
                <div class="flex justify-end items-center space-x-2">
                  <nrc-icon class="w-3 h-3" [name]="'check'" [fillColor]="'success'" />

                  <span class="text-sm text-white font-light text-end">
                    {{ 'COMPONENTS.FILE_UPLOADER.UPLOAD_STATUS.SUCCESS' | translate }}
                  </span>
                </div>
              }
              @case ('failed') {
                <div class="flex justify-end items-center space-x-2">
                  <nrc-icon class="w-3 h-3 rotate-45" [name]="'plus'" [fillColor]="'error'" />

                  <span class="text-sm text-white font-light text-end">
                      {{ fileListItem.error() || 'COMPONENTS.FILE_UPLOADER.UPLOAD_STATUS.FAILED' | translate }}
                  </span>
                </div>
              }
            }
          </div>

          <nrc-progress-bar class="w-full" [progress]="fileListItem.progress()" />
        </div>

        <div class="flex justify-end items-center ml-4 mr-2 space-x-2">
          @if (fileListItem.status() === 'in-progress') {
            <nrc-loading-spinner />
          } @else {
            <button class="w-4 h-4 rotate-45 opacity-75 hover:opacity-100" (click)="removeFileFromList(i)">
              <nrc-icon [name]="'plus'" />
            </button>
          }
        </div>
      </div>
    }
  </div>
</div>
