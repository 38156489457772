import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

/**
 * Animation for sidebar opening/closing with smooth transitions
 * Simple implementation with direct transitions.
 */
export const sidebarAnimation: AnimationTriggerMetadata = trigger('sidebarAnimation', [
  state('expanded', style({
    width: '100%',
    opacity: 1
  })),

  state('collapsed', style({
    width: '0px',
    opacity: 0
  })),

  transition('expanded => collapsed', [
    animate('300ms ease-out')
  ]),

  transition('collapsed => expanded', [
    animate('300ms ease-in')
  ]),

  transition('void => expanded', [
    style({ width: '0px', opacity: 0 }),
    animate('300ms ease-in')
  ]),

  transition('void => collapsed', [
    style({ width: '0px', opacity: 0 })
  ])
]);

/**
 * Animation for sidebar content with simplified states.
 */
export const sidebarContentAnimation: AnimationTriggerMetadata = trigger('sidebarContentAnimation', [
  state('visible', style({
    opacity: 1,
    transform: 'translateX(0)'
  })),

  state('hiddenLeft', style({
    opacity: 0,
    transform: 'translateX(-10px)'
  })),

  state('hiddenRight', style({
    opacity: 0,
    transform: 'translateX(10px)'
  })),

  transition('visible <=> hiddenLeft, visible <=> hiddenRight', [
    animate('250ms ease-in-out')
  ]),

  transition('void => visible', [
    style({ opacity: 0 }),
    animate('250ms ease-in')
  ]),

  transition('void => hiddenLeft, void => hiddenRight', [
    style({ opacity: 0 })
  ])
]);
