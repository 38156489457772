import { CommonModule, DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { ListItemType } from '@newroom-connect/library/interfaces';
import { DrawItemPipe, EvaluatePipe, FirstCharOfWordsPipe, ToFileSizePipe, ToNumberPipe, ToStringPipe } from '@newroom-connect/library/pipes';
import { HoverDelayDirective } from '@newroom-connect/library/directives';

import { ButtonComponent } from '../../../buttons/button/button.component';
import { ChipComponent } from '../../../chip/chip.component';
import { IconComponent } from '../../../icon/icon.component';
import { MediaPreviewComponent } from '../../../media-preview/media-preview.component';

import { UserDetailTooltipComponent } from './user-detail-tooltip/user-detail-tooltip.component';

export interface IRowListItemConfiguration {
  type: ListItemType;
  value: unknown;
  additionalInfo?: unknown;
  thumbnailConfig?: {
    backgroundColorClasses?: string[];
    thumbnailSourceKey?: string;
    fallbackThumbnailSourceKey?: string;
    mimetypeKey?: string;
    fallbackMimetypeKey?: string;
  };
  thumbnailSource?: string;
  isHighlighted?: boolean;
  userData?: IUserProfileTooltipData
  [key: string]: any; // Each type has some more differing properties.
}

export interface IUserProfileTooltipData {
  id: string,
  profileColor: string,
  firstName: string,
  lastName: string,
  roleName: string,
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    ChipComponent,
    IconComponent,
    MediaPreviewComponent,
    UserDetailTooltipComponent,
    DrawItemPipe,
    EvaluatePipe,
    FirstCharOfWordsPipe,
    HoverDelayDirective,
    ToFileSizePipe,
    ToNumberPipe,
    ToStringPipe,
    DatePipe
  ],
  selector: 'nrc-row-list-item',
  templateUrl: './row-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowListItemComponent {
  @Input({ required: true }) public config!: IRowListItemConfiguration;
  @Input() public isSelected?: boolean;
  @Input() public index?: number;

  @Output() public rowListItemClickedEvent = new EventEmitter();

  public listItemType = ListItemType;
  public buttonType = ButtonType;
  public actionRole = ActionRole;
}
