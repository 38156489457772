import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { DownloadProgress, DownloadStatus } from '@newroom-connect/library/services';

import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'nrc-file-download-status',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    IconComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './file-download-status.component.html',
  animations: [fadeInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileDownloadStatusComponent {
  @Input() public downloadProgress: DownloadProgress | null = null;

  public downloadStatus = DownloadStatus;
}
