import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  /**
   * @constructor
   *
   * @param translateService
   */
  constructor(
    private readonly translateService: TranslateService
  ) {
    this.initializeBrowserLanguage();
  }

  /**
   *
   */
  public initialize(): void {
    this.initializeBrowserLanguage();
  }

  /**
   * Find the translated value of a given key.
   * Only complete keys are translated.
   *
   * @param key Key to find a translated value for.
   * @param substitute String replace the marker (in i18n file: "%s%") of translation value.
   *
   * @returns The translated value of the given key.
   *
   * @throws
   */
  public translate(key: string, substitute?: string | Record<string, unknown>): string {
    let translation = this.translateService.instant(key);

    if (typeof translation !== 'string') {
      throw new Error('The key could not be found. Please provide the complete key.');
    }

    if (!substitute) {
      return translation;
    }

    if (typeof substitute === 'string') {
      translation = translation.replace('%s%', substitute);
    } else {
      translation = this.translateService.instant(key, substitute);
    }

    return translation;
  }

  /**
   * Fetch the current language from the browser and use this language, if it is supported.
   */
  private initializeBrowserLanguage(): void {
    const browserLanguage = this.translateService.getBrowserLang();

    if (browserLanguage && this.translateService.getLangs().includes(browserLanguage)) {
      this.translateService.use(browserLanguage);
    }
  }
}
