<div class="w-3.5 text-left">
  @if (checked) {
    <span class="text-xs text-green-500 leading-6 tracking-wide">
      &check;&nbsp;
    </span>
  } @else {
    <span class="text-xs text-gray-50 leading-6 tracking-wide">
      &bull;&nbsp;
    </span>
  }
</div>
