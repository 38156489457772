import { AfterViewInit, Directive, ElementRef, Input, Renderer2, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[nrcOverflowTarget]'
})
export class OverflowTargetDirective implements AfterViewInit {
  @Input('nrcOverflowTarget') public targetElement!: HTMLElement;
  @Input() public parentElement?: HTMLElement;
  @Input() public elementHeight?: number;
  @Input() public elementSpaceY = 4; // The space in pixels on y-axis that should be added between the element and the target element.

  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  /**
   *
   */
  public ngAfterViewInit(): void {
    this.updatePosition();
  }

  /**
   * Update the position of the element based on the given target and parent element.
   */
  private updatePosition(): void {
    const elementHeight = this.elementHeight ?? this.elementRef.nativeElement.getBoundingClientRect();
    const targetPosition = this.targetElement.getBoundingClientRect();
    const parentBottomPosition = this.parentElement ? this.parentElement.getBoundingClientRect().bottom : window.innerHeight;

    const elementTopPositionRelativeToTarget = this.targetElement.offsetHeight + this.elementSpaceY;

    const elementBottomPosition = targetPosition.bottom + elementHeight;

    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'absolute');

    // Check if the element will exceed the parent container and set the position above the target, if so.
    if (elementBottomPosition > parentBottomPosition) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', `${elementTopPositionRelativeToTarget}px`);
      this.renderer.removeStyle(this.elementRef.nativeElement, 'top');
    } else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${elementTopPositionRelativeToTarget}px`);
      this.renderer.removeStyle(this.elementRef.nativeElement, 'bottom');
    }
  }
}
