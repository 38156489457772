export const environment = {
  local: false,
  production: false,
  webApp: {
    baseUrl: 'https://suite-dev.newroom-connect.com'
  },
  api: {
    baseUrl: 'https://data-api-dev.newroom-connect.com'
  },
  auth: {
    baseUrl: 'https://auth-api-dev.newroom-connect.com'
  },
  websocketServer: {
    baseUrl: 'wss://data-api-dev.newroom-connect.com'
  },
  config: {
    googleAnalytics: {
      settings: {
        // eslint-disable-next-line camelcase
        anonymize_ip: true
      }
    }
  }
};
