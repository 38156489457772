import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'drawItem'
})
export class DrawItemPipe implements PipeTransform {
  /**
   * Draw an item from the items array based on the given index.
   * The item is drawed based on the mod of the index.
   *
   * @param items The items to draw the item from.
   * @param index The index to use for drawing the item.
   *
   * @returns The drawed item.
   *
   * @example items: ["abc", "def", "ghi"], index: 1 -> "def"
   * @example items: ["abc", "def", "ghi"], index: 3 -> "abc"
   */
  public transform(items: unknown[] | undefined, index: number): unknown {
    if (!items) {
      return;
    }

    return items[index % items.length];
  }
}
