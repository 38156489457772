<table class="w-full">
  @if (config.caption) {
    <caption>{{ config.caption }}</caption>
  }

  <thead>
    <tr>
      @if (config.selectMultiple) {
        <th class="p-6 w-32">
          <div
            class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out px-4 py-2"
            tabindex="0"
            aria-hidden="true"
            (click)="$event.stopPropagation(); toggleAllItemsIsSelected();"
          >
            <span
              class="flex justify-center items-center mr-2 w-5 h-5 bg-transparent
                border-solid rounded-sm cursor-pointer
                transition-all duration-250 ease-in-out
                group-[]:bg-transparent"
              [ngClass]="{
                'border-1 border-gray-400': !isEveryPageItemSelectedSig() && !isSomeButNotEveryPageItemSelectedSig(),
                'border-10 animate-shrink-bounce before:content-[\'\'] before:absolute before:border-b-1.5 before:border-b-solid': isSomeButNotEveryPageItemSelectedSig() || isEveryPageItemSelectedSig(),
                'border-[#454545] before:top-3 before:w-2 before:h-2 before:border-b-[#fefefe]': isSomeButNotEveryPageItemSelectedSig(),
                'border-cyan before:top-5 before:left-5 before:border-r-1.5 before:border-r-solid before:border-r-transparent before:border-b-transparent before:rotate-45 before:origin-bottom-left before:animate-checkbox-check': isEveryPageItemSelectedSig()
              }"
            ></span>
          </div>
        </th>
      }

      @for (column of config.columns; track $index) {
        <th class="text-[#adadad] text-base text-left font-semibold tracking-wide uppercase p-6">
          <div class="flex space-x-3" [ngClass]="{
            'justify-start': !column.align || column.align === 'left',
            'justify-center': column.align === 'center',
            'justify-end': column.align === 'right'
          }">
            <span>{{ column.label }}</span>

            <div class="group flex items-center space-x-2">
              <nrc-icon
                class="w-4 h-4 opacity-0 group-hover:opacity-50 hover:!opacity-100 transition-opacity"
                [ngClass]="{
                  'opacity-50': column.orderBy === 'desc',
                  '!opacity-100': column.orderBy === 'asc'
                }"
                [fillColor]="column.orderBy === 'asc' ? 'cyan' : 'white'"
                [name]="'arrow-sharp-down'"
                (click)="orderByColumn(column, 'asc')"
              />

              <nrc-icon
                class="w-4 h-4 rotate-180 opacity-0 group-hover:opacity-50 hover:!opacity-100 transition-opacity"
                [ngClass]="{
                  'opacity-50': column.orderBy === 'asc',
                  '!opacity-100': column.orderBy === 'desc'
                }"
                [fillColor]="column.orderBy === 'desc' ? 'cyan' : 'white'"
                [name]="'arrow-sharp-down'"
                (click)="orderByColumn(column, 'desc')"
              />
            </div>
          </div>
        </th>
      }

      @if (config.itemActionButtons) {
        <th class="text-[#adadad] text-base text-center font-semibold tracking-wide uppercase p-6">
          ACTIONS
        </th>
      }
    </tr>
  </thead>

  <tbody>
    @for (item of items(); track rowIndex; let rowIndex = $index) {
      <tr class="group hover:bg-white/5 border-y-1 border-solid border-white/10 cursor-pointer -z-10 last:border-b-0">
        <!-- A checkbox for selecting the item in the list -->
        @if (config.selectMultiple) {
          <td class="p-6 w-32">
            <div
              class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out px-4 py-2"
              tabindex="0"
              aria-hidden="true"
              (click)="$event.stopPropagation(); toggleItemIsSelectedAtIndex(rowIndex);"
            >
              <span
                class="flex justify-center items-center mr-2 w-5 h-5 bg-transparent
                  border-solid rounded-sm cursor-pointer
                  transition-all duration-250 ease-in-out
                  group-[]:bg-transparent"
                [ngClass]="{
                  'border-1 border-gray-400': !item.isSelected,
                  'border-10 border-cyan animate-shrink-bounce before:content-[\'\'] before:absolute before:top-5 before:left-5 before:border-r-1.5 before:border-r-solid before:border-r-transparent before:border-b-1.5 before:border-b-solid before:border-b-transparent before:rotate-45 before:origin-bottom-left before:animate-checkbox-check': item.isSelected
                }"
              ></span>
            </div>
          </td>
        }

        @for (column of config.columns; track itemIndex; let itemIndex = $index) {
          <td class="p-6" tabindex="0" (click)="rowClickedEvent.emit({ index: rowIndex, item })" (keydown)="null">
            <nrc-row-list-item
              class="flex"
              [ngClass]="{
                'justify-start': !column.align || column.align === 'left',
                'justify-center mr-13': column.align === 'center',
                'justify-end': column.align === 'right'
              }"
              [index]="rowIndex"
              [isSelected]="item.isSelected"
              [config]="{
                type: column.type,
                value: item.value | extractProperty: column.key,
                additionalInfo: column['additionalInfoKey'] ? (item.value | extractProperty: (column['additionalInfoKey'] | toString)) : undefined,
                thumbnailConfig: column.thumbnailConfig,
                mimetype: column.thumbnailConfig && column.thumbnailConfig.thumbnailSourceKey && (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                  ? 'image/png'
                  : (item.value | extractProperty: (column.thumbnailConfig?.fallbackMimetypeKey | toString) | toString),
                thumbnailSource: column.thumbnailConfig && column.thumbnailConfig.thumbnailSourceKey
                  ? (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                    ? (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                    : (item.value | extractProperty: (column.thumbnailConfig.fallbackThumbnailSourceKey | toString) | toString)
                  : undefined,
                userData: column.key === 'createdAt' || column.key === 'updatedAt' ? {
                  id: (item.value | extractProperty: (column.userData.idPropertyKey | toString)) | toString,
                  profileColor: (item.value | extractProperty: (column.userData.profileColorKey)) | toString,
                  firstName: (item.value | extractProperty: (column.userData.firstNameKey)) | toString,
                  lastName: (item.value | extractProperty: (column.userData.lastNameKey)) | toString,
                  roleName: (item.value | extractProperty: (column.userData.roleNameKey)) | toString,
                } : undefined,
                isHighlighted: column.isHighlighted,
                actionRole: item.actionRole,
                tooltipText: column['tooltipText'],
                extractorFn: column['extractorFn'],
                tooltipTextFn: column['tooltipTextFn'],
                visibilityFn: column['visibilityFn']
              }"
              (rowListItemClickedEvent)="rowClickedEvent.emit({ index: rowIndex, itemIndex, item })"
            />
          </td>
        }

        <!-- Action buttons for each item -->
        @if (config.itemActionButtons && config.itemActionButtons.length > 0) {
          <td class="p-6">
            <div class="flex justify-center items-center space-x-3 visible md:invisible md:group-hover:visible">
              @for (actionButton of config.itemActionButtons; track $index) {
                <nrc-button
                  class="relative w-10 h-10"
                  [icon]="actionButton?.icon"
                  [label]="actionButton?.label"
                  [buttonType]="actionButton?.buttonType"
                  [role]="actionButton?.role"
                  [tooltipText]="actionButton?.tooltipText"
                  (clickEvent)="itemActionButtonEvent.emit({ value: item, actionButton })"
                />
              }
            </div>
          </td>
        }
      </tr>
    }
  </tbody>
</table>
