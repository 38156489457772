import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { take, takeUntil } from 'rxjs';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { FormControlPipe, TranslatePipe } from '@newroom-connect/library/pipes';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { AutoTranslateService, ITranslateInputOutput } from '@newroom-connect/library/services';

import { ButtonComponent } from '../buttons/button/button.component';
import { ModalComponent, ModalTitleSize } from '../modal/modal.component';
import { IconComponent } from '../icon/icon.component';
import { DestroyableComponent } from '../abstract/destroyable/destroyable.component';
import { InputLanguageSelectorComponent, InputLanguageSelectorComponentType } from '../inputs/input-language-selector/input-language-selector.component';

interface IAutoTranslateForm {
  from: FormControl<string>;
  to: FormControl<string>;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    ModalComponent,
    ButtonComponent,
    InputLanguageSelectorComponent,
    FormControlPipe,
    TranslatePipe
  ],
  selector: 'nrc-auto-translate',
  templateUrl: './auto-translate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AutoTranslateComponent extends DestroyableComponent implements OnInit {
  @Input({ required: true }) public selectedLanguageCode!: string;
  @Input() public languageCodes: string[] = [];
  @Input() public inputsToTranslate: { [key: string]: string } = {};

  @Output() public autoTranslateResult = new EventEmitter<ITranslateInputOutput>();
  @Output() public closeEvent = new EventEmitter<void>();

  public autoTranslateFormGroup = new FormGroup<IAutoTranslateForm>({
    to: new FormControl<string>('', { nonNullable: true }),
    from: new FormControl<string>('', { nonNullable: true })
  });
  public isTranslateModalOpen = false;
  public sourceLanguageCodes: string[] = [];
  public targetLanguageCodes: string[] = [];

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public inputLanguageSelectorComponentType = InputLanguageSelectorComponentType;
  public modalTitleSize = ModalTitleSize;

  constructor(
    private readonly autoTranslateService: AutoTranslateService
  ) {
    super();
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.sourceLanguageCodes = [...this.languageCodes];
    this.targetLanguageCodes = this.sourceLanguageCodes.filter(code => code !== this.selectedLanguageCode);

    if (!ArrayHelper.isNotEmpty(this.sourceLanguageCodes) || !ArrayHelper.isNotEmpty(this.targetLanguageCodes)) {
      return;
    }

    this.autoTranslateFormGroup.patchValue({ from: this.selectedLanguageCode, to: this.targetLanguageCodes.at(0) });

    this.registerSourceLanguageCodeFormChange();
  }

  /**
   * Translates the dynamic values of the areaElementAction.
   * Emits the result of calling the translateJson endpoint after building the translationParams.
   */
  public async translateInputs(): Promise<void> {
    if (!this.inputsToTranslate) {
      return;
    }

    const translationParams = {
      from: this.autoTranslateFormGroup.controls.from.value.slice(0, 2),
      to: this.autoTranslateFormGroup.controls.to.value.slice(0, 2),
      payload: this.inputsToTranslate
    };

    this.autoTranslateService.translateAreaElementActionInputs(translationParams).pipe(take(1)).subscribe(result => {
      this.autoTranslateResult.emit({
        from: this.autoTranslateFormGroup.controls.from.value,
        to: this.autoTranslateFormGroup.controls.to.value,
        payload: result.payload
      });
    });
  }

  /**
   * Watches the changes in from languageCode and sets the to languageCode.
   */
  private registerSourceLanguageCodeFormChange(): void {
    this.autoTranslateFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(value => {
        this.sourceLanguageCodes = [...this.languageCodes.filter(code => code !== value.to)];
        this.targetLanguageCodes = [...this.sourceLanguageCodes.filter(code => code !== value.from)];
      });
  }
}
