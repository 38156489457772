import { takeUntil } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input, OnInit, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { InputComponent } from '../input.component';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'nrc-input-toggle',
  templateUrl: './input-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputToggleComponent),
      multi: true
    }
  ]
})
export class InputToggleComponent extends InputComponent<boolean> implements OnInit {
  @Input() public disabled?: boolean = false;

  public isChecked = signal<boolean>(false);

  /**
   *
   */
  public override ngOnInit(): void {
    this.isChecked.set(this.formControl.value);

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => this.isChecked.set(value));
  }

  /**
   * Toggle the value of the form control of the input component.
   */
  public toggleValue(): void {
    // Do nothing if toggle is disabled
    if (this.disabled) {
      return;
    }

    this.isChecked.set(!this.formControl.value);

    this.formControl.patchValue(this.isChecked());
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }
}
