<div class="flex w-full justify-center pb-8">
  @if (fileMimetype && fileSource) {
    <div
      class="w-28 h-28 flex justify-center relative"
      [ngClass]="{ 'animate-rotate-bounce': isBackgroundThumbnailAnimatedSig() }"
    >
      <nrc-media-preview
        class="w-full h-full flex justify-center rounded-10 overflow-hidden shadow-inner bg-[#fefefe1a]"
        [mimetype]="fileMimetype"
        [source]="fileSource"
        [mediaPreviewTypes]="['image', 'video-snippet']"
      />
    </div>
  }

  <form class="flex flex-col grow justify-between pl-5 py-1" [formGroup]="fileTitleForm">
    <div class="w-full">
      @if (fileTitleForm) {
        <nrc-input-text
          class="w-full"
          [formControl]="fileTitleForm.controls['title']"
          [type]="'text'"
          [name]="'file-replace-title'"
          [id]="'file-replace-title'"
          [label]="'COMPONENTS.FILE_REPLACER.INPUTS.TITLE.LABEL' | translate"
          [classList]="['bg-white/25']"
          [readonly]="true"
        />
      }

      <div class="w-full">
        <span class="text-white/50 text-xs font-light">
          {{ fileDescription }}
        </span>
      </div>
    </div>

    <nrc-note
      class="w-full"
      [message]="{
        message: 'COMPONENTS.FILE_REPLACER.NOTE.MESSAGE.MESSAGE' | translate,
        level: level.WARN
      }"
    />
  </form>
</div>

<!-- File replacement modal - hidden by default -->
<nrc-file-uploader
  [acceptableMimeTypes]="acceptableMimeTypes"
  [isMultipleUploadEnabled]="false"
  (filesChangedEvent)="$event.length > 0 ? fileChangedEvent.emit($event[0]) : null"
  (fileSizeLimitExceededEvent)="fileSizeLimitExceededEvent.emit($event)"
/>
