<div class="relative w-full h-full">
  @if (isPdfLoading) {
    <div class="absolute inset-0 z-10 flex items-center justify-center bg-white/30">
      <nrc-loading-spinner class="w-8 h-8 text-cyan-700"></nrc-loading-spinner>
      <div class="text-sm text-cyan-700">Loading PDF...</div>
    </div>
  } @else if (pdfError) {
    <div class="flex items-center justify-center w-full h-full">
      <div class="p-4 text-center text-red-500">
        {{ pdfError }}
      </div>
    </div>
  }

  @if (!pdfError && safeUrl) {
    <embed
      class="w-full h-full"
      type="application/pdf"
      [src]="safeUrl"
      [ngStyle]="{
        'border': showBorders ? '1px solid #ccc' : 'none'
      }"
      (load)="onPdfLoaded()"
      (error)="handleError($event)"
    />
  }
</div>
