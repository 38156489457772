import { AfterViewInit, Component, inject, Signal, signal } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, finalize, of, takeUntil } from 'rxjs';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { ApiError } from '@newroom-connect/library/errors';
import { ISystemMessage } from '@newroom-connect/library/interfaces';
import { AuthService, SessionService, StateService, TranslationService, LoggingService, ILoginInput, ToastService } from '@newroom-connect/library/services';
import { Level } from '@newroom-connect/library/enums';

import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';

@Component({
  animations: [fadeInOutAnimation],
  template: ''
})
export abstract class SignInBaseComponent extends DestroyableComponent implements AfterViewInit {
  protected readonly router = inject(Router);
  protected readonly authService = inject(AuthService);
  protected readonly sessionService = inject(SessionService);
  protected readonly stateService = inject(StateService);
  protected readonly translationService = inject(TranslationService);
  protected readonly loggingService = inject(LoggingService);
  protected readonly toastService = inject(ToastService);

  public confirmRegistrationMessage: Signal<ISystemMessage>;
  public signInErrorMessage?: string;
  public userNotConfirmed = false;
  protected loginEmail?: string;

  constructor() {
    super();

    // Preload the confirm registration message
    this.confirmRegistrationMessage = signal<ISystemMessage>({
      headline: this.translationService.translate('PAGES.SIGNUP.CONFIRM_EMAIL.HEADLINE'),
      message: this.translationService.translate('PAGES.SIGNUP.CONFIRM_EMAIL.MESSAGE'),
      icon: 'inbox'
    });
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    // Wrap session refresh into timeout to let Angular detect the change on the next browser MicroTask.
    setTimeout(() => {
      const userSession = this.sessionService.getUserSession();

      if (userSession?.needsRefresh()) {
        this.stateService.setLoadingState(true);
        this.authService.refreshSession().pipe(
          takeUntil(this.destroy$),
          catchError(error => this.handleLoginError(error)),
          finalize(() => this.stateService.setLoadingState(false))
        ).subscribe(() => {
          this.onSessionRefreshed();
        });
      }
    }, 0);
  }

  /**
   *
   * @param event
   * @param isCodeSignin
   */
  public handleLogin(event: ILoginInput, isCodeSignin = false): void {
    this.stateService.setLoadingState(true);

    this.loginEmail = event.email;
    this.authService.login({ ...event, isCodeLogin: isCodeSignin }).pipe(
      takeUntil(this.destroy$),
      catchError(error => this.handleLoginError(error, isCodeSignin)),
      finalize(() => this.stateService.setLoadingState(false))
    ).subscribe(() => {
      this.onLoginSuccess();
    });
  }

  /**
   * Handle a resend confirmation event where the user will get a new confirmation email.
   */
  public handleResendConfirmation(): void {
    if (!this.loginEmail) {
      return;
    }
    this.stateService.setLoadingState(true);
    this.authService.resendConfirmation({ email: this.loginEmail }).pipe(
      takeUntil(this.destroy$),
      catchError(error => this.handleLoginError(error))
    ).subscribe(() => this.stateService.setLoadingState(false));
  }

  /**
   * Handle login error, clears user session and sets the signin error message.
   *
   * @param error
   * @param isCodeSignin
   *
   * @returns
   */
  protected handleLoginError(error: Error, isCodeSignin = false): Observable<void> {
    if (error instanceof ApiError && error.message === 'SessionExpiredException') {
      this.signInErrorMessage = this.translationService.translate('ERROR.SESSION_EXPIRED.MESSAGE');
      this.sessionService.clearUserSession();
    } else if (error instanceof ApiError && error.statusCode === 401) {
      this.userNotConfirmed = true;
    } else {
      this.signInErrorMessage = this.translationService.translate(isCodeSignin ? 'ERROR.CODE_LOGIN_FAILED.MESSAGE' : 'ERROR.LOGIN_FAILED.MESSAGE');
    }

    // For the case of code sign in, we use the alert service to display a toaster message
    if (this.signInErrorMessage && isCodeSignin) {
      this.toastService.setMessage({
        message: this.signInErrorMessage,
        level: Level.ERROR
      });
    }

    return of();
  }

  /**
   *
   */
  protected abstract onLoginSuccess(): void;

  /**
   *
   */
  protected abstract onSessionRefreshed(): void;
}
