import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BehaviorSubject } from 'rxjs';

export type ResizeEvent = {
  width?: number;
  height?: number;
  top?: number;
  left?: number;
};

@Directive({
  selector: '[nrcResize]',
  standalone: true
})
export class ResizeDirective {
  @Input({ required: true }) public targetContainerRef!: ElementRef<HTMLElement>;
  @Input() public keepAspectRatio?: boolean;
  @Input() public isResizeDisabled = false;

  @Output() public resizeEvent = new EventEmitter<ResizeEvent>();
  @Output() public lastIsResizableStatusEvent = new EventEmitter<boolean>();

  private startX = 0;
  private startY = 0;
  private initialWidth = 0;
  private initialHeight = 0;
  private initialTop = 0;
  private initialLeft = 0;
  private initialAspectRatio = 0;
  private isResizable$ = new BehaviorSubject<boolean>(false);
  private resizeCorner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight' = 'topLeft';

  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  /**
   * @constructor
   */
  constructor() {
    this.isResizable$.pipe(takeUntilDestroyed()).subscribe(isResizable => this.lastIsResizableStatusEvent.emit(isResizable));

    this.onMousemove = this.onMousemove.bind(this);
    this.onMouseup = this.onMouseup.bind(this);
  }

  /**
   *
   * @param event
   */
  @HostListener('mousedown', ['$event'])
  public onMousedown(event: MouseEvent): void {
    if (this.isResizeDisabled) {
      return;
    }

    const rect = this.elementRef.nativeElement.getBoundingClientRect();

    this.isResizable$.next(event.clientX > rect.right - 10 && event.clientY > rect.bottom - 10);

    this.startX = event.clientX;
    this.startY = event.clientY;

    this.initialWidth = 100 * (rect.width / this.targetContainerRef.nativeElement.offsetWidth);
    this.initialHeight = 100 * (rect.height / this.targetContainerRef.nativeElement.offsetHeight);
    this.initialTop = 100 * (this.elementRef.nativeElement.offsetTop / this.targetContainerRef.nativeElement.offsetHeight);
    this.initialLeft = 100 * (this.elementRef.nativeElement.offsetLeft / this.targetContainerRef.nativeElement.offsetWidth);
    this.initialAspectRatio = this.initialWidth / this.initialHeight;

    // Detect which corner of the element was clicked and start the resizing process from there.
    if (event.clientX < rect.left + 10 && event.clientY < rect.top + 10) {
      this.isResizable$.next(true);
      this.resizeCorner = 'topLeft';
    } else if (event.clientX > rect.right - 10 && event.clientY < rect.top + 10) {
      this.isResizable$.next(true);
      this.resizeCorner = 'topRight';
    } else if (event.clientX < rect.left + 10 && event.clientY > rect.bottom - 10) {
      this.isResizable$.next(true);
      this.resizeCorner = 'bottomLeft';
    } else if (event.clientX > rect.right - 10 && event.clientY > rect.bottom - 10) {
      this.isResizable$.next(true);
      this.resizeCorner = 'bottomRight';
    }

    if (this.isResizable$.getValue()) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'none');

      document.addEventListener('mousemove', this.onMousemove);
      document.addEventListener('mouseup', this.onMouseup);
    }
  }

  /**
   *
   * @param event
   */
  private onMousemove(event: MouseEvent): void {
    if (!this.isResizable$.getValue()) {
      return;
    }

    const deltaX = 100 * ((event.clientX - this.startX) / this.targetContainerRef.nativeElement.offsetWidth);
    const deltaY = 100 * ((event.clientY - this.startY) / this.targetContainerRef.nativeElement.offsetHeight);

    let newWidth: number | undefined;
    let newHeight: number | undefined;
    let newTop: number | undefined;
    let newLeft: number | undefined;

    switch (this.resizeCorner) {
      case 'topLeft':
        newWidth = this.initialWidth - deltaX;
        newHeight = this.keepAspectRatio ? newWidth / this.initialAspectRatio : this.initialHeight - deltaY;
        newTop = this.initialTop + deltaY;
        newLeft = this.initialLeft + deltaX;
        break;
      case 'topRight':
        newWidth = this.initialWidth + deltaX;
        newHeight = this.keepAspectRatio ? newWidth / this.initialAspectRatio : this.initialHeight - deltaY;
        newTop = this.initialTop + deltaY;
        break;
      case 'bottomLeft':
        newWidth = this.initialWidth - deltaX;
        newHeight = this.keepAspectRatio ? newWidth / this.initialAspectRatio : this.initialHeight + deltaY;
        newLeft = this.initialLeft + deltaX;
        break;
      case 'bottomRight':
        newWidth = this.initialWidth + deltaX;
        newHeight = this.keepAspectRatio ? newWidth / this.initialAspectRatio : this.initialHeight + deltaY;
        break;
      default:
        break;
    }

    if (newWidth !== undefined) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${newWidth}%`);
    }

    if (newHeight !== undefined) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${newHeight}%`);
    }

    if (newTop !== undefined) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${newTop}%`);
    }

    if (newLeft !== undefined) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${newLeft}%`);
    }

    this.resizeEvent.emit({
      width: newWidth,
      height: newHeight,
      top: newTop,
      left: newLeft
    });
  }

  /**
   *
   */
  private onMouseup(): void {
    if (this.isResizable$.getValue()) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'auto');

      document.removeEventListener('mousemove', this.onMousemove);
      document.removeEventListener('mouseup', this.onMouseup);
    }

    this.isResizable$.next(false);
  }
}
