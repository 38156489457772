import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'evaluate'
})
export class EvaluatePipe implements PipeTransform {
  /**
   * Evaluate a given function.
   *
   * @param fn The function to evaluate.
   * @param args The arguments to pass to the function.
   *
   * @returns The return value of the evaluated function.
   */
  public transform(fn: (...args: unknown[]) => unknown, ...args: unknown[]): unknown {
    return fn(...args);
  }
}
