import { EditorConfig } from '@ckeditor/ckeditor5-core';

// Define font size configuration that will be reused
const fontSizeConfig = {
  options: [
    5,
    5.5,
    6,
    6.5,
    7,
    7.5,
    8,
    9,
    10,
    10.5,
    11,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    36,
    48,
    72
  ],
  supportAllValues: true
};

const fontFamilyConfig = {
  options: [
    'default',
    'MBCorpoAScreen1Cond-Regular',
    'MBCorpoAScreen2Cond-Regular',
    'MBCorpoATextCond-Regular',
    'MBCorpoATitleCond-Regular',
    'MBCorpoATitle-Regular',
    'MBCorpoSText-Bold',
    'MBCorpoSText-CondBold',
    'MBCorpoSText-CondLight',
    'MBCorpoSText-CondRegular',
    'MBCorpoSText-Light',
    'MBCorpoSText-Regular',
    'MBCorpoSTitle-Bold',
    'MBCorpoSTitle-CondBold',
    'MBCorpoSTitle-CondLight',
    'MBCorpoSTitle-CondRegular',
    'MBCorpoSTitle-Light',
    'MBCorpoSTitle-Regular'
  ],
  supportAllValues: true
};

export const editorConfigWithToolbar: EditorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      'showBlocks',
      'findAndReplace',
      '|',
      'fontSize',
      'lineHeight',
      'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'removeFormat',
      'alignment',
      '|',
      'outdent',
      'indent',
      '|',
      'link',
      'insertImageViaUrl',
      'fileLibrary',
      'uploadButton'
    ],
    shouldNotGroupWhenFull: false
  },
  fontFamily: fontFamilyConfig,
  fontSize: fontSizeConfig,
  balloonToolbar: ['bold', 'italic', 'fontSize', 'lineHeight', '|', 'link', '|', 'bulletedList', 'numberedList'],
  blockToolbar: [
    'fontSize',
    'lineHeight',
    'fontColor',
    'fontBackgroundColor',
    '|',
    'bold',
    'italic',
    '|',
    'link',
    'insertTable',
    '|',
    'bulletedList',
    'numberedList',
    'outdent',
    'indent'
  ],
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true
      }
    ]
  },
  image: {
    toolbar: [
      'toggleImageCaption',
      'imageTextAlternative',
      '|',
      'imageStyle:inline',
      'imageStyle:wrapText',
      'imageStyle:breakText',
      '|',
      'resizeImage'
    ]
  },
  initialData: '',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  placeholder: 'Type or paste your content here!'
};

export const editorConfigWithoutToolbar: EditorConfig = {
  ...editorConfigWithToolbar,
  toolbar: {
    items: [],
    shouldNotGroupWhenFull: false
  },
  balloonToolbar: [
    'bold', 'italic', '|', 'fontSize', 'lineHeight', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor'
  ],
  blockToolbar: [
    'bold', 'italic', '|', 'fontSize', 'lineHeight', 'fontFamily', '|', 'fontColor', 'fontBackgroundColor'
  ]
};
