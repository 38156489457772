import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { rangeValidator } from '@newroom-connect/library/validators';
import { ReorderDirective, ReorderEvent, ReOrderableItemDirective } from '@newroom-connect/library/directives';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { IActionButton } from '@newroom-connect/library/interfaces';

import { ButtonComponent } from '../../buttons';
import { CollapsibleComponent } from '../../collapsible/collapsible.component';
import { InputCheckboxComponent } from '../input-checkbox/input-checkbox.component';
import { InputDatepickerComponent } from '../input-datepicker/input-datepicker.component';
import { InputRadioComponent, InputRadioComponentOption, InputRadioComponentType } from '../input-radio/input-radio.component';
import { InputSelectComponent } from '../input-select/input-select.component';
import { InputTextComponent } from '../input-text/input-text.component';
import { InputTimepickerComponent } from '../input-timepicker/input-timepicker.component';
import { InputComponent } from '../input.component';

export interface IFormBuilderInput {
  label: string;
  placement: string;
  type: SupportedInputsTypes;
  required: boolean;
  isTextArea?: boolean;
  isRange?: boolean;
  options?: IFormInputOptionInput[];
  minimum?: number;
  maximum?: number;
}

type FormInputOptionForm = {
  label: FormControl<string>;
}

interface IFormInputOptionInput {
  label: string;
}

export enum SupportedInputsTypes {
  TEXT = 'TEXT',
  SELECT = 'SELECT',
  DROPDOWN = 'DROPDOWN',
  RADIO_BUTTON = 'RADIO_BUTTON',
  DATE = 'DATE',
  TIME = 'TIME',
  SLIDER = 'SLIDER',
  CHECKBOX = 'CHECKBOX'
}

export type FormBuilderForm = {
  type: FormControl<SupportedInputsTypes>;
  label: FormControl<string>;
  required: FormControl<boolean>;
  placement: FormControl<string>;
  isTextArea?: FormControl<boolean>;
  isRange?: FormControl<boolean>;
  rangeStart?: FormControl<Date>;
  rangeEnd?: FormControl<Date>;
  options?: FormArray<FormGroup<FormInputOptionForm>>,
  minimum?: FormControl<number>,
  maximum?: FormControl<number>
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputRadioComponent,
    InputTextComponent,
    CollapsibleComponent,
    ReorderDirective,
    ReOrderableItemDirective,
    ButtonComponent,
    InputSelectComponent,
    InputDatepickerComponent,
    InputTimepickerComponent,
    InputCheckboxComponent
  ],
  selector: 'nrc-input-form-builder',
  templateUrl: './input-form-builder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputFormBuilderComponent),
      multi: true
    }
  ]
})
export class InputFormBuilderComponent extends InputComponent<IFormBuilderInput[]> implements OnInit, OnChanges {
  public formBuilderInputs: FormArray<FormGroup<FormBuilderForm>> = new FormArray<FormGroup<FormBuilderForm>>([]);

  public supportedInputTypesOptions: InputRadioComponentOption[] = [
    {
      label: 'Text',
      icon: 'text',
      value: 'TEXT'
    },
    {
      label: 'Select',
      icon: 'arrow-down',
      value: 'SELECT'
    },
    {
      label: 'Checkbox',
      icon: 'checkbox',
      value: 'CHECKBOX'
    },
    {
      label: 'Radio Button',
      icon: 'radio',
      value: 'RADIO_BUTTON'
    },
    {
      label: 'Date',
      icon: 'calendar',
      value: 'DATE'
    },
    {
      label: 'Time',
      icon: 'time',
      value: 'TIME'
    },
    {
      label: 'Slider',
      icon: 'slider',
      value: 'SLIDER'
    }
  ];

  public supportedInputTypesRecord: Record<string, InputRadioComponentOption> = this.supportedInputTypesOptions.reduce(
    (acc, option) => {
      acc[String(option.value)] = option;
      return acc;
    },
    {} as Record<string, InputRadioComponentOption>
  );

  public radioInputType = InputRadioComponentType;
  public radioInputStyle = InputRadioComponentType;
  public buttonType = ButtonType;
  public buttonActionRole = ActionRole;
  public supportedInputTypes = SupportedInputsTypes;

  /**
   * @constructor
   *
   * @param formBuilder
   */
  constructor(
    private readonly formBuilder: FormBuilder
  ) {
    super();
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.formControl && this.formControl.value && Array.isArray(this.formControl.value)) {
      this.formControl.value.forEach((input: IFormBuilderInput, index: number) => {
        this.createFormBuilderInputForm(index, input);
      });
    }
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['value']) {
      this.formControl.patchValue(changes['value'].currentValue, { emitEvent: true });
    }
  }

  /**
   *
   * @param event
   * @param event.fromIndex
   * @param event.toIndex
   */
  public handleReorderEvent(event: ReorderEvent): void {
    const { fromIndex, toIndex } = event;

    if (fromIndex === toIndex || toIndex < 0) {
      return; // No change needed if indices are the same
    }

    const movedControl = this.formBuilderInputs.at(fromIndex);

    this.formBuilderInputs.removeAt(fromIndex);
    this.formBuilderInputs.insert(toIndex, movedControl);

    this.setControlValue(); // Update the form control value after reordering
  }

  /**
   *
   * @param actionButton
   * @param index
   */
  public handleActionButtonEvent(actionButton: IActionButton, index: number): void {
    switch (actionButton.id) {
      case 'delete':
        this.formBuilderInputs.removeAt(index, { emitEvent: true });
        this.setControlValue();
        break;
      default:
        break;
    }
  }

  /**
   * Reset the input of the form control and the HTML input element.
   */
  public resetInput(): void {
    this.formControl.reset({});
  }

  /**
   * Create and return a form builder input form group.
   *
   * @param index
   * @param input Input details, if provided it will be used to initialize the form controls.
   */
  public createFormBuilderInputForm(index: number, input?: IFormBuilderInput): void {
    const formInputs: Partial<FormBuilderForm> = {
      type: new FormControl<SupportedInputsTypes>(input ? input.type : SupportedInputsTypes.TEXT, { nonNullable: true, validators: [Validators.required] }),
      label: new FormControl<string>(input ? input.label : `Form item ${index + 1}`, { nonNullable: true, validators: [Validators.required] }),
      placement: new FormControl<string>(input ? input.placement : 'FULL', { nonNullable: true, validators: [Validators.required] }),
      required: new FormControl<boolean>(input ? input.required : false, { nonNullable: true, validators: [Validators.required] })
    };

    const formInputGroup = this.formBuilder.group(formInputs as FormBuilderForm);

    this.addConditionalControls(formInputGroup, input);
    this.registerFormInputChange(formInputGroup);

    this.formBuilderInputs.push(formInputGroup);
    this.setControlValue();
  }

  /**
   * Create and return a form builder input form group.
   *
   * @param optionsControl
   * @param option Option details, if provided it will be used to initialize the form controls.
   *
   */
  public addNewOptionInputForm(optionsControl: FormArray<FormGroup<FormInputOptionForm>>, option?: IFormInputOptionInput): void {
    const optionFormInput = this.formBuilder.group<FormInputOptionForm>({
      label: new FormControl<string>(option ? option.label : '', { nonNullable: true, validators: [Validators.required] })
    });

    optionsControl.push(optionFormInput);
    this.setControlValue();
  }

  /**
   *
   * @param optionsControl
   * @param optionIndex
   */
  public handelOptionDelete(optionsControl: FormArray<FormGroup<FormInputOptionForm>>, optionIndex: number): void {
    optionsControl.removeAt(optionIndex);
    this.setControlValue();
  }

  /**
   * Adds or removes conditional controls to the form group based on the input type.
   *
   * @param formInputs - The form group to which conditional controls are added or removed.
   * @param input - The input data used to initialize the form controls, if available.
   */
  private addConditionalControls(formInputs: FormGroup<FormBuilderForm>, input?: IFormBuilderInput): void {
    const type = input?.type || SupportedInputsTypes.TEXT;

    if (type === SupportedInputsTypes.TEXT) {
      formInputs.addControl('isTextArea', new FormControl<boolean>(input?.isTextArea ?? false, { nonNullable: true }));
    } else {
      formInputs.removeControl('isTextArea');
    }

    if ([SupportedInputsTypes.DATE, SupportedInputsTypes.TIME, SupportedInputsTypes.SLIDER].includes(type)) {
      formInputs.addControl('isRange', new FormControl<boolean>(input?.isRange ?? false, { nonNullable: true }));
    } else {
      formInputs.removeControl('isRange');
    }

    if ([SupportedInputsTypes.DATE, SupportedInputsTypes.TIME].includes(type)) {
      formInputs.addControl('rangeStart', new FormControl<Date>(new Date(), { nonNullable: true }));
      formInputs.addControl('rangeEnd', new FormControl<Date>(new Date(), { nonNullable: true }));

      // Apply the combined validator for rangeStart and rangeEnd
      formInputs.setValidators(rangeValidator('rangeStart', 'rangeEnd'));
    } else {
      formInputs.removeControl('rangeStart');
      formInputs.removeControl('rangeEnd');
    }

    if ([SupportedInputsTypes.SELECT, SupportedInputsTypes.CHECKBOX, SupportedInputsTypes.RADIO_BUTTON].includes(type)) {
      const options = new FormArray<FormGroup<FormInputOptionForm>>([]);

      formInputs.addControl('options', options);

      if (input?.options) {
        for (const optionInput of input.options) {
          this.addNewOptionInputForm(options, optionInput);
        }
      }
    } else {
      formInputs.removeControl('options');
    }

    if (type === SupportedInputsTypes.SLIDER) {
      formInputs.addControl('minimum', new FormControl<number>(input?.minimum ?? 0, { nonNullable: true }));
      formInputs.addControl('maximum', new FormControl<number>(input?.maximum ?? 100, { nonNullable: true }));

      // Apply the combined validator for minimum and maximum
      formInputs.setValidators(rangeValidator('minimum', 'maximum'));
    } else {
      formInputs.removeControl('minimum');
      formInputs.removeControl('maximum');
    }
  }

  /**
   * Registers a value change listener on the form type control to add or remove conditional controls.
   *
   * @param formGroup - The form group to register the listener on.
   */
  private registerFormInputChange(formGroup: FormGroup<FormBuilderForm>): void {
    formGroup.controls.type.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      this.addConditionalControls(formGroup, { ...formGroup.value as IFormBuilderInput, type: value });
      this.setControlValue();
    });

    formGroup.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.setControlValue();
    });
  }

  /**
   * Sets the value of the input FormControl based on the formBuilderInputs.
   */
  private setControlValue(): void {
    const isValid = this.formBuilderInputs.controls.every(control => !control.invalid);
    const value = isValid ? this.formBuilderInputs.controls.map(control => control.value) : null;

    this.formControl.patchValue(value, { emitEvent: true });
  }
}
