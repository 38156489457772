<div class="space-y-4">
  <p class="text-sm/[16px] md:text-xs/[14px] text-gray-100">Create a password that:</p>

  <div>
    <div class="flex justify-start">
      <nrc-password-format-validator-status [checked]="isAtLeastSixChars()" />
      <p class="text-sm/[16px] md:text-xs/[14px] pt-1 text-[#adadad] md:text-gray-500 leading-6 tracking-wide">contains at least 6 characters</p>
    </div>

    <div class="flex justify-start">
      <nrc-password-format-validator-status [checked]="isLowerAndUpperCase()" />
      <p class="text-sm/[16px] md:text-xs/[14px] pt-1 text-[#adadad] md:text-gray-500 leading-6 tracking-wide">contains both lower (a-z) and upper case letters (A-Z)</p>
    </div>

    <div class="flex justify-start">
      <nrc-password-format-validator-status [checked]="isNumberAndSymbol()" />
      <p class="text-sm/[16px] md:text-xs/[14px] pt-1 text-[#adadad] md:text-gray-500 leading-6 tracking-wide">contains at least one number (0-9) and a symbol</p>
    </div>
  </div>
</div>
