import { Directive, ElementRef, HostListener, OnInit, inject } from '@angular/core';
import { MathHelper } from '@newroom-connect/library/helpers';

@Directive({
  standalone: true,
  selector: '[nrcGridColsByContainer]'
})
export class GridColsByContainerDirective implements OnInit {
  private readonly el: ElementRef<HTMLDivElement> = inject(ElementRef<HTMLDivElement>);

  /**
   *
   */
  public ngOnInit(): void {
    this.updateGridCols();
  }

  /**
   *
   */
  @HostListener('window:resize')
  public onChange(): void {
    this.updateGridCols();
  }

  /**
   * Update the grid cols of the container based on its width.
   */
  private updateGridCols(): void {
    const gridCols = Math.max(1, MathHelper.exponentOfHighestPowerOfBase(this.el.nativeElement.getBoundingClientRect().width) - 6);

    this.el.nativeElement.style.gridTemplateColumns = `repeat(${gridCols}, minmax(0, 1fr))`;
  }
}
