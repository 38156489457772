import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { Observable, catchError, from, map, of, switchMap } from 'rxjs';
import { Level } from '@newroom-connect/library/enums';
import { AuthService, LoggingService, SessionService, StateService, ToastService, TranslationService } from '@newroom-connect/library/services';
import { UrlBuilderHelper } from '@newroom-connect/library/helpers';

export const LoginGuard: CanActivateFn = (route: ActivatedRouteSnapshot): Observable<boolean> => {
  const router = inject(Router);
  const authService = inject(AuthService);
  const loggingService = inject(LoggingService);
  const sessionService = inject(SessionService);
  const stateService = inject(StateService);
  const toastService = inject(ToastService);
  const translationService = inject(TranslationService);

  return authService.refreshSessionIfNeeded().pipe(
    catchError(error => {
      loggingService.error(error);

      stateService.setLoadingState(false);
      toastService.setMessage({
        message: translationService.translate('ERROR.SESSION_EXPIRED.MESSAGE'),
        level: Level.ERROR
      });

      sessionService.clearUserSession();

      return of();
    }),
    switchMap(() => {
      const userSession = sessionService.getUserSession();

      let isLoggedIn = false;

      let finalUrl = '';

      try {
        isLoggedIn = userSession?.isValid() ?? false;
        finalUrl = UrlBuilderHelper.buildUrlPath(route.data['notLoggedInRoute'], route) ?? '404';
      } catch (error) {
        loggingService.error(error);
      }

      if (isLoggedIn) {
        return of(true);
      }

      return from(router.navigate([finalUrl])).pipe(
        catchError(error => {
          loggingService.error(error);

          return of(false);
        }),
        map(() => false)
      );
    })
  );
};
