export enum ActionRole {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TRANSPARENT_PRIMARY = 'transparent-primary',
  TRANSPARENT_SECONDARY = 'transparent-secondary',
  DISABLED = 'disabled',
  ERROR = 'error',
  WARNING = 'warning',
  SUCCESS = 'success'
}
