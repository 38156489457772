import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2, inject, QueryList, ContentChildren, HostBinding } from '@angular/core';

import { ReOrderableItemDirective } from './reorderable-item.directive';

export type ReorderEvent = { fromIndex: number; toIndex: number };

@Directive({
  selector: '[nrcReorder]',
  standalone: true
})
export class ReorderDirective {
  private static readonly MOVEMENT_THRESHOLD = 0.4;

  @Output() public reorderEvent = new EventEmitter<ReorderEvent>();

  @ContentChildren(ReOrderableItemDirective) public reOrderableItems!: QueryList<ReOrderableItemDirective>;

  @HostBinding('class') public get hostClass(): string {
    return 'relative';
  }

  private readonly elementRef: ElementRef<HTMLElement> = inject(ElementRef<HTMLElement>);
  private readonly renderer = inject(Renderer2);

  private startY = 0;
  private isReOrderable = false;
  private fromIndex = -1;
  private toIndex = -1;
  private lastMovedIndex = -1;

  private selectedElement: HTMLElement | null = null;

  /**
   * @constructor
   */
  constructor() {
    this.onMousedown = this.onMousedown.bind(this);
    this.onMousemove = this.onMousemove.bind(this);
    this.onMouseup = this.onMouseup.bind(this);
  }

  /**
   * Handle mousedown event to start reordering.
   *
   * @param event - Mouse event.
   */
  @HostListener('mousedown', ['$event'])
  public onMousedown(event: MouseEvent): void {
    this.startY = event.clientY;
    this.isReOrderable = true;
    this.lastMovedIndex = -1;

    const selectedIndex = this.findSelectedIndex(event.clientY);

    if (selectedIndex !== -1) {
      this.initializeDrag(selectedIndex);
    }
  }

  /**
   * Find the index of clicked element.
   *
   * @param clientY - Mouse Y position.
   *
   * @returns Selected element index.
   */
  private findSelectedIndex(clientY: number): number {
    return this.reOrderableItems.toArray().findIndex((item) => {
      const elementRef = item.elementRef.nativeElement;
      const boundingRect = elementRef.getBoundingClientRect();

      return clientY > boundingRect.top && clientY < boundingRect.bottom;
    });
  }

  /**
   * Initialize drag state.
   *
   * @param selectedIndex - Index of selected item.
   */
  private initializeDrag(selectedIndex: number): void {
    this.selectedElement = this.reOrderableItems.get(selectedIndex)?.elementRef.nativeElement ?? null;

    if (this.selectedElement) {
      this.fromIndex = selectedIndex;
      this.toIndex = selectedIndex;
      this.renderer.addClass(this.selectedElement, 'dragging');
    }

    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
  }

  /**
   * Handle mousemove event during drag.
   *
   * @param event - Mouse event.
   */
  public onMousemove(event: MouseEvent): void {
    if (!this.isReOrderable || !this.selectedElement) {
      return;
    }

    const deltaY = event.clientY - this.startY;

    this.updateElementPosition(deltaY);
    this.checkAndUpdateItemPositions(event);
  }

  /**
   * Update dragged element position.
   *
   * @param deltaY - Change in Y position.
   */
  private updateElementPosition(deltaY: number): void {
    if (!this.selectedElement) {
      return;
    }

    this.renderer.setStyle(this.selectedElement, 'transform', `translateY(${deltaY}px)`);
  }

  /**
   * Check and update positions of other items.
   *
   * @param event
   */
  private checkAndUpdateItemPositions(event: MouseEvent): void {
    if (!this.selectedElement) {
      return;
    }

    const reOrderableItemsArray = this.reOrderableItems.toArray();
    let updatedToIndex = this.fromIndex; // Start with the original index

    for (let i = 0; i < reOrderableItemsArray.length; i++) {
      if (i === this.fromIndex) {
        continue;
      }

      this.toIndex = Math.max(0, Math.min(i, reOrderableItemsArray.length - 1));

      const item = reOrderableItemsArray[i];
      const siblingElementRef = item.elementRef.nativeElement;
      const siblingElementRect = siblingElementRef.getBoundingClientRect();

      if (this.shouldMoveItem(event.clientY, siblingElementRect, this.toIndex)) {
        updatedToIndex = i; // Update the `toIndex` if movement is detected

        // Apply transition styles to the moving elements
        if (i > this.fromIndex && i !== this.lastMovedIndex) {
          this.renderer.addClass(siblingElementRef, 'animate-move-up');
        } else if (i < this.fromIndex && i !== this.lastMovedIndex) {
          this.renderer.addClass(siblingElementRef, 'animate-move-down');
        }

        break;
      } else {
        this.renderer.removeClass(siblingElementRef, 'animate-move-down');
        this.renderer.removeClass(siblingElementRef, 'animate-move-up');
      }
    }

    this.toIndex = updatedToIndex;
  }

  /**
   * Determine if item should move based on threshold crossing.
   * @param currentY
   * @param targetRect
   * @param targetIndex
   * @returns
   */
  private shouldMoveItem(currentY: number, targetRect: DOMRect, targetIndex: number): boolean {
    const threshold = targetRect.height * ReorderDirective.MOVEMENT_THRESHOLD;

    const moveDown = targetIndex > this.fromIndex && currentY > targetRect.top + threshold;

    const moveUp = targetIndex < this.fromIndex && currentY < targetRect.bottom - threshold;

    return moveDown || moveUp;
  }

  /**
   *
   * @param element
   */
  private resetItemPosition(element: HTMLElement): void {
    this.renderer.removeClass(element, 'animate-move-down');
    this.renderer.removeClass(element, 'animate-move-up');
  }

  /**
   * Handle mouseup event.
   */
  public onMouseup(): void {
    if (!this.selectedElement || !this.isReOrderable) {
      return;
    }

    this.cleanupDrag();

    // Emit the event only if the item has actually moved
    if (this.fromIndex !== this.toIndex) {
      this.reorderEvent.emit({ fromIndex: this.fromIndex, toIndex: this.toIndex });
    }

    this.resetState();
  }

  /**
   * Cleanup after drag.
   */
  private cleanupDrag(): void {
    if (!this.selectedElement) {
      return;
    }

    this.renderer.removeStyle(this.selectedElement, 'transform');
    this.renderer.removeClass(this.selectedElement, 'dragging');

    document.removeEventListener('mousemove', this.onMousemove);
    document.removeEventListener('mouseup', this.onMouseup);

    const reOrderableItemsArray = this.reOrderableItems.toArray();

    // Remove transition styles from all elements
    reOrderableItemsArray.forEach(item => {
      this.resetItemPosition(item.elementRef.nativeElement);
    });

    this.isReOrderable = false;
  }

  /**
   *
   */
  private resetState(): void {
    this.isReOrderable = false;
    this.selectedElement = null;
    this.lastMovedIndex = -1;
    this.fromIndex = -1;
    this.toIndex = -1;
  }
}
