import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { GridColsByContainerDirective } from '@newroom-connect/library/directives';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IActionButton, IActionButtonEvent, IListConfiguration, IListItem, IListItemClickedEvent, IListOptionsSort, ListItemType } from '@newroom-connect/library/interfaces';
import { ExtractPropertiesPipe, ExtractPropertyPipe, JoinPipe, ToStringPipe } from '@newroom-connect/library/pipes';

import { BaseListComponent } from '../abstract/base-list.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { CheckboxComponent } from '../../checkbox/checkbox.component';

import { GridListItemComponent } from './grid-list-item/grid-list-item.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CheckboxComponent,
    GridListItemComponent,
    GridColsByContainerDirective,
    ExtractPropertiesPipe,
    ExtractPropertyPipe,
    JoinPipe,
    ToStringPipe
  ],
  selector: 'nrc-grid-list',
  templateUrl: './grid-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListComponent extends BaseListComponent {
  @Input({ required: true }) public config!: IListConfiguration;

  @Output() public sortEvent = new EventEmitter<IListOptionsSort>();
  @Output() public gridItemClickedEvent = new EventEmitter<IListItemClickedEvent>();
  @Output() public itemActionButtonEvent = new EventEmitter<IActionButtonEvent>();

  public listItemType = ListItemType;
  public actionRole = ActionRole;
  public buttonType = ButtonType;
  public isItemSelectionActive = false;

  /**
   *
   */
  public toggleItemSelection(): void {
    this.isItemSelectionActive = !this.isItemSelectionActive;

    if (!this.isItemSelectionActive) {
      this.items.update(items => {
        if (items) {
          items.forEach(item => item.isSelected = false);
        }

        return items ? [...items] : null;
      });
    }

    this.itemSelectionToggleEvent.emit(this.isItemSelectionActive);
  }

  /**
   * Handle the event when a grid item is clicked.
   * Only handle the grid item, when the item selection has been activated.
   *
   * @param item The grid item that was clicked.
   * @param gridIndex The index of the clicked item inside the grid.
   */
  public handleGridItemClickedEvent(item: IListItem, gridIndex: number): void {
    const items = this.items();

    if (!items) {
      return;
    }

    // Toggle the selection state of the grid items based on the selection active.
    if (this.isItemSelectionActive) {
      this.toggleItemIsSelectedAtIndex(gridIndex);
    } else {
      for (let i = 0; i < items.length; i++) {
        this.items.update(items => {
          if (!items) {
            return null;
          }

          items[i].isSelected = gridIndex === i ? !items[i].isSelected : false;

          return [...items];
        });
      }
    }

    this.gridItemClickedEvent.emit({ index: gridIndex, item });
  }

  /**
   *
   * @param event
   * @param event.index
   * @param event.actionButton
   */
  public handleItemAction(event: { index: number, actionButton: IActionButton }): void {
    const item = this.items()?.[event.index];

    if (item) {
      this.itemActionButtonEvent.emit({ value: item, actionButton: event.actionButton });
    }
  }
}
