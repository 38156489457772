import { Directive, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, debounce, distinctUntilChanged, takeUntil, timer } from 'rxjs';

export enum DelayEvent {
  MouseEnter = 'mouseenter',
  MouseLeave = 'mouseleave',
}

@Directive({
  standalone: true,
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nrcHoverDelay]'
})
export class HoverDelayDirective implements OnInit, OnDestroy {
  @Input() public debounceTimeMouseEnter = 0; // Default debounce time for "mouseenter" events in milliseconds.
  @Input() public debounceTimeMouseLeave = 0; // Default debounce time for "mouseleave" events in milliseconds.
  @Input() public targetId!: string; // ID of the element to apply the delay to.

  private delay$ = new Subject<DelayEvent>();
  private destroy$ = new Subject<void>();

  /**
   *
   */
  public ngOnInit(): void {
    this.delay$.pipe(
      debounce(delayEvent => timer(delayEvent === DelayEvent.MouseEnter ? this.debounceTimeMouseEnter : this.debounceTimeMouseLeave)),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(delayEvent => {
      const targetMenuElement = document.getElementById(this.targetId);

      if (!targetMenuElement) {
        return;
      }

      const toggleFrom = delayEvent === DelayEvent.MouseEnter ? 'hidden': 'visible';
      const toggleTo = delayEvent === DelayEvent.MouseEnter ? 'visible': 'hidden';

      if (targetMenuElement.classList.contains(toggleFrom)) {
        targetMenuElement.classList.toggle(toggleTo);
        targetMenuElement.classList.toggle(toggleFrom);
      }
    });
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   *
   */
  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.delay$.next(DelayEvent.MouseEnter);
  }

  /**
   *
   */
  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.delay$.next(DelayEvent.MouseLeave);
  }
}
