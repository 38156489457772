<div class="flex flex-col items-center space-y-2">
  <svg class="flex justify-center items-center" xmlns="http://www.w3.org/2000/svg" [style.width]="(radius * 2) + 'px'" [style.height]="(radius * 2) + 'px'" >
    <style>
      text {
        font: normal normal normal 34px/40px Public Sans;
        letter-spacing: 0px;
        fill: #FEFEFE;
      }

      tspan {
        font: normal normal normal 34px/40px Public Sans;
        fill: #FEFEFE;
      }
    </style>

    <!-- Background Circle -->
    <circle
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadiusSig()"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke]="gradientColor.strokeColor"
      fill="transparent"
    ></circle>

    <circle
      #progressRingCircle
      [attr.cx]="radius"
      [attr.cy]="radius"
      [attr.r]="innerRadiusSig()"
      [attr.stroke-width]="strokeWidth"
      [attr.stroke-dasharray]="strokeDasharray()"
      [attr.stroke-dashoffset]="strokeDashoffset()"
      class="progress-ring-circle"
      stroke="url(#gradientColor)"
      fill="transparent"
      stroke-linecap="round"
    ></circle>

    <!-- Text and Label -->
    @if (label) {
      <text class="font-light text-[2.5rem] text-[#fefefe] " text-anchor="middle" x="50%" y="50%">
        {{ progress }}%
        <tspan
          dy="1.2em"
          x="50%"
          y="50%"
          class="font-light text-2xl text-[#fefefe]"
        >{{ label }}</tspan>
      </text>
    }

    <linearGradient id="gradientColor" x1="0%" y1="0%" x2="100%" y2="0%">
      <stop offset="0%" [style.stop-color]="gradientColor.startColor" [style.stop-opacity]="gradientColor.startOpacity" />
      <stop offset="100%" [style.stop-color]="gradientColor.endColor" [style.stop-opacity]="gradientColor.endOpacity" />
    </linearGradient>
  </svg>

  @if (subline) {
    <span class="text-white/50 text-md">{{ subline }}</span>
  }
</div>
