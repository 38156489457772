import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, WritableSignal, forwardRef, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR, Validators } from '@angular/forms';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';

export type InputPasswordType = 'password' | 'text';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'nrc-input-password',
  templateUrl: './input-password.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPasswordComponent),
      multi: true
    }
  ]
})
export class InputPasswordComponent extends InputComponent<string> implements OnInit {
  @Input() public passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).{6,}$/;
  @Input() public invalidHint?: string;

  public showPassword = false;
  public inputType: WritableSignal<InputPasswordType> = signal<InputPasswordType>('password');

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    const validators = this.formControl.validator
      ? [this.formControl.validator, Validators.pattern(this.passwordRegExp)]
      : [Validators.pattern(this.passwordRegExp)]
    ;

    this.formControl.setValidators(validators);
  }

  /**
   *
   */
  public toggleShowPassword(): void {
    this.showPassword = !this.showPassword;

    this.inputType.set(this.showPassword ? 'text' : 'password');
  }
}
