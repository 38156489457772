import { Observable, from } from 'rxjs';

export class InputHelper {
  /**
   * Copy text input to the system clipboard.
   *
   * @param input The input to copy to the system clipboard.
   *
   * @returns Observable that emits the result of copying the input to the system clipboard.
   */
  public static copyToClipboard(input: string): Observable<void> {
    return from(navigator.clipboard.writeText(input));
  }
}
