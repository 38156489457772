import { trigger, transition, style, animate, state } from '@angular/animations';

export const collapseAnimation = trigger('collapseAnimation', [
  state('open', style({
    height: '*'
  })),
  state('closed', style({
    height: '0',
    overflow: 'hidden'
  })),
  transition('open <=> closed', [
    animate('0.25s ease-in-out')
  ])
]);
