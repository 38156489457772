import { Injectable, OnDestroy } from '@angular/core';
import { Observable, combineLatest, map, forkJoin, of, switchMap, Subject, takeUntil, ReplaySubject } from 'rxjs';
import { IArea, IAreaElement } from '@newroom-connect/library/interfaces';
import { FileHelper } from '@newroom-connect/library/helpers';

import { AreaService } from '../area/area.service';
import { LoggingService } from '../logging/logging.service';
import { WebsocketService } from '../websocket/websocket.service';

import { AreaCacheService } from './area-cache.service';
import { AreaElementCacheService } from './area-element-cache.service';
import { PreloadingStatus } from './abstract-cache.service';

/**
 * Configuration options for the CacheService.
 */
export interface CacheServiceConfig {
  /**
   * The base URL for API requests.
   */
  apiBaseUrl: string;
}

// Add a new interface for the area memory cache entry
interface AreaCacheEntry {
  area: IArea;
  timestamp: number;
  expiresAt: number;
}

/**
 * Main cache service that acts as a registry for specialized cache services.
 * Provides a unified interface for caching different types of entities.
 */
@Injectable({
  providedIn: 'root'
})
export class CacheService implements OnDestroy {
  public readonly preloadingStatus$: Observable<PreloadingStatus>;
  private readonly destroy$ = new Subject<void>();
  private readonly config$ = new ReplaySubject<CacheServiceConfig>(1);

  private currentConfig: CacheServiceConfig | null = null;

  // Add a memory cache map to store recently fetched areas
  private areaMemoryCache = new Map<string, AreaCacheEntry>();
  // Cache expiration time in milliseconds (default: 5 minutes)
  private readonly AREA_CACHE_EXPIRATION = 5 * 60 * 1000;

  /**
   * @constructor
   *
   * @param areaCacheService
   * @param areaElementCacheService
   * @param areaService
   * @param websocketService
   * @param logger
   */
  constructor(
    private readonly areaCacheService: AreaCacheService,
    private readonly areaElementCacheService: AreaElementCacheService,
    private readonly areaService: AreaService,
    private readonly websocketService: WebsocketService,
    private readonly logger: LoggingService
  ) {
    // Create a combined observable for preloading status
    this.preloadingStatus$ = combineLatest([
      this.areaCacheService.preloadingStatus$,
      this.areaElementCacheService.preloadingStatus$
    ]).pipe(
      map(([areaStats, elementStats]) => ({
        total: areaStats.total + elementStats.total,
        loaded: areaStats.loaded + elementStats.loaded,
        progress: this.calculateCombinedProgress(areaStats, elementStats)
      }))
    );

    // Initialize WebSocket listeners
    this.initWebSocketListeners();

    // Listen for configuration changes
    this.config$.pipe(takeUntil(this.destroy$)).subscribe(config => {
      this.currentConfig = config;
      this.logger.debug('Cache service configured with API base URL:', config.apiBaseUrl);
    });
  }

  /**
   * Configure the cache service with the provided options.
   *
   * @param config The configuration options.
   */
  public configure(config: CacheServiceConfig): void {
    this.config$.next(config);
  }

  /**
   * Initialize WebSocket listeners for cache invalidation.
   */
  private initWebSocketListeners(): void {
    // Listen for area update events
    this.websocketService.watchTopic<{ id: string; projectId: string }>('area.update')
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        // Extract the data payload from the WebSocket message
        this.handleAreaUpdate(message.data);
      });

    // Listen for area element update events
    this.websocketService.watchTopic<{ id: string; areaId: string; projectId: string }>('area-element.update')
      .pipe(takeUntil(this.destroy$))
      .subscribe(message => {
        // Extract the data payload from the WebSocket message
        this.handleAreaElementUpdate(message.data);
      });

    this.logger.debug('WebSocket listeners initialized for cache invalidation');
  }

  /**
   * Handle area update events from WebSocket.
   *
   * @param data The WebSocket event data.
   * @param data.id
   * @param data.projectId
   */
  private handleAreaUpdate(data: { id: string; projectId: string }): void {
    if (!data || !data.id || !data.projectId) {
      this.logger.warn('Received invalid area update event data:', data);
      return;
    }

    this.logger.debug(`Received area update for area ID: ${data.id}`);

    // Fetch the updated area data
    this.areaService.getArea(data.projectId, data.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe(updatedArea => {
        if (!updatedArea) {
          this.logger.warn(`Could not fetch updated area with ID: ${data.id}`);
          return;
        }

        // Get the API base URL from configuration or fallback
        const apiBaseUrl = this.getApiBaseUrl();

        if (!apiBaseUrl) {
          this.logger.warn('Could not determine API base URL for cache update');
          return;
        }

        // Ensure the area cache in AreaService is updated
        this.areaService.updateCachedArea(updatedArea);

        // Update the area in file cache with priority
        this.preloadArea(updatedArea, apiBaseUrl, true)
          .subscribe(success => {
            this.logger.debug(`Area cache updated via WebSocket: ${success}`);
          });
      });
  }

  /**
   * Handle area element update events from WebSocket.
   *
   * @param data The WebSocket event data.
   * @param data.id
   * @param data.areaId
   * @param data.projectId
   */
  private handleAreaElementUpdate(data: { id: string; areaId: string; projectId: string }): void {
    if (!data || !data.id || !data.areaId || !data.projectId) {
      this.logger.warn('Received invalid area element update event data:', data);
      return;
    }

    this.logger.debug(`Received area element update for element ID: ${data.id}`);

    // Get the area containing the updated element
    this.areaService.getArea(data.projectId, data.areaId)
      .pipe(
        takeUntil(this.destroy$),
        switchMap(area => {
          if (!area) {
            this.logger.warn(`Could not fetch area containing updated element: ${data.id}`);
            return of(null);
          }

          // Find the updated element
          const updatedElement = area.areaElements.find(element => element.id === data.id);

          if (!updatedElement) {
            this.logger.warn(`Could not find updated element with ID: ${data.id}`);
            return of(null);
          }

          return of({ area, element: updatedElement });
        })
      )
      .subscribe(result => {
        if (!result) {
          return;
        }

        // Get the API base URL from configuration or fallback
        const apiBaseUrl = this.getApiBaseUrl();

        if (!apiBaseUrl) {
          this.logger.warn('Could not determine API base URL for cache update');
          return;
        }

        // Update the element in cache with priority
        this.preloadAreaElement(result.element, apiBaseUrl, true)
          .subscribe(success => {
            this.logger.debug(`Area element cache updated via WebSocket: ${success}`);
          });
      });
  }

  /**
   * Get the API base URL from the current configuration or fallback to a default.
   *
   * @returns The API base URL if available, null otherwise.
   */
  private getApiBaseUrl(): string | null {
    if (!this.currentConfig || !this.currentConfig.apiBaseUrl) {
      return null;
    }

    return this.currentConfig.apiBaseUrl;
  }

  /**
   * Cleanup resources on component destruction.
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Preloads all resources for an area, including linked area backgrounds.
   *
   * @param area The area containing resources to preload.
   * @param apiBaseUrl The base URL for API requests.
   * @param isPriority Whether these resources should be prioritized.
   * @param preloadLinkedAreas Whether to preload backgrounds of linked areas.
   *
   * @returns Observable that completes when all resources are loaded.
   */
  public preloadArea(
    area: IArea,
    apiBaseUrl: string,
    isPriority = false,
    preloadLinkedAreas = true
  ): Observable<boolean> {
    // First, preload the area's own resources
    const itemsToPreload = this.areaCacheService.prepareItemsForPreload(area, apiBaseUrl);
    const areaPreloadObs = this.areaCacheService.preloadItems(itemsToPreload, isPriority);

    // If we don't need to preload linked areas, return the original observable
    if (!preloadLinkedAreas) {
      return areaPreloadObs;
    }

    // Preload linked area backgrounds with high priority
    return areaPreloadObs.pipe(
      switchMap(() => this.preloadLinkedAreaBackgrounds(area, apiBaseUrl))
    );
  }

  /**
   * Preloads backgrounds of areas that are linked via move area elements.
   *
   * @param area The source area containing move area elements.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Observable that completes when all linked area backgrounds are loaded.
   */
  public preloadLinkedAreaBackgrounds(area: IArea, apiBaseUrl: string): Observable<boolean> {
    const projectId = area.projectId;
    const moveAreaIds = this.areaCacheService.extractMoveAreaTargetIds(area);

    if (!moveAreaIds.length) {
      return of(true);
    }

    this.logger.debug(`Found ${moveAreaIds.length} linked areas to preload backgrounds for`);

    // Clean expired entries from memory cache
    this.cleanExpiredAreaCacheEntries();

    // Get each linked area and preload its background
    const preloadObservables = moveAreaIds.map(areaId => {
      // Create a cache key for this area
      const cacheKey = `${projectId}:${areaId}`;

      // Check if we have this area cached in memory
      const cachedArea = this.areaMemoryCache.get(cacheKey);

      if (cachedArea) {
        this.logger.debug(`Using cached area for ${areaId} (${cachedArea.area.slug})`);
        // Use the cached area
        return this.preloadAreaBackgroundsOnly(cachedArea.area, apiBaseUrl);
      }

      // Not cached, fetch from API
      return this.areaService.getArea(projectId, areaId).pipe(
        switchMap(linkedArea => {
          if (!linkedArea) {
            return of(false);
          }

          // Cache the fetched area
          this.cacheAreaInMemory(cacheKey, linkedArea);

          return this.preloadAreaBackgroundsOnly(linkedArea, apiBaseUrl);
        })
      );
    });

    // If there are no preload observables, return success
    if (!preloadObservables.length) {
      return of(true);
    }

    // Combine all preload operations
    return forkJoin(preloadObservables).pipe(
      map(results => results.every(result => result))
    );
  }

  /**
   * Helper method to preload only the background files for an area.
   *
   * @param area The area containing background files to preload.
   * @param apiBaseUrl The base URL for API requests.
   * @returns Observable that completes when backgrounds are loaded.
   */
  private preloadAreaBackgroundsOnly(area: IArea, apiBaseUrl: string): Observable<boolean> {
    // Only preload the background files with highest priority
    const backgroundItems = this.extractBackgroundFilesOnly(area, apiBaseUrl);

    if (backgroundItems.length) {
      this.logger.debug(`Preloading ${backgroundItems.length} background files for linked area: ${area.slug}`);
      return this.areaCacheService.preloadItems(backgroundItems, true);
    }

    return of(true);
  }

  /**
   * Store an area in the memory cache with expiration.
   *
   * @param cacheKey The cache key (projectId:areaId).
   * @param area The area to cache.
   */
  private cacheAreaInMemory(cacheKey: string, area: IArea): void {
    const now = Date.now();

    this.areaMemoryCache.set(cacheKey, {
      area,
      timestamp: now,
      expiresAt: now + this.AREA_CACHE_EXPIRATION
    });
    this.logger.debug(`Cached area ${area.slug} (${area.id}) in memory cache`);
  }

  /**
   * Remove expired entries from the area memory cache.
   */
  private cleanExpiredAreaCacheEntries(): void {
    const now = Date.now();
    let expiredCount = 0;

    this.areaMemoryCache.forEach((entry, key) => {
      if (entry.expiresAt < now) {
        this.areaMemoryCache.delete(key);
        expiredCount++;
      }
    });

    if (expiredCount > 0) {
      this.logger.debug(`Removed ${expiredCount} expired area cache entries`);
    }
  }

  /**
   * Extracts only the background files from an area for preloading.
   *
   * @param area The area to extract background files from.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Array of background file URLs with high priority.
   */
  private extractBackgroundFilesOnly(
    area: IArea,
    apiBaseUrl: string
  ): { url: string; priority: 'high' | 'normal' }[] {
    if (!area || !area.translations) {
      return [];
    }

    const backgroundItems: { url: string; priority: 'high' | 'normal' }[] = [];
    const processedUrls = new Set<string>();

    for (const translation of area.translations) {
      if (!translation.backgroundFile) {
        continue;
      }

      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.backgroundFile);

      if (!url || processedUrls.has(url)) {
        continue;
      }

      processedUrls.add(url);
      backgroundItems.push({ url, priority: 'high' });
    }

    return backgroundItems;
  }

  /**
   * Preloads all resources for an area element.
   *
   * @param areaElement The area element containing resources to preload.
   * @param apiBaseUrl The base URL for API requests.
   * @param isPriority Whether these resources should be prioritized.
   *
   * @returns Observable that completes when all resources are loaded.
   */
  public preloadAreaElement(
    areaElement: IAreaElement,
    apiBaseUrl: string,
    isPriority = false
  ): Observable<boolean> {
    const itemsToPreload = this.areaElementCacheService.prepareItemsForPreload(areaElement, apiBaseUrl);

    return this.areaElementCacheService.preloadItems(itemsToPreload, isPriority);
  }

  /**
   * Updates cached area resources when the backend data has changed.
   *
   * @param area The updated area.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Observable that completes when all resources are updated.
   */
  public updateAreaCache(area: IArea, apiBaseUrl: string): Observable<boolean> {
    return this.preloadArea(area, apiBaseUrl, true);
  }

  /**
   * Updates cached area element resources when the backend data has changed.
   *
   * @param areaElement The updated area element.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Observable that completes when all resources are updated.
   */
  public updateAreaElementCache(
    areaElement: IAreaElement,
    apiBaseUrl: string
  ): Observable<boolean> {
    return this.preloadAreaElement(areaElement, apiBaseUrl, true);
  }

  /**
   * Gets statistics about the area memory cache.
   *
   * @returns Object with cache stats.
   */
  public getAreaMemoryCacheStats(): { size: number, entries: Array<{ key: string, area: IArea, age: number }> } {
    const now = Date.now();
    const entries = Array.from(this.areaMemoryCache.entries()).map(([key, entry]) => ({
      key,
      area: entry.area,
      age: Math.round((now - entry.timestamp) / 1000) // Age in seconds
    }));

    return {
      size: this.areaMemoryCache.size,
      entries
    };
  }

  /**
   * Clears the in-memory area cache.
   *
   * @returns Number of entries cleared.
   */
  public clearAreaMemoryCache(): number {
    const size = this.areaMemoryCache.size;

    this.areaMemoryCache.clear();
    this.logger.debug(`Cleared area memory cache (${size} entries)`);
    return size;
  }

  /**
   * Clears all cached resources.
   *
   * @returns Boolean indicating success.
   */
  public clearCache(): boolean {
    this.clearAreaMemoryCache();

    // Clear the AreaService's cache too
    this.areaService.clearAreaCaches();

    return this.areaCacheService.clearCache() && this.areaElementCacheService.clearCache();
  }

  /**
   * Cancels all pending preload operations.
   *
   * @returns Boolean indicating success.
   */
  public cancelPendingOperations(): boolean {
    return this.areaCacheService.cancelPendingOperations() && this.areaElementCacheService.cancelPendingOperations();
  }

  /**
   * Checks if a specific resource is already loaded in any cache.
   *
   * @param url URL of the resource to check.
   *
   * @returns Boolean indicating if the resource is loaded.
   */
  public isResourceLoaded(url: string): boolean {
    return this.areaCacheService.isItemLoaded(url) || this.areaElementCacheService.isItemLoaded(url);
  }

  /**
   * Returns the current preloading statistics from all cache services.
   * Combines statistics from all specialized services.
   *
   * @returns Object containing combined preloading statistics.
   */
  public getPreloadingStats(): PreloadingStatus {
    const areaStats = this.areaCacheService.getPreloadingStats();
    const elementStats = this.areaElementCacheService.getPreloadingStats();

    return {
      total: areaStats.total + elementStats.total,
      loaded: areaStats.loaded + elementStats.loaded,
      progress: this.calculateCombinedProgress(areaStats, elementStats)
    };
  }

  /**
   * Calculates combined progress from multiple cache services.
   *
   * @param stats Array of preloading status objects.
   * @returns Combined progress percentage.
   */
  private calculateCombinedProgress(...stats: PreloadingStatus[]): number {
    let totalItems = 0;
    let loadedItems = 0;

    for (const stat of stats) {
      totalItems += stat.total;
      loadedItems += stat.loaded;
    }

    return totalItems > 0 ? Math.round((loadedItems / totalItems) * 100) : 100;
  }

  /**
   * Preloads specific items with given URLs and priorities.
   * Utility method used for fast background preloading during navigation.
   *
   * @param items Array of URL and priority pairs to preload.
   * @param isPriority Whether these items should be prioritized.
   * @returns Observable that completes when all items are loaded.
   */
  public preloadItems(
    items: { url: string; priority: 'high' | 'normal' }[],
    isPriority = false
  ): Observable<boolean> {
    // We'll use the area cache service for this as it's optimized for images
    return this.areaCacheService.preloadItems(items, isPriority);
  }
}
