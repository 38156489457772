<div
  tabindex="-1"
  class="relative w-full h-full"
  nrcDoubleClick
  (doubleClickEvent)="handleAreaElementDblClick()"
  (click)="handleAreaElementClick($event)"
  (keyup)="null"
>
  <div
    class="group relative w-full h-full flex justify-center items-center hover:cursor-pointer"
    tabindex="-1"
    [style.backgroundColor]="areaElement.baseColor"
    [style.border-radius]="areaElement.radius + 'px'"
    (keyup)="null"
  >
    @if (!areaElement.baseColor) {
      <nrc-media-preview
        class="absolute w-full h-full top-0 left-0 overflow-hidden z-10 object-cover"
        [ngClass]="{
          'bg-white/5': !backgroundFile()
        }"
        [style.border-radius]="areaElement.radius + 'px'"
        [style.opacity]="backgroundFileOpacity()"
        [mimetype]="backgroundFile() ? backgroundFile()!.mimetype : 'image/png'"
        [objectFit]="'fill'"
        [source]="backgroundFile() ? apiBaseUrl + '/projects/' + backgroundFile()!.projectId + '/files/' + backgroundFile()!.id + '/stream' : undefined"
        [mediaPreviewTypes]="['image', 'video-snippet']"
      />
    }

    @if (areaElement.icon) {
      <!-- Icon size should be proportional to the area element size  -->
      <nrc-icon
        class="flex justify-start h-1/3 w-auto z-20"
        [name]="areaElement.icon"
        [fillColorHex]="areaElement.iconColor ?? '#ffffff'"
      />
    }

    @if (tooltipText()) {
      <nrc-tooltip
        class="tooltip tooltip-group"
        [text]="tooltipText()!"
      />
    }

    <!-- Display icon in the upper-right corner of the area element, if it is locked. -->
    @if (areaElement.isLocked) {
      <nrc-icon
        class="absolute top-2 right-2 w-3 h-3 max-w-full max-h-full z-20"
        [name]="'lock-closed'"
      />
    }
  </div>

  <!-- Drag points for resizing the area element -->
  @if (isSelected() && isResizable && !areaElement.isLocked) {
    <div class="absolute top-0 left-0 bg-white w-2 h-2 rounded-full z-20 hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 left-0 bg-white w-2 h-2 rounded-full z-20 hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute top-0 right-0 bg-white w-2 h-2 rounded-full z-20 hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 right-0 bg-white w-2 h-2 rounded-full z-20 hover:cursor-grab active:cursor-grabbing"></div>
  }
</div>
