import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { collapseAnimation } from '@newroom-connect/library/animations';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IsNonEmptyArrayPipe } from '@newroom-connect/library/pipes';
import { IActionButton } from '@newroom-connect/library/interfaces';

import { ButtonComponent } from '../buttons/button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconComponent,
    IsNonEmptyArrayPipe
  ],
  selector: 'nrc-collapsible',
  templateUrl: './collapsible.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation]
})
export class CollapsibleComponent {
  @Input() public icon?: string;
  @Input() public label?: string;
  @Input() public isCollapsed?: boolean;
  @Input() public disabled?: boolean;
  @Input() public actionButtons: IActionButton[] = [];

  @Output() public actionButtonClicked = new EventEmitter<IActionButton>();

  @Output() public collapsedEvent = new EventEmitter<boolean>();

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  public handleToggleCollapse(): void {
    this.isCollapsed = !this.isCollapsed;

    this.collapsedEvent.emit(this.isCollapsed);
  }
}
