import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionRole } from '@newroom-connect/library/enums';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../../../buttons/button/button.component';

@Component({
  selector: 'nrc-file-library-modal-footer',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    TranslatePipe
  ],
  templateUrl: './file-library-modal-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileLibraryModalFooterComponent {
  @Input() public showFileLibrary?: boolean;
  @Input() public hideSelection?: boolean;

  @Output() public backEvent = new EventEmitter<void>();
  @Output() public closeEvent = new EventEmitter<void>();

  public actionRole = ActionRole;
}
