import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { LoggingService } from '@newroom-connect/library/services';

import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';

@Component({
  selector: 'nrc-pdf-viewer',
  standalone: true,
  imports: [CommonModule, LoadingSpinnerComponent],
  templateUrl: './pdf-viewer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfViewerComponent implements OnChanges {
  @Input() public src?: string;
  @Input() public showBorders = false;

  @Output() public loaded = new EventEmitter<void>();
  @Output() public errorOccurred = new EventEmitter<any>();

  public isPdfLoading = true;
  public pdfError: string | null = null;
  public safeUrl: SafeResourceUrl | null = null;

  constructor(
    private readonly logger: LoggingService,
    private readonly sanitizer: DomSanitizer
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (!changes['src'] || !this.src) {
      return;
    }

    this.loadPdf();
  }

  /**
   * Loads the PDF from the source URL.
   */
  private loadPdf(): void {
    this.isPdfLoading = true;
    this.pdfError = null;

    if (!this.src) {
      this.pdfError = 'No PDF source provided';
      this.isPdfLoading = false;
      this.errorOccurred.emit(new Error('No PDF source provided'));
      return;
    }

    try {
      // Add parameters to hide toolbar and thumbnails
      const urlWithParams = `${this.src + (this.src.includes('?') ? '&' : '?')}#toolbar=0&navpanes=0&view=FitH`;

      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(urlWithParams);

      this.isPdfLoading = false;
      this.loaded.emit();
    } catch (error) {
      this.handleError(error);
    }
  }

  /**
   * Handles PDF loading completion.
   */
  public onPdfLoaded(): void {
    this.isPdfLoading = false;
    this.loaded.emit();
  }

  /**
   * Handles PDF loading errors.
   *
   * @param error Error from the PDF embed.
   */
  public handleError(error: any): void {
    this.logger.error('PDF loading error:', error);
    this.pdfError = error?.message || 'Failed to load PDF document';
    this.isPdfLoading = false;
    this.errorOccurred.emit(error);
  }
}
