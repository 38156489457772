<div class="group w-full h-full flex flex-col justify-center items-stretch">
  <nrc-icon
    class="w-5 h-5"
    [ngClass]="{
      'opacity-50': isDisabled,
      'peer': tooltipText
    }"
    [name]="icon"
    [fillColor]="fillColor"
  />

  <nrc-tooltip
    class="tooltip tooltip-group"
    [text]="tooltipText"
  />
</div>
