import { inject, Injectable } from '@angular/core';
import { filter, firstValueFrom } from 'rxjs';
import { ISystemMessage } from '@newroom-connect/library/interfaces';
import { TranslationService } from '@newroom-connect/library/services';

import { ModalService } from '../../modal/modal.component';
import { UnsavedChangesAlertComponent } from '../unsaved-changes-alert.component';

@Injectable({
  providedIn: 'root'
})
export class UnsavedChangesService {
  private readonly translationService = inject(TranslationService);
  private readonly modalService = inject(ModalService);

  /**
   *
   * @param message
   * @returns
   */
  public async confirmUnsavedChanges(message: ISystemMessage): Promise<boolean> {
    const modalId = `unsaved-changes-${Date.now()}`;

    // Open the modal
    this.modalService.open({
      component: UnsavedChangesAlertComponent,
      inputs: {
        id: modalId,
        alertMessage: message
      }
    });

    // Wait for the modal to close and get the result
    const result = await firstValueFrom(
      this.modalService.modalClosed$.pipe(
        filter(event => event.id === modalId)
      )
    );

    return result.result ?? false;
  }
}
