<div class="absolute top-8 left-8">
  <div class="flex items-center gap-4">
    <nrc-icon
      class="w-12 h-12"
      [name]="'translate-circle'"
      [fillColor]="'cyan'"
    />
    <span class="font-light text-4xl tracking-wide">
      {{ translationModalParams().modalTitle }}
    </span>
  </div>
</div>

<p class="font-light text-md text-[#ADADAD] pt-10">
  {{ translationModalParams().modalDescription }}
</p>

<form [formGroup]="autoTranslateFormGroup" #overflowParentElement class="flex flex-col space-y-4 my-3">
  <div class="h-[3rem]">
    <nrc-input-language-selector
      [options]="sourceLanguageCodes"
      [formControl]="autoTranslateFormGroup.controls.from"
      [type]="inputLanguageSelectorComponentType.LABELED"
      [overflowElement]="overflowParentElement"
      [label]="'From'"
      (languageChanged)="autoTranslateFormGroup.controls.from.setValue($event)"
    />
  </div>

  <div class="h-[3rem]">
    <nrc-input-language-selector
      [options]="targetLanguageCodes"
      [formControl]="autoTranslateFormGroup.controls.to"
      [type]="inputLanguageSelectorComponentType.LABELED"
      [overflowElement]="overflowParentElement"
      [label]="'To'"
      (languageChanged)="autoTranslateFormGroup.controls.to.setValue($event)"
    />
  </div>

  @if (translationModalParams().modalFieldsDescription.length > 0) {
    <span [innerHTML]="translationModalParams().modalFieldsDescription"></span>
  }
</form>

<hr class="w-full h-[2px] mt-3 bg-[#2d2d2d] border-none">

<div class="flex flex-row justify-between w-full mt-3 mb-1">
  <nrc-button
    [label]="'Cancel'"
    [buttonType]="buttonType.DEFAULT"
    [role]="actionRole.SECONDARY"
    [fontWeight]="'bold'"
    (clickEvent)="closeEvent.emit()"
  />

  <nrc-button
    [label]="'Translate'"
    [buttonType]="buttonType.DEFAULT"
    [role]="actionRole.PRIMARY"
    [fontWeight]="'bold'"
    [disabled]="autoTranslateFormGroup.invalid"
    (clickEvent)="handleAutoTranslateEvent()"
  />
</div>
