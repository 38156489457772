import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { IsNonEmptyArrayPipe } from '@newroom-connect/library/pipes';
import { FormValidatorHelper } from '@newroom-connect/library/helpers';
import { ActionRole, Level } from '@newroom-connect/library/enums';

import { IconComponent, IconFillColor } from '../icon/icon.component';
import { NoteComponentInput, NoteComponent } from '../note/note.component';
import { ButtonComponent } from '../buttons/button/button.component';
import { InputTextComponent } from '../inputs/input-text/input-text.component';

export type AlertMessage = {
  message: string;
  headline?: string;
  footer?: string;
  level?: Level;
  icon?: string;
};

export type AlertListItem = {
  label: string;
  href?: string;
  hrefLabel?: string;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    NoteComponent,
    ButtonComponent,
    InputTextComponent,
    IsNonEmptyArrayPipe
  ],
  selector: 'nrc-alert',
  templateUrl: './alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent implements OnInit {
  @Input() public message?: AlertMessage | null;
  @Input() public actionButton?: { label: string; };
  @Input() public confirmButton?: { label: string; };
  @Input() public confirmationInput?: string;
  @Input() public itemsList?: AlertListItem[];
  @Input() public blurBackground? = true;
  @Input() public confirmButtonRole?: ActionRole;

  // Name of a custom icon which can be set.
  // If not specified, a default icon will be used based on the given message level.
  @Input() public icon?: string;

  // A custom fill color of an icon, if an icon is provided.
  @Input() public iconFillColor: IconFillColor = 'white';

  @Input() public note?: NoteComponentInput;

  @Output() public actionEvent = new EventEmitter<unknown>();
  @Output() public confirmEvent = new EventEmitter<unknown>();

  public level = Level;

  public confirmationForm = new FormGroup({
    confirmationInput: new FormControl('')
  });

  /**
   *
   */
  public ngOnInit(): void {
    // In case the alert is for confirming an input, add required and match string validators.
    if (this.confirmationInput) {
      this.confirmationForm.controls.confirmationInput.setValidators(
        [Validators.required, FormValidatorHelper.matchesString(this.confirmationInput)]
      );
    }

    // If no icon was set, set it from the given message based on the message level.
    // If the message contains a custom icon, use this.
    if (!this.icon) {
      if (this.message?.icon) {
        this.icon = this.message.icon;
      } else {
        this.icon = this.getIconNameFromMessageLevel();
      }
    }
  }

  /**
   *
   * @param event
   */
  @HostListener('document:keyup.escape', ['$event'])
  public onKeyupEscapeHandler(event: KeyboardEvent): void {
    if (this.actionButton) {
      event.stopPropagation();
      this.actionEvent.emit();
    }
  }

  /**
   *
   * @param event
   */
  @HostListener('document:keyup.enter', ['$event'])
  public onKeyupEnterHandler(event: KeyboardEvent): void {
    if (this.confirmButton && !this.confirmationForm.invalid) {
      event.stopPropagation();
      this.confirmEvent.emit();
    }
  }

  /**
   *
   * @returns
   */
  public getIconNameFromMessageLevel(): string {
    let iconName = 'info';

    if (!this.message?.level) {
      return iconName;
    }

    switch (this.message.level) {
      case Level.ERROR:
      case Level.WARN:
        iconName = 'exclamation-mark';
        break;
      case Level.SUCCESS:
        iconName = 'check';
        break;
      default:
        break;
    }

    return iconName;
  }
}
