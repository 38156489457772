<div
  class="w-full h-full flex"
  [ngClass]="{
    'justify-end': !showFileLibrary || hideSelection,
    'justify-between': showFileLibrary && !hideSelection
  }"
>
  @if (showFileLibrary && !hideSelection) {
    <nrc-button
      class="relative w-24"
      [label]="'COMPONENTS.FILES.FILE_LIBRARY_MODAL.FOOTER.BUTTONS.BACK' | translate"
      [role]="actionRole.TRANSPARENT_PRIMARY"
      (clickEvent)="backEvent.emit()"
    />
  }

  <nrc-button
    class="relative w-24"
    [label]="(showFileLibrary ? 'COMPONENTS.FILES.FILE_LIBRARY_MODAL.FOOTER.BUTTONS.CHOOSE' : 'COMPONENTS.FILES.FILE_LIBRARY_MODAL.FOOTER.BUTTONS.DONE') | translate"
    (clickEvent)="closeEvent.emit()"
  />
</div>
