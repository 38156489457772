import { Injectable, inject } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IListOptions, IAPIListResponse } from '@newroom-connect/library/interfaces';

import { ApiService } from '../api/api.service';
import { WebsocketService } from '../websocket/websocket.service';

@Injectable({
  providedIn: 'root'
})
export abstract class EntityService {
  protected static baseUrl = '';

  protected readonly apiService = inject(ApiService);
  protected readonly websocketService = inject(WebsocketService);

  // Define a list of search properties the derived service is able to search for.
  // This is used to build the filters for a list request to the API.
  protected abstract searchableProperties: string[];

  /**
   * Setter for the base URL used in the service for all API calls.
   *
   * @param baseUrl The base URL to set.
   */
  public static setBaseUrl(baseUrl: string): void {
    EntityService.baseUrl = baseUrl;
  }

  /**
   *
   * @param path
   * @param headers
   * @param options
   *
   * @returns
   */
  protected list<EntityT>(path: string, headers?: HttpHeaders, options?: IListOptions): Observable<IAPIListResponse<EntityT>> {
    if (options?.search && typeof options.search === 'string' && options.search.length > 0) {
      if (!options) {
        options = {};
      }

      if (!options.filters) {
        options.filters = {};
      }

      options.filters = { ...options.filters, ...this.buildSearchFilters(options.search ?? '') };

      // Delete the search option from the options, since we use `filters` for the API request.
      delete options.search;
    }

    return this.apiService.list<IAPIListResponse<EntityT>>(path, undefined, options);
  }

  /**
   * Build the search filters from the given search term and the searchable properties defined in the derived service.
   * For search filters, each property will be searched for with the "contains" filter rule in an overall "OR" statement.
   *
   * If the current searchable property ends with "[]", we treat this as a "many"-relation and some of the items should match the condition.
   *
   * @param searchTerm The search term to build the search filters for.
   *
   * @returns The build filters based on the search term.
   *
   * @example `searchableProperties` = ['mimetype', 'translations[].title'], `searchTerm` = 'jpg'
   *  -> Search for all items containing 'jpg' in the `mimetype` OR in the `title` from at least one `translation` of the item.
   */
  protected buildSearchFilters(searchTerm: string): Record<string, any> {
    const filters: Record<string, any> = { OR: [] };

    for (const searchableProperty of this.searchableProperties) {
      const propertyParts = searchableProperty.split('.');

      const filterPart: Record<string, any> = {};

      let filterPartRef = filterPart;

      for (let i = 0; i < propertyParts.length; i++) {
        const propertyPartCleaned = propertyParts[i].endsWith('[]') ? propertyParts[i].substring(0, propertyParts[i].length - 2) : propertyParts[i];

        filterPartRef[propertyPartCleaned] = {};

        filterPartRef = filterPartRef[propertyPartCleaned];

        if (propertyParts[i].endsWith('[]')) {
          filterPartRef['some'] = {};

          filterPartRef = filterPartRef['some'];
        }
      }

      filterPartRef['contains'] = searchTerm;
      filterPartRef['mode'] = 'insensitive';

      filters['OR'].push(filterPart);
    }

    return filters;
  }
}
