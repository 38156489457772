import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActionRole } from '@newroom-connect/library/enums';
import { FillColorPipe, ColorType } from '@newroom-connect/library/pipes';

export type IconFillColor = ColorType;
export type IconStrokeColor = ColorType;

@Component({
  standalone: true,
  imports: [CommonModule, FillColorPipe],
  selector: 'nrc-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('iconContainer') public iconContainerRef?: ElementRef<HTMLDivElement>;

  @Input({ required: true }) public name!: string;
  @Input() public fillColor?: IconFillColor;
  @Input() public strokeColor?: IconStrokeColor;
  @Input() public fillColorHex?: string;
  @Input() public strokeColorHex?: string;
  @Input() public classList?: string[];

  @Output() public clickEvent = new EventEmitter<void>();

  public actionRole = ActionRole;
  // Generate a unique ID for the icon to be used as a clip path ID.
  public uniqueId = Math.random().toString(36).substring(2, 15);

  private iconContainerChanges!: MutationObserver;

  /**
   * @constructor
   *
   * @param renderer
   */
  constructor(
    private readonly renderer: Renderer2
  ) {}

  /**
   *
   */
  public ngAfterViewInit(): void {
    this.registerIconContainerChangesObserver();

    this.fillIconColor();
    this.strokeIconColor();
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.iconContainerChanges.disconnect();
  }

  /**
   *
   */
  public ngOnChanges(): void {
    this.fillIconColor();
    this.strokeIconColor();
  }

  /**
   * Fill the icon color of the icon inside the container.
   */
  private fillIconColor(): void {
    if (this.iconContainerRef && this.fillColorHex) {
      for (let i = 0; i < this.iconContainerRef.nativeElement.children.length; i++) {
        const svgElement = this.iconContainerRef.nativeElement.children[i];

        if (svgElement.tagName === 'svg') {
          this.renderer.setStyle(svgElement, 'fill', this.fillColorHex);
        }
      }
    }
  }

  private strokeIconColor(): void {
    if (this.iconContainerRef && this.strokeColorHex) {
      for (let i = 0; i < this.iconContainerRef.nativeElement.children.length; i++) {
        const svgElement = this.iconContainerRef.nativeElement.children[i];

        if (svgElement.tagName === 'svg') {
          // Find all path elements within the SVG
          const pathElements = svgElement.getElementsByTagName('path');

          for (let j = 0; j < pathElements.length; j++) {
            this.renderer.setStyle(pathElements[j], 'stroke', this.strokeColorHex);
          }
        }
      }
    }
  }

  /**
   * Register a mutation observer to the icon container to watch for changes to the SVG child elements.
   * If they changed, apply the current fill color HEX value as CSS style `fill` attribute to the SVG child elements.
   */
  private registerIconContainerChangesObserver(): void {
    if (this.iconContainerRef) {
      this.iconContainerChanges = new MutationObserver((mutations: MutationRecord[]) => mutations.forEach((mutation: MutationRecord) => {
        if (mutation.addedNodes.length > 0) {
          const svgElement = mutation.addedNodes[0] as SVGElement;

          if (svgElement.tagName === 'svg') {
            if (this.fillColorHex) {
              this.renderer.setStyle(svgElement, 'fill', this.fillColorHex);
            }

            if (this.strokeColorHex) {
              const pathElements = svgElement.getElementsByTagName('path');

              for (let j = 0; j < pathElements.length; j++) {
                this.renderer.setStyle(pathElements[j], 'stroke', this.strokeColorHex);
              }
            }
          }
        }
      }));

      this.iconContainerChanges.observe(this.iconContainerRef.nativeElement, { childList: true });
    }
  }
}
