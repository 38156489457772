export enum ScriptStatus {
  LOADED = 'Loaded',
  ERROR = 'Error',
}

export interface IScript {
  src: string;
  attributes?: Record<string, string>;
}

export interface ILoadScriptResult {
  loaded: boolean;
  status: ScriptStatus;
}

// `HTMLScriptElement` with IE-specific extensions.
export interface HTMLScriptElementIE extends HTMLScriptElement {
  readyState?: string;
  onreadystatechange?: () => void;
}
