import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { IScript } from '@newroom-connect/library/interfaces';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { UsercentricsCookies, UsercentricsEvent } from '@newroom-connect/library/enums';

import { ProjectService } from '../project';
import { LoggingService } from '../logging/logging.service';
import { CookiesService } from '../cookies/cookies.service';
import { ScriptService } from '../script/script.service';

// Since analytics settings often use camelCase, we disable the ESLint rule for this service.
/* eslint-disable camelcase */

interface IAnalyticsSettings {
  anonymize_ip: boolean;
  [key: string]: unknown;
}

// Interface for Google Analytics gtag function
interface Gtag {
  (command: 'config', targetId: string, config: object): void;
  (command: 'event', action: string, params: object): void;
  (command: 'js', date: Date): void;
}

// Declare gtag function for Google Analytics.
declare let gtag: Gtag;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private static readonly GOOGLE_ANALYTICS_PRESET_ID = '4a0b59f4-ccc7-4d24-a85f-de6f1f29f128';

  private initialized = false;
  private analyticsSettings?: IAnalyticsSettings;
  private googleAnalyticsKeyId?: string;

  /**
   *
   * @param router
   * @param projectService
   * @param cookiesService
   * @param scriptService
   * @param logger
   */
  constructor(
    private router: Router,
    private projectService: ProjectService,
    private cookiesService: CookiesService,
    private scriptService: ScriptService,
    private logger: LoggingService
  ) {
    this.setupRouterEventListener();
    this.setupCookieChangeListener();
  }

  /**
   * Set a new page for analytics tracking.
   *
   * @param path
   */
  public async setNewPage(path: string): Promise<void> {
    const isInitialized = await this.initialize();

    if (!isInitialized) {
      return;
    }

    if (!this.googleAnalyticsKeyId) {
      this.logger.error('AnalyticsService: No tracking ID');

      return;
    }

    gtag('config', this.googleAnalyticsKeyId, {
      ...this.analyticsSettings,
      page_title: 'newroom connect SaaS',
      page_path: path,
      page_location: document.location.href
    });
  }

  /**
   * Add a new google analytics event.
   *
   * @param eventName
   * @param eventCategory
   * @param eventLabel
   */
  public async addEvent(eventName: string, eventCategory?: string, eventLabel?: string): Promise<void> {
    const isInitialized = await this.initialize();

    if (!isInitialized) {
      return;
    }

    gtag('event', eventName, {
      ...this.analyticsSettings,
      event_label: eventLabel,
      event_category: eventCategory
    });
  }

  /**
   * Function to set the analytics settings in the projects where this is used.
   *
   * @param settings
   */
  public setAnalyticsSettings(settings: IAnalyticsSettings): void {
    this.analyticsSettings = settings;
  }

  /**
   * Initialize and configure analytics with the project's provider and ID.
   * @returns If initialization was successful or not.
   */
  private async initialize(): Promise<boolean> {
    if (this.initialized) {
      return true;
    }

    if (!this.isAnalyticsAllowed()) {
      return false;
    }

    this.logger.debug('AnalyticsService: Initializing analytics service');

    this.googleAnalyticsKeyId = this.determineGoogleAnalyticsKeyId();

    if (!this.googleAnalyticsKeyId) {
      this.logger.debug('AnalyticsService: No Google Analytics Key ID found, will not use Google Analytics');
      return false;
    }

    await this.loadGoogleAnalyticsScript();

    this.initializeGoogleAnalytics();

    this.logger.debug('AnalyticsService: Initialized analytics service');
    return true;
  }

  /**
   * Check if analytics is allowed based on cookie choice and project configuration.
   *
   * @returns
   */
  private isAnalyticsAllowed(): boolean {
    const cookieChoice = this.cookiesService.getChoice(UsercentricsCookies.GOOGLE_ANALYTICS);

    if (!cookieChoice) {
      this.logger.debug('AnalyticsService: Cookie choice denies Analytics, will not use Google Analytics', cookieChoice);
      return false;
    }

    const currentSignInConfig = this.projectService.getCurrentSignInConfig();

    if (!currentSignInConfig || !currentSignInConfig.analytics.collectData) {
      this.logger.debug('AnalyticsService: Project does not have enabled Google Analytics, will not use Google Analytics');
      return false;
    }

    return true;
  }

  /**
   *
   */
  private async loadGoogleAnalyticsScript(): Promise<void> {
    const trackingScript: IScript = {
      src: `https://www.googletagmanager.com/gtag/js?id=${this.googleAnalyticsKeyId}`,
      attributes: {
        type: 'text/javascript'
      }
    };

    await this.scriptService.loadScript(trackingScript);
  }

  /**
   *
   */
  private initializeGoogleAnalytics(): void {
    if (!this.googleAnalyticsKeyId) {
      return;
    }

    // Set up Google Analytics dataLayer.
    (window as any).dataLayer = (window as any).dataLayer || [];

    this.initialized = true;

    gtag('js', new Date());
    gtag('config', this.googleAnalyticsKeyId, {
      send_page_view: false,
      ...this.analyticsSettings
    });
  }

  /**
   * Set up listener for router events to track page changes.
   */
  private setupRouterEventListener(): void {
    this.router.events
      .pipe(filter((event): event is NavigationEnd => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.logger.debug('AnalyticsService: Page changed', event);

        this.setNewPage(event.url);
      });
  }

  /**
   * Set up listener for cookie changes.
   */
  private setupCookieChangeListener(): void {
    this.cookiesService.subscribe({
      next: (data) => {
        if (data.event === UsercentricsEvent.CONSENT_STATUS_CHANGE) {
          this.handleCookieChoiceChange();
        }
      }
    });
  }

  /**
   * Handle changes in cookie choices.
   */
  private handleCookieChoiceChange(): void {
    const analyticsAllowed = this.cookiesService.getChoice(UsercentricsCookies.GOOGLE_ANALYTICS);

    if (!analyticsAllowed) {
      this.initialized = false;

      return;
    }

    this.logger.debug('AnalyticsService: "Google Analytics" cookie choice changed, sending page view', analyticsAllowed);
    this.setNewPage(window.location.pathname);
  }

  /**
   * Determine and return Google Analytics key id from the project analytics providers list if it is enabled.
   *
   * @returns Google Analytics key id if enabled or undefined if not enabled.
   */
  private determineGoogleAnalyticsKeyId(): string | undefined {
    const currentSignInConfig = this.projectService.getCurrentSignInConfig();

    if (!currentSignInConfig) {
      return undefined;
    }

    const providers = currentSignInConfig.analytics.providers;

    if (!ArrayHelper.isNotEmpty(providers)) {
      return undefined;
    }

    if (!ArrayHelper.isNotEmpty(providers)) {
      return;
    }

    const googleAnalyticsProvider = providers.find(provider => provider.preset.id === AnalyticsService.GOOGLE_ANALYTICS_PRESET_ID && provider.enabled);

    return googleAnalyticsProvider?.analyticsKeyId;
  }
}
