<div
  class="w-full h-full group relative flex flex-col justify-between items-center p-2 hover:bg-white/10 transition-opacity"
  nrcHoverDelay
  [debounceTimeMouseLeave]="100"
  [targetId]="'contextMenuContainer-' + uniqueHash"
  [ngClass]="{
    'opacity-50 hover:cursor-default': disabled,
    'hover:cursor-pointer': !disabled && !contextMenu,
    'hover:cursor-default': contextMenu
  }"
  tabindex="0"
  [attr.disabled]="this.disabled"
  (click)="handleClickEvent()"
  (keyup)="null"
>

  <nrc-icon
    class="inline-block w-full"
    [ngClass]="{
      'h-full': !label,
      'h-1/2': label
    }"
    [name]="icon"
  />

  @if (label) {
    <span class="text-xs text-center text-[#898989] font-light flex justify-center items-center">
      {{ label }}
    </span>
  }

  @if (tooltipText) {
    <nrc-tooltip
      class="absolute z-20 w-16 min-h-[20px] mt-2 -ml-8 left-1/2 opacity-0 group-hover:opacity-100 scale-0 group-hover:scale-100 transition-opacity transition-transform"
      [ngClass]="{
        '-top-full': tooltipTextTopside,
        'top-full': !tooltipTextTopside
      }"
      [tooltipTextTopside]="tooltipTextTopside"
      [text]="tooltipText"
    />
  }

  @if (contextMenu && !disabled) {
    <div
      class="hidden bg-transparent-dark rounded-lg absolute z-20 -translate-y-28 transition duration-250 ease-in-out delay-250"
      [id]="'contextMenuContainer-' + uniqueHash"
    >
      <div class="grid grid-cols-1 divide-y divide-[#2d2d2d] w-40 justify-center items-start">
        @for (actionButton of contextMenu; track actionButton.id) {
          <div class="flex flex-row justify-between items-center py-2 px-4 cursor-pointer">
            <nrc-action-button-menu-item
              class="flex justify-between w-full"
              [icon]="actionButton?.icon"
              [label]="actionButton?.label"
              [role]="actionButton?.role"
              (click)="handleContextMenuClickEvent($event, actionButton)"
            />
          </div>
        }
      </div>
    </div>
  }
</div>
