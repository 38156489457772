import { Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Pipe({
  standalone: true,
  name: 'isChildRouteActive',
  pure: false
})
export class IsChildRouteActivePipe implements PipeTransform {
  /**
   * @constructor
   *
   * @param route
   */
  constructor(
    private readonly route: ActivatedRoute
  ) {}

  /**
   *
   * @param path
   * @param outlet
   *
   * @returns
   */
  public transform(path: string, outlet?: string): unknown {
    return this.route.children.some(
      childRoute => (!outlet || outlet === childRoute.outlet) && childRoute.routeConfig?.path === path
    );
  }
}
