import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders as AngularHttpHeaders,
  HttpParams as AngularHttpParams,
  HttpEvent,
  HttpEventType
} from '@angular/common/http';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { IListOptions } from '@newroom-connect/library/interfaces';
import { ApiError } from '@newroom-connect/library/errors';

import { LoggingService } from '../logging/logging.service';

export class HttpHeaders extends AngularHttpHeaders {}
export class HttpParams extends AngularHttpParams {}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  protected baseUrl = '';

  protected defaultHeaders: HttpHeaders;

  /**
   * @constructor
   *
   * @param http
   * @param loggingService
   */
  constructor(
    protected readonly http: HttpClient,
    protected readonly loggingService: LoggingService
  ) {
    this.defaultHeaders = new HttpHeaders();
    this.defaultHeaders.set('Content-Type', 'application/json');
  }

  /**
   * Handle HTTP error response.
   *
   * @param error The HTTP error to handle.
   *
   * @returns The handled HTTP error.
   */
  public static handleHttpError(error: HttpErrorResponse): ApiError {
    let errorMessage = '';

    if (error.error) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `HttpService: Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    return new ApiError(errorMessage, error.name, error.status);
  }

  /**
   * Transform the given HTTP headers to a record of type `<string, any>`.
   *
   * @param headers
   *
   * @returns
   */
  private static headersToRecord(headers?: HttpHeaders): Record<string, any> {
    const record: Record<string, any> = {};

    if (!headers) {
      return record;
    }

    for (const key of headers.keys()) {
      const value = headers.get(key);

      if (value) {
        record[key] = value;
      }
    }

    return record;
  }

  /**
   *
   * @param options
   *
   * @returns
   */
  private static buildParamsFromListOptions(options: IListOptions): HttpParams {
    let params = new HttpParams();

    if (options && Object.keys(options).length > 0) {
      for (const optionKey of Object.keys(options)) {
        if (options[optionKey as keyof IListOptions]) {
          params = params.append(optionKey, JSON.stringify(options[optionKey as keyof IListOptions]));
        }
      }
    }

    return params;
  }

  /**
   * Setter for the base URL used in the service for all API calls.
   *
   * @param baseUrl The base URL to set.
   */
  public setBaseUrl(baseUrl: string): void {
    this.baseUrl = baseUrl;
  }

  /**
   *
   * @param path
   * @param headers
   * @param options
   *
   * @returns
   */
  public list<T = any>(
    path: string,
    headers?: HttpHeaders,
    options?: IListOptions
  ): Observable<T> {
    if (!headers) {
      headers = new HttpHeaders();
    }

    const url = this.buildRequestUrl(path);

    return this.buildRequest<T>(this.http.get<T>(url, {
      headers: this.getRequestHeaders(headers),
      params: options ? ApiService.buildParamsFromListOptions(options) : undefined
    }));
  }

  /**
   *
   * @param path
   * @param headers
   * @param params
   *
   * @returns
   */
  public get<T>(path: string, headers?: HttpHeaders, params?: Record<string, any>): Observable<T> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform GET request to ${url}...`);

    return this.buildRequest<T>(this.http.get<T>(url, {
      headers: this.getRequestHeaders(headers),
      params
    }));
  }

  /**
   *
   * @param path
   * @param payload
   * @param options
   *
   * @returns
   */
  public post<T>(path: string, payload?: any, options?: any): Observable<T> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform POST request to ${url}...`);

    return this.buildRequest<T>(this.http.post<T>(url, payload, options) as Observable<T>);
  }

  /**
   *
   * @param path
   * @param payload
   *
   * @returns
   */
  public postWithReportedProgress<T>(path: string, payload?: any): Observable<HttpEvent<T>> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform POST request to ${url} with reported progress...`);

    return this.buildRequest<HttpEvent<T>>(this.http.post<T>(url, payload, {
      reportProgress: true,
      observe: 'events'
    }));
  }

  /**
   *
   * @param path
   * @param payload
   *
   * @returns
   */
  public put<T>(path: string, payload?: any): Observable<T> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform PUT request to ${url}...`);

    return this.buildRequest<T>(this.http.put<T>(url, payload));
  }

  /**
   *
   * @param path
   * @param payload
   *
   * @returns
   */
  public patch<T>(path: string, payload?: any): Observable<T> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform PATCH request to ${url}...`);

    return this.buildRequest<T>(this.http.patch<T>(url, payload));
  }

  /**
   *
   * @param path
   *
   * @returns
   */
  public delete<T>(path: string): Observable<T> {
    const url = this.buildRequestUrl(path);

    this.loggingService.debug(`HttpService: Perform DELETE request to ${url}...`);

    return this.buildRequest<T>(this.http.delete<T>(url));
  }

  /**
   * Build the request observable by appending custom error handling strategy.
   *
   * @param request$ The request to build the new request observable from.
   *
   * @returns The new request observable.
   */
  protected buildRequest<T>(request$: Observable<T>): Observable<T> {
    return request$.pipe(
      catchError(error => {
        this.loggingService.error('Request failed: ', error);
        return throwError(() => ApiService.handleHttpError(error as HttpErrorResponse));
      }),
      tap(response => {
        if (!((response as HttpEvent<any>).type === HttpEventType.DownloadProgress) && !(response instanceof Error)) {
          this.loggingService.debug('Got response from the API: ', response, ((response as HttpEvent<any>).type === HttpEventType.DownloadProgress));
        }
      })
    );
  }

  /**
   * Build the request URL where the base URL and the given path are combined.
   *
   * @param path
   *
   * @returns
   */
  private buildRequestUrl(path: string): string {
    return `${this.baseUrl}/${path}`;
  }

  /**
   * Returns a copy of the general request headers merged with the specified headers.
   *
   * @param headers Specific headers to merge.
   *
   * @returns Merged HTTP headers of general and specified headers.
   */
  private getRequestHeaders(headers: HttpHeaders | undefined): HttpHeaders {
    return new HttpHeaders({ ...ApiService.headersToRecord(this.defaultHeaders), ...ApiService.headersToRecord(headers) });
  }
}
