import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  standalone: true,
  selector: 'nrc-password-format-validator-status',
  templateUrl: './password-format-validator-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormatValidatorStatusComponent {
  @Input() public checked = false;
}
