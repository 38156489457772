@if (!config['visibilityFn'] || config['visibilityFn'](config.value)) {
  @switch (config.type) {
    @case (listItemType.TEXT) {
      <div class="flex items-center justify-start w-full space-x-4">
        @if (config.thumbnailConfig && config.thumbnailConfig.backgroundColorClasses && !(config.thumbnailSource | toString)) {
          <div
            class="flex items-center justify-center h-12 rounded-full basis-12 shrink-0 grow-0"
            [ngClass]="config.thumbnailConfig.backgroundColorClasses ? (config.thumbnailConfig.backgroundColorClasses | drawItem:(index ?? 0) | toString) : 'bg-cyan'"
          >
            <span class="uppercase">
              {{ config.value | toString | firstCharOfWords }}
            </span>
          </div>
        }

        @if (config['mimetype'] && config['thumbnailSource']) {
          <div class="relative flex items-center justify-center w-14 h-14">
            <nrc-media-preview
              class="top-0 left-0 w-full h-full overflow-hidden rounded-md"
              [mimetype]="config['mimetype']"
              [source]="config['thumbnailSource'] | toString"
              [mediaPreviewTypes]="['image']"
              [classList]="'bg-[#fefefe1a] shadow-[0px 6px 14px #00000033] border-2 border-solid border-white/10 rounded-10'"
            />
          </div>
        }

        <div class="flex flex-col overflow-auto max-w-fit">
          <span class="overflow-hidden text-base tracking-wide text-ellipsis whitespace-nowrap"
            [ngClass]="{
              'text-[#898989] font-light': !config.isHighlighted,
              'text-[#fafafa] font-semibold': config.isHighlighted && !isSelected,
              'text-cyan font-semibold': config.isHighlighted && isSelected,
            }"
          >{{ config.value }}</span>

          @if (config.additionalInfo) {
            <span class="text-[#898989] text-base font-light tracking-wide">
              {{ config.additionalInfo | toString }}
            </span>
          }
        </div>
      </div>
    }
    @case (listItemType.FILE_SIZE) {
      <div
        class="flex items-center justify-start w-full"
        [ngClass]="{
          'text-[#898989] font-light': !config.isHighlighted,
          'text-[#fafafa] font-semibold': config.isHighlighted && !isSelected,
          'text-cyan font-semibold': config.isHighlighted && isSelected,
        }"
      >
        <span class="uppercase">
          {{ config.value | toNumber | toFileSize }}
        </span>
      </div>
    }
    @case (listItemType.DATE) {
      @if (config.userData) {
        <div class="p-2">
          <nrc-user-detail-tooltip
            [firstName]="config.userData.firstName"
            [lastName]="config.userData.lastName"
            [profileColor]="config.userData.profileColor"
            [userRoleName]="config.userData.roleName"
          />
        </div>
      }

      <div
        class="flex items-center justify-start w-full space-x-2"
        [ngClass]="{
          'text-[#898989] font-light': !config.isHighlighted,
          'text-[#fafafa] font-semibold': config.isHighlighted && !isSelected,
          'text-cyan font-semibold': config.isHighlighted && isSelected,
        }"
      >
        <span class="uppercase text-nowrap">
          {{ config.value | toString | date: 'yyyy-MM-dd hh:mm' }}
        </span>
      </div>
    }
    @case (listItemType.CHIP) {
      <div class="flex flex-row justify-start space-x-2">
        <nrc-chip [label]="config.value" [config]="{ actionRole: config['actionRole'] }" />

        @if (config.additionalInfo) {
          <span class="text-[#898989] text-base font-light tracking-wide">
            {{ config.additionalInfo | toString }}
          </span>
        }
      </div>
    }
    @case (listItemType.ICON) {
      <nrc-icon
        class="block w-5 h-5"
        [name]="config['extractorFn'] ? (config['extractorFn'] | evaluate:config.value | toString) : (config.value | toString)"
      />
    }
    @case (listItemType.BUTTON) {
      <nrc-button
        class="relative flex w-9 h-9"
        [icon]="config['extractorFn'] ? config['extractorFn'](config.value) : (config.value | toString)"
        [buttonType]="buttonType.ICON"
        [role]="actionRole.TRANSPARENT_SECONDARY"
        [disabled]="config['disabled']"
        [tooltipText]="config['tooltipTextFn'] ? config['tooltipTextFn'](config.value) : config['tooltipText']"
        (clickEvent)="rowListItemClickedEvent.emit()"
      />
    }
    @default {
      <div>Unable to display item. Type not supported.</div>
    }
  }
}
