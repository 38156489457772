<form
  class="flex flex-col w-full p-2 space-y-2 rounded-lg bg-white/5"
  [ngClass]="{
    'opacity-50 select-none pointer-events-none': formControl.disabled
  }"
  [formGroup]="colorOpacityFormGroup"
>
  <div class="flex items-start justify-between max-w-full">
    <div class="flex space-x-2">
      <input
        #colorInput
        class="w-10 h-10 bg-transparent border-none rounded-md outline-none hover:cursor-pointer"
        type="color"
        formControlName="color"
        [id]="id"
        [name]="name"
      >

      <div class="grow flex flex-col space-y-0.5">
        @if (label) {
          <span class="text-xxs text-[#adadad] font-light tracking-wide">
            {{ label }}
          </span>
        }

        <input
          class="p-0.5 w-full bg-transparent border-none outline-none focus:ring-cyan hover:ring-cyan hover:ring-1 text-sm text-neutral-100 font-light tracking-wider"
          [ngClass]="{
            'text-error' : formControl.touched && formControl.invalid
          }"
          type="text"
          formControlName="color"
          [value]="colorOpacityFormGroup.controls.color.value"
          (change)="onColorChange()"
        />
      </div>
    </div>

    <nrc-button
      class="relative flex w-7 h-7"
      [icon]="'pipette'"
      [buttonType]="buttonType.ICON"
      [role]="actionRole.TRANSPARENT_SECONDARY"
      (clickEvent)="openColorPicker()"
    />
  </div>

  <div class="flex flex-col gap-1 pt-4">
    <span class="text-xxs text-[#adadad] font-light tracking-wide">
      {{ 'COMPONENTS.SHARED.INPUTS.COLOR.OPACITY.LABEL' | translate }}
    </span>

    <nrc-input-range
      [formControl]="colorOpacityFormGroup.controls.opacity"
      [min]="0"
      [max]="100"
      [label]="'%'"
    />
  </div>
</form>
