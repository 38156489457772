<div class="relative w-full h-full min-h-[40px] flex flex-col space-y-1">
  <!-- The input element itself -->
  <div
    #selectInputElement
    class="h-full flex flex-col justify-center items-stretch cursor-pointer"
    [ngClass]="{
      'rounded-[100px]': isRound,
      'bg-neutral-900 shadow': !isTransparent,
      'bg-transparent' : isTransparent,
      'border-b-1 border-b-gray-700 rounded-t': !isRound && !isTransparent
    }"
    tabindex="0"
    aria-hidden="true"
    (click)="showOptions = !showOptions"
  >
    @if (label) {
      <span
        class="ml-4 mt-0.5 text-xxs"
        [ngClass]="{
          'text-error' : formControl.touched && formControl.invalid,
          'text-cyan': showOptions && !(formControl.touched && formControl.invalid),
          'text-gray-400': !showOptions && !(formControl.touched && formControl.invalid)
        }"
      >{{ label }}</span>
    }

    <div
      class="flex justify-between items-center"
      [ngClass]="{
        'pb-1.5': !isRound,
        'px-4': !fitContent,
      }"
    >
      <input
        #inputElement
        type="text"
        class="w-1 h-5 text-sm bg-transparent border-transparent focus:border-transparent outline-none focus:outline-none p-0 placeholder:text-white focus:ring-0"
        [ngClass]="{
          'w-1': fitContent && !(selectedOptions | toArray | isNonEmptyArray),
          'w-full': !fitContent || (selectedOptions | toArray | isNonEmptyArray)
        }"
        [formControl]="searchInputFormControl"
        [name]="name + '-search-input'"
        [id]="id + '-search-input'"
        [autocomplete]="name + '-search-input'"
        [placeholder]="(selectedOptions | toArray | isNonEmptyArray) && !multiple ? ((selectedOptions | toArray)[0] | extractProperty: labelProperty) : placeholder"
      />

      <nrc-icon
        class="w-4 h-4"
        [ngClass]="{
          'pb-2': !isRound && !isTransparent,
          'pb-1': isTransparent
        }"
        [name]="'select'"
        [classList]="showOptions ? ['transition-transform', 'rotate-180'] : ['transition-transform']"
      />
    </div>
  </div>

  <!-- The collapsible options menu of the multi select -->
  @if (showOptions) {
    <div
      [nrcOverflowTarget]="selectInputElement"
      [parentElement]="overflowParentElement"
      [elementHeight]="80"
      [elementSpaceY]="isRound ? 24 : 4"
      class="w-full min-w-[192px] max-h-48 bg-neutral-900 overflow-auto rounded z-10"
      [ngClass]="{
        'flex justify-center items-center': options.length === 0
      }"
      [@growAnimation]="{
        value: '', params: {
          heightStart: '0',
          heightEnd: '80px'
        }
      }"
    >
      @if (options.length === 0) {
        <nrc-loading class="relative my-8" />
      } @else if (optionsFiltered | isNonEmptyArray) {
        @for (option of optionsFiltered; track i; let i = $index) {
          <div
            class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out px-4 py-2 cursor-pointer hover:bg-white/5"
            tabindex="0"
            aria-hidden="true"
            (click)="selectOption(option)"
          >
            @if (multiple) {
              <span
                class="flex justify-center items-center mr-4 w-5 h-5 bg-transparent
                  border-solid rounded-sm cursor-pointer
                  transition-all duration-250 ease-in-out
                  group-[hover]:bg-transparent group-[]:bg-transparent"
                [ngClass]="{
                  'border-1 border-gray-400': !(option | hasSetValue:selectedOptions),
                  'border-10 border-cyan animate-shrink-bounce before:content-[\'\'] before:absolute before:top-5 before:left-5 before:border-r-1.5 before:border-r-solid before:border-r-transparent before:border-b-1.5 before:border-b-solid before:border-b-transparent before:rotate-45 before:origin-bottom-left before:animate-checkbox-check': option | hasSetValue:selectedOptions
                }"
              ></span>
            }

            <span class="cursor-pointer">
              {{ option | extractProperty: labelProperty }}
            </span>
          </div>
        }
      }
    </div>
  }
</div>

<!-- The list of selected options represented as chips -->
@if (multiple && showTags) {
  <div class="flex flex-wrap mt-4">
    @for (selectedOption of (selectedOptions | toArray); track i; let i = $index) {
      <nrc-chip
        class="mr-2 mb-2"
        @fadeInOutAnimation
        [label]="(selectedOption | extractProperty: labelProperty) | toString"
        [config]="{ isClosable: true }"
        (closeEvent)="removeOption(selectedOption)"
      />
    }
  </div>
}
