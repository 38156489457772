/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {
  /**
   * @constructor
   *
   * @param logger
   */
  constructor(
    private readonly logger: NGXLogger
  ) { }

  /**
   *
   * @param message
   * @param additional
   */
  public debug(message?: any, ...additional: any[]): void {
    this.logger.debug(message, ...additional);
  }

  /**
   *
   * @param message
   * @param additional
   */
  public info(message?: any, ...additional: any[]): void {
    this.logger.info(message, ...additional);
  }

  /**
   *
   * @param message
   * @param additional
   */
  public warn(message?: any, ...additional: any[]): void {
    this.logger.warn(message, ...additional);
  }

  /**
   *
   * @param message
   * @param additional
   */
  public error(message?: any, ...additional: any[]): void {
    this.logger.error(message, ...additional);
  }
}
