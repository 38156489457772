import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, signal } from '@angular/core';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { Level } from '@newroom-connect/library/enums';
import { IFile } from '@newroom-connect/library/interfaces';
import { FileHelper, ImageHelper } from '@newroom-connect/library/helpers';
import { TranslatePipe, StartsWithPipe } from '@newroom-connect/library/pipes';
import { LoggingService, ToastService, TranslationService } from '@newroom-connect/library/services';

import { ImageComponent } from '../../../image/image.component';
import { VideoComponent } from '../../../video/video.component';
import { IconComponent } from '../../../icon/icon.component';
import { TooltipComponent } from '../../../tooltip/tooltip.component';
import { ToastComponent } from '../../../toast/toast.component';

@Component({
  selector: 'nrc-area-background-2d',
  standalone: true,
  imports: [
    ImageComponent,
    VideoComponent,
    IconComponent,
    TooltipComponent,
    ToastComponent,
    TranslatePipe,
    StartsWithPipe
  ],
  animations: [fadeInOutAnimation],
  templateUrl: './area-background-2d.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaBackground2DComponent implements OnChanges {
  @Input({ required: true }) public apiBaseUrl = '';
  @Input({ required: true }) public backgroundFile!: IFile;
  @Input() public isEditMode = true;

  public source = '';
  public alt = '';
  public mimetype = '';
  public doesBackgroundImageMeetRequirementsSig = signal<boolean>(false);

  /**
   * @constructor
   *
   * @param loggingService
   * @param toastService
   * @param translationService
   */
  constructor(
    private readonly loggingService: LoggingService,
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService
  ) {}

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const backgroundFile = changes['backgroundFile']?.currentValue;

    if (backgroundFile) {
      this.handleBackgroundFile(backgroundFile);
    }
  }

  /**
   * Open a warning message prompting the user to upload or select a 16:9 aspect ratio background image.
   */
  public openAspectRatioWarningMessage(): void {
    // Only display the toast message if we are in edit mode.
    if (!this.isEditMode) {
      return;
    }

    this.toastService.setMessage({
      message: this.translationService.translate('COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_2D.TOAST.ASPECT_RATIO_WARNING'),
      level: Level.WARN
    });
  }

  /**
   * Handle a background file where the image properties are being set.
   * Also, check if the given background image meets the specified requirements and set the appropriate signal respectively.
   *
   * @param backgroundFile The background file object to handle.
   */
  private handleBackgroundFile(backgroundFile: IFile): void {
    try {
      this.source = FileHelper.getFileSourceURI(this.apiBaseUrl, backgroundFile);
      this.alt = backgroundFile.filenameDisk;
      this.mimetype = backgroundFile.mimetype;

      this.doesBackgroundImageMeetRequirementsSig.set(ImageHelper.doesImageMeetDimensions(backgroundFile));

      if (!this.doesBackgroundImageMeetRequirementsSig()) {
        this.openAspectRatioWarningMessage();
      }
    } catch (error) {
      this.loggingService.error('Unable to handle background file in 2D context: ', error);
    }
  }
}
