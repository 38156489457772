import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, signal } from '@angular/core';
import { FileType } from '@newroom-connect/library/enums';
import { FileHelper } from '@newroom-connect/library/helpers';
import { IFile } from '@newroom-connect/library/interfaces';
import { LoggingService } from '@newroom-connect/library/services';

import { CubemapComponent, CubemapFaceMap } from '../../../cubemap/cubemap.component';

@Component({
  selector: 'nrc-area-background-360-degree',
  standalone: true,
  imports: [CubemapComponent],
  templateUrl: './area-background-360-degree.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaBackground360DegreeComponent implements OnChanges {
  @Input({ required: true }) public apiBaseUrl = '';
  @Input({ required: true }) public backgroundFile!: IFile;

  public cubemapFaceMapSig = signal<CubemapFaceMap | null>(null);

  /**
   * @constructor
   *
   * @param loggingService
   */
  constructor(
    private readonly loggingService: LoggingService
  ) {}

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundFile']) {
      if (changes['backgroundFile'].currentValue) {
        try {
          this.cubemapFaceMapSig.set(this.generateCubemapFaceMapFromFile(changes['backgroundFile'].currentValue));
        } catch (error) {
          this.loggingService.error(error);

          this.cubemapFaceMapSig.set(null);
        }
      } else {
        this.cubemapFaceMapSig.set(null);
      }
    }
  }

  /**
   * Generate a cubemap face map from the provided file having an image URL for each face.
   * A face map consists of 6 different faces (px, py, pz, nx, ny, nz) which are used to create a cubemap.
   *
   * @param file The file to create the cubemap face map from.
   *
   * @returns The generated cubemap face map.
   *
   * @throws Error, if not every face contains an image URL.
   */
  private generateCubemapFaceMapFromFile(file: IFile): CubemapFaceMap {
    const faceMap: CubemapFaceMap = {
      px: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_PX)),
      py: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_PY)),
      pz: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_PZ)),
      nx: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_NX)),
      ny: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_NY)),
      nz: FileHelper.getFileSourceURI(this.apiBaseUrl, file.formats?.find(fileFormat => fileFormat.type === FileType.CUBEMAP_FACE_NZ))
    };

    if (!Object.values(faceMap).every(faceUrl => !!faceUrl)) {
      throw new Error('Unable to generate cubemap face map from file: Not every face contains an image URL.');
    }

    return faceMap;
  }
}
