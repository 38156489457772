import { MediaType } from '@newroom-connect/library/enums';
import { ICardItem, IFile, IFileItem, MediaItem } from '@newroom-connect/library/interfaces';

import { FileService } from '../file/file.service';

export class MediaLibraryHelper {
  /**
   * Hydrates the media library data.
   *
   * @param mediaLibraryValue Media library value to hydrate.
   * @param mediaLibraryFiles Media library files to hydrate.
   *
   * @returns Hydrated media library data.
   */
  public static hydrateMediaLibraryData(mediaLibraryValue: MediaItem[], mediaLibraryFiles: IFile[]): MediaItem[] {
    if (!mediaLibraryValue || !mediaLibraryFiles) {
      return [];
    }

    const hydratedMediaLibraryValue: MediaItem[] = [...mediaLibraryValue];

    for (const item of hydratedMediaLibraryValue) {
      if (this.isFileItem(item)) {
        const file = mediaLibraryFiles.find(file => file.id === item.fileId);

        if (!file) {
          continue;
        }

        item.file = FileService.hydrateFile(file);
      }

      if (item.thumbnailId) {
        const thumbnail = mediaLibraryFiles.find(file => file.id === item.thumbnailId);

        if (!thumbnail) {
          continue;
        }

        item.thumbnail = FileService.hydrateFile(thumbnail);
      }

      if (this.isCardItem(item) && item.items) {
        item.items = this.hydrateMediaLibraryData(item.items, mediaLibraryFiles);
      }
    }

    return hydratedMediaLibraryValue;
  }

  /**
   * Type guard for card item.
   *
   * @param item Item to check.
   *
   * @returns Boolean indicating if item is a card item.
   */
  public static isCardItem(item: MediaItem): item is ICardItem {
    return item && item.type === MediaType.CARD;
  }

  /**
   * Type guard for file item.
   *
   * @param item Item to check.
   *
   * @returns Boolean indicating if item is a file item.
   */
  public static isFileItem(item: MediaItem | Partial<MediaItem>): item is IFileItem {
    return item && item.type === MediaType.FILE;
  }
}
