<div
  class="fixed inset-0 z-50 w-full flex items-center justify-center"
  [ngClass]="{
    'backdrop-blur-sm' : blurBackground
  }"
>
  <div
    class="max-w-[33%] max-h-[32rem] text-gray-50 border-0 md:border-2 rounded-0 md:rounded-[10px] bg-transparent-dark shadow tracking-wide"
    [ngClass]="{
      'border-transparent': !message || message.level === level.INFO,
      'border-error': message?.level === level.ERROR,
      'border-warning': message?.level === level.WARN,
      'border-success': message?.level === level.SUCCESS,
    }"
    role="alert"
  >
    <div class="px-9 pt-12">
      <div class="flex items-center">
        @if (icon) {
          <div
            class="w-10 h-10 flex justify-center items-center rounded-full"
            [ngClass]="{
              'bg-cyan': !message || message.level === level.INFO,
              'bg-error': message?.level === level.ERROR,
              'bg-warning': message?.level === level.WARN,
              'bg-success': message?.level === level.SUCCESS
            }"
          >
            <nrc-icon
              class="w-5 h-5"
              [name]="icon"
              [fillColor]="iconFillColor"
            />
          </div>
        }

        <span class="sr-only"></span>

        <h3 class="text-3xl font-light ml-4">{{ message?.headline }}</h3>
      </div>

      <div
        class="mt-4 text-base font-light"
        [ngClass]="{ 'mb-4': !confirmationInput }"
        [innerHTML]="message?.message">
      </div>

      @if (confirmationForm && confirmationInput) {
        <div class="mb-2 mt-6">
          <p class="mb-2">Type &ldquo;{{ confirmationInput }}&rdquo; to confirm:</p>

          <form [formGroup]="confirmationForm">
            <nrc-input-text
              [formControl]="confirmationForm.controls['confirmationInput']"
              [type]="'text'"
              [name]="'alert-confirmation-text'"
              [id]="'alert-confirmation-text'"
              [label]="'Confirmation text'"
            />
          </form>
        </div>
      }

      @if (itemsList | isNonEmptyArray) {
        <div class="mb-2 max-h-60 overflow-y-auto">
          <table class="w-full table-fixed">
            <tbody>
              @for (item of itemsList; track $index) {
                <tr class="hover:bg-white/5 border-y-1 border-solid border-white/10 -z-10 last:border-b-1">
                  <td
                    class="flex py-4 px-2"
                    [ngClass]="{
                      'justify-between': item.href,
                      'justify-center': !item.href
                    }"
                  >
                    <span class="text-sm font-light text-[#adadad]">{{ item.label }}</span>

                    @if (item.href && item.hrefLabel) {
                      <a
                        class="text-sm text-cyan hover:text-cyan-highlight"
                        [href]="item.href"
                        target="_blank"
                      >
                        {{ item.hrefLabel }}
                      </a>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }

      @if (note) {
        <nrc-note
          [message]="note.message"
          [icon]="note.icon"
          [iconFillColor]="note.iconFillColor"
        />
      }
    </div>

    <!-- Inject custom content defined inside the HTML element of the alert component -->
    <ng-content></ng-content>

    @if (message?.footer) {
      <div
        class="pt-4 px-9 text-base font-light"
        [ngClass]="{ 'mt-4': !confirmationInput }"
        [innerHTML]="message?.footer"
      ></div>
    }

    <hr class="w-full h-[1px] my-6 bg-gray-500 border-none">

    <div
      class="flex px-9 pb-8"
      [ngClass]="{
        'justify-between': actionButton,
        'justify-end': !actionButton
      }"
    >
      @if (actionButton) {
        <button
          type="button"
          class="text-cyan bg-none font-medium text-base/[19px] pr-3 py-1.5 mr-2 text-center hover:text-cyan-highlight"
          (click)="actionEvent.emit()"
        >{{ actionButton.label }}</button>
      }

      @if (confirmButton) {
        <nrc-button
          class="relative"
          [label]="confirmButton.label"
          [disabled]="confirmationForm && confirmationForm.invalid"
          [role]="confirmButtonRole"
          (clickEvent)="confirmEvent.emit()"
        />
      }
    </div>
  </div>
</div>
