import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';

import { IconComponent, IconFillColor } from '../../icon/icon.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent
  ],
  selector: 'nrc-file-type',
  templateUrl: './file-type.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTypeComponent implements OnInit {
  @Input({ required: true }) public mimetype!: string;
  @Input({ required: false }) public iconColor!: IconFillColor;
  @Input({ required: false }) public classList!: string;

  public iconName = signal<string>('file-type-text');

  public ngOnInit(): void {
    this.iconName.set(this.getIconNameFromMimetype());
  }

  /**
   * Determine the icon name to be shown based on the given mimetype input.
   *
   * @returns The icon name based on the mimetype input.
   */
  private getIconNameFromMimetype(): string {
    let iconName = 'file-type-text';

    if (this.mimetype === 'application/pdf') {
      iconName = 'file-type-pdf';
    } else if (this.mimetype.startsWith('audio/')) {
      iconName = 'file-type-audio';
    } else if (this.mimetype.startsWith('image/')) {
      iconName = 'file-type-image';
    } else if (this.mimetype.startsWith('video/')) {
      iconName = 'file-type-video';
    } else if (this.mimetype.startsWith('code/')) {
      iconName = 'file-type-code';
    }

    return iconName;
  }
}
