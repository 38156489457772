import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  /**
   * Pipe for joining items based on a given delimiter.
   *
   * @param items The items to join.
   * @param delimiter The delimiter of the join operation.
   *
   * @returns A list of extracted properties as a string separated by the given `delimiter`. Default delimiter is ','.
   */
  public transform(items: unknown[], delimiter = ','): string {
    return items.join(delimiter);
  }
}
