import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { IArea, ActionName } from '@newroom-connect/library/interfaces';
import { FileHelper, ArrayHelper } from '@newroom-connect/library/helpers';

import { LoggingService } from '../logging/logging.service';

import { AbstractCacheService } from './abstract-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AreaCacheService extends AbstractCacheService {
  /**
   * @constructor
   *
   * @param httpClient
   * @param swUpdate
   * @param logger
   */
  constructor(
    protected override readonly httpClient: HttpClient,
    protected override readonly swUpdate: SwUpdate | null,
    protected override readonly logger: LoggingService
  ) {
    super(httpClient, swUpdate, logger);
    // Custom database name for area cache
    Object.defineProperty(this, 'DB_NAME', { value: 'areaCache' });
  }

  /**
   * Prepares area files for preloading with priority levels.
   *
   * @param area The area containing files to preload.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Array of URLs to preload, prioritized backgrounds first.
   */
  public override prepareItemsForPreload(area: IArea, apiBaseUrl: string): { url: string; priority: 'high' | 'normal' }[] {
    if (!area) {
      return [];
    }

    const itemsToPreload: { url: string; priority: 'high' | 'normal' }[] = [];
    const processedUrls = new Set<string>();

    // Process area background files (high priority)
    this.processAreaBackgroundFiles(area, apiBaseUrl, processedUrls, itemsToPreload);

    // Process area element files
    this.processAreaElementFiles(area, apiBaseUrl, processedUrls, itemsToPreload);

    // Sort by priority (high priority first)
    return itemsToPreload.sort((a, b) => {
      if (a.priority === 'high' && b.priority !== 'high') {
        return -1;
      }
      if (a.priority !== 'high' && b.priority === 'high') {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Process area background files for preloading.
   *
   * @param area The area containing translations with background files.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processAreaBackgroundFiles(area: IArea, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    if (!ArrayHelper.isNotEmpty(area.translations)) {
      return;
    }

    for (const translation of area.translations) {
      if (!translation.backgroundFile) {
        continue;
      }

      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.backgroundFile);

      if (!url || processedUrls.has(url)) {
        continue;
      }

      processedUrls.add(url);
      itemsToPreload.push({ url, priority: 'high' });
    }
  }

  /**
   * Process area element files including backgrounds and media.
   *
   * @param area The area containing elements to process.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processAreaElementFiles(area: IArea, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    if (!ArrayHelper.isNotEmpty(area.areaElements)) {
      return;
    }

    for (const areaElement of area.areaElements) {
      if (!ArrayHelper.isNotEmpty(areaElement.translations)) {
        continue;
      }

      for (const translation of areaElement.translations) {
        // Process background files (high priority)
        this.processElementBackgroundFile(translation, apiBaseUrl, processedUrls, itemsToPreload);
      }
    }
  }

  /**
   * Processes a single element background file for preloading.
   *
   * @param translation The element translation to process.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processElementBackgroundFile(translation: any, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    if (!translation.backgroundFile) {
      return;
    }

    const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.backgroundFile);

    if (!url || processedUrls.has(url)) {
      return;
    }

    processedUrls.add(url);
    itemsToPreload.push({ url, priority: 'high' });
  }

  /**
   * Extracts move area element IDs from an area. These are targets for navigation.
   *
   * @param area The area to analyze for move elements.
   * @returns Array of area IDs that are targets for navigation.
   */
  public extractMoveAreaTargetIds(area: IArea): string[] {
    if (!area || !ArrayHelper.isNotEmpty(area.areaElements)) {
      return [];
    }

    const moveTargetIds = new Set<string>();

    for (const areaElement of area.areaElements) {
      if (!ArrayHelper.isNotEmpty(areaElement.translations)) {
        continue;
      }

      for (const translation of areaElement.translations) {
        const action = translation.action;

        if (
          action &&
          action.name === ActionName.MOVE &&
          action.value &&
          action.value.moveAreaId
        ) {
          moveTargetIds.add(action.value.moveAreaId as string);
        }
      }
    }

    return Array.from(moveTargetIds);
  }
}
