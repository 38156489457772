import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LoggingService, ProjectService } from '@newroom-connect/library/services';

export const IsProjectSelectedGuard: CanActivateFn = () => {
  const router = inject(Router);
  const projectService = inject(ProjectService);
  const loggingService = inject(LoggingService);

  const currentProject = projectService.getCurrentProject();

  // If the current project is not set, navigate back to the home screen.
  if (!currentProject) {
    router.navigate(['/home']).catch((error: Error) => loggingService.error(error));
  }

  return !!currentProject;
};
