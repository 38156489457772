import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Level } from '@newroom-connect/library/enums';

import { IconComponent, IconFillColor } from '../icon/icon.component';

export type NoteMessage = {
  message: string;
  headline?: string;
  footer?: string;
  level?: Level;
  icon?: string;
};

export type NoteComponentInput = {
  message?: NoteMessage | null;
  icon?: string;
  iconFillColor?: IconFillColor;
};

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent
  ],
  selector: 'nrc-note',
  templateUrl: './note.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NoteComponent implements OnInit {
  @Input() public message?: NoteMessage | null;

  // Name of a custom icon which can be set.
  // If not specified, a default icon will be used based on the given message level.
  @Input() public icon?: string;

  // A custom fill color of an icon, if an icon is provided.
  @Input() public iconFillColor?: IconFillColor = 'white';

  public level = Level;

  /**
   *
   */
  public ngOnInit(): void {
    // If no icon was set, set it from the given message based on the message level.
    // If the message contains a custom icon, use this.
    if (!this.icon) {
      if (this.message?.icon) {
        this.icon = this.message.icon;
      } else {
        this.icon = this.getIconNameFromMessageLevel();
      }
    }
  }

  /**
   *
   * @returns
   */
  public getIconNameFromMessageLevel(): string {
    let iconName = 'info';

    if (!this.message?.level) {
      return iconName;
    }

    switch (this.message.level) {
      case Level.ERROR:
      case Level.WARN:
        iconName = 'triangle-warning';
        break;
      case Level.SUCCESS:
        iconName = 'check';
        break;
      default:
        break;
    }

    return iconName;
  }
}
