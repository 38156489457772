import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, effect, signal } from '@angular/core';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';

import { IconComponent, IconFillColor } from '../../icon/icon.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';

export type ButtonTextSize = 'xs' | 'sm' | 'base' | 'lg' | 'xl';
export type ButtonFontWeight = 'light' | 'normal' | 'bold' | 'semibold';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, TooltipComponent],
  selector: 'nrc-button',
  templateUrl: './button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ButtonComponent implements OnChanges {
  @Input() public disabled?: boolean;
  @Input() public isRound?: boolean = true;
  @Input() public icon?: string;
  @Input() public label?: string;
  @Input() public textSize?: ButtonTextSize = 'sm';
  @Input() public fontWeight?: ButtonFontWeight = 'normal';
  @Input() public buttonType?: ButtonType;
  @Input() public tooltipText?: string;
  @Input() public role?: ActionRole;
  @Input() public align?: string = 'center';

  @Output() public clickEvent = new EventEmitter();

  public actionRole = ActionRole;
  public buttonTypeActive = ButtonType;

  public isHovered = signal<boolean>(false);

  public iconFillColor = signal<IconFillColor>('white');

  /**
   * @constructor
   */
  constructor() {
    // Update the icon fill color, if the hover state of the button changed.
    effect(() => {
      const isHovered = this.isHovered();

      if (!this.disabled || !isHovered) {
        this.updateIconFillColor();
      }
    }, { allowSignalWrites: true });
  }

  /**
   *
   */
  public ngOnChanges(): void {
    this.updateIconFillColor();
  }

  /**
   * Update the icon fill color based on the button's state.
   */
  private updateIconFillColor(): void {
    switch (this.role) {
      case ActionRole.PRIMARY:
        this.iconFillColor.set('black');
        break;
      case ActionRole.SECONDARY:
      case ActionRole.TRANSPARENT_SECONDARY:
        this.iconFillColor.set(this.isHovered() ? 'cyan' : 'white');
        break;
      case ActionRole.TRANSPARENT_PRIMARY:
        this.iconFillColor.set('cyan');
        break;
      case ActionRole.ERROR:
        this.iconFillColor.set(this.isHovered() ? 'error' : 'white');
        break;
      case ActionRole.WARNING:
        this.iconFillColor.set(this.isHovered() ? 'warning' : 'white');
        break;
      case ActionRole.SUCCESS:
        this.iconFillColor.set(this.isHovered() ? 'success' : 'white');
        break;
      default:
        break;
    }
  }
}
