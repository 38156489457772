import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { FormControlPipe } from '@newroom-connect/library/pipes';
import { ContenteditableDirective, IContentEditableChangeEvent } from '@newroom-connect/library/directives';
import { AreaElementService } from '@newroom-connect/library/services';

import { InputComponent } from '../input.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormControlPipe,
    ContenteditableDirective
  ],
  selector: 'nrc-input-editable-span',
  templateUrl: './input-editable-span.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputEditableSpanComponent),
      multi: true
    }
  ]
})
export class InputEditableSpanComponent extends InputComponent<string> implements OnInit {
  @Output() public elementNameChanged = new EventEmitter<string>();

  // Used for the default name, if parent component has multiple SpanInputs
  @Input() public index?: number;

  @ViewChild('spanInput') public spanInputRef!: ElementRef<HTMLSpanElement>;

  /**
   * @constructor
   *
   */
  constructor(
  ) {
    super();
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Handles all cases for the value changes.
   *
   * @param event Container for change element options.
   * @param event.name Current name for the input.
   * @param event.isFocused Flag to check if user is focused on input.
   * @param event.saveChanges Flag to check if name should be saved.
   */
  public valueChanged(event: IContentEditableChangeEvent): void {
    let newAreaElementName = event.name;

    // If the formcontrol value is empty and the user leaves the field, set the value to default one.
    if (event.name === '' && !event.isFocused) {
      newAreaElementName = `${AreaElementService.DEFAULT_AREA_ELEMENT_NAME} ${this.index}`;
      this.formControl.patchValue(newAreaElementName, { emitEvent: true });
    }

    if (event.saveChanges) {
      this.elementNameChanged.emit(newAreaElementName);
    }
  }
}
