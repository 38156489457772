import { ChangeDetectionStrategy, Component, Input, OnInit, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { OrderByStatusPipe, ToStringPipe } from '@newroom-connect/library/pipes';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { ITranslationAttempt, TranslationJobStatus } from '@newroom-connect/library/interfaces';
import { TranslateDocumentJobService } from '@newroom-connect/library/services';

import { LoadingSpinnerComponent } from '../../loading/loading-spinner/loading-spinner.component';
import { IconComponent } from '../../icon/icon.component';
import { CollapsibleComponent } from '../../collapsible/collapsible.component';
import { LanguageComponent } from '../../language/language.component';

@Component({
  selector: 'nrc-file-translation-status',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ToStringPipe,
    OrderByStatusPipe,
    IconComponent,
    CollapsibleComponent,
    LanguageComponent,
    LoadingSpinnerComponent
  ],
  templateUrl: './file-translation-status.component.html',
  animations: [fadeInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileTranslationStatusComponent implements OnInit {
  @Input() public fileId?: string;

  public fileTranslationStatus = TranslationJobStatus;
  public isCollapsed = signal<boolean>(false);

  public activeDocumentTranslation = signal<ITranslationAttempt[]>([]).asReadonly();

  /**
   * @constructor
   *
   * @param router
   * @param translateDocumentJobService
   */
  constructor(
    private readonly router: Router,
    private readonly translateDocumentJobService: TranslateDocumentJobService
  ) {}

  /**
   *
   */
  public ngOnInit(): void {
    this.activeDocumentTranslation = this.translateDocumentJobService.watchActiveDocumentTranslation();
  }

  /**
   *
   * @param originalFileId
   * @param newFileId
   */
  public handleNavigationEvent(originalFileId?: string, newFileId?: string): void {
    if (!newFileId || !originalFileId) {
      return;
    }

    // Navigate to the file detail settings page of the newly translated file.
    this.router.navigate(['/files', newFileId, { outlets: { fileDetailOutlet: ['settings'] } }]);
  }
}
