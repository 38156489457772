<div class="flex justify-start items-center space-x-4 border-l-1 border-white/50">
  <span class="text-xs font-light text-[#adadad] pl-2">View</span>

  <nrc-icon
    class="w-5 h-5 cursor-pointer"
    [name]="'grid'"
    [fillColor]="selectedLayout === 'grid' ? 'white' : 'semi-transparent'"
    (click)="handleLayoutChanged('grid')"
  />

  <nrc-icon
    class="w-5 h-5 cursor-pointer"
    [name]="'rows'"
    [fillColor]="selectedLayout === 'rows' ? 'white' : 'semi-transparent'"
    (click)="handleLayoutChanged('rows')"
  />
</div>
