import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 *
 * @param minControlName
 * @param maxControlName
 *
 * @returns
 */
export const rangeValidator = (minControlName: string, maxControlName: string): ValidatorFn => {
  return (formGroup: AbstractControl): ValidationErrors | null => {
    const minControl = formGroup.get(minControlName);
    const maxControl = formGroup.get(maxControlName);

    if (!minControl || !maxControl) {
      return null;
    }

    const minValue = minControl.value;
    const maxValue = maxControl.value;

    if (minValue instanceof Date && maxValue instanceof Date) {
      return minValue < maxValue ? null : { rangeError: 'startAfterEnd' };
    }

    if (typeof minValue === 'number' && typeof maxValue === 'number') {
      return minValue < maxValue ? null : { rangeError: 'minGreaterThanMax' };
    }

    return null;
  };
};

