<ng-container [ngTemplateOutlet]="theme === modalTheme.STANDARD ? standardTheme : theme === modalTheme.DARK ? darkTheme : standardTheme">
</ng-container>

<ng-template #standardTheme>
  <div class="modal-overlay">
    <div class="modal-backdrop" (click)="closeModal()" (keyup)="null" [tabindex]="null"></div>
    <div
      class="justify-between bg-transparent-dark modal"
      [ngClass]="{
        'modal-small': size === modalSize.SMALL,
        'modal-medium': size === modalSize.MEDIUM,
        'modal-large': size === modalSize.LARGE
      }">
      <div
        class="px-4 mt-2 modal-header"
        [ngClass]="{
          'mb-4': paddedContent,
        }"
      >
        <span class="modal-title drop-shadow-[0_2px_4px_rgba(0,0,0,0.8)] text-sm">{{ title }}</span>
        <button class="modal-close-button" (click)="closeModal()" [class.hidden]="size === modalSize.FULL_SCREEN">
          <nrc-icon [classList]="['rotate-45']" [name]="'plus'" />
        </button>
      </div>
      <div
        class="flex flex-col rounded-md modal-content"
        [ngClass]="{
          'p-8 mt-8': paddedContent
        }">

        @if (isLoadingContent()) {
          <nrc-loading-spinner />
        }

        <ng-container #contentContainer></ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #darkTheme>
<div class="modal-overlay">
    <div class="modal-backdrop" (click)="closeModal()" (keyup)="null" [tabindex]="null"></div>
    <div
      class="justify-between modal bg-[#000000] bg-opacity-95 rounded-none"
      [ngClass]="{
        'modal-small': size === modalSize.SMALL,
        'modal-medium': size === modalSize.MEDIUM,
        'modal-large': size === modalSize.LARGE,
        'w-screen h-screen max-h-full max-w-full': size === modalSize.FULL_SCREEN
      }">
      <div
        class="px-4 mt-2 modal-header"
        [ngClass]="{
          'mb-4': paddedContent,
        }"
      >
        <span class="modal-title drop-shadow-[0_2px_4px_rgba(0,0,0,0.8)] text-4xl mt-14 py-8">{{ title }}</span>

        <!-- This is a workaround, should be updated after global area elements are handled -->
        @if (!openInFullscreen) {
          <button
            class="relative flex items-center justify-center w-6 h-6 bg-white modal-close-button -top-3 -right-4"
            [class.hidden]="size === modalSize.FULL_SCREEN"
            (click)="closeModal()"
          >
            <div class="w-3 h-3">
              <nrc-icon [classList]="['rotate-45']" [name]="'plus'" [fillColorHex]="'#000000'"/>
            </div>
          </button>
        }
      </div>
      <div
        class="flex flex-col rounded-md modal-content"
        [ngClass]="{
          'p-8 mt-8': paddedContent
        }">

        @if (isLoadingContent()) {
          <nrc-loading-spinner />
        }

        <ng-container #contentContainer></ng-container>
      </div>
    </div>
  </div>
</ng-template>
