type RecordableKeys<T> = {
  [K in keyof T]:
    T[K] extends string | number | symbol
    ? K
    : never
}[keyof T] // Get a union of the values that are not `never`.

export class ArrayHelper {
  /**
   * Check if the given item is an array and not empty.
   *
   * @param item The item to check.
   *
   * @returns Boolean indicating if the given item is an array and not empty or not.
   */
  public static isNotEmpty(item: unknown): boolean {
    return Array.isArray(item) && item.length > 0;
  }

  /**
   *
   * @param array
   * @param fromIndex
   * @param toIndex
   *
   * @returns
   *
   * @throws
   */
  public static move<T>(array: T[], fromIndex: number, toIndex: number): T[] {
    if (toIndex >= array.length) {
      throw new Error('Unable to move item to index greater than array length');
    }

    const arrayCopy = [...array];

    arrayCopy.splice(toIndex, 0, arrayCopy.splice(fromIndex, 1)[0]);

    return arrayCopy;
  }

  /**
   * Converts a given array and selector to a record.
   *
   * Source: https://stackoverflow.com/questions/61414459/typescript-generic-array-to-record-function-with-proper-type-restrictions .
   *
   * @param array
   * @param selector
   *
   * @returns The array converted to a Record with the provided selector.
   */
  public static toRecord<T extends { [P in RecordableKeys<T>]: string | number | symbol }, K extends RecordableKeys<T>>(array: T[], selector: K): Record<T[K], T> {
    return array.reduce((acc, item) => ({ ...acc, [item[selector]]: item }), {} as Record<T[K], T>);
  }
}
