<div class="w-full flex flex-col justify-center items-center pt-4 px-2 space-y-4">
  @if (config.selectMultiple) {
    <div class="w-full flex items-center" [ngClass]="{
      'justify-between ': isItemSelectionActive,
      'justify-end ': !isItemSelectionActive,
    }">
      @if (isItemSelectionActive) {
        <div class="flex justify-center items-center -translate-x-4">
          <nrc-checkbox
            [checkStatus]="isEveryPageItemSelectedSig() ? 'checked' : (isSomeButNotEveryPageItemSelectedSig() ? 'semi-checked' : 'unchecked')"
            (clickEvent)="toggleAllItemsIsSelected()"
          />

          <span class="text-white text-sm -translate-x-2">All</span>
        </div>
      }

      <nrc-button
        [label]="isItemSelectionActive ? 'Deselect files' : 'Select files'"
        [icon]="isItemSelectionActive ? 'multi-check-off' : 'multi-check'"
        [role]="actionRole.SECONDARY"
        (clickEvent)="toggleItemSelection()"
      />
    </div>
  }

  <div class="w-full grid gap-2 md:gap-4 3xl:gap-8" nrcGridColsByContainer>
    @for (item of items(); track i; let i = $index) {
      <div class="w-full flex justify-center" attr.id="grid-item-{{i}}">
        @for (column of config.columns; track $index) {
          <div
            class="w-full cursor-pointer"
            tabindex="0"
            (keydown)="null"
          >
            @if (!column['visibilityFn'] || column['visibilityFn'](item.value | extractProperty: column.key)) {
              <nrc-grid-list-item
                class="w-full flex flex-col items-center justify-between"
                [ngClass]="{
                  'justify-start': !column.align || column.align === 'left',
                  'justify-center mr-13': column.align === 'center',
                  'justify-end': column.align === 'right'
                }"
                [index]="i"
                [isSelected]="item.isSelected"
                [config]="{
                    type: column.type,
                    value: item.value | extractProperty: column.key,
                    originalValue: item.value,
                    additionalInfo: column.additionalInfoKeys ? (item.value | extractProperties: column.additionalInfoKeys | join: ' | ') : undefined,
                    thumbnailConfig: column.thumbnailConfig,
                    mimetype: column.thumbnailConfig && column.thumbnailConfig.thumbnailSourceKey && (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                      ? 'image/png'
                      : (item.value | extractProperty: (column.thumbnailConfig?.fallbackMimetypeKey | toString) | toString),
                    thumbnailSource: column.thumbnailConfig && column.thumbnailConfig.thumbnailSourceKey
                      ? (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                        ? (item.value | extractProperty: (column.thumbnailConfig.thumbnailSourceKey | toString) | toString)
                        : (item.value | extractProperty: (column.thumbnailConfig.fallbackThumbnailSourceKey | toString) | toString)
                      : undefined,
                    isHighlighted: column.isHighlighted,
                    showTooltip: column.showTooltip,
                    actionRole: item.actionRole,
                    buttonConfigs: column['buttonConfigs'],
                    extractorFn: column['extractorFn'],
                    tooltipTextFn: column['tooltipTextFn'],
                    itemActionButtons: config.itemActionButtons
                }"
                [showCheckbox]="isItemSelectionActive"
                [showContextMenu]="!isItemSelectionActive"
                (configButtonClickEvent)="this.gridItemClickedEvent.emit({ index: i, itemIndex: $event.index, item });"
                (itemActionButtonEvent)="handleItemAction($event)"
                (itemClickEvent)="handleGridItemClickedEvent(item, i)"
              />
            }
          </div>
        }
      </div>
    }
  </div>
</div>

