import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconComponent } from '../../icon/icon.component';

export interface IFileExplorer {
  folders?: IFileExplorerFolder[];
}

export interface IFileExplorerFolder {
  folders?: IFileExplorerFolder;
  title?: string;
  isSelected?: boolean;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent
  ],
  selector: 'nrc-file-explorer',
  templateUrl: './file-explorer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileExplorerComponent {
  @Input() public headline?: string;
  @Input() public fileExplorer?: IFileExplorer;

  @Output() public itemClickEvent = new EventEmitter<IFileExplorerFolder>();

  /**
   *
   * @param folder
   */
  public toggleSelectItem(folder: IFileExplorerFolder): void {
    folder.isSelected = folder.isSelected ? false : true;

    this.itemClickEvent.emit(folder);
  }
}
