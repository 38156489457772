import { inject, Injectable, signal } from '@angular/core';
import { HttpEvent, HttpEventType, HttpHeaders, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Observable, catchError, finalize, forkJoin, map, of, tap } from 'rxjs';
import { IFile, IFileHydrated, IAPIListResponse, IListOptions, IWebsocketMessage } from '@newroom-connect/library/interfaces';
import { ArrayHelper, ImageHelper, FileHelper } from '@newroom-connect/library/helpers';
import { FileType } from '@newroom-connect/library/enums';

import { EntityService } from '../entity/entity.service';
import { LoggingService } from '../logging/logging.service';

export interface IUpdateFileTranslationInput {
  code: string;
  title?: string;
  filenameDownload?: string;
}

export interface IUpdateFileInput {
  translations?: IUpdateFileTranslationInput[];
}

export enum FileGatewayAction {
  TRANSLATE_DOCUMENT = 'TRANSLATE_DOCUMENT',
  CUBEMAP_GENERATION = 'CUBEMAP_GENERATION'
}

export enum DownloadStatus {
  PENDING = 'PENDING',
  PREPARING = 'PREPARING',
  ZIPPING = 'ZIPPING',
  COMPLETED = 'COMPLETED',
  DOWNLOADING = 'DOWNLOADING',
  FAILED = 'FAILED'
}

export interface DownloadProgress {
  status: DownloadStatus;
  processed?: number;
  total?: number;
  filename?: string;
}

export interface IFileGatewayJobDetails {
  id?: string;
  status?: string;
  from?: string;
  to?: string;
  projectId: string;
  file?: IFileHydrated,
  fileId?: string;
}

export interface IFileGatewayData {
  action: FileGatewayAction;
  job: IFileGatewayJobDetails,
  file?: IFileHydrated,
}

@Injectable({
  providedIn: 'root'
})
export class FileService extends EntityService {
  private readonly logger = inject(LoggingService);

  public searchableProperties = ['mimetype', 'translations[].title'];

  public downloadProgress = signal<DownloadProgress | null>(null);
  private sseEventSource: EventSource | null = null;

  /**
   * Hydrate the provided file with more information.
   *
   * @param file The file to hydrate with more information.
   *
   * @returns The object of the hydrated file.
   */
  public static hydrateFile(file: IFile): IFileHydrated {
    const fileExtension = file.filenameDisk.match(/\.[0-9a-z]+$/i);

    const isCubemapSupported = !!(file.width && file.height && ImageHelper.isCubeMapSupported({ width: file.width, height: file.height }));
    const isCubemapInProgress = ArrayHelper.isNotEmpty(file.queueJobs) && file.queueJobs!.some(
      queueJob => queueJob.name === 'panorama-to-cubemap' && queueJob.status === 'ACTIVE'
    );

    const thumbnailFormat = file.formats?.find(fileFormat => fileFormat.type === FileType.THUMBNAIL);

    return {
      ...file,
      source: `${FileHelper.getFileSourceURI(FileService.baseUrl, file)}?time=${new Date().getTime()}`,
      thumbnail: thumbnailFormat ? `${FileHelper.getFileSourceURI(FileService.baseUrl, thumbnailFormat)}?time=${new Date().getTime()}` : undefined,
      extension: fileExtension ? fileExtension[0].slice(1).toUpperCase() : '',
      title: file.translations[0]?.title ?? file.filenameDisk,
      cubemapStatus: FileHelper.isFileCubemap(file) ? 'generated' : (isCubemapInProgress ? 'in-progress' : (isCubemapSupported ? 'supported' : undefined)),
      isCubemapModalVisible: false
    };
  }

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listFiles(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IFileHydrated>> {
    return super.list<IFile>(`projects/${projectId}/files`, undefined, options).pipe(
      map(response => {
        response.data = response.data.map(file => FileService.hydrateFile(file));
        return response as IAPIListResponse<IFileHydrated>;
      })
    );
  }

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listSourceFiles(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IFileHydrated>> {
    const requestOptions: IListOptions = options ?? {};

    if (!requestOptions.filters) {
      requestOptions.filters = {};
    }

    if (!requestOptions.filters['AND']) {
      requestOptions.filters['AND'] = [];
    }

    requestOptions.filters['AND'].push(
      { type: { equals: 'SOURCE' } }
    );

    return this.listFiles(projectId, requestOptions);
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public getFile(projectId: string, fileId: string): Observable<IFileHydrated> {
    return this.apiService.get<IFile>(`projects/${projectId}/files/${fileId}`).pipe(
      map(file => FileService.hydrateFile(file))
    );
  }

  /**
   *
   * @param projectId
   * @param fileId
   * @param input
   *
   * @returns
   */
  public updateFile(projectId: string, fileId: string, input: IUpdateFileInput): Observable<IFileHydrated> {
    return this.apiService.patch<IFile>(`projects/${projectId}/files/${fileId}`, input).pipe(
      map(file => FileService.hydrateFile(file))
    );
  }

  /**
   * Downloads files and handles progress tracking including SSE for zip creation.
   *
   * @param projectId
   * @param fileIds
   * @returns Observable of download events.
   */
  public downloadFiles(projectId: string, fileIds: string[]): Observable<HttpResponse<Blob>> {
    // If multiple files, start SSE connection before the download
    if (fileIds.length > 1) {
      this.downloadProgress.set({ status: DownloadStatus.PENDING, total: fileIds.length, processed: 0 });

      this.connectToDownloadProgressSSE(projectId);
    }

    return this.apiService.post<HttpResponse<Blob>>(
      `projects/${projectId}/files/download`,
      { fileIds },
      {
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
        headers: new HttpHeaders({
          'Accept': 'application/zip'
        })
      }
    ).pipe(
      tap(response => {
        if (!response.body || response.type !== HttpEventType.Response) {
          return;
        }

        const downloadedFileName = this.processDownloadResponse(response);

        this.downloadProgress.set({ status: DownloadStatus.COMPLETED, filename: downloadedFileName });
        // Clear the progress indicator after 5 seconds
        setTimeout(() => {
          this.downloadProgress.set(null);
        }, 10000);
      }),
      finalize(() => this.disconnectDownloadProgressSSE()),
      catchError(() => {
        this.downloadProgress.set({ status: DownloadStatus.FAILED });

        // Clear the progress indicator after 5 seconds
        setTimeout(() => {
          this.downloadProgress.set(null);
        }, 5000);
        return of();
      })
    );
  }

  /**
   *
   * @param event
   *
   * @returns
   * @throws
   */
  private processDownloadResponse(event: HttpResponse<Blob>): string {
    const blob = event.body;
    const contentDisposition = event.headers.get('content-disposition');
    const contentType = event.headers.get('content-type');

    if (!blob || !contentDisposition || !contentType) {
      throw new Error('Invalid download response');
    }

    const fileName = FileHelper.extractFilenameFromContentDispositionHeader(contentDisposition);

    FileHelper.downloadFile(blob, fileName, contentType);

    return fileName;
  }

  /**
   * Connects to SSE endpoint to track zip creation progress.
   *
   * @param projectId
   */
  private connectToDownloadProgressSSE(projectId: string): void {
    this.disconnectDownloadProgressSSE();

    this.sseEventSource = new EventSource(
      `${FileService.baseUrl}/projects/${projectId}/files/download-progress`,
      { withCredentials: true }
    );

    // Handle SSE open
    this.sseEventSource.onopen = (): void => {
      this.logger.debug('SSE connection opened');
    };

    // Handle SSE messages
    this.sseEventSource.onmessage = (event): void => {
      try {
        const progress = JSON.parse(event.data) as DownloadProgress;

        this.downloadProgress.set(progress);
      } catch (error) {
        this.logger.error('Error parsing SSE message:', error);
      }
    };

    // Handle SSE errors
    this.sseEventSource.onerror = (): void => {
      // Only disconnect on real errors, not connection closing
      if (this.sseEventSource?.readyState === EventSource.CLOSED) {
        this.disconnectDownloadProgressSSE();
        this.downloadProgress.set({ status: DownloadStatus.FAILED, total: 0, processed: 0 });
      }
    };
  }

  /**
   * Closes SSE connection if open.
   */
  private disconnectDownloadProgressSSE(): void {
    if (this.sseEventSource) {
      this.sseEventSource.close();
      this.sseEventSource = null;
    }
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public deleteFile(projectId: string, fileId: string): Observable<IFile> {
    return this.apiService.delete<IFile>(`projects/${projectId}/files/${fileId}`);
  }

  /**
   *
   * @param projectId
   * @param fileIds
   *
   * @returns
   */
  public deleteFiles(projectId: string, fileIds: string[]): Observable<IFile[]> {
    return forkJoin(fileIds.map(fileId => this.apiService.delete<IFile>(`projects/${projectId}/files/${fileId}`)));
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public convertFile(projectId: string, fileId: string): Observable<void> {
    return this.apiService.post<void>(`projects/${projectId}/files/${fileId}/convert`);
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public watchFiles(projectId: string): Observable<IWebsocketMessage<IFileGatewayData>> {
    return this.websocketService.watchTopic<IFileGatewayData>(`projects/${projectId}/files`).pipe(
      map(message => {
        if (!message.data.file) {
          return message;
        }

        // If a file is present in the gateway message, hydrate it so it includes all the extra properties needed.
        message.data.file = FileService.hydrateFile(message.data.file);

        return message;
      })
    );
  }
}
