import { Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Observable, forkJoin, map } from 'rxjs';
import { IFile, IFileHydrated, IAPIListResponse, IListOptions, IWebsocketMessage } from '@newroom-connect/library/interfaces';
import { ArrayHelper, ImageHelper, FileHelper } from '@newroom-connect/library/helpers';
import { FileType } from '@newroom-connect/library/enums';

import { EntityService } from '../entity/entity.service';

export interface IUpdateFileTranslationInput {
  code: string;
  title?: string;
  filenameDownload?: string;
}

export interface IUpdateFileInput {
  translations?: IUpdateFileTranslationInput[];
}

@Injectable({
  providedIn: 'root'
})
export class FileService extends EntityService {
  public searchableProperties = ['mimetype', 'translations[].title'];

  /**
   * Hydrate the provided file with more information.
   *
   * @param file The file to hydrate with more information.
   *
   * @returns The object of the hydrated file.
   */
  public static hydrateFile(file: IFile): IFileHydrated {
    const fileExtension = file.filenameDisk.match(/\.[0-9a-z]+$/i);

    const isCubemapSupported = !!(file.width && file.height && ImageHelper.isCubeMapSupported({ width: file.width, height: file.height }));
    const isCubemapInProgress = ArrayHelper.isNotEmpty(file.queueJobs) && file.queueJobs!.some(
      queueJob => queueJob.name === 'panorama-to-cubemap' && queueJob.status === 'ACTIVE'
    );

    const thumbnailFormat = file.formats?.find(fileFormat => fileFormat.type === FileType.THUMBNAIL);

    return {
      ...file,
      source: `${FileHelper.getFileSourceURI(FileService.baseUrl, file)}?time=${new Date().getTime()}`,
      thumbnail: thumbnailFormat ? `${FileHelper.getFileSourceURI(FileService.baseUrl, thumbnailFormat)}?time=${new Date().getTime()}`: undefined,
      extension: fileExtension ? fileExtension[0].slice(1).toUpperCase() : '',
      title: file.translations[0]?.title ?? file.filenameDisk,
      cubemapStatus: FileHelper.isFileCubemap(file) ? 'generated' : (isCubemapInProgress ? 'in-progress' : (isCubemapSupported ? 'supported' : undefined)),
      isCubemapModalVisible: false
    };
  }

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listFiles(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IFileHydrated>> {
    return super.list<IFile>(`projects/${projectId}/files`, undefined, options).pipe(
      map(response => {
        response.data = response.data.map(file => FileService.hydrateFile(file));
        return response as IAPIListResponse<IFileHydrated>;
      })
    );
  }

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listSourceFiles(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IFileHydrated>> {
    const requestOptions: IListOptions = options ?? {};

    if (!requestOptions.filters) {
      requestOptions.filters = {};
    }

    if (!requestOptions.filters['AND']) {
      requestOptions.filters['AND'] = [];
    }

    requestOptions.filters['AND'].push(
      { type: { equals: 'SOURCE' } }
    );

    return this.listFiles(projectId, requestOptions);
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public getFile(projectId: string, fileId: string): Observable<IFileHydrated> {
    return this.apiService.get<IFile>(`projects/${projectId}/files/${fileId}`).pipe(
      map(file => FileService.hydrateFile(file))
    );
  }

  /**
   *
   * @param projectId
   * @param fileId
   * @param input
   *
   * @returns
   */
  public updateFile(projectId: string, fileId: string, input: IUpdateFileInput): Observable<IFileHydrated> {
    return this.apiService.patch<IFile>(`projects/${projectId}/files/${fileId}`, input).pipe(
      map(file => FileService.hydrateFile(file))
    );
  }

  /**
   * @param projectId
   * @param fileIds
   *
   * @returns
   */
  public downloadFiles(projectId: string, fileIds: string[]): Observable<HttpResponse<Blob>> {
    return this.apiService.post<HttpResponse<Blob>>(`projects/${projectId}/files/download`, { fileIds }, {
      responseType: 'blob',
      observe: 'response'
    });
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public deleteFile(projectId: string, fileId: string): Observable<IFile> {
    return this.apiService.delete<IFile>(`projects/${projectId}/files/${fileId}`);
  }

  /**
   *
   * @param projectId
   * @param fileIds
   *
   * @returns
   */
  public deleteFiles(projectId: string, fileIds: string[]): Observable<IFile[]> {
    return forkJoin(fileIds.map(fileId => this.apiService.delete<IFile>(`projects/${projectId}/files/${fileId}`)));
  }

  /**
   *
   * @param projectId
   * @param fileId
   *
   * @returns
   */
  public convertFile(projectId: string, fileId: string): Observable<void> {
    return this.apiService.post<void>(`projects/${projectId}/files/${fileId}/convert`);
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public watchFiles(projectId: string): Observable<IWebsocketMessage<IFileHydrated>> {
    return this.websocketService.watchTopic<IFile>(`projects/${projectId}/files`).pipe(
      map(message => {
        message.data = FileService.hydrateFile(message.data);

        return message as IWebsocketMessage<IFileHydrated>;
      })
    );
  }
}
