@if (translatableFormGroup[languageCodeFormControl.value]) {
  <form class="flex flex-col justify-between bg-white/5 p-2 rounded-lg" [formGroup]="translatableFormGroup[languageCodeFormControl.value]">
      <div class="relative h-6">
        <nrc-input-language-selector
          class="absolute top-0 left-0 z-20"
          [formControl]="languageCodeFormControl"
          [options]="languageCodes"
          [value]="selectedLanguageCode()"
          [textSize]="inputFileSelectorTextSize.SMALL"
          (languageChanged)="handleLanguageChangeEvent($event)"
        />
      </div>
    <div class="flex">
      <nrc-input-file
        class="w-full"
        [formControl]="translatableFormGroup[languageCodeFormControl.value].controls.fileId"
        [fileTitle]="currentFile()?.translations?.at(0)?.title"
        [projectId]="projectId"
        [size]="size"
        [apiBaseUrl]="apiBaseUrl"
        [label]="'File title'"
        (fileChangedEvent)="handleFileChangeEvent($event)"
      />
    </div>

    @if (controlOpacity) {
      <div class="flex flex-col space-y-2 pt-2">
        <span class="text-xxs text-[#adadad] font-light tracking-wide">
          {{ 'COMPONENTS.SHARED.INPUTS.COLOR.OPACITY.LABEL' | translate }}
        </span>

        <nrc-input-range
          [formControl]="translatableFormGroup[languageCodeFormControl.value].controls.opacity"
          (valueChange)="handleOpacityChangeEvent($event)"
          [min]="0"
          [max]="100"
          [label]="'%'"
        />
      </div>
    }
  </form>
}