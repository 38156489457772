import { Injectable, signal } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { lastValueFrom } from 'rxjs';
import { IAreaElement, IAreaElementActionPreset, IAreaElementActionValue, IAPIListResponse, IListOptions } from '@newroom-connect/library/interfaces';
import { AreaElementEvent } from '@newroom-connect/library/types';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';

export interface IUpdateAreaElementInput extends Omit<IAreaElement, 'translations'> {
  translations?: IUpdateAreaElementTranslationInput[];
}

export interface IUpdateAreaElementTranslationInput {
  code: string,
  action?: IAreaElementActionInput,
  description?: string;
  backgroundFileId?: string;
  fileOpacity?: number;
}

export interface IAreaElementActionInput {
  name: string;
  presetId: string;
  value: IAreaElementActionValue | undefined;
}

export interface IGenerateAreaElementOptions {
  areaId: string;
  preset: IAreaElementActionPreset[],
  languageCodes: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AreaElementService extends EntityService {
  public areaElementSelectedSig = signal<IAreaElement | null>(null);

  public searchableProperties = ['name'];

  public static readonly DEFAULT_AREA_ELEMENT_NAME = 'Untitled Element';
  private static readonly DEFAULT_AREA_ELEMENT_WIDTH = 10;
  private static readonly DEFAULT_AREA_ELEMENT_HEIGHT = 10;
  private static readonly DEFAULT_AREA_ELEMENT_RADIUS = 9999;
  private static readonly DEFAULT_AREA_ELEMENT_EVENT: AreaElementEvent = { name: 'NONE' };
  private static readonly DEFAULT_AREA_ELEMENT_BASE_COLOR = '#000000ff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON_COLOR = '#ffffffff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON = 'trigger';

  private cachedAreaElementActionPresets?: IAPIListResponse<IAreaElementActionPreset>;

  /**
   * Generate a new area element based on the given input and some default parameters.
   * The area element will be initialized with a default size (width and height) and a position relative to the area the element is in.
   *
   * Since the area element is not saved on the server yet, it will get assigned an empty ID for itself.
   *
   * @param input The input to create the area element from.
   *
   * @returns The generated area element based on the given input and default parameters.
   */
  public generateAreaElement(input: IGenerateAreaElementOptions): IAreaElement {
    // The first item in the presets is considered to be the default action preset.
    const [defaultActionPreset] = input.preset;

    const areaElement: IAreaElement = {
      id: uuidv4(),
      areaId: input.areaId,
      name: AreaElementService.DEFAULT_AREA_ELEMENT_NAME,
      event: AreaElementService.DEFAULT_AREA_ELEMENT_EVENT,
      boundingBox: {
        width: AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH,
        height: AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT,
        top: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH / 2),
        left: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT / 2)
      },
      radius: AreaElementService.DEFAULT_AREA_ELEMENT_RADIUS,
      isEnabled: true,
      isLocked: false,
      baseColor: AreaElementService.DEFAULT_AREA_ELEMENT_BASE_COLOR,
      icon: AreaElementService.DEFAULT_AREA_ELEMENT_ICON,
      iconColor: AreaElementService.DEFAULT_AREA_ELEMENT_ICON_COLOR,
      // Add provided default action to all possible language codes to area element translations.
      translations: input.languageCodes.map(code => {
        return {
          languageCode: code,
          action: {
            name: defaultActionPreset.name,
            value: {},
            preset: defaultActionPreset
          },
          areaElementId: input.areaId,
          description: ''
        };
      })
    };

    return areaElement;
  }

  /**
   *
   * @param areaElements
   * @returns
   */
  public generateAreaElementsUpdateInput(areaElements?: IAreaElement[]): IUpdateAreaElementInput[] {
    if (!areaElements || !ArrayHelper.isNotEmpty(areaElements)) {
      return [];
    }

    return areaElements.map((areaElement) => {
      return {
        id: areaElement.id,
        areaId: areaElement.areaId,
        name: areaElement.name,
        event: areaElement.event,
        boundingBox: areaElement.boundingBox,
        radius: areaElement.radius,
        isEnabled: areaElement.isEnabled,
        isLocked: areaElement.isLocked,
        baseColor: areaElement.baseColor ?? null,
        icon: areaElement.icon,
        iconColor: areaElement.iconColor,
        translations: areaElement.translations.map((translation) => {
          return {
            code: translation.languageCode,
            action: translation.action ? {
              name: translation.action.name,
              value: translation.action.value,
              presetId: translation.action.preset.id
            } : undefined,
            description: translation.description,
            backgroundFileId: translation.backgroundFileId,
            fileOpacity: translation.fileOpacity
          };
        })
      };
    });
  }

  /**
   * Fetches a list of supported area element action presets.
   *
   * @param options
   * @returns
   */
  public async listAreaElementActionPreset(options?: IListOptions): Promise<IAPIListResponse<IAreaElementActionPreset>> {
    if (this.cachedAreaElementActionPresets) {
      return this.cachedAreaElementActionPresets;
    }

    const result = await lastValueFrom(super.list<IAreaElementActionPreset>('presets/areas/actions', undefined, options));

    this.cachedAreaElementActionPresets = result;
    return result;
  }
}
