import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';

@Component({
  selector: 'nrc-tab',
  template: '',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabComponent extends DestroyableComponent {
  @Input({ required: true }) public id!: string;
  @Input({ required: true }) public title!: string;
  @Input() public disabled? = false;
}
