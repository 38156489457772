import { Pipe, PipeTransform } from '@angular/core';
import { ObjectHelper } from '@newroom-connect/library/helpers';

@Pipe({
  standalone: true,
  name: 'extractProperty'
})
export class ExtractPropertyPipe implements PipeTransform {
  /**
   * Pipe for extracting a property from a given object.
   *
   * @param value The object to extract the property from.
   * @param propertyName The name of the property to extract the value from.
   *
   * @returns The value of the object property.
   */
  public transform(value: unknown, propertyName?: string): unknown {
    if (!propertyName || typeof value !== 'object' || value === null) {
      return value;
    }

    return ObjectHelper.extractProperty(value, propertyName);
  }
}
