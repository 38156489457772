<div #timepickerContainer class="w-full relative inline-block text-left">
  <div
    tabindex="0"
    class="peer flex space-x-3 w-full h-10 bg-neutral-900 pl-4 pt-4 pr-10
      rounded-t text-sm/[16px] border-0 border-b-1 caret-cyan
      focus:border-b-cyan-highlight focus:outline-none focus:ring-0"
    [ngClass]="{
      'border-b-error': formControl.invalid,
      'border-b-gray-700': formControl.valid
    }"
    (click)="toggleTimepicker()"
    (keyup)="null"
  >
    <nrc-icon
      class="block w-4 h-4 -translate-y-1"
      [name]="'time'"
    />

    <div>
      <span>{{ currentDate() | date:'HH:mm' }}</span>

      <div
        class="absolute ml-11 mt-0.5 top-0 left-0 text-xxs text-[#adadad]"
        [ngClass]="{
          'text-error': formControl.invalid
        }"
      >
        {{ label }}
      </div>
    </div>
  </div>

  @if (isTimepickerOpen) {
    <div class="w-40 h-80 fixed flex justify-center items-center mt-2 px-6 py-4 space-x-4 rounded-md shadow-lg bg-neutral-900 ring-1 ring-black ring-opacity-5 z-50">
      <div class="w-full h-full flex flex-col justify-center items-center space-y-1">
        <nrc-button
          class="w-6 h-6 relative flex rotate-90"
          [icon]="'arrow-back'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          [disabled]="selectedHour === 0"
          (clickEvent)="selectedHour > 0 ? selectHour(selectedHour - 1) : null"
        />

        <div #hourSelection class="w-full h-full hide-scrollbar overflow-auto touch-pan-y">
          <div class="flex flex-col justify-center items-center space-y-2">
            @for (hour of hours; track hour) {
              <div
                tabindex="0"
                class="w-9 h-9 flex justify-center items-center"
                [ngClass]="{
                  'opacity-30': selectedHour !== hour,
                  'bg-white/5 rounded-full': selectedHour === hour
                }"
                (click)="selectHour(hour)"
                (keyup)="null"
              >
                <span class="text-sm">
                  {{ hour < 10 ? '0' + hour : hour }}
                </span>
              </div>
            }
          </div>
        </div>

        <nrc-button
          class="w-6 h-6 relative flex -rotate-90"
          [icon]="'arrow-back'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          [disabled]="selectedHour === 23"
          (clickEvent)="selectedHour < 23 ? selectHour(selectedHour + 1) : null"
        />
      </div>

      <span class="text-sm">:</span>

      <div class="w-full h-full flex flex-col justify-center items-center space-y-1">
        <nrc-button
          class="w-6 h-6 relative flex rotate-90"
          [icon]="'arrow-back'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          [disabled]="selectedMinute === 0"
          (clickEvent)="selectedMinute > 0 ? selectMinute(selectedMinute - 1) : null"
        />

        <div #minuteSelection class="w-full h-full hide-scrollbar overflow-auto touch-pan-y">
          <div class="flex flex-col justify-center items-center space-y-2">
            @for (minute of minutes; track minute) {
              <div
                tabindex="0"
                class="w-9 h-9 flex justify-center items-center"
                [ngClass]="{
                  'opacity-30': selectedMinute !== minute,
                  'bg-white/5 rounded-full': selectedMinute === minute
                }"
                (click)="selectMinute(minute)"
                (keyup)="null"
              >
                <span class="text-sm">
                  {{ minute < 10 ? '0' + minute : minute }}
                </span>
              </div>
            }
          </div>
        </div>

        <nrc-button
          class="w-6 h-6 relative flex -rotate-90"
          [icon]="'arrow-back'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          [disabled]="selectedMinute === 59"
          (clickEvent)="selectedMinute < 59 ? selectMinute(selectedMinute + 1) : null"
        />
      </div>
    </div>
  }
</div>
