import { Pipe, PipeTransform } from '@angular/core';
import { TranslationJobStatus, ITranslationAttempt } from '@newroom-connect/library/interfaces';

@Pipe({
  name: 'orderByStatus',
  standalone: true
})
export class OrderByStatusPipe implements PipeTransform {
  private statusPriority = new Map<TranslationJobStatus, number>([
    [TranslationJobStatus.ACTIVE, 1],
    [TranslationJobStatus.COMPLETED, 2],
    [TranslationJobStatus.PENDING, 3],
    [TranslationJobStatus.FAILED, 4],
    [TranslationJobStatus.PAUSED, 5]
  ]);

  /**
   *
   * @param translations
   *
   * @returns
   */
  public transform(translations: ITranslationAttempt[]): ITranslationAttempt[] {
    if (!translations) {
      return [];
    }

    return [...translations].sort((a, b) => {
      // Sort by priority first
      const priorityDiff = (this.statusPriority.get(a.status) ?? 99) -
        (this.statusPriority.get(b.status) ?? 99);

      if (priorityDiff !== 0) {
        return priorityDiff;
      }

      // If same status, sort by timestamp (newest first)
      return b.timestamp - a.timestamp;
    });
  }
}
