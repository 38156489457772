import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../../../buttons/button/button.component';
import { IconComponent } from '../../../icon/icon.component';

@Component({
  selector: 'nrc-area-background-empty',
  standalone: true,
  imports: [IconComponent, ButtonComponent, TranslatePipe],
  templateUrl: './area-background-empty.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaBackgroundEmptyComponent {
  @Output() public changeBackgroundEvent = new EventEmitter<void>();
}
