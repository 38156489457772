<div
  class="flex flex-row justify-between items-center w-full h-full group disabled:opacity-50 hover:scale-105 transition-transform duration-250"
  [ngClass]="classList"
  (mouseenter)="isHovered.set(true)"
  (mouseleave)="isHovered.set(false)"
  (click)="handleItemActionButtonClickEvent()"
  (keyup)="null"
  tabindex="0"
>
  <div class="flex justify-center">
    <span class="text-sm font-light text-{{ iconFillColor() }}"> {{ label }} </span>
  </div>

  @if (icon) {
    <nrc-icon
      class="w-4 h-4 cursor-pointer "
      [name]="icon"
      [fillColor]="iconFillColor()"
    />
  }
</div>
