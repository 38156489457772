import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IconComponent } from '../../icon/icon.component';
import { ListLayout } from '../list.component';

@Component({
  standalone: true,
  imports: [
    IconComponent
  ],
  selector: 'nrc-list-layout-selector',
  templateUrl: './list-layout-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListLayoutSelectorComponent {
  @Input({ required: true }) public selectedLayout = 'grid';

  @Output() public layoutChangedEvent = new EventEmitter<ListLayout>();

  public handleLayoutChanged(layout: ListLayout): void {
    this.selectedLayout = layout;
    this.layoutChangedEvent.emit(layout);
  }
}
