<div
  class="relative flex flex-row justify-between rounded-full"
  [ngClass]="{
    'w-40 h-10': type === 'REGULAR',
    'w-16 h-7': type === 'ICON',
    }"
  >
  @for (option of options; let i = $index; track i) {
    <div
      class="absolute top-0 h-full bg-white/25 p-2"
      [ngClass]="{
        'rounded-l-full': i === 0,
        'rounded-r-full': i === options.length -1
      }"
      [style.width]="(100 / options.length) + '%'"
      [style.left]="(100 / options.length * i) + '%'"
    ></div>
  }

  <div class="h-full absolute flex justify-center items-center top-0 left-0 bg-cyan backdrop-blur-md shadow-[0_3px_6px_#00000029] transition-transform duration-250 rounded-full origin-left"
    [style.width]="(100 / options.length) + '%'"
    [style.transform]="'translateX(' + (100 * selectedIndex) + '%)'"
  ></div>

  @for (option of options; let i = $index; track i) {
    <div class="absolute top-0 h-full flex justify-center items-center bg-transparent p-2 space-x-2 cursor-pointer" tabindex="0" (click)="selectOption(i)" (keydown)="null"
      [style.width]="(100 / options.length) + '%'"
      [style.left]="(100 / options.length * i) + '%'"
    >

    @switch (type) {
      @case ('ICON') {
        @if (option.icon) {
          <nrc-icon
            class="w-3 h-3 cursor-pointer"
            [name]="option.icon"
            [fillColor]="i === selectedIndex ? 'white' : 'semi-transparent'"
          />
        }
      }

      @case ('REGULAR') {
        @if (option.icon) {
          <nrc-icon
            class="w-3 h-3 cursor-pointer"
            [name]="option.icon"
            [fillColor]="i === selectedIndex ? 'white' : 'semi-transparent'"
          />
        }

        <span
          class="text-sm transition-colors duration-250 cursor-pointer"
          [ngClass]="{
          'text-white/50': i !== selectedIndex,
          'text-white': i === selectedIndex,
          }"
        >
          {{ option.label || option.value }}
        </span>
      }
    }
    </div>
  }
</div>
