export type ColorWithAlphaChannel = { color: string; alpha: number };

export class ColorHelper {
  public static readonly HEX_ALPHA_REGEXP = /^#(?:[0-9a-f]{8})$/;

  private static readonly MAX_RGB_VALUE = 255;

  /**
   * Extract the color and the alpha channel value from a string in HEX alpha format `(#rrggbbaa)`.
   * The color value is extracted as HEX string `(#rrggbb)` while the alpha channel value is extracted as a number.
   *
   * @param hex The HEX alpha string to extract the color and alpha channel from.
   *
   * @returns The extracted color and alpha channel value.
   *
   * @throws Error, if the provided hex alpha string has an invalid format.
   */
  public static hexAlphaToColorWithAlpha(hex: string): ColorWithAlphaChannel {
    if (!ColorHelper.HEX_ALPHA_REGEXP.test(hex)) {
      throw new Error('Unable to convert hex alpha string: Wrong format (needs "#rrggbbaa")');
    }

    return {
      color: hex.substring(0, 7),
      alpha: parseInt(hex.substring(7), 16)
    };
  }

  /**
   * Build a string in HEX alpha format `(#rrggbbaa)` from a color and an alpha channel value.
   *
   * @param colorWithAlpha The color and alpha channel to convert to HEX alpha format.
   *
   * @returns The built string in HEX alpha format.
   */
  public static colorWithAlphaToHexAlpha(colorWithAlpha: ColorWithAlphaChannel): string {
    let opacityHex = Math.round(ColorHelper.MAX_RGB_VALUE * (colorWithAlpha.alpha / 100)).toString(16);

    if (opacityHex.length < 2) {
      opacityHex = `0${opacityHex}`;
    }

    return `${colorWithAlpha.color}${opacityHex}`;
  }
}
