import { ChangeDetectionStrategy, Component, effect, EventEmitter, Input, OnChanges, Output, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { IAreaElement, IFile, IFileHydrated } from '@newroom-connect/library/interfaces';
import { AreaElementService, LanguageService } from '@newroom-connect/library/services';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { DoubleClickDirective } from '@newroom-connect/library/directives';

import { IconComponent } from '../../icon/icon.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';

@Component({
  selector: 'nrc-area-element',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    TooltipComponent,
    MediaPreviewComponent,
    DoubleClickDirective
  ],
  templateUrl: './area-element.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaElementComponent implements OnChanges {
  @Input({ required: true }) public apiBaseUrl = '';
  @Input({ required: true }) public languageCode!: string;
  @Input({ required: true }) public areaElement!: IAreaElement;
  @Input() public isResizable = false;

  @Output() public clickEvent = new EventEmitter<void>();
  @Output() public doubleClickEvent = new EventEmitter<void>();

  public backgroundFile = signal<IFile | IFileHydrated | null>(null);
  public backgroundFileOpacity = signal<number>(1);
  public tooltipText = signal<string | null>(null);

  public isSelected = signal<boolean>(false);

  /**
   *
   * @param areaElementService
   * @param languageService
   */
  constructor(private readonly areaElementService: AreaElementService, private readonly languageService: LanguageService) {
    effect(() => {
      const currentSelectedElement = this.areaElementService.areaElementSelectedSig();
      const languageCode = this.languageService.watchSelectedLanguageCode()();

      if (!currentSelectedElement || !this.areaElement || !languageCode) {
        return;
      }

      this.isSelected.set(currentSelectedElement.id === this.areaElement.id);
      this.languageCode = languageCode;

      this.setAreaElementBackgroundFile(languageCode);
    }, { allowSignalWrites: true });
  }

  /**
   *
   */
  public ngOnChanges(): void {
    this.setAreaElementBackgroundFile(this.languageCode);
    this.setAreaElementTooltipText(this.languageCode);
  }

  /**
   *
   * @param event
   */
  public handleAreaElementClick(event: MouseEvent): void {
    if (event.detail !== 1) {
      return;
    }

    event.stopPropagation();

    this.isSelected.set(true);
    this.clickEvent.emit();
  }

  /**
   *
   */
  public handleAreaElementDblClick(): void {
    this.doubleClickEvent.emit();
  }

  /**
   *
   * @param languageCode
   */
  private setAreaElementBackgroundFile(languageCode: string): void {
    if (!this.areaElement || !ArrayHelper.isNotEmpty(this.areaElement.translations)) {
      return;
    }

    const translationPerLanguageCode = this.areaElement.translations.find(translation => translation.languageCode === languageCode);

    if (!translationPerLanguageCode) {
      return;
    }

    const fileOpacity = translationPerLanguageCode.fileOpacity ?? 100;

    this.backgroundFile.set(translationPerLanguageCode.backgroundFile ?? null);
    this.backgroundFileOpacity.set(fileOpacity / 100);
  }

  /**
   *
   * @param languageCode
   */
  private setAreaElementTooltipText(languageCode: string): void {
    if (!this.areaElement || !ArrayHelper.isNotEmpty(this.areaElement.translations)) {
      return;
    }

    const translationPerLanguageCode = this.areaElement.translations.find(translation => translation.languageCode === languageCode);

    if (!translationPerLanguageCode) {
      return;
    }

    this.tooltipText.set(translationPerLanguageCode.description ?? null);
  }
}
