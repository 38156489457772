<div class="absolute top-8 left-8">
  <div class="flex items-center gap-4">
    <nrc-icon
      class="w-12 h-12"
      [name]="'translate-circle'"
      [fillColor]="'cyan'"
    />
    <span class="font-light text-4xl tracking-wide">
      {{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.TITLE' | translate}}
    </span>
  </div>
</div>

<p class="font-light text-md text-[#ADADAD] pt-10">
  {{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.SELECT_LANGUAGE' | translate}}
</p>

<form [formGroup]="autoTranslateFormGroup" #overflowParentElement class="flex flex-col space-y-4 my-3">
  <div class="h-[3rem]">
    <nrc-input-language-selector
      [options]="sourceLanguageCodes"
      [formControl]="autoTranslateFormGroup.controls.from"
      [type]="inputLanguageSelectorComponentType.LABELED"
      [overflowElement]="overflowParentElement"
      [label]="'From'"
      (languageChanged)="autoTranslateFormGroup.controls.from.setValue($event)"
    />
  </div>
  <div class="h-[3rem]">
    <nrc-input-language-selector
      [options]="targetLanguageCodes"
      [formControl]="autoTranslateFormGroup.controls.to"
      [type]="inputLanguageSelectorComponentType.LABELED"
      [overflowElement]="overflowParentElement"
      [label]="'To'"
      (languageChanged)="autoTranslateFormGroup.controls.to.setValue($event)"
    />
  </div>
  <div class="font-light text-sm">
    <p>{{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.ELEMENTS_CANNOT_BE_TRANSLATED' | translate}}</p>
    <ul class="ml-6 list-disc text-[#ADADAD]">
      <li class="my-1">{{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.IDS' | translate}}</li>
      <li class="my-1">{{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.RADIO_BUTTONS' | translate}}</li>
      <li class="my-1">{{'PAGES.AREA_DETAIL.AREA_ELEMENT.AREA_ELEMENT_ACTION_INPUTS_TRANSLATION.FILE_INPUTS' | translate}}</li>
    </ul>
  </div>
</form>

<hr class="w-full h-[2px] mt-3 bg-[#2d2d2d] border-none">

<div class="flex flex-row justify-between w-full mt-3 mb-1">
  <nrc-button
    [label]="'Cancel'"
    [buttonType]="buttonType.DEFAULT"
    [role]="actionRole.SECONDARY"
    [fontWeight]="'bold'"
    (clickEvent)="closeEvent.emit()"
  />
  <nrc-button
    [label]="'Translate'"
    [buttonType]="buttonType.DEFAULT"
    [role]="actionRole.PRIMARY"
    [fontWeight]="'bold'"
    [disabled]="autoTranslateFormGroup.invalid"
    (clickEvent)="translateInputs()"
  />
</div>
