import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, of, switchMap, throwError } from 'rxjs';
import { IArea, AreaPerspective, IAPIListResponse, IListOptions } from '@newroom-connect/library/interfaces';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';

import { IUpdateAreaElementInput } from './area-element.service';

export interface ICreateAreaInput {
  projectId: string;
  title: string; // Will be distributed across all translations
  translations: { code: string; }[]; // The language codes which are used for i81n properties of the area
  perspective: AreaPerspective;
  templateId?: string; // The optional ID of the template to pre-populate the project data with
}

export interface IUpdateAreaInput {
  slug?: string;
  enabled?: boolean;
  translations?: IUpdateAreaTranslationInput[];
  password?: string | null;
  areaElements?: IUpdateAreaElementInput[];
}

export interface IUpdateAreaTranslationInput {
  code: string;
  title?: string;
  backgroundFileId?: string;
}

export interface IDuplicateAreaInput {
  title: string;
}

@Injectable({
  providedIn: 'root'
})
export class AreaService extends EntityService {
  public static readonly AREA_DEFAULT_TITLE = 'Unknown Area';

  public searchableProperties = ['slug', 'translations[].title'];

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listAreas(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IArea>> {
    return super.list<IArea>(`projects/${projectId}/areas`, undefined, options);
  }

  /**
   *
   * @param projectId
   * @param areaId
   *
   * @returns
   */
  public getArea(projectId: string, areaId: string): Observable<IArea> {
    return this.apiService.get<IArea>(`projects/${projectId}/areas/${areaId}`);
  }

  /**
   *
   * @param projectId
   * @param areaSlug
   *
   * @returns
   */
  public getAreaBySlug(projectId: string, areaSlug: string): Observable<IArea> {
    return this.listAreas(projectId, { filters: { slug: areaSlug } }).pipe(
      switchMap(response => {
        if (ArrayHelper.isNotEmpty(response.data)) {
          // Re-fetch the area with the found id, to guarantee all needed properties are available.
          // Needed to fetch the area elements translations and file references ect..
          return this.getArea(projectId, response.data[0].id);
        }

        return throwError(() => new HttpErrorResponse({
          error: 'Area not found',
          status: 404
        }));
      })
    );
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public createArea(input: ICreateAreaInput): Observable<IArea> {
    return this.apiService.post<IArea>(`projects/${input.projectId}/areas`, input);
  }

  /**
   *
   * @param projectId
   * @param areaId
   * @param input
   *
   * @returns
   */
  public updateArea(projectId: string, areaId: string, input: IUpdateAreaInput): Observable<IArea> {
    return this.apiService.patch<IArea>(`projects/${projectId}/areas/${areaId}`, input);
  }

  /**
   *
   * @param projectId
   * @param areaId
   *
   * @returns
   */
  public deleteArea(projectId: string, areaId: string): Observable<IArea> {
    return this.apiService.delete<IArea>(`projects/${projectId}/areas/${areaId}`);
  }

  /**
   *
   * @param projectId
   * @param areaIds
   *
   * @returns
   */
  public deleteAreas(projectId: string, areaIds: string[]): Observable<IArea[]> {
    return forkJoin(areaIds.map(areaId => this.apiService.delete<IArea>(`projects/${projectId}/areas/${areaId}`)));
  }

  /**
   *
   * @param projectId
   * @param areaId
   * @param input
   *
   * @returns
   */
  public duplicateArea(projectId: string, areaId: string, input: IDuplicateAreaInput): Observable<IArea> {
    return this.apiService.post<IArea>(`projects/${projectId}/areas/${areaId}/duplicate`, input);
  }
}
