<div [ngClass]="{ 'opacity-50 select-none pointer-events-none': disabled }">
  <input
  #inputElement
  type="checkbox"
  [name]="name"
  [id]="id"
  [tabindex]="tabIndex"
  [checked]="!!formControl.value"
  class="hidden w-0 h-0 peer"
  (input)="handleInputChange($event)"
/>

<label
  [for]="id"
  class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out"
>
  <span class="flex justify-center items-center mr-2 w-5 h-5 bg-transparent
    border-1 border-solid border-gray-400 rounded-sm cursor-pointer
    transition-all duration-250 ease-in-out
    group-[hover]:bg-transparent peer-focus:group-[]:bg-transparent
    peer-checked:group-[]:border-10 peer-checked:group-[]:border-solid peer-checked:group-[]:border-cyan peer-checked:group-[]:animate-shrink-bounce
    peer-checked:group-[]:before:content-[''] peer-checked:group-[]:before:absolute peer-checked:group-[]:before:top-[11px] peer-checked:group-[]:before:left-[4.5px]
    peer-checked:group-[]:before:border-r-1.5 peer-checked:group-[]:before:border-r-solid peer-checked:group-[]:before:border-r-transparent
    peer-checked:group-[]:before:border-b-1.5 peer-checked:group-[]:before:border-b-solid peer-checked:group-[]:before:border-b-transparent
    peer-checked:group-[]:before:rotate-45 peer-checked:group-[]:before:origin-bottom-left peer-checked:group-[]:before:animate-checkbox-check"
  >
  </span>
  {{ label }}
</label>

</div>
