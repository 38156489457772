import { Directive, ElementRef, EventEmitter, HostListener, Output, inject, OnDestroy, OnInit } from '@angular/core';
import { Subject, buffer, debounceTime, filter, map } from 'rxjs';

export type DoubleClickEvent = MouseEvent;

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nrcDoubleClick]',
  standalone: true
})
export class DoubleClickDirective implements OnInit, OnDestroy {
  @Output() public doubleClickEvent = new EventEmitter<DoubleClickEvent>();

  private readonly elementRef = inject(ElementRef);
  private readonly click$ = new Subject<MouseEvent>();
  private readonly DOUBLE_CLICK_DELAY = 250;

  /**
   * Listens for click events on the host element and emits them to the click$ subject.
   *
   * @param event The MouseEvent triggered by clicking the element.
   */
  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    this.click$.next(event);
  }

  /**
   * Initializes the double click detection logic using RxJS operators.
   * First, Buffer collects clicks within the specified time window.
   * Then, Filter ensures we only process pairs of clicks.
   * Finally, Map extracts the second click event for emission.
   *
   */
  public ngOnInit(): void {
    this.click$
      .pipe(
        buffer(this.click$.pipe(debounceTime(this.DOUBLE_CLICK_DELAY))),
        filter((clickEvents: MouseEvent[]) => clickEvents.length === 2),
        map((clickEvents: MouseEvent[]) => clickEvents[1])
      )
      .subscribe((event: MouseEvent) => {
        this.doubleClickEvent.emit(event);
      });
  }

  /**
   * Cleans up the click$ subject to prevent memory leaks.
   *
   */
  public ngOnDestroy(): void {
    this.click$.complete();
  }
}
