import { trigger, transition, style, animate } from '@angular/animations';

export const growAnimation = trigger('growAnimation', [
  transition(':enter', [
    style({ height: '{{ heightStart }}' }),
    animate('350ms ease-out', style({ height: '{{ heightEnd }}' }))
  ], { params: { heightStart: '0', heightEnd: '100%' } }),
  transition(':leave', [
    animate('350ms ease-in', style({ height: '{{ heightStart }}' }))
  ], { params: { heightStart: '0' } })
]);
