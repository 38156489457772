export class StringHelper {
  /**
   * Transform a given value to a string.
   *
   * If the value is `typeof object`, the object will be stringified.
   * If the value is `typeof undefined`, an empty string will be returned.
   * Otherwise the value will just be wrapped into a string.
   *
   * @param value The value to transform to a string.
   *
   * @returns The stringified value.
   */
  public static toString(value: unknown): string {
    switch (typeof value) {
      case 'object':
        return JSON.stringify(value);
      case 'undefined':
        return '';
      default:
        return `${value}`;
    }
  }

  /**
   * Generates a random string of a specified length for minor handling purposes.
   *
   * Note: This method is not secure enough for cryptographic purposes and should not be used for generating passwords, tokens, or other sensitive data.
   * The generated string will contain only alphanumeric characters (a-z, A-Z, 0-9).
   *
   * @param length The length of the random string to generate.
   * @returns A random string of the specified length.
   */
  public static generateRandomString(length: number): string {
    return Math.random()
      .toString(36)
      .substring(2, length + 2); // Add 2 to include the substring start index
  }

  /**
   * Converts a `camelCase` string to a `kebab-case` string.
   * For further information see: https://stackoverflow.com/questions/63116039/camelcase-to-kebab-case .
   *
   * @param camelCaseString
   * @returns
   */
  public static camelToKebab(camelCaseString: string): string {
    return camelCaseString.replace(/[A-Z]+(?![a-z])|[A-Z]/g, (matchedSubstring, offset) => (offset ? '-' : '') + matchedSubstring.toLowerCase());
  }
}
