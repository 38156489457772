<button
  type="button"
  class="w-full h-full group peer disabled:opacity-50"
  [ngClass]="{
    'flex enabled:hover:scale-95 transition-transform duration-500': buttonType === buttonTypeActive.ICON,
    'flex items-center space-x-3': buttonType !== buttonTypeActive.ICON,
    'p-2.5': !label,
    'px-5 py-2.5': label,
    'justify-center': align === 'center' && buttonType !== buttonTypeActive.ICON,
    'justify-start': align === 'left' && buttonType !== buttonTypeActive.ICON,
    'justify-end': align === 'right' && buttonType !== buttonTypeActive.ICON,
    'peer': tooltipText,
    'btn-primary': !role || role === actionRole.PRIMARY,
    'btn-secondary': role === actionRole.SECONDARY,
    'btn-transparent-primary': role === actionRole.TRANSPARENT_PRIMARY,
    'btn-transparent-secondary': role === actionRole.TRANSPARENT_SECONDARY,
    'btn-error': role === actionRole.ERROR,
    'btn-warning': role === actionRole.WARNING,
    'btn-success': role === actionRole.SUCCESS,
    'rounded': !isRound,
    'rounded-full': isRound
  }"
  [disabled]="disabled ?? false"
  (mouseenter)="isHovered.set(true)"
  (mouseleave)="isHovered.set(false)"
  (click)="$event.stopPropagation(); clickEvent.emit();"
>
  @if (icon) {
    <nrc-icon
      [name]="icon"
      [ngClass]="{
        'w-1/2 h-1/2 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 group-enabled:cursor-pointer group-disabled:cursor-default': buttonType === buttonTypeActive.ICON,
        'w-5 h-5': buttonType !== buttonTypeActive.ICON
      }"
      [fillColor]="iconFillColor()"
    />
  }

  <!-- The font size increases in icon only mode to use a character like "+" as an icon -->
  @if (label) {
    <span
      [ngClass]="{
        'font-light text-3xl md:text-xl xl:text-3xl': buttonType === buttonTypeActive.ICON,
        'font-light': buttonType !== buttonTypeActive.ICON && fontWeight === 'light',
        'font-normal': buttonType !== buttonTypeActive.ICON && fontWeight === 'normal',
        'font-semibold': buttonType !== buttonTypeActive.ICON && fontWeight === 'semibold',
        'font-bold': buttonType !== buttonTypeActive.ICON && fontWeight === 'bold',
        'text-xs': buttonType !== buttonTypeActive.ICON && textSize === 'xs',
        'text-sm': buttonType !== buttonTypeActive.ICON && textSize === 'sm',
        'text-base': buttonType !== buttonTypeActive.ICON && textSize === 'base',
        'text-lg': buttonType !== buttonTypeActive.ICON && textSize === 'lg',
        'text-xl': buttonType !== buttonTypeActive.ICON && textSize === 'xl'
      }"
    >
      {{ label }}
    </span>
  }
</button>

@if (tooltipText) {
  <nrc-tooltip
    class="tooltip tooltip-peer pt-2 min-w-[4.5rem]"
    [text]="tooltipText"
  />
}
