import { Injectable, Signal, signal } from '@angular/core';
import { BehaviorSubject, Observable, debounceTime } from 'rxjs';

export interface IErrorMessage {
  message: string;
  headline?: string;
}

@Injectable({
  providedIn: 'root'
})
export class StateService {
  public isLoading$ = new BehaviorSubject<boolean>(false);
  public errorMessage = signal<IErrorMessage | null>(null);

  /**
   *
   * @param debounceTimeInMs
   *
   * @returns
   */
  public watchLoadingState(debounceTimeInMs = 500): Observable<boolean> {
    return this.isLoading$.pipe(debounceTime(debounceTimeInMs));
  }

  /**
   *
   * @param isLoading
   */
  public setLoadingState(isLoading: boolean): void {
    this.isLoading$.next(isLoading);
  }

  /**
   *
   * @returns
   */
  public watchErrorMessage(): Signal<IErrorMessage | null> {
    return this.errorMessage.asReadonly();
  }

  /**
   * Set the error message.
   * After a specified timeout, reset the error message.
   *
   * @param errorMessage
   * @param resetTimeoutInMs
   */
  public setErrorMessage(errorMessage: IErrorMessage, resetTimeoutInMs = 5000): void {
    this.errorMessage.set(errorMessage);

    setTimeout(() => {
      this.errorMessage.set(null);
    }, resetTimeoutInMs);
  }

  /**
   * Reset the current error message of the state service.
   */
  public resetErrorMessage(): void {
    this.setErrorMessage({ message: '' }, 0);
  }
}
