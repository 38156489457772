import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'daysFromNow'
})
export class DaysFromNowPipe implements PipeTransform {
  /**
   * Calculate the day difference between the given date and the current date.
   *
   * @param targetDate The target date to calculate the difference from now for.
   *
   * @returns The day difference between the given date and the current date.
   */
  public transform(targetDate: Date): number {
    // The number of milliseconds in one day.
    const ONE_DAY = 1000 * 60 * 60 * 24;

    // Calculate the difference in milliseconds.
    const differenceMs = Math.abs(Date.now() - targetDate.getTime());

    // Convert back to days and return.
    return Math.round(differenceMs / ONE_DAY);
  }
}
