import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, forwardRef, inject, signal } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ToStringPipe } from '@newroom-connect/library/pipes';
import { ActionRole, Level } from '@newroom-connect/library/enums';
import { ISystemMessage } from '@newroom-connect/library/interfaces';
import { TranslationService } from '@newroom-connect/library/services';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { UnsavedChangesService } from '../../unsaved-changes-alert/unsaved-changes-alert.component';

export enum InputRadioComponentType {
  REGULAR = 'REGULAR',
  ROWS = 'ROWS',
  COLUMNS = 'COLUMNS',
  CARDS = 'CARDS'
}

export enum InputRadioComponentSize {
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export interface InputRadioComponentOption {
  value: unknown;
  label: string;
  icon?: string;
  description?: string;
  id?: string; // A custom ID for each radio option. If not provided, the radio ID + option index will be used.
}

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, IconComponent, TooltipComponent, ToStringPipe],
  selector: 'nrc-input-radio',
  templateUrl: './input-radio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true
    }
  ]
})
export class InputRadioComponent<T = any> extends InputComponent<string> {
  protected readonly unsavedChangesService = inject(UnsavedChangesService);
  protected readonly translationService = inject(TranslationService);

  @Input() public options: InputRadioComponentOption[] = [];
  @Input() public type = InputRadioComponentType.REGULAR;
  @Input() public size = InputRadioComponentSize.MEDIUM;
  @Input() public confirmChange = false;

  public inputRadioComponentType = InputRadioComponentType;
  public inputRadioComponentSize = InputRadioComponentSize;
  public actionRole = ActionRole;

  public selectedOption = signal<T | null>(null);
  public selectedOptionDescription = signal<string | null>(null);

  /**
   *
   * @param value
   */
  public override writeValue(value: any): void {
    this.selectedOption.set(value);

    const optionDescription = this.options.find((radioOption) => radioOption.value === value);

    if (optionDescription) {
      this.selectedOptionDescription.set(optionDescription.description || null);
    }
  }

  /**
   * Select a given option where it is set as the selected option and the form control value.
   * If the given option has a description, it is set as the selected option description.
   *
   * @param option The option to select.
   */
  public async selectOption(option: T | unknown): Promise<void> {
    if (this.confirmChange) {
      const previousOption = this.options.find(
        radioOption => radioOption.value === this.selectedOption()
      );

      if (!previousOption || previousOption.value === option) {
        this.selectOption(option);
        return;
      }

      const message: ISystemMessage = {
        headline: this.translationService.translate('COMPONENTS.SHARED.INPUTS.RADIO_BUTTON.CHANGE_OPTION.HEADLINE'),
        message: this.translationService.translate('COMPONENTS.SHARED.INPUTS.RADIO_BUTTON.CHANGE_OPTION.MESSAGE', { label: this.label, option: previousOption?.label }),
        footer: this.translationService.translate('COMPONENTS.SHARED.INPUTS.RADIO_BUTTON.CHANGE_OPTION.FOOTER'),
        level: Level.ERROR
      };

      const discardChanges = await this.unsavedChangesService.confirmUnsavedChanges(message);

      if (!discardChanges) {
        return;
      }
    }

    this.selectedOption.set(option as T);

    const optionDescription = this.options.find(radioOption => radioOption.value === option);

    if (optionDescription) {
      this.selectedOptionDescription.set(optionDescription.description || null);
    }

    this.formControl.setValue(option);
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }

  /**
   *
   * @param value
   *
   * @returns
   */
  public isOptionSelected(value: T | unknown): boolean {
    return this.selectedOption() === value;
  }
}
