import { IFile } from '@newroom-connect/library/interfaces';
import { Dimensions } from '@newroom-connect/library/types';

export class ImageHelper {
  private static readonly MINIMUM_PANORAMA_RESOLUTION = 8192;
  private static readonly MAXIMUM_PANORAMA_RESOLUTION = 16384;

  /**
   * Check if image is panorama by calculating if width and height match 2:1 aspect ratio.
   *
   * @param resolution
   * @param resolution.width
   * @param resolution.height
   *
   * @returns Indicator, if image is panorama.
   */
  public static isPanoramaImage(resolution: Dimensions): boolean {
    return resolution.width === (resolution.height * 2);
  }

  /**
   * Check by the given resolution, if the image would be supported as a cube map.
   *
   * @param resolution
   * @param resolution.width
   * @param resolution.height
   *
   * @returns Indicator, if image is supported as cube map.
   */
  public static isCubeMapSupported(resolution: Dimensions): boolean {
    return resolution.width >= ImageHelper.MINIMUM_PANORAMA_RESOLUTION && resolution.width <= ImageHelper.MAXIMUM_PANORAMA_RESOLUTION && ImageHelper.isPanoramaImage(resolution);
  }

  /**
   * Checks if a file meets the aspect-ratio requirements.
   *
   * @param file
   * @param width
   * @param height
   *
   * @returns True if the file meets the aspect-ration requirements, false otherwise.
   */
  public static doesImageMeetDimensions(file: IFile, width = 1920, height = 1080): boolean {
    return (file.width && file.height) ? (file.width / file.height === width / height) : false;
  }
}
