@if (source) {
  @switch (mediaPreviewType()) {
    @case ('image') {
      <nrc-image
        class="w-full h-full"
        [source]="{ uri: source, mimetype }"
        [objectFit]="objectFit"
        [isRounded]="true"
        (loadComplete)="loadComplete.emit()"
      />
    }
    @case ('video') {
      <nrc-video
        class="w-full h-full"
        [sources]="[{ uri: source, mimetype }]"
        [isRounded]="true"
        (loadComplete)="loadComplete.emit()"
      />
    }
    @case ('video-snippet') {
      <nrc-video
        class="w-full h-full"
        [sources]="[{ uri: source, mimetype }]"
        [isRounded]="true"
        [autoplay]="true"
        [loop]="true"
        [controls]="false"
        [muted]="true"
        [playsInline]="true"
        [disablePictureInPicture]="true"
        [objectFit]="'cover'"
        (loadComplete)="loadComplete.emit()"
      />
    }
    @case ('audio') {
      <nrc-audio class="w-full h-full" [sources]="[{ uri: source, mimetype }]" />
    }
    @default {
      <nrc-file-type class="flex items-center justify-center w-full h-full rounded-md grow" [nrcAspectRatio]="'height'" [mimetype]="mimetype" [classList]="classList" [iconColor]="'white'"/>
    }
  }
} @else {
  <nrc-file-type class="flex items-center justify-center w-full h-full grow" [mimetype]="mimetype" [classList]="classList" [iconColor]="'white'"/>
}
