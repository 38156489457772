import { JwtHelper } from '@newroom-connect/library/helpers';
import { ITokenSet } from '@newroom-connect/library/interfaces';

/**
 * Model which represents a user session.
 */
export class UserSession {
  private tokenSet?: Partial<ITokenSet>;
  private sessionId?: string;
  private rememberMe?: boolean;
  private isPreviewMode = false;
  private username?: string;
  private isCodeLogin = false;

  /**
   * @constructor
   *
   * @param tokenSet
   * @param sessionId
   * @param username
   * @param rememberMe
   * @param isPreviewMode
   * @param isCodeLogin
   */
  constructor(
    tokenSet: Partial<ITokenSet>,
    sessionId: string,
    username?: string,
    rememberMe?: boolean,
    isPreviewMode = false,
    isCodeLogin = false
  ) {
    this.tokenSet = tokenSet;
    this.sessionId = sessionId;
    this.username = username;
    this.rememberMe = rememberMe;
    this.isPreviewMode = isPreviewMode;
    this.isCodeLogin = isCodeLogin;
  }

  /**
   * Get the access token of the current user session.
   *
   * @returns Access token of the user session or `null`, if the token is not set.
   */
  public getAccessToken(): string | null {
    return this.tokenSet?.accessToken ?? null;
  }

  /**
   * Get the ID token of the current user session.
   *
   * @returns ID token of the user session or `null`, if the token is not set.
   */
  public getIdToken(): string | null {
    return this.tokenSet?.idToken ?? null;
  }

  /**
   * Get the Session ID of the current user session.
   *
   * @returns Session ID of the user session or `null`, if the token is not set.
   */
  public getSessionId(): string | null {
    return this.sessionId ?? null;
  }

  /**
   * Get the refresh token of the current user session.
   *
   * @returns Refresh token of the user session or `null`, if the token is not set.
   */
  public getRefreshToken(): string | null {
    return this.tokenSet?.refreshToken ?? null;
  }

  /**
   * Get the "remember me" status of the user session.
   *
   * @returns Remember me status of the user session.
   */
  public getRememberMe(): boolean {
    return this.rememberMe ?? false;
  }

  /**
   * Get the username of the current user session.
   *
   * @returns Username of the user session or `null`, if the username is not set.
   */
  public getUsername(): string | null {
    return this.username ?? null;
  }

  /**
   * Check if the current user session is valid.
   *
   * @returns Boolean indicating if the current user session is valid.
   */
  public isValid(): boolean {
    const accessToken = this.getAccessToken();
    const idToken = this.getIdToken();

    if (!accessToken || !idToken) {
      return false;
    }

    return !JwtHelper.isTokenExpired(accessToken) && !JwtHelper.isTokenExpired(idToken);
  }

  /**
   * Check if the user session needs to refresh.
   *
   * @returns Boolean indicating whether the user session needs to be refreshed.
   */
  public needsRefresh(): boolean {
    if (this.isValid()) {
      return false;
    }

    const refreshToken = this.getRefreshToken();

    if (!refreshToken) {
      this.tokenSet = undefined;
      return false;
    }

    return true;
  }

  /**
   * Get the preview mode status of the user session.
   *
   * @returns Preview mode status of the user session.
   */
  public getIsPreviewMode(): boolean {
    return this.isPreviewMode;
  }

  /**
   * Get the code login status of the user session.
   *
   * @returns Code login status of the user session.
   */
  public getIsCodeLogin(): boolean {
    return this.isCodeLogin;
  }
}
