
@switch (config.type) {
  @case (listItemType.CARD) {
    <div
      class="w-full flex items-center shadow-[0_6px_14px_#00000033] bg-[#fefefe0d] hover:bg-[#fefefe1a] rounded-10 border-2 border-solid"
      [ngClass]="{
        'border-white/5': !isSelected,
        'border-cyan-highlight': isSelected,
      }"
      [nrcAspectRatio]="'width'"
    >
      <div
        class="relative w-full h-full flex flex-col items-center"
        nrcHoverDelay
        [debounceTimeMouseLeave]="200"
        [targetId]="'actionButtonMenu-'+ index"
      >
        @if (config.thumbnailConfig && config.mimetype) {
          <nrc-media-preview
            class="absolute top-0 left-0 w-full h-full flex justify-center rounded-10 overflow-hidden"
            [mimetype]="config.mimetype"
            [source]="config.thumbnailSource | toString"
            [mediaPreviewTypes]="['image', 'video-snippet']"
            (click)="handleItemClickEvent(index)"
          />
        }

        @if (showCheckbox) {
          <nrc-checkbox
            class="absolute top-1 left-0"
            [checkStatus]="isSelected ? 'checked' : 'unchecked'"
          />
        }

        @if (config['buttonConfigs']) {
          <div class="absolute top-1 right-1 flex flex-col space-y-1">
            @for (buttonConfig of config['buttonConfigs']; track buttonConfigIndex; let buttonConfigIndex = $index) {
              @if (!buttonConfig['visibilityFn'] || buttonConfig['visibilityFn'] | evaluate: (config.originalValue | extractProperty: buttonConfig.key)) {
                <nrc-button
                  class="w-8 h-8 relative"
                  [icon]="buttonConfig['extractorFn'] ? (buttonConfig['extractorFn'] | evaluate: (config.originalValue | extractProperty: buttonConfig.key) | toString) : (config.value | toString)"
                  [buttonType]="buttonType.ICON"
                  [role]="actionRole.SECONDARY"
                  [disabled]="buttonConfig['disabled']"
                  [tooltipText]="buttonConfig['tooltipTextFn'] ? (buttonConfig['tooltipTextFn'] | evaluate: (config.originalValue | extractProperty: buttonConfig.key)) : buttonConfig['tooltipText']"
                  (clickEvent)="configButtonClickEvent.emit({ index: buttonConfigIndex })"
                />
              }
            }
          </div>
        }

        <!-- Action buttons for each item -->
        @if (showContextMenu) {
          <div
            class="hidden bg-transparent-dark rounded-lg absolute top-full left-1/2 transform -translate-x-1/2 translate-y-1 z-20 transition duration-1000 ease-in-out delay-1000"
            [id]="'actionButtonMenu-' + index"
          >
            <div class="grid grid-cols-1 divide-y divide-[#2d2d2d] w-56 justify-center items-start">
              @for (actionButton of config['itemActionButtons']; track $index) {
                <div class="flex flex-row justify-between items-center py-2 px-4 cursor-pointer">
                  <nrc-action-button-menu-item
                    class="flex justify-between w-full"
                    [icon]="actionButton?.icon"
                    [label]="actionButton?.label"
                    [role]="actionButton?.role"
                    (clickEvent)="handleItemActionButtonClickEvent(actionButton, index)"
                  />
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>

    <div class="w-full flex flex-col items-start pt-2">
      <div class="relative w-full flex flex-col justify-center items-center">
        <div class="peer w-full flex flex-row">
          <span
            class="text-sm text-ellipsis overflow-hidden whitespace-nowrap"
            [ngClass]="{
              'text-white font-light': !config.isHighlighted,
              'text-cyan font-semibold': config.isHighlighted && isSelected,
            }"
          >
            {{ config.value }}
          </span>
        </div>

        @if (config.showTooltip) {
          <nrc-tooltip
            class="tooltip tooltip-peer w-52"
            [text]="config.value | toString"
          />
        }
      </div>

      <div class="flex flex-row">
        @if (config.additionalInfo) {
          <span class="text-white/50 text-sm font-light">
            {{ config.additionalInfo | toString }}
          </span>
        }
      </div>
    </div>
  }
}
