import { Observable, retry, timer } from 'rxjs';

export interface IRetryConfiguration {
  count: number;
  delay: number;
}

export class RxJSHelper {
  private static readonly DEFAULT_RETRY_CONFIG: IRetryConfiguration = { count: 3, delay: 200 };

  /**
   * Create an observable which pipes a retry with exponential backoff into the source observable.
   * This method can be used as a pipe operator in an Observable chain.
   *
   * @param config An optional configuration object to declare a custom backoff strategy.
   * @param config.count The number of retries.
   * @param config.delay The initial interval in milliseconds used for the backoff.
   *
   * @returns
   */
  public static retryBackoff<T>(config: IRetryConfiguration = RxJSHelper.DEFAULT_RETRY_CONFIG): (obs$: Observable<T>) => Observable<T> {
    return (obs$: Observable<T>) => obs$.pipe(
      retry({
        count: config.count,
        delay: (_, retryIndex) => {
          const d = Math.pow(2, retryIndex - 1) * config.delay;

          return timer(d);
        }
      })
    );
  }
}
