import { trigger, state, style, animate, transition } from '@angular/animations';

export const slideFadeAnimation = trigger('slideFade', [
  state('*', style({ opacity: 1, transform: 'translateY(0)' })),
  transition(':enter', [
    style({ opacity: 0, transform: 'translateY(20px)' }),
    animate('300ms ease-out')
  ]),
  transition(':leave', [
    animate('300ms ease-in', style({ opacity: 0, transform: 'translateY(-20px)' }))
  ]),
  transition('* => *', [
    style({ opacity: 0, transform: 'translateY(20px)' }),
    animate('300ms ease-out')
  ])
]);
