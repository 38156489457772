@if (source) {
  @switch (mediaPreviewType()) {
    @case ('image') {
      <nrc-image class="w-full h-full" [source]="{ uri: source, mimetype }" [objectFit]="objectFit" />
    }
    @case ('video') {
      <nrc-video class="w-full h-full" [sources]="[{ uri: source, mimetype }]" />
    }
    @case ('video-snippet') {
      <nrc-video class="w-full h-full" [sources]="[{ uri: source, mimetype }]" [autoplay]="true" [loop]="true" [controls]="false" [muted]="true" [playsInline]="true" [disablePictureInPicture]="true" [objectFit]="'cover'" />
    }
    @case ('audio') {
      <nrc-audio class="w-full h-full" [sources]="[{ uri: source, mimetype }]" />
    }
    @default {
      <nrc-file-type class="w-full h-full flex justify-center items-center" [nrcAspectRatio]="'height'" [mimetype]="mimetype" [classList]="classList" [iconColor]="'white'"/>
    }
  }
} @else {
  <nrc-file-type class="w-full h-full flex justify-center items-center" [mimetype]="mimetype" [classList]="classList" [iconColor]="'white'"/>
}
