import { Pipe, PipeTransform } from '@angular/core';

import { ColorType } from '../fill-color/fill-color.pipe';

export type StrokeColor = ColorType;

@Pipe({
  standalone: true,
  name: 'strokeColor'
})
export class StrokeColorPipe implements PipeTransform {
  /**
   * Determine the stroke color CSS class based on the given icon stroke color.
   *
   * @param strokeColor The stroke color to determine the stroke color CSS class from.
   *
   * @returns The Tailwind CSS class of the stroke color.
   */
  public transform(strokeColor?: StrokeColor): { [className: string]: any; } {
    const strokeColorMap: Record<string, boolean> = {
      'stroke-white': !strokeColor || strokeColor === 'white',
      'stroke-black': strokeColor === 'black',
      'stroke-cyan': strokeColor === 'cyan',
      'stroke-transparent': strokeColor === 'transparent',
      'stroke-white/50': strokeColor === 'semi-transparent',
      'stroke-error': strokeColor === 'error',
      'stroke-warning': strokeColor === 'warning',
      'stroke-success': strokeColor === 'success'
    };

    for (const strokeColorMapKey of Object.keys(strokeColorMap)) {
      if (!strokeColorMap[strokeColorMapKey]) {
        delete strokeColorMap[strokeColorMapKey];
      }
    }

    return strokeColorMap;
  }
}
