import { Injectable, signal } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { lastValueFrom } from 'rxjs';
import { IAreaElement, IAreaElementActionPreset, IAPIListResponse, IListOptions, ActionName, ActionValueType, AreaElementIconPositionOptions, MediaLibraryActionValue } from '@newroom-connect/library/interfaces';
import { AreaElementAnimationSettings, AreaElementEvent, AreaElementShadowSettings } from '@newroom-connect/library/types';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';
import { MediaLibraryHelper } from '../media-library/media-library.helper';

export interface IUpdateAreaElementInput extends Omit<IAreaElement, 'translations'> {
  translations?: IUpdateAreaElementTranslationInput[];
}

export interface IUpdateAreaElementTranslationInput {
  code: string;
  action?: IAreaElementActionInput;
  description?: string;
  backgroundFileId?: string;
  fileOpacity?: number;
}

export interface IAreaElementActionInput<T extends ActionName = ActionName> {
  name: T;
  presetId: string;
  value: ActionValueType<T>
}

export interface IGenerateAreaElementOptions {
  areaId: string;
  preset: IAreaElementActionPreset[];
  languageCodes: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AreaElementService extends EntityService {
  public readonly areaElementSelectedSig = signal<IAreaElement | null>(null);
  public readonly searchableProperties = ['name'];
  public static readonly DEFAULT_AREA_ELEMENT_NAME = 'Untitled Element';

  private static readonly DEFAULT_AREA_ELEMENT_WIDTH = 10;
  private static readonly DEFAULT_AREA_ELEMENT_HEIGHT = 10;
  private static readonly DEFAULT_AREA_ELEMENT_RADIUS = 10;
  private static readonly DEFAULT_AREA_ELEMENT_EVENT: AreaElementEvent = { name: 'NONE' };
  private static readonly DEFAULT_AREA_ELEMENT_BASE_COLOR = '#000000ff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON_COLOR = '#ffffffff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON = 'trigger';
  private static readonly DEFAULT_AREA_ELEMENT_ICON_POSITION = AreaElementIconPositionOptions.CENTER;
  private static readonly DEFAULT_AREA_ELEMENT_ICON_SIZE = 30;
  private static readonly DEFAULT_AREA_ELEMENT_SHADOW: AreaElementShadowSettings = {
    color: '#00000080',
    offsetX: 0,
    offsetY: 0,
    blur: 0,
    spread: 0
  };
  private static readonly DEFAULT_AREA_ELEMENT_ANIMATION: AreaElementAnimationSettings = {
    name: 'none',
    duration: 300,
    isInfinite: false
  };

  private cachedAreaElementActionPresets?: IAPIListResponse<IAreaElementActionPreset>;

  /**
   * Generate a new area element based on the given input and some default parameters.
   *
   * @param input - The input options for generating the area element.
   *
   * @returns The generated area element.
   */
  public generateAreaElement(input: IGenerateAreaElementOptions): IAreaElement {
    const [defaultActionPreset] = input.preset;

    const areaElement: IAreaElement = {
      id: uuidv4(),
      areaId: input.areaId,
      name: AreaElementService.DEFAULT_AREA_ELEMENT_NAME,
      event: AreaElementService.DEFAULT_AREA_ELEMENT_EVENT,
      boundingBox: {
        width: AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH,
        height: AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT,
        top: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH / 2),
        left: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT / 2)
      },
      radius: AreaElementService.DEFAULT_AREA_ELEMENT_RADIUS,
      isEnabled: true,
      isLocked: false,
      baseColor: AreaElementService.DEFAULT_AREA_ELEMENT_BASE_COLOR,
      icon: AreaElementService.DEFAULT_AREA_ELEMENT_ICON,
      iconColor: AreaElementService.DEFAULT_AREA_ELEMENT_ICON_COLOR,
      iconPosition: AreaElementService.DEFAULT_AREA_ELEMENT_ICON_POSITION,
      iconSize: AreaElementService.DEFAULT_AREA_ELEMENT_ICON_SIZE,
      shadow: AreaElementService.DEFAULT_AREA_ELEMENT_SHADOW,
      animation: AreaElementService.DEFAULT_AREA_ELEMENT_ANIMATION,
      translations: input.languageCodes.map(code => ({
        languageCode: code,
        action: {
          name: defaultActionPreset.name,
          value: {} as ActionValueType<typeof defaultActionPreset.name>,
          preset: defaultActionPreset
        },
        textOverlay: '',
        areaElementId: input.areaId,
        description: ''
      }))
    };

    return areaElement;
  }

  /**
   * Generate update input for area elements.
   *
   * @param areaElements The area elements to generate update input for.
   *
   * @returns The generated update input.
   */
  public generateAreaElementsUpdateInput(areaElements?: IAreaElement[]): IUpdateAreaElementInput[] {
    if (!areaElements || !ArrayHelper.isNotEmpty(areaElements)) {
      return [];
    }

    return areaElements.map((areaElement) => ({
      id: areaElement.id,
      areaId: areaElement.areaId,
      name: areaElement.name,
      event: areaElement.event,
      boundingBox: areaElement.boundingBox,
      radius: areaElement.radius,
      isEnabled: areaElement.isEnabled,
      isLocked: areaElement.isLocked,
      baseColor: areaElement.baseColor ?? null,
      icon: areaElement.icon,
      iconColor: areaElement.iconColor,
      iconPosition: areaElement.iconPosition,
      iconSize: areaElement.iconSize,
      shadow: areaElement.shadow,
      animation: areaElement.animation,
      translations: areaElement.translations.map((translation) => ({
        code: translation.languageCode,
        action: translation.action ? {
          name: translation.action.name as ActionName,
          value: translation.action.value as ActionValueType<typeof translation.action.name>,
          presetId: translation.action.preset.id,
          actionId: translation.actionId
        } : undefined,
        description: translation.description,
        textOverlay: translation.textOverlay,
        backgroundFileId: translation.backgroundFileId,
        fileOpacity: translation.fileOpacity
      }))
    }));
  }

  /**
   * Fetch and cache list of area element action presets.
   *
   * @param options Optional list options.
   *
   * @returns List of area element action presets.
   */
  public async listAreaElementActionPreset(options?: IListOptions): Promise<IAPIListResponse<IAreaElementActionPreset>> {
    if (this.cachedAreaElementActionPresets) {
      return this.cachedAreaElementActionPresets;
    }

    const result = await lastValueFrom(super.list<IAreaElementActionPreset>('presets/areas/actions', undefined, options));

    this.cachedAreaElementActionPresets = result;

    return result;
  }

  /**
   * Duplicates the specified area element.
   *
   * @param areaElement
   *
   * @returns The duplicated area element.
   */
  public duplicateAreaElement(areaElement: IAreaElement): IAreaElement {
    const areaElementDuplicate: IAreaElement = {
      id: uuidv4(),
      areaId: areaElement.areaId,
      name: areaElement.name,
      event: areaElement.event,
      boundingBox: {
        width: areaElement.boundingBox.width,
        height: areaElement.boundingBox.height,
        top: areaElement.boundingBox.top + 2,
        left: areaElement.boundingBox.left + 2
      },
      radius: areaElement.radius,
      isEnabled: areaElement.isEnabled,
      isLocked: areaElement.isLocked,
      baseColor: areaElement.baseColor,
      icon: areaElement.icon,
      iconColor: areaElement.iconColor,
      iconSize: areaElement.iconSize,
      shadow: areaElement.shadow,
      animation: areaElement.animation,
      iconPosition: areaElement.iconPosition,
      translations: areaElement.translations.map(translation => ({
        languageCode: translation.languageCode,
        action: translation.action ? {
          name: translation.action.name,
          value: translation.action.value,
          preset: translation.action.preset
        } :undefined,
        textOverlay: translation.textOverlay,
        areaElementId: translation.areaElementId,
        description: translation.description
      }))
    };

    return areaElementDuplicate;
  }

  /**
   * Hydrates the media library data.
   *
   * @param areaElements Area elements to hydrate.
   *
   * @returns Hydrated area elements.
   */
  public static hydrateAreaElementsMediaLibrary(areaElements: IAreaElement[]): IAreaElement[] {
    const areaElementsHydrated: IAreaElement[] = [...areaElements];

    for (const areaElement of areaElementsHydrated) {
      const mediaLibraryActionValue = areaElement.translations
        .find(translation => translation.action && (translation.action.name === ActionName.MEDIA_LIBRARY || translation.action.name === ActionName.MEDIA_SLIDER))?.action?.value as MediaLibraryActionValue;

      if (!mediaLibraryActionValue || !mediaLibraryActionValue.mediaLibraryFiles) {
        continue;
      }

      // Support old values of MediaSlider and MediaLibrary where the old mediaLibraryData stored directly the array of media items.
      // The new changes store the media items in the mediaLibraryData.data property along with options for openInFullscreen and gridLayoutStretched.
      if (Array.isArray(mediaLibraryActionValue.mediaLibraryData) && !mediaLibraryActionValue.mediaLibraryData.data) {
        mediaLibraryActionValue.mediaLibraryData = {
          data: mediaLibraryActionValue.mediaLibraryData,
          openInFullscreen: false,
          gridLayoutStretched: false
        };
      }

      mediaLibraryActionValue.mediaLibraryData.data = MediaLibraryHelper.hydrateMediaLibraryData(mediaLibraryActionValue.mediaLibraryData.data, mediaLibraryActionValue.mediaLibraryFiles);
    }

    return areaElementsHydrated;
  }
}
