@if (translatableFormControls) {
  <div class="relative flex flex-col w-full">
    <div class="w-full px-2 bg-neutral-900 absolute top-1">
      <div class="flex flex-row pt-0.5 items-center">
        <div class="relative w-20 h-6">
          <nrc-input-language-selector
            class="absolute"
            [formControl]="languageCodeFormControl"
            [options]="languageCodes"
            [value]="currentLanguageCode()"
            [textSize]="inputLanguageSelectorTextSize.SMALL"
            (languageChanged)="languageCodeChangedEvent.emit($event)"
          />
        </div>

        <!-- Button displayed next to language selector -->
        <nrc-button
          class="w-6 h-6 relative"
          [icon]="'translate'"
          [buttonType]="buttonType.ICON"
          [role]="actionRole.TRANSPARENT_SECONDARY"
          (clickEvent)="toggleAutoTranslateModal()"
        />
      </div>
    </div>

    <!-- Input and Textarea should not be affected by dropdown; positioned under the dropdown -->
    <div class="flex flex-row w-full align-middle items-center pt-4">
      <div class="flex flex-col w-full items-start">
        @switch (type) {
          @case (translatableInputType.TEXT_AREA) {
            <textarea
              #inputElement
              class="peer placeholder-transparent block w-full h-20 max-h-40 bg-neutral-900 autofill:bg-neutral-900 px-3 pt-5
                rounded-t text-sm/[16px] border-0 border-b-1 caret-cyan
                focus:border-b-cyan-highlight focus:outline-none focus:ring-0 disabled:opacity-50"
              [name]="name"
              [id]="id"
              [tabindex]="tabIndex"
              [autocomplete]="name"
              [maxlength]="maxLength"
              [ngClass]="{
                'border-b-error': translatableFormControls[currentLanguageCode()].touched && translatableFormControls[currentLanguageCode()].invalid,
                'border-b-gray-700': translatableFormControls[currentLanguageCode()].valid,
                classList
              }"
              [placeholder]="placeholder"
              [formControl]="translatableFormControls[currentLanguageCode()] | formControl"
              [value]="translatableFormControls[currentLanguageCode()].value"
            ></textarea>
          }

          @case (translatableInputType.TEXT) {
            <input
              #inputElement
              class="peer placeholder-transparent block w-full h-12 max-h-20 bg-neutral-900 autofill:bg-neutral-900 px-3 pt-6
                rounded-t text-sm/[16px] border-0 border-b-1 caret-cyan
                focus:border-b-cyan-highlight focus:outline-none focus:ring-0 disabled:opacity-50 focus:pt-5"
              [type]="type"
              [name]="name"
              [id]="id"
              [tabindex]="tabIndex"
              [autocomplete]="name"
              [maxlength]="maxLength"
              [ngClass]="{
                'border-b-error': translatableFormControls[currentLanguageCode()].touched && translatableFormControls[currentLanguageCode()].invalid,
                'border-b-gray-700': translatableFormControls[currentLanguageCode()].valid,
                classList
              }"
              [placeholder]="placeholder"
              [formControl]="translatableFormControls[currentLanguageCode()] | formControl"
              [value]="translatableFormControls[currentLanguageCode()].value"
            />
          }
        }

        <label
          class="absolute pl-3 pt-3 text-xxs text-[#adadad] peer-focus:hidden
            peer-placeholder-shown:text-gray-400
            peer-placeholder-shown:mt-2
            peer-placeholder-shown:text-sm/[16px]
            duration-200"
          [for]="id"
          [ngClass]="{
            'text-error': translatableFormControls[currentLanguageCode()].touched && translatableFormControls[currentLanguageCode()].invalid
          }"
        >{{ label }}</label>
      </div>
    </div>
  </div>
}
<ng-template #autoTranslateModalContent>
  <nrc-auto-translate
    [languageCodes]="languageCodes"
    [selectedLanguageCode]="currentLanguageCode()"
    [inputsToTranslate]="autoTranslateInputs()"
    (autoTranslateResult)="handleAutoTranslateResult($event)"
    (closeEvent)="isTranslationModalOpen.set(false)"
  />
</ng-template>
