import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, signal } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Level } from '@newroom-connect/library/enums';
import { FileHelper } from '@newroom-connect/library/helpers';
import { IFileUploadItem } from '@newroom-connect/library/interfaces';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../../buttons/button/button.component';
import { FileUploaderComponent } from '../file-uploader/file-uploader.component';
import { IconComponent } from '../../icon/icon.component';
import { InputTextComponent } from '../../inputs/input-text/input-text.component';
import { LoadingComponent } from '../../loading/loading.component';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';
import { ModalComponent } from '../../modal/modal.component';
import { NoteComponent } from '../../note/note.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FileUploaderComponent,
    IconComponent,
    NoteComponent,
    MediaPreviewComponent,
    ModalComponent,
    LoadingComponent,
    InputTextComponent,
    ButtonComponent,
    TranslatePipe
  ],
  selector: 'nrc-file-replacer',
  templateUrl: './file-replacer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileReplacerComponent implements OnChanges {
  @Input() public fileTitle?: string;
  @Input() public fileMimetype?: string;
  @Input() public fileSource?: string;
  @Input() public fileDescription?: string;

  @Output() public fileChangedEvent = new EventEmitter<IFileUploadItem>();
  @Output() public fileSizeLimitExceededEvent = new EventEmitter<File>(); // Emit the file which exceeds the size limit.

  public acceptableMimeTypes?: string[];

  public level = Level;

  public fileTitleForm = new FormGroup({
    title: new FormControl({ value: '', disabled: true })
  });

  public isBackgroundThumbnailAnimatedSig = signal<boolean>(false);

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['fileTitle']?.currentValue) {
      this.fileTitleForm.controls['title'].patchValue(changes['fileTitle']?.currentValue);
    }

    // Set the acceptable mimetypes for the file uploader based on the mimetype of the given file.
    if (changes['fileMimetype']?.currentValue) {
      const mimetypeGroup = FileHelper.extractMimeTypeGroup(changes['fileMimetype']?.currentValue);

      this.acceptableMimeTypes = mimetypeGroup ? [`${mimetypeGroup}/*`] : undefined;
    }

    if (!this.isBackgroundThumbnailAnimatedSig() && Object.keys(changes).every(changeKey => changes[changeKey].previousValue !== undefined)) {
      this.animateBackgroundThumbnail();
    }
  }

  /**
   * Animate the background thumbnail where the corresponding signal is set to `true` and then reset to `false` after a specified timeout.
   *
   * @param timeoutInMs The timeout im milliseconds to reset the animation signal.
   */
  private animateBackgroundThumbnail(timeoutInMs = 1000): void {
    this.isBackgroundThumbnailAnimatedSig.set(true);

    setTimeout(() => {
      this.isBackgroundThumbnailAnimatedSig.set(false);
    }, timeoutInMs);
  }
}
