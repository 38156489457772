import { Injectable, Signal, signal } from '@angular/core';
import { Level } from '@newroom-connect/library/enums';

export interface IToastMessage {
  message: string;
  level?: Level;
}

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  public message = signal<IToastMessage | null>(null);

  /**
   * Watch the current alert message.
   *
   * @returns The current alert message as read-only signal.
   */
  public watchMessage(): Signal<IToastMessage | null> {
    return this.message.asReadonly();
  }

  /**
   * Set the toast message.
   * After a specified timeout, reset the toast message, if a reset timeout is specified (default timeout is 5 seconds).
   *
   * @param message The message to set.
   * @param resetTimeoutInMs The timeout to reset the toast message.
   */
  public setMessage(message: IToastMessage | null, resetTimeoutInMs = 5000): void {
    this.message.set(message);

    setTimeout(() => {
      this.message.set(null);
    }, resetTimeoutInMs);
  }

  /**
   * Reset the alert message to am empty object.
   */
  public resetMessage(): void {
    this.setMessage(null, 0);
  }
}
