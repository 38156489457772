import { Title } from '@angular/platform-browser';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ITranslateActionInputOutput, ITranslateDocumentInput, ITranslateDocumentOutput } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';
import { TranslateDocumentJobService } from '../translate-document-job/translate-document-job.service';

@Injectable({
  providedIn: 'root'
})
export class AutoTranslateService extends EntityService {
  private readonly translateDocumentJobService = inject(TranslateDocumentJobService);

  protected searchableProperties = [];

  /**
   * Translates the values of the keys in the provided JSON object.
   *
   * @param autoTranslateParams
   * @returns
   */
  public translateAreaElementActionInputs(autoTranslateParams: ITranslateActionInputOutput): Observable<ITranslateActionInputOutput> {
    const translationParams = {
      from: autoTranslateParams.from,
      to: autoTranslateParams.to,
      payload: autoTranslateParams.payload
    };

    return this.apiService.post<ITranslateActionInputOutput>('translate/json', translationParams);
  }

  /**
   * Translates documents.
   *
   * @param projectId
   * @param translateDocParams
   * @returns
   */
  public translateDocuments(projectId: string, translateDocParams: ITranslateDocumentInput): Observable<ITranslateDocumentOutput> {
    return this.apiService.post<ITranslateDocumentOutput>('translate/document', translateDocParams)
      .pipe(
        tap((result) => {
          // Save translation attempt after successful API call
          this.translateDocumentJobService.updateJob(result.jobId, {
            fileId: translateDocParams.fileId,
            name: translateDocParams.fileTitle,
            status: result.jobStatus,
            projectId,
            sourceLanguage: result.from,
            targetLanguage: result.to,
            id: result.jobId,
            newFileId: result.newFileId,
            timestamp: Date.now()
          });
        })
      );
  }
}
