import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, signal } from '@angular/core';
import { FileHelper } from '@newroom-connect/library/helpers';
import { IFile } from '@newroom-connect/library/interfaces';

import { AreaBackground2DComponent } from './area-background-2d/area-background-2d.component';
import { AreaBackground360DegreeComponent } from './area-background-360-degree/area-background-360-degree.component';
import { AreaBackgroundEmptyComponent } from './area-background-empty/area-background-empty.component';

@Component({
  selector: 'nrc-area-background',
  standalone: true,
  imports: [
    AreaBackground2DComponent,
    AreaBackground360DegreeComponent,
    AreaBackgroundEmptyComponent
  ],
  templateUrl: './area-background.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaBackgroundComponent implements OnChanges {
  @Input({ required: true }) public apiBaseUrl = '';
  @Input() public backgroundFile?: IFile;
  @Input() public isEditMode = true;

  @Output() public changeBackgroundEvent = new EventEmitter<void>();

  public isBackgroundFileCubemapSig = signal<boolean>(false);

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['backgroundFile']) {
      this.isBackgroundFileCubemapSig.set(changes['backgroundFile'].currentValue ? FileHelper.isFileCubemap(changes['backgroundFile'].currentValue) : false);
    }
  }
}
