import { trigger, transition, style, query, animate, group } from '@angular/animations';

export const routeFadeAnimation =
  trigger('routeAnimations', [
    transition('* <=> *', [
      query(':enter', [
        style({ opacity: 0 })
      ], { optional: true }
      ),
      group([
        query(':leave', [
          animate(150, style({ opacity: 0 }))
        ], { optional: true }
        ),
        query(':enter', [
          style({ opacity: 0 }),
          animate(150, style({ opacity: 1 }))
        ], { optional: true }
        )
      ])
    ])
  ]);
