import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router } from '@angular/router';
import { catchError, from, map, of } from 'rxjs';
import { LoggingService, SessionService } from '@newroom-connect/library/services';
import { UrlBuilderHelper } from '@newroom-connect/library/helpers';

export const AlreadyLoggedInGuard: CanActivateFn = (route: ActivatedRouteSnapshot) => {
  const router = inject(Router);
  const sessionService = inject(SessionService);
  const loggingService = inject(LoggingService);

  const userSession = sessionService.getUserSession();

  let isLoggedIn = false;

  let finalUrl = '';

  try {
    isLoggedIn = userSession?.isValid() ?? false;
    finalUrl = UrlBuilderHelper.buildUrlPath(route.data['alreadyLoggedInRoute'], route) ?? '404';
  } catch (error) {
    loggingService.error(error);
  }

  if (isLoggedIn) {
    return from(router.navigate([finalUrl])).pipe(
      catchError(error => {
        loggingService.error(error);
        return of(null);
      }),
      map(() => false)
    );
  }

  return of(!isLoggedIn);
};
