<div class="w-full h-full flex flex-col space-y-4">
  <!-- Header and preview of the current selected file - only displayed, if current item is set -->
  @if (currentFile) {
    <div class="w-full h-full flex justify-between">
      <nrc-media-preview
        class="w-28 h-28 rounded-10 overflow-hidden shadow-inner"
        [mimetype]="currentFile.mimetype"
        [source]="currentFile.source"
        [mediaPreviewTypes]="['image', 'video-snippet']"
      />

      <form class="flex flex-col grow justify-start pl-5 space-y-3" [formGroup]="fileTitleForm">
        <div class="w-full">
          @if (fileTitleForm) {
            <nrc-input-text
              class="w-full"
              [formControl]="fileTitleForm.controls['title']"
              [type]="'text'"
              [name]="'file-replace-title'"
              [id]="'file-replace-title'"
              [label]="'COMPONENTS.FILE_REPLACER.INPUTS.TITLE.LABEL' | translate"
              [classList]="['bg-white/25']"
              [readonly]="true"
            />
          }

          <div class="w-full">
            <span class="text-white/50 text-xs font-light">
              {{ currentFile | extractProperties: [{ key: 'extension', format: objectPropertyFormat.TEXT }, { key: 'createdAt', format: objectPropertyFormat.DATE}, { key: 'size', format: objectPropertyFormat.FILE_SIZE }] | join: ' | ' }}
            </span>
          </div>
        </div>

        <nrc-note
          class="w-full"
          [message]="{
            message: 'COMPONENTS.FILE_REPLACER.NOTE.MESSAGE.MESSAGE' | translate,
            level: level.WARN
          }"
        />
      </form>
    </div>
  }

  <!-- The selection to either upload a file or go to the file library. -->
  <div class="w-full h-full flex flex-col space-y-4">
    <div
      class="w-full min-h-[8rem] p-[4rem] bg-white/10 rounded-10 flex flex-col justify-center items-center space-y-2 hover:bg-white/5 hover:cursor-pointer"
      tabindex="1"
      (click)="showFileLibraryEvent.emit()"
      (keydown)="null"
    >
      <nrc-icon
        class="w-24 h-16 opacity-30"
        [name]="'file-search'"
      />

      <span class="text-base text-white font-normal">
        {{ 'COMPONENTS.FILES.FILE_LIBRARY_MODAL.OPTIONS.SEARCH_FILE_LIBRARY.HEADLINE' | translate }}
      </span>

      <label class="text-sm text-white/50 font-light" for="fileDropInput">
        {{ 'COMPONENTS.FILES.FILE_LIBRARY_MODAL.OPTIONS.SEARCH_FILE_LIBRARY.SUBLINE' | translate }}
      </label>
    </div>

    <nrc-file-uploader
      class="w-full"
      tabindex="0"
      [isMultipleUploadEnabled]="false"
      [acceptableMimeTypes]="acceptableMimeTypes"
      (filesChangedEvent)="fileUploadEvent.emit($event)"
    />
  </div>
</div>
