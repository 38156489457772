import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, signal } from '@angular/core';

import { PasswordFormatValidatorStatusComponent } from './password-format-validator-status/password-format-validator-status.component';

@Component({
  standalone: true,
  imports: [PasswordFormatValidatorStatusComponent],
  selector: 'nrc-password-format-validator',
  templateUrl: './password-format-validator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormatValidatorComponent implements OnChanges {
  @Input() public password?: string | null;

  public isAtLeastSixChars = signal<boolean>(false);
  public isLowerAndUpperCase = signal<boolean>(false);
  public isNumberAndSymbol = signal<boolean>(false);

  /**
   * Check for changes on component inputs.
   *
   * @param changes The input changes.
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const newPassword = changes['password']?.currentValue || '';

    this.isAtLeastSixChars.set(newPassword?.length >= 6);
    this.isLowerAndUpperCase.set(this.containsUpperCaseAndLowerCase(newPassword));
    this.isNumberAndSymbol.set(this.containsNumberAndSymbol(newPassword));
  }

  /**
   * Check if a string contains uppercase and lowercase letters.
   *
   * @param text The text to check.
   *
   * @returns Indicator, if the regex matches.
   */
  private containsUpperCaseAndLowerCase(text: string): boolean {
    return /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(text);
  }

  /**
   * Check if a string contains numbers and symbols.
   *
   * @param text The text to check.
   *
   * @returns Indicator, if the regex matches.
   */
  private containsNumberAndSymbol(text: string): boolean {
    return /^(?=.*\d)(?=.*[-+_!@#$%^&*.,?]).+$/.test(text);
  }
}
