import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'isEmptyArray'
})
export class IsEmptyArrayPipe implements PipeTransform {
  /**
   * Check if the given value is an empty array.
   *
   * @param value The value to check.
   *
   * @returns Boolean indicating if the value is an empty array.
   */
  public transform(value: unknown): boolean {
    return Array.isArray(value) && value.length === 0;
  }
}
