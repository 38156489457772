import { ChangeDetectionStrategy, Component, effect, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, signal, ViewChild, AfterViewInit, HostBinding, Renderer2, ChangeDetectorRef, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { IAreaElement, IFile, IFileHydrated } from '@newroom-connect/library/interfaces';
import { AreaElementService, LanguageService } from '@newroom-connect/library/services';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { DoubleClickDirective } from '@newroom-connect/library/directives';

import { IconComponent } from '../../icon/icon.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { MediaPreviewComponent } from '../../media-preview/media-preview.component';

@Component({
  selector: 'nrc-area-element',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    TooltipComponent,
    MediaPreviewComponent,
    DoubleClickDirective
  ],
  templateUrl: './area-element.component.html',
  styleUrls: ['./area-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AreaElementComponent implements OnChanges, OnDestroy, AfterViewInit {
  @Input({ required: true }) public apiBaseUrl = '';
  @Input({ required: true }) public languageCode!: string;
  @Input({ required: true }) public areaElement!: IAreaElement;
  @Input() public isResizable = false;
  @Input() public parentContainerElement?: HTMLElement;
  @Input() public editMode = false;

  @Output() public clickEvent = new EventEmitter<void>();
  @Output() public doubleClickEvent = new EventEmitter<void>();

  public backgroundFile = signal<IFile | IFileHydrated | null>(null);
  public textOverlay = signal<SafeHtml | null>(null);

  public backgroundFileOpacity = signal<number>(1);
  public tooltipText = signal<string | null>(null);
  public boxShadow = signal<string>('none');
  public animation = signal<string>('none');

  public isSelected = signal<boolean>(false);
  private resizeObserver: ResizeObserver;
  private readonly BASE_EDITOR_WIDTH = 1920; // 16:9 aspect ratio at 1080p

  @ViewChild('areaElementWrapper') private wrapperElement?: ElementRef;

  @HostBinding('style.transition')
  public get transition(): string {
    return '--text-scale 0.3s ease-in-out';
  }

  /**
   *
   * @param elementRef
   * @param renderer
   * @param areaElementService
   * @param languageService
   * @param sanitizer
   */
  constructor(
    public elementRef: ElementRef,
    private readonly renderer: Renderer2,
    private readonly areaElementService: AreaElementService,
    private readonly languageService: LanguageService,
    private readonly sanitizer: DomSanitizer
  ) {
    effect(() => {
      const currentSelectedElement = this.areaElementService.areaElementSelectedSig();
      const languageCode = this.languageService.watchSelectedLanguageCode()();

      if (!currentSelectedElement || !this.areaElement || !languageCode) {
        return;
      }

      this.isSelected.set(currentSelectedElement.id === this.areaElement.id);
      this.languageCode = languageCode;

      this.setAreaElementOptions(this.languageCode);
    }, { allowSignalWrites: true });

    this.resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(() => {
        if (!this.parentContainerElement) {
          return;
        }

        this.updateTextOverlayScale(this.parentContainerElement.getBoundingClientRect().width);
      });
    });
  }

  /**
   * Initialize resize observer after view is initialized.
   */
  public ngAfterViewInit(): void {
    if (!this.wrapperElement) {
      return;
    }

    this.resizeObserver.observe(this.wrapperElement.nativeElement);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['languageCode']) {
      this.setAreaElementOptions(this.languageCode);
    }
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }

  /**
   *
   * @param event
   */
  public handleAreaElementClick(event: MouseEvent): void {
    if (event.detail !== 1) {
      return;
    }

    event.stopPropagation();

    this.isSelected.set(true);
    this.clickEvent.emit();
  }

  /**
   *
   */
  public handleAreaElementDblClick(): void {
    this.doubleClickEvent.emit();
  }

  /**
   *
   * @param languageCode
   */
  private setAreaElementOptions(languageCode: string): void {
    if (!this.areaElement || !ArrayHelper.isNotEmpty(this.areaElement.translations)) {
      return;
    }

    const translationPerLanguageCode = this.areaElement.translations.find(translation => translation.languageCode === languageCode);

    if (!translationPerLanguageCode) {
      return;
    }

    // Set Area element background file properties.
    const fileOpacity = translationPerLanguageCode.fileOpacity ?? 100;

    this.backgroundFile.set(translationPerLanguageCode.backgroundFile ?? null);
    this.backgroundFileOpacity.set(fileOpacity / 100);

    // Set box-shadow value
    if (this.areaElement.shadow) {
      const { offsetX, offsetY, blur, spread, color } = this.areaElement.shadow;

      this.boxShadow.set(`${offsetX}px ${offsetY}px ${blur}px ${spread}px ${color}`);
    } else {
      this.boxShadow.set('none');
    }

    // Set animation value
    if (this.areaElement.animation) {
      const { name, duration, isInfinite } = this.areaElement.animation;

      if (name !== 'none') {
        // Format: animation-name duration iteration-count
        this.animation.set(`${name} ${duration}ms ${isInfinite ? 'infinite' : '1'}`);
      } else {
        this.animation.set('none');
      }
    } else {
      this.animation.set('none');
    }

    // Temporary to support area elements with no icon size property.
    if (isNaN(this.areaElement.iconSize)) {
      this.areaElement.iconSize = 30;
    }

    // Set Area element tooltip text properties.
    this.tooltipText.set(translationPerLanguageCode.description ?? null);

    // Process and set Area element text overlay properties.
    if (translationPerLanguageCode.textOverlay) {
      const processedContent = this.processWYSIWYGContent(translationPerLanguageCode.textOverlay);

      this.textOverlay.set(this.sanitizer.bypassSecurityTrustHtml(processedContent));
    } else {
      this.textOverlay.set(null);
    }
  }

  /**
   * Process WYSIWYG content to handle font sizes properly.
   *
   * @param content The HTML content from the WYSIWYG editor.
   *
   * @returns Processed HTML content with CSS variables for font sizes.
   */
  private processWYSIWYGContent(content: string): string {
    const tempDiv = this.renderer.createElement('div');

    this.renderer.setProperty(tempDiv, 'innerHTML', content);

    // Process all elements with font-size style
    const elementsWithFontSize = tempDiv.querySelectorAll('[style*="font-size"]');

    for (const element of elementsWithFontSize) {
      const style = element.getAttribute('style') || '';
      const fontSizeMatch = style.match(/font-size:\s*(\d+)px/);

      if (fontSizeMatch) {
        const originalSize = fontSizeMatch[1];

        // Store the original size in a CSS variable
        element.setAttribute(
          'style',
          `${style}; --original-size: ${originalSize}px`
        );
      }
    }

    // Process all elements with line-height style
    const elementsWithLineHeight = tempDiv.querySelectorAll('[style*="line-height"]');

    for (const element of elementsWithLineHeight) {
      const style = element.getAttribute('style') || '';
      const lineHeightMatch = style.match(/line-height:\s*([\d.]+)(px|em|%)?/);

      if (lineHeightMatch) {
        const originalLineHeight = lineHeightMatch[1];
        const unit = lineHeightMatch[2] || '';

        // Store the original line-height in a CSS variable
        element.setAttribute(
          'style',
          `${style}; --original-line-height: ${originalLineHeight}${unit}`
        );
      }
    }

    return tempDiv.innerHTML;
  }

  /**
   * Update the text overlay scale based on the container width.
   *
   * @param containerWidth The width of the parent container element.
   */
  private updateTextOverlayScale(containerWidth: number): void {
    if (!this.wrapperElement) {
      return;
    }

    // Calculate scale based on the ratio between container width and base width
    const baseScale = containerWidth / this.BASE_EDITOR_WIDTH;

    // Apply scale with bounds to keep text readable (between 0.5 and 2)
    const boundedScale = Math.min(Math.max(0.1, baseScale), 2);

    this.wrapperElement.nativeElement.style.setProperty('--text-scale', boundedScale);
  }
}
