import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, OnInit, ViewChild, forwardRef, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';
import { ButtonComponent } from '../../buttons/button/button.component';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, ButtonComponent],
  selector: 'nrc-input-timepicker',
  templateUrl: './input-timepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTimepickerComponent),
      multi: true
    }
  ]
})
export class InputTimepickerComponent extends InputComponent implements OnInit {
  @ViewChild('hourSelection') public hourSelectionRef?: ElementRef<HTMLDivElement>;
  @ViewChild('minuteSelection') public minuteSelectionRef?: ElementRef<HTMLDivElement>;
  @ViewChild('timepickerContainer') public timepickerContainerRef!: ElementRef<HTMLDivElement>;

  public currentDate = signal(new Date());
  public isTimepickerOpen = false;

  public selectedHour = 0;
  public selectedMinute = 0;

  public hours: number[] = Array.from({ length: 24 }, (_, i) => i);
  public minutes: number[] = Array.from({ length: 60 }, (_, i) => i);

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    const validators = this.formControl.validator ? [this.formControl.validator] : [];

    this.formControl.setValidators(validators);
  }

  /**
   *
   * @param value
   */
  public override writeValue(value: string): void {
    super.writeValue(value);

    const currentDate = new Date(value);

    this.selectedHour = currentDate.getHours();
    this.selectedMinute = currentDate.getMinutes();

    this.currentDate.set(new Date(currentDate));

    this.scrollToSelection();
  }

  /**
   * Listen to click events on the document and close the datepicker if click is not in the component.
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public clickOutside(event: any): void {
    if (event.target !== this.timepickerContainerRef.nativeElement && !this.timepickerContainerRef.nativeElement.contains(event.target)) {
      this.closeTimepicker();
    }
  }

  /**
   *
   */
  public toggleTimepicker(): void {
    this.isTimepickerOpen = !this.isTimepickerOpen;

    if (this.isTimepickerOpen) {
      setTimeout(() => {
        this.scrollToSelection();
      }, 100);
    }
  }

  /**
   *
   */
  public closeTimepicker(): void {
    this.isTimepickerOpen = false;
  }

  /**
   *
   * @param hour
   */
  public selectHour(hour: number): void {
    if (hour !== this.selectedHour) {
      this.selectedHour = hour;

      const date = this.formControl.value as Date;

      date.setHours(hour);

      this.formControl.patchValue(date);
    }
  }

  /**
   *
   * @param minute
   */
  public selectMinute(minute: number): void {
    if (minute !== this.selectedMinute) {
      this.selectedMinute = minute;

      const date = this.formControl.value as Date;

      date.setMinutes(minute);

      this.formControl.patchValue(date);
    }
  }

  /**
   *
   */
  private scrollToSelection(): void {
    this.hourSelectionRef?.nativeElement.scrollTo({ top: 44 * (this.selectedHour - 2) - 22, behavior: 'smooth' });
    this.minuteSelectionRef?.nativeElement.scrollTo({ top: 44 * (this.selectedMinute - 2) - 22, behavior: 'smooth' });
  }
}
