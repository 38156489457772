<div
  #areaElementWrapper
  tabindex="-1"
  class="relative w-full h-full"
  nrcDoubleClick
  (doubleClickEvent)="handleAreaElementDblClick()"
  (click)="handleAreaElementClick($event)"
  (keyup)="null"
  [ngClass]="{
    'hover:cursor-pointer': areaElement.event?.name === 'CLICK' || editMode,
  }"
>
  <div
    class="relative flex items-center justify-center w-full h-full transition-all group"
    tabindex="-1"
    [style.animation]="animation()"
    [style.backgroundColor]="areaElement.baseColor"
    [style.border-radius]="areaElement.radius + 'px'"
    [style.box-shadow]="boxShadow()"
    (keyup)="null"
  >
    @if (backgroundFile()) {
      <nrc-media-preview
        class="absolute top-0 left-0 z-10 object-cover w-full h-full overflow-hidden"
        [ngClass]="{
          'bg-white/5': !backgroundFile()
        }"
        [style.border-radius]="areaElement.radius + 'px'"
        [style.opacity]="backgroundFileOpacity()"
        [mimetype]="backgroundFile() ? backgroundFile()!.mimetype : 'image/png'"
        [objectFit]="'fill'"
        [source]="backgroundFile() ? apiBaseUrl + '/projects/' + backgroundFile()!.projectId + '/files/' + backgroundFile()!.id + '/stream' : undefined"
        [mediaPreviewTypes]="['image', 'video-snippet']"
      />
    }
    <div class="absolute inset-0 z-20 flex items-center overflow-hidden">
      <!-- Content Wrapper -->
      <div
        class="flex w-full h-full px-1 transition-transform duration-500 ease-in-out"
        [ngClass]="{
          'px-0.5': areaElement.boundingBox.width < 5,
          'md:px-1.5 2xl:px-2.5': areaElement.boundingBox.width >= 5
          }"
      >
        <!-- Dynamic Layout Container -->
        <div
          class="flex flex-col items-center justify-center w-full h-full transition-all duration-500 ease-in-out transform"
          [ngClass]="{
            'flex-col': areaElement.iconPosition === 'CENTER',
            'gap-1 sm:gap-1.5 md:gap-2 2xl:gap-3': !!textOverlay(),
            'justify-start': areaElement.iconPosition === 'LEFT',
            'justify-center': !areaElement.iconPosition || areaElement.iconPosition === 'CENTER',
            'justify-end': areaElement.iconPosition === 'RIGHT'
          }"
        >
          @if (areaElement.icon && areaElement.icon !== 'empty' && (areaElement.iconPosition === 'LEFT' || areaElement.iconPosition === 'CENTER')) {
            <div class="flex items-center justify-center" [style.height]="areaElement.iconSize + '%'" [style.aspect-ratio]="'1/1'">
              <nrc-icon
                class="w-full h-full transition-transform duration-500 ease-in-out hover:scale-110"
                [name]="areaElement.icon"
                [fillColorHex]="areaElement.iconColor ?? '#ffffff'"
              />
            </div>
          }

          @if (textOverlay()) {
            <div
              class="flex items-center w-full overflow-hidden transition-all duration-500 ease-in-out opacity-95 hover:opacity-100"
            >
              <div
                class="block w-full h-full prose-invert"
                [innerHTML]="textOverlay()"
              ></div>
            </div>
          }

          @if (areaElement.icon && areaElement.iconPosition === 'RIGHT') {
            <div class="flex items-center justify-center" [style.width]="areaElement.iconSize + '%'" [style.height]="areaElement.iconSize + '%'" [style.aspect-ratio]="'1/1'">
              <nrc-icon
                class="w-full h-full transition-transform duration-500 ease-in-out hover:scale-110"
                [name]="areaElement.icon"
                [fillColorHex]="areaElement.iconColor ?? '#ffffff'"
              />
            </div>
          }
        </div>
      </div>
    </div>

    @if (tooltipText()) {
      <nrc-tooltip
        class="tooltip tooltip-group"
        [text]="tooltipText()!"
      />
    }

    <!-- Display icon in the upper-right corner of the area element, if it is locked. -->
    @if (areaElement.isLocked && editMode) {
      <nrc-icon
        class="absolute z-20 w-3 h-3 max-w-full max-h-full top-2 right-2"
        [name]="'lock-closed'"
      />
    }
  </div>

  <!-- Drag points for resizing the area element -->
  @if (isSelected() && isResizable && !areaElement.isLocked) {
    <div class="absolute top-0 left-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 left-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute top-0 right-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
    <div class="absolute bottom-0 right-0 z-20 w-2 h-2 bg-white rounded-full hover:cursor-grab active:cursor-grabbing"></div>
  }
</div>
