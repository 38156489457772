import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActionRole } from '@newroom-connect/library/enums';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';

export interface ISegmentOption<T = any> {
  value: T;
  label?: string;
  icon?: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'nrc-input-segment',
  templateUrl: './input-segment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSegmentComponent),
      multi: true
    }
  ]
})
export class InputSegmentComponent extends InputComponent<string> implements OnInit, OnChanges {
  @Input() public options: ISegmentOption[] = [];
  @Input() public value: string | null = null;
  @Input() public type = 'REGULAR';

  @Output() public optionChangedEvent = new EventEmitter<ISegmentOption>();

  public selectedIndex = 0;

  public actionRole = ActionRole;

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    this.formControl.setValue(this.options[0].value);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['formControl']) {
      this.selectOption(this.options.findIndex((option) => option.value === this.formControl.value));
    }

    if (changes && changes['value'] && this.value) {
      this.selectOption(this.options.findIndex((option) => option.value === this.value));
    }
  }

  /**
   * Select an option where `selectedIndex` is set to the given option index and the form control is set to the option value.
   *
   * @param optionIndex The index of the option to select from the options array.
   */
  public selectOption(optionIndex: number): void {
    this.selectedIndex = optionIndex;

    if (!this.options[optionIndex]) {
      return;
    }

    this.optionChangedEvent.emit(this.options[optionIndex]);

    this.formControl.setValue(this.options[optionIndex].value);
    this.formControl.markAsDirty();
    this.formControl.markAsTouched();
  }
}
