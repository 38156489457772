import { CommonModule, SlicePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { HoverDelayDirective } from '@newroom-connect/library/directives';

@Component({
  selector: 'nrc-user-detail-tooltip',
  standalone: true,
  imports: [
    SlicePipe,
    HoverDelayDirective,
    CommonModule
  ],
  animations: [fadeInOutAnimation],
  templateUrl: './user-detail-tooltip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserDetailTooltipComponent implements OnInit {
  @Input() public firstName = '';
  @Input() public lastName = '';
  @Input() public profileColor = '';
  @Input() public userRoleName = '';

  public fullName = '';

  public ngOnInit(): void {
    this.fullName = `${this.firstName} ${this.lastName}`;
  }
}
