import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionRole } from '@newroom-connect/library/enums';

import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    IconComponent
  ],
  selector: 'nrc-empty-list',
  templateUrl: './empty-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyListComponent {
  @Input() public headline?: string;
  @Input() public content?: string;
  @Input() public buttonLabel?: string;

  @Output() public buttonClickEvent = new EventEmitter();

  public actionRole = ActionRole;
}
