<form class="flex flex-wrap justify-center items-center gap-4" [formGroup]="paginationForm">
  <!-- Items per page selector -->
  <span class="text-xs text-[#898989]">Items per page</span>

  <nrc-input-select
    class="w-28 h-10"
    [formControl]="paginationForm.controls.pageSize"
    [name]="'list-items-per-page'"
    [id]="'list-items-per-page'"
    [options]="pageSizeOptions"
    [labelProperty]="'value'"
    [valueProperty]="'value'"
    [isRound]="true"
    [searchEnabled]="false"
    [overflowParentElement]="overflowParentElement"
  />

  <!-- Current item index indicator -->
  <span class="text-xs text-[#898989]">
    Items {{ itemStartIndex() + 1 }} - {{ itemEndIndex() + 1 }} of {{ meta?.total ?? 0 }}
  </span>

  <!-- Page navigator -->
  @if (lastPage() > 1) {
    <div class="flex justify-between items-center space-x-4">
      <nrc-button
        class="w-9 h-9 relative flex"
        [icon]="'arrow-back'"
        [buttonType]="buttonType.ICON"
        [role]="actionRole.SECONDARY"
        [disabled]="itemStartIndex() === 0"
        (clickEvent)="paginate(-1)"
      />

      <div class="flex justify-center items-center space-x-4">
        <!-- The first page -->
        @if (paginationForm.controls.page.value > 2) {
          <span
            class="text-base text-[#898989] cursor-pointer"
            tabindex="0"
            (click)="paginate(-1)"
            (keyup)="null"
          >
            {{ paginationForm.controls.page.value - 2 }}
          </span>
        }

        <!-- The previous page -->
        @if (paginationForm.controls.page.value > 1) {
          <span
            class="text-base text-[#898989] cursor-pointer"
            tabindex="0"
            (click)="paginate(-1)"
            (keyup)="null"
          >
            {{ paginationForm.controls.page.value - 1 }}
          </span>
        }

        <!-- The current page -->
        <span class="text-base text-[#f8f8f8] cursor-pointer">
          {{ paginationForm.controls.page.value }}
        </span>

        <!-- The next page -->
        @if (paginationForm.controls.page.value * paginationForm.controls.pageSize.value < (meta?.total ?? 0)) {
          <span
            class="text-base text-[#898989] cursor-pointer"
            tabindex="0"
            (click)="paginate(1)"
            (keyup)="null"
          >
            {{ paginationForm.controls.page.value + 1 }}
          </span>
        }

        <!-- Show ellipsis, if there are more than 2 next pages -->
        @if ((paginationForm.controls.page.value + 2) * paginationForm.controls.pageSize.value < (meta?.total ?? 0)) {
          <span class="text-base text-[#898989] cursor-pointer">
            ...
          </span>
        }

        <!-- The last page -->
        @if ((paginationForm.controls.page.value + 1) * paginationForm.controls.pageSize.value < (meta?.total ?? 0)) {
          <span
            class="text-base text-[#898989] cursor-pointer"
            tabindex="0"
            (click)="paginate(lastPage() - paginationForm.controls.page.value)"
            (keyup)="null"
          >
            {{ lastPage() }}
          </span>
        }
      </div>

      <nrc-button
        class="w-9 h-9 relative flex rotate-180"
        [icon]="'arrow-back'"
        [buttonType]="buttonType.ICON"
        [role]="actionRole.SECONDARY"
        [disabled]="itemEndIndex() + 1 >= (meta?.total ?? 0)"
        (clickEvent)="paginate(1)"
      />
    </div>
  }
</form>
