import { Pipe, PipeTransform } from '@angular/core';
import { ArrayHelper, ObjectHelper } from '@newroom-connect/library/helpers';
import { IObjectProperty } from '@newroom-connect/library/interfaces';

@Pipe({
  standalone: true,
  name: 'extractProperties'
})
export class ExtractPropertiesPipe implements PipeTransform {
  /**
   * Pipe for extracting a list of properties from a given object as a string.
   *
   * @param value The target object to extract the properties from.
   * @param propertiesToExtract The properties to extract from the object in the given format.
   *
   * @returns The value of the object property.
   */
  public transform(value: unknown, propertiesToExtract: IObjectProperty[]): unknown[] {
    if (!ArrayHelper.isNotEmpty(propertiesToExtract)) {
      return [];
    }

    return ObjectHelper.extractProperties(value, propertiesToExtract);
  }
}
