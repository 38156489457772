import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IFileHydrated, IFileUploadItem } from '@newroom-connect/library/interfaces';
import { ObjectPropertyFormat, Level } from '@newroom-connect/library/enums';
import { TranslatePipe, JoinPipe, ExtractPropertiesPipe } from '@newroom-connect/library/pipes';

import { InputTextComponent } from '../../../inputs/input-text/input-text.component';
import { IconComponent } from '../../../icon/icon.component';
import { NoteComponent } from '../../../note/note.component';
import { FileUploaderComponent } from '../../file-uploader/file-uploader.component';
import { MediaPreviewComponent } from '../../../media-preview/media-preview.component';

@Component({
  selector: 'nrc-file-library-modal-selector',
  templateUrl: './file-library-modal-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    InputTextComponent,
    IconComponent,
    NoteComponent,
    FileUploaderComponent,
    MediaPreviewComponent,
    TranslatePipe,
    ExtractPropertiesPipe,
    JoinPipe
  ]
})
export class FileLibraryModalSelectorComponent implements OnChanges {
  @Input() public currentFile?: IFileHydrated;
  @Input() public acceptableMimeTypes: string[] = [];

  @Output() public showFileLibraryEvent = new EventEmitter<void>();
  @Output() public fileUploadEvent = new EventEmitter<IFileUploadItem[]>();

  public fileTitleForm = new FormGroup({
    title: new FormControl({ value: '', disabled: true })
  });

  public level = Level;
  public objectPropertyFormat = ObjectPropertyFormat;

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentFile']?.currentValue) {
      const currentFile = changes['currentFile']?.currentValue as IFileHydrated;

      this.fileTitleForm.controls.title.patchValue(currentFile.title);
    }
  }
}
