import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toDate'
})
export class ToDatePipe implements PipeTransform {
  /**
   * Transform a value to a date.
   *
   * @param value The value to be converted to a date.
   *
   * @returns The transformed value as date.
   */
  public transform(value: string | number): Date {
    return new Date(value);
  }
}
