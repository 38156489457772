import { Injectable, signal, Signal } from '@angular/core';
import { fromEvent, map, merge, of } from 'rxjs';

export interface INetworkStatus {
  isOnline: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  // By default we assume the network is online.
  private networkStatus = signal<INetworkStatus>({ isOnline: true });

  /**
   * @constructor
   */
  constructor() {
    // Start checking the network status immediately on service initialization.
    this.checkNetworkStatus();
  }

  /**
   * Watch the current network status.
   *
   * @returns The signal of the current network status.
   */
  public watchNetworkStatus(): Signal<INetworkStatus> {
    return this.networkStatus.asReadonly();
  }

  /**
   * Watch the current network status and emit the status changes.
   */
  private checkNetworkStatus(): void {
    merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine)).subscribe(status => {
      this.networkStatus.set({ isOnline: status });
    });
  }
}
