import { Injectable, Signal, effect, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ITokenSet } from '@newroom-connect/library/interfaces';
import { UserSession } from '@newroom-connect/library/models';

import { LocalStorageService } from '../local-storage/local-storage.service';
import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class SessionService {
  private static readonly LOCAL_STORAGE_KEYS = ['accessToken', 'idToken', 'refreshToken', 'rememberMe'];

  private userSession = signal<UserSession | null>(null);
  private signInRoute = signal<string>('/signin');

  /**
   * @constructor
   *
   * @param router
   * @param localStorageService
   * @param loggingService
   */
  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly loggingService: LoggingService
  ) {
    effect(() => {
      const userSession = this.userSession();

      if (userSession === null) {
        this.router.navigate([this.signInRoute()]).catch(error => this.loggingService.error(error));
      }
    }, { allowSignalWrites: true });
  }

  /**
   * Initializes the session service where the token set from the local storage is loaded into the user session.
   */
  public initialize(): void {
    this.userSession.set(new UserSession({
      accessToken: this.localStorageService.get('accessToken'),
      idToken: this.localStorageService.get('idToken'),
      refreshToken: this.localStorageService.get('refreshToken')
    }, Boolean(this.localStorageService.get('rememberMe') ?? false)));
  }

  /**
   * Watch the current user session.
   *
   * @returns Read-only signal of the current user session.
   */
  public watchUserSession(): Signal<UserSession | null> {
    return this.userSession.asReadonly();
  }

  /**
   * Get the current user session.
   *
   * @returns The current user session or `null` if no session is available.
   */
  public getUserSession(): UserSession | null {
    return this.userSession();
  }

  /**
   * Set tokens from a given token set in the local storage and the current token set.
   *
   * @param tokenSet The token set to set.
   * @param rememberMe Indicator, if "rememberMe" is desired -> will store tokens in local storage.
   */
  public setUserSession(tokenSet: ITokenSet, rememberMe?: boolean): void {
    if (rememberMe) {
      this.localStorageService.set('accessToken', tokenSet.accessToken);
      this.localStorageService.set('idToken', tokenSet.idToken);
      this.localStorageService.set('refreshToken', tokenSet.refreshToken);
      this.localStorageService.set('rememberMe', `${rememberMe ? true : false}`);
    }

    this.userSession.set(new UserSession(tokenSet, rememberMe));
  }

  /**
   * Remove existing tokens from the local storage and the current token set.
   */
  public clearUserSession(): void {
    for (const localStorageKey of SessionService.LOCAL_STORAGE_KEYS) {
      this.localStorageService.remove(localStorageKey);
    }

    this.userSession.set(null);
  }

  /**
   * Sets the signin route where the user will be redirected in case the user session is cleared.
   *
   * @param route
   */
  public setSignInRoute(route: string): void {
    this.signInRoute.set(route);
  }
}
