import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild, WritableSignal, effect, forwardRef, signal } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs';
import { FormControlPipe } from '@newroom-connect/library/pipes';
import { ActionRole, ButtonType, ModalSize, TranslatableInputType } from '@newroom-connect/library/enums';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { ITranslateInputOutput } from '@newroom-connect/library/services';
import { TranslationElement } from '@newroom-connect/library/types';

import { InputComponent } from '../input.component';
import { InputLanguageSelectorComponent, InputLanguageSelectorTextSize } from '../input-language-selector/input-language-selector.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { AutoTranslateComponent } from '../../auto-translate/auto-translate.component';
import { ModalComponent, ModalService } from '../../modal/modal.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    IconComponent,
    FormControlPipe,
    ButtonComponent,
    AutoTranslateComponent,
    InputLanguageSelectorComponent
  ],
  selector: 'nrc-input-translatable-text',
  templateUrl: './input-translatable-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTranslatableTextComponent),
      multi: true
    }
  ]
})
export class InputTranslatableTextComponent extends InputComponent<TranslationElement<string | null>> implements OnChanges {
  @Input({ required: true }) public languageCodes!: string[];
  @Input() public maxLength: string | number | null = 256;
  @Input() public type: TranslatableInputType = TranslatableInputType.TEXT_AREA;
  @Input() public selectedLanguageCode?: WritableSignal<string>;

  @Output() public languageCodeChangedEvent = new EventEmitter<string>();

  @ViewChild('autoTranslateModalContent') public autoTranslateModalContent!: TemplateRef<any>;

  public translatableFormControls: Record<string, FormControl<string | null>> = {};
  public languageCodeFormControl = new FormControl<string>('', { nonNullable: true });
  public isTranslationModalOpen = signal<boolean>(false);
  public autoTranslateInputs = signal<{ [key: string]: string }>({});
  public currentLanguageCode = signal<string>('');

  public actionRole = ActionRole;
  public buttonType = ButtonType;
  public translatableInputType = TranslatableInputType;
  public inputLanguageSelectorTextSize = InputLanguageSelectorTextSize;
  /**
   * @constructor
   *
   * @param modalService
   */
  constructor(private readonly modalService: ModalService) {
    super();

    // If the selected language changed externally, update the form control to reflect the new language.
    effect(() => {
      if (!this.selectedLanguageCode) {
        return;
      }

      const currentLanguageCode = this.selectedLanguageCode();

      if (currentLanguageCode !== this.languageCodeFormControl.value) {
        this.currentLanguageCode.set(currentLanguageCode);
        this.languageCodeFormControl.patchValue(currentLanguageCode);
      }
    }, { allowSignalWrites: true });
  }

  /**
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['formControl']) {
      this.initializeFormControls();
      this.registerFormControlsChange();
    }
  }

  /**
   * Initializes the translatable form controls for each language code.
   *
   * Iterates over the language codes and creates a new form control for each one,
   * using the corresponding value from the `formControl` as the initial value, or an empty string if none is provided.
   */
  public initializeFormControls(): void {
    if (!ArrayHelper.isNotEmpty(this.languageCodes)) {
      return;
    }

    const [defaultLanguageCode] = this.languageCodes;

    this.languageCodeFormControl.patchValue(defaultLanguageCode);
    this.currentLanguageCode.set(defaultLanguageCode);

    for (const languageCode of this.languageCodes) {
      this.translatableFormControls[languageCode] = new FormControl<string>(this.formControl.value[languageCode] ?? '');
    }
  }

  /**
   * Registers value change listeners for translatable form controls.
   * When the value changes for one of the translatable form controls,
   * it updates the corresponding language code in the main form control value.
   *
   */
  public registerFormControlsChange(): void {
    if (!this.translatableFormControls) {
      return;
    }

    for (const languageCode of this.languageCodes) {
      this.translatableFormControls[languageCode].valueChanges.pipe(
        debounceTime(200),
        takeUntil(this.destroy$)
      ).subscribe((value) => {
        const oldFormControlValue = this.formControl.value as TranslationElement<string | null>;

        oldFormControlValue[languageCode] = value;

        this.formControl.patchValue(oldFormControlValue);
        this.writeValue(this.formControl.value);
      });
    }
  }

  /**
   *
   * @param result
   */
  public handleAutoTranslateResult(result: ITranslateInputOutput): void {
    const { from, to, payload } = result;

    if (from === this.languageCodeFormControl.value && payload) {
      this.translatableFormControls[to].patchValue(payload['formControlValue']);

      this.languageCodeFormControl.patchValue(to, { emitEvent: true });

      this.currentLanguageCode.set(to);
    }

    this.toggleAutoTranslateModal();
  }

  /**
   *
   */
  public toggleAutoTranslateModal(): void {
    if (!this.isTranslationModalOpen()) {
      this.autoTranslateInputs.set({ formControlValue: this.formControl.value[this.languageCodeFormControl.value] });

      const modalRef = this.modalService.open<ModalComponent>({
        component: ModalComponent,
        inputs: {
          id: 'AutoTranslateModal',
          size: ModalSize.SMALL,
          paddedContent: true
        },
        content: this.autoTranslateModalContent
      });

      modalRef.instance.closeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
        this.modalService.close('AutoTranslateModal');
      });

      this.isTranslationModalOpen.set(true);
    } else {
      this.modalService.close('AutoTranslateModal');
      this.isTranslationModalOpen.set(false);
    }
  }
}
