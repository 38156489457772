import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { MediaSource } from '@newroom-connect/library/types';

import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';

export type ImageObjectFit = 'contain' | 'cover';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoadingSpinnerComponent
  ],
  selector: 'nrc-image',
  templateUrl: './image.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent {
  @Input({ required: true }) public source!: MediaSource;
  @Input() public alt?: string;
  @Input() public objectFit: 'cover' | 'contain' | 'fill' = 'cover';
  @Input() public isTransparent = false;
  @Input() public isRounded = false;

  @Output() public loadComplete = new EventEmitter<void>();

  public hasLoadedData = false;

  /**
   * Handle image load completion.
   */
  public onLoadComplete(): void {
    this.hasLoadedData = true;
    this.loadComplete.emit();
  }
}
