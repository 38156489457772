<div class="flex flex-col items-center mx-auto overflow-y-auto w-full h-full p-0.5">
  <div class="w-full space-y-4">
    <div class="flex flex-col items-start md:items-start justify-between">
      <p class="text-3xl leading-tight tracking-normal">
        {{ 'PAGES.SIGNIN.PAGE_HEADER' | translate }}
      </p>
      <p class="text-xs mt-4">
        {{ 'PAGES.SIGNIN.CODE_USER' | translate }}
      </p>
    </div>

    @if (errorMessage) {
      <div class="w-full bg-[#9730307f] h-10 border-l-2 border-l-error">
        <span class="text-xs min-h-full inline-flex items-center ml-3">{{ errorMessage }}</span>
      </div>
    }

    <form [formGroup]="signInCodeLoginForm" class="w-full grid gap-6 grid-cols-1 mt-12 md:mt-6 mb-12 md:mb-6" action="#">
      <nrc-input-password
        [formControl]="signInCodeLoginForm.controls.code"
        [passwordRegExp]="codeRegExp"
        [name]="'signin-code'"
        [id]="'signin-code'"
        [label]="'Code'"
        [invalidHint]="'ERROR.INVALID_CODE.MESSAGE' | translate"
        [classList]="['bg-[#454545]', 'md:bg-neutral-900']"
      />
    </form>

    <div class="flex justify-end">
      <nrc-button
        class="mt-6"
        [label]="'Log in'"
        (clickEvent)="login()"
        [disabled]="signInCodeLoginForm.invalid"
      />
    </div>
  </div>
</div>
