import { Injectable, inject, signal } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, map, switchMap, throwError, of, tap, shareReplay } from 'rxjs';
import { IArea, AreaPerspective, IAPIListResponse, IListOptions } from '@newroom-connect/library/interfaces';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';
import { LoggingService } from '../logging/logging.service';

import { AreaElementService, IUpdateAreaElementInput } from './area-element.service';

export interface ICreateAreaInput {
  projectId: string;
  title: string; // Will be distributed across all translations
  translations: { code: string; }[]; // The language codes which are used for i81n properties of the area
  perspective: AreaPerspective;
  templateId?: string; // The optional ID of the template to pre-populate the project data with
}

export interface IUpdateAreaInput {
  slug?: string;
  enabled?: boolean;
  translations?: IUpdateAreaTranslationInput[];
  password?: string | null;
  areaElements?: IUpdateAreaElementInput[];
}

export interface IUpdateAreaTranslationInput {
  code: string;
  title?: string;
  backgroundFileId?: string;
}

export interface IDuplicateAreaInput {
  title: string;
}

// Interface for cache entries with TTL
interface AreaCacheEntry {
  data: IArea;
  timestamp: number;
  expiresAt: number;
}

@Injectable({
  providedIn: 'root'
})
export class AreaService extends EntityService {
  private readonly logger = inject(LoggingService);

  public static readonly AREA_DEFAULT_TITLE = 'Unknown Area';

  // Default cache TTL in milliseconds (5 minutes)
  private readonly CACHE_TTL = 5 * 60 * 1000;

  // In-memory caches for areas
  private areaByIdCache = new Map<string, AreaCacheEntry>();
  private areaBySlugCache = new Map<string, AreaCacheEntry>();

  // Request cache to deduplicate in-flight requests
  private areaRequests = new Map<string, Observable<IArea>>();

  public searchableProperties = ['slug', 'translations[].title'];

  public currentArea = signal<IArea | null>(null);

  /**
   * Clears all area caches.
   */
  public clearAreaCaches(): void {
    this.areaByIdCache.clear();
    this.areaBySlugCache.clear();
    this.areaRequests.clear();
    this.logger.debug('Area caches cleared');
  }

  /**
   * Gets an area by ID with caching.
   *
   * @param projectId
   * @param areaId
   *
   * @returns
   */
  public getArea(projectId: string, areaId: string): Observable<IArea> {
    // Clean expired cache entries
    this.cleanExpiredCacheEntries();

    // Create a cache key
    const cacheKey = `${projectId}:${areaId}`;

    // Check if we have a cached area
    const cachedArea = this.areaByIdCache.get(cacheKey);

    if (cachedArea) {
      this.logger.debug(`Using cached area for ID ${areaId}`);
      return of(cachedArea.data);
    }

    // Check if we have an in-flight request
    const pendingRequest = this.areaRequests.get(cacheKey);

    if (pendingRequest) {
      this.logger.debug(`Reusing in-flight request for area ID ${areaId}`);
      return pendingRequest;
    }

    // Create a new request with caching
    const request = this.apiService.get<IArea>(`projects/${projectId}/areas/${areaId}`).pipe(
      map(area => {
        // Hydrate the area elements with the media library files
        area.areaElements = AreaElementService.hydrateAreaElementsMediaLibrary(area.areaElements);
        return area;
      }),
      tap(area => {
        // Cache the result
        this.cacheArea(projectId, area);
        // Remove from in-flight requests
        this.areaRequests.delete(cacheKey);
      }),
      shareReplay(1) // Share the result with all subscribers
    );

    // Store the in-flight request
    this.areaRequests.set(cacheKey, request);

    return request;
  }

  /**
   * Gets an area by slug with caching.
   *
   * @param projectId
   * @param areaSlug
   *
   * @returns
   */
  public getAreaBySlug(projectId: string, areaSlug: string): Observable<IArea> {
    // Clean expired cache entries
    this.cleanExpiredCacheEntries();

    // Create a cache key
    const cacheKey = `${projectId}:slug:${areaSlug}`;

    // Check if we have a cached area
    const cachedArea = this.areaBySlugCache.get(cacheKey);

    if (cachedArea) {
      this.logger.debug(`Using cached area for slug ${areaSlug}`);
      return of(cachedArea.data);
    }

    // Check if we have an in-flight request
    const pendingRequest = this.areaRequests.get(cacheKey);

    if (pendingRequest) {
      this.logger.debug(`Reusing in-flight request for area slug ${areaSlug}`);
      return pendingRequest;
    }

    // Create a new request with caching
    const request = this.listAreas(projectId, { filters: { slug: areaSlug } }).pipe(
      switchMap(response => {
        if (ArrayHelper.isNotEmpty(response.data)) {
          // Re-fetch the area with the found id, to guarantee all needed properties are available.
          // Use getArea method which now includes caching
          return this.getArea(projectId, response.data[0].id);
        }

        return throwError(() => new HttpErrorResponse({
          error: 'Area not found',
          status: 404
        }));
      }),
      tap(area => {
        // Cache the result by slug
        this.areaBySlugCache.set(cacheKey, {
          data: area,
          timestamp: Date.now(),
          expiresAt: Date.now() + this.CACHE_TTL
        });
        // Remove from in-flight requests
        this.areaRequests.delete(cacheKey);
      }),
      shareReplay(1) // Share the result with all subscribers
    );

    // Store the in-flight request
    this.areaRequests.set(cacheKey, request);

    return request;
  }

  /**
   * Cache an area in memory.
   *
   * @param projectId
   * @param area
   */
  private cacheArea(projectId: string, area: IArea): void {
    const now = Date.now();
    const expiresAt = now + this.CACHE_TTL;

    // Cache by ID
    this.areaByIdCache.set(`${projectId}:${area.id}`, {
      data: area,
      timestamp: now,
      expiresAt
    });

    // Cache by slug
    if (area.slug) {
      this.areaBySlugCache.set(`${projectId}:slug:${area.slug}`, {
        data: area,
        timestamp: now,
        expiresAt
      });
    }

    this.logger.debug(`Cached area ${area.slug} (${area.id})`);
  }

  /**
   * Remove expired entries from all caches.
   */
  private cleanExpiredCacheEntries(): void {
    const now = Date.now();
    let expiredCount = 0;

    // Clean ID cache
    this.areaByIdCache.forEach((entry, key) => {
      if (entry.expiresAt < now) {
        this.areaByIdCache.delete(key);
        expiredCount++;
      }
    });

    // Clean slug cache
    this.areaBySlugCache.forEach((entry, key) => {
      if (entry.expiresAt < now) {
        this.areaBySlugCache.delete(key);
        expiredCount++;
      }
    });

    if (expiredCount > 0) {
      this.logger.debug(`Removed ${expiredCount} expired area cache entries`);
    }
  }

  /**
   * Updates a cached area when it has been modified.
   *
   * @param area The updated area.
   */
  public updateCachedArea(area: IArea): void {
    if (!area || !area.id || !area.projectId) {
      return;
    }

    this.cacheArea(area.projectId, area);
    this.logger.debug(`Updated cached area ${area.slug} (${area.id})`);
  }

  /**
   *
   * @param projectId
   * @param options
   *
   * @returns
   */
  public listAreas(projectId: string, options?: IListOptions): Observable<IAPIListResponse<IArea>> {
    return super.list<IArea>(`projects/${projectId}/areas`, undefined, options);
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public createArea(input: ICreateAreaInput): Observable<IArea> {
    return this.apiService.post<IArea>(`projects/${input.projectId}/areas`, input).pipe(
      tap(area => {
        // Cache the newly created area
        this.cacheArea(input.projectId, area);
      })
    );
  }

  /**
   *
   * @param projectId
   * @param areaId
   * @param input
   *
   * @returns
   */
  public updateArea(projectId: string, areaId: string, input: IUpdateAreaInput): Observable<IArea> {
    return this.apiService.patch<IArea>(`projects/${projectId}/areas/${areaId}`, input).pipe(
      map(area => {
        // Hydrate the area elements with the media library files.
        area.areaElements = AreaElementService.hydrateAreaElementsMediaLibrary(area.areaElements);
        return area;
      }),
      tap(area => {
        // Update the cached area
        this.cacheArea(projectId, area);
      })
    );
  }

  /**
   *
   * @param projectId
   * @param areaId
   *
   * @returns
   */
  public deleteArea(projectId: string, areaId: string): Observable<IArea> {
    // Remove from caches
    this.areaByIdCache.delete(`${projectId}:${areaId}`);

    // We need to check all slug cache entries to find any matching the area ID
    this.areaBySlugCache.forEach((entry, key) => {
      if (entry.data.id === areaId) {
        this.areaBySlugCache.delete(key);
      }
    });

    return this.apiService.delete<IArea>(`projects/${projectId}/areas/${areaId}`);
  }

  /**
   *
   * @param projectId
   * @param areaIds
   *
   * @returns
   */
  public deleteAreas(projectId: string, areaIds: string[]): Observable<IArea[]> {
    // Remove all areas from caches
    for (const areaId of areaIds) {
      this.areaByIdCache.delete(`${projectId}:${areaId}`);

      // Check all slug cache entries
      this.areaBySlugCache.forEach((entry, key) => {
        if (entry.data.id === areaId) {
          this.areaBySlugCache.delete(key);
        }
      });
    }

    return forkJoin(areaIds.map(areaId => this.apiService.delete<IArea>(`projects/${projectId}/areas/${areaId}`)));
  }

  /**
   *
   * @param projectId
   * @param areaId
   * @param input
   *
   * @returns
   */
  public duplicateArea(projectId: string, areaId: string, input: IDuplicateAreaInput): Observable<IArea> {
    return this.apiService.post<IArea>(`projects/${projectId}/areas/${areaId}/duplicate`, input).pipe(
      tap(area => {
        // Cache the duplicated area
        this.cacheArea(projectId, area);
      })
    );
  }

  /**
   * Get cache statistics.
   *
   * @returns Object with cache stats.
   */
  public getCacheStats(): {
    idCacheSize: number;
    slugCacheSize: number;
    pendingRequests: number;
    } {
    return {
      idCacheSize: this.areaByIdCache.size,
      slugCacheSize: this.areaBySlugCache.size,
      pendingRequests: this.areaRequests.size
    };
  }
}
