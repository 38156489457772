  <div class="relative flex flex-col w-full ">
  <div class="relative flex w-full align-middle items-center">
    <div class="flex flex-col w-full items-start">
      <input
        #inputElement
        [type]="inputType()"
        [name]="name"
        [id]="id"
        class="block w-full h-10 bg-neutral-900 pl-4 pt-4 pr-10 rounded-t text-sm/[16px]
          border-0 border-b-1 border-b-gray-700 caret-cyan appearance-none
          peer focus:border-b-cyan-highlight focus:outline-none focus:ring-0"
        [ngClass]="{
          'border-b-error focus:border-b-error' : formControl.touched && formControl.invalid,
          'border-b-gray-70' : !formControl.invalid
        }"
        [autocomplete]="name"
        [tabindex]="tabIndex"
        [placeholder]="placeholder"
        (input)="handleInputChange($event)"
      />

      <label
        class="absolute ml-4 mt-0.5 text-xxs text-cyan
          peer-placeholder-shown:text-gray-400
          peer-placeholder-shown:mt-3
          peer-placeholder-shown:text-sm/[16px]
          duration-300"
        [for]="id"
      >{{ label }}</label>
    </div>

    <a href="javascript:void(0)" class="absolute h-3.5 ps-2 right-[15px]" (click)="toggleShowPassword()">
      <nrc-icon
        class="block w-3.5 h-3.5"
        [name]="showPassword ? 'visibility-off' : 'visibility-on'"
      />
    </a>
  </div>

  @if (invalidHint && formControl.invalid && formControl.touched) {
    <p class="text-xs text-error leading-tight tracking-[.01125em] pt-1">
      {{ invalidHint }}
    </p>
  }
</div>
