import { Pipe, PipeTransform } from '@angular/core';

export type ColorType = 'black' | 'white' | 'cyan' | 'transparent' | 'semi-transparent' | 'error' | 'warning' | 'success';

export type FillColor = ColorType;

@Pipe({
  standalone: true,
  name: 'fillColor'
})
export class FillColorPipe implements PipeTransform {
  /**
   * Determine the fill color CSS class based on the given icon fill color.
   *
   * @param fillColor The fill color to determine the fill color CSS class from.
   *
   * @returns The Tailwind CSS class of the fill color.
   */
  public transform(fillColor?: FillColor): { [className: string]: any; } {
    const fillColorMap: Record<string, boolean> = {
      'fill-white': !fillColor || fillColor === 'white',
      'fill-black': fillColor === 'black',
      'fill-cyan': fillColor === 'cyan',
      'fill-transparent': fillColor === 'transparent',
      'fill-white/50': fillColor === 'semi-transparent',
      'fill-error': fillColor === 'error',
      'fill-warning': fillColor === 'warning',
      'fill-success': fillColor === 'success'
    };

    for (const fillColorMapKey of Object.keys(fillColorMap)) {
      if (!fillColorMap[fillColorMapKey]) {
        delete fillColorMap[fillColorMapKey];
      }
    }

    return fillColorMap;
  }
}
