import { Directive, ElementRef, EventEmitter, HostListener, Output, Renderer2, inject } from '@angular/core';

export type ReorderEvent = { fromIndex: number; toIndex: number };

@Directive({
  selector: '[nrcReorder]',
  standalone: true
})
export class ReorderDirective {
  @Output() public reorderEvent = new EventEmitter<ReorderEvent>();

  private readonly elementRef: ElementRef<HTMLDivElement> = inject(ElementRef<HTMLDivElement>);
  private readonly renderer = inject(Renderer2);

  private startY = 0;
  private isReorderable = false;
  private fromIndex = -1;
  private toIndex = -1;

  private selectedElement: HTMLElement | null = null;

  /**
   * @constructor
   */
  constructor() {
    this.onMousedown = this.onMousedown.bind(this);
    this.onMousemove = this.onMousemove.bind(this);
    this.onMouseup = this.onMouseup.bind(this);
  }

  /**
   *
   * @param event
   */
  @HostListener('mousedown', ['$event'])
  public onMousedown(event: MouseEvent): void {
    this.startY = event.clientY;

    this.isReorderable = true;

    const children = this.elementRef.nativeElement.children;

    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);

      if (!child) {
        continue;
      }

      const childBoundingRect = child.getBoundingClientRect();

      if (event.clientY > childBoundingRect.top && event.clientY < childBoundingRect.bottom) {
        this.selectedElement = child as HTMLElement;

        this.fromIndex = i;
        this.toIndex = i;
      }
    }

    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
  }

  /**
   *
   * @param event
   */
  public onMousemove(event: MouseEvent): void {
    if (!this.isReorderable || !this.selectedElement) {
      return;
    }

    const deltaY = event.clientY - this.startY;

    const children = this.elementRef.nativeElement.children;

    for (let i = 0; i < children.length; i++) {
      const child = children.item(i);

      if (!child || i === this.fromIndex) {
        continue;
      }

      const childMidY = child.getBoundingClientRect().top + (child.getBoundingClientRect().height / 2);
      const selectedElementMidY = this.selectedElement.getBoundingClientRect().top + (this.selectedElement.getBoundingClientRect().height / 2);

      if ((selectedElementMidY > childMidY && i > this.fromIndex) || (selectedElementMidY < childMidY && i < this.fromIndex)) {
        this.toIndex = Math.max(0, Math.min(i, children.length - 1));
      }
    }

    this.renderer.setStyle(this.selectedElement, 'transform', `translateY(${deltaY}px)`);
  }

  /**
   *
   */
  public onMouseup(): void {
    if (!this.selectedElement) {
      return;
    }

    if (this.isReorderable) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'auto');
      this.renderer.removeStyle(this.selectedElement, 'transform');

      document.removeEventListener('mousemove', this.onMousemove);
      document.removeEventListener('mouseup', this.onMouseup);

      // Only emit a reorder event if the element from and to index changed.
      if (this.fromIndex !== this.toIndex) {
        this.reorderEvent.emit({ fromIndex: this.fromIndex, toIndex: this.toIndex });
      }

      this.toIndex = -1;
    }

    this.isReorderable = false;
  }
}
