import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormControlPipe } from '@newroom-connect/library/pipes';
import { InputHelper } from '@newroom-connect/library/helpers';
import { ToastService } from '@newroom-connect/library/services';
import { Level } from '@newroom-connect/library/enums';

import { InputComponent } from '../input.component';
import { IconComponent } from '../../icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, IconComponent, FormControlPipe],
  selector: 'nrc-input-text',
  templateUrl: './input-text.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextComponent),
      multi: true
    }
  ]
})
export class InputTextComponent extends InputComponent<string> implements OnInit, OnChanges {
  @Input({ required: true }) public type = 'text';
  @Input() public value? = '';
  @Input() public clipboardEnabled?: boolean;
  @Input() public clearEnabled = true;
  @Input() public readonly = false;
  @Input() public hint?: string;
  @Input() public invalidHint?: string;

  /**
   * @constructor
   *
   * @param toastService
   */
  constructor(
    private readonly toastService: ToastService
  ) {
    super();
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    const validators = this.formControl.validator ? [this.formControl.validator] : [];

    // TODO: Improve this since this should not be handled inside the text component.
    if (this.type === 'email') {
      validators.push(Validators.email);
    }

    this.formControl.setValidators(validators);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['value']) {
      this.formControl.patchValue(changes['value'].currentValue, { emitEvent: true });
    }
  }

  /**
   * Reset the input of the form control and the HTML input element.
   */
  public resetInput(): void {
    this.formControl.reset('');
  }

  /**
   *
   * @param input
   * @param toastTimeout
   */
  public copyToClipboard(input: unknown, toastTimeout = 3000): void {
    InputHelper.copyToClipboard(input as string);

    this.toastService.setMessage({
      message: 'Copied value to clipboard.',
      level: Level.SUCCESS
    }, toastTimeout);
  }
}
