import { Injectable, signal, Signal } from '@angular/core';
import { Observable, map } from 'rxjs';
import { IAPIListResponse, ILanguage } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService extends EntityService {
  public searchableProperties = [];

  private selectedLanguageCode = signal<string | null>(null);

  /**
   *
   * @param languageCode
   */
  public setSelectedLanguageCode(languageCode: string): void {
    this.selectedLanguageCode.set(languageCode);
  }

  /**
   *
   * @returns
   */
  public watchSelectedLanguageCode(): Signal<string | null> {
    return this.selectedLanguageCode.asReadonly();
  }

  /**
   *
   * @returns
   */
  public listLanguages(): Observable<IAPIListResponse<ILanguage>> {
    return this.apiService.list<IAPIListResponse<ILanguage>>('languages').pipe(
      map(languages => {
        languages.data.sort((a, b) => a.precedence - b.precedence);

        return languages;
      })
    );
  }
}
