import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IActionButton } from '@newroom-connect/library/interfaces';

import { ToolbarItemComponent } from './toolbar-item/toolbar-item.component';

export type ToolbarItem = {
  id: string;
  iconName: string;
  label?: string;
  tooltipText?: string;
  tooltipTextTopside?: boolean;
  disabled?: boolean;
  contextMenu?: IActionButton[]
}

@Component({
  selector: 'nrc-toolbar',
  standalone: true,
  imports: [ToolbarItemComponent],
  templateUrl: './toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {
  @Input() public label?: string;
  @Input() public items?: ToolbarItem[];

  @Output() public itemClickEvent = new EventEmitter<ToolbarItem>();
  @Output() public contextMenuClickEvent = new EventEmitter<IActionButton>();

  /**
   * Handles click on toolbar item by emitting clicked toolbar item.
   *
   * @param item
   */
  public handleClickEvent(item: ToolbarItem): void {
    this.itemClickEvent.emit(item);
  }

  /**
   * Handles click on context menu item of toolbar item by emitting the clicked action button.
   *
   * @param actionButton
   */
  public handleContextMenuActionButtonEvent(actionButton: IActionButton): void {
    this.contextMenuClickEvent.emit(actionButton);
  }
}
