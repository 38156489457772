<form class="w-full flex flex-col space-y-2 bg-white/5 p-2 rounded-lg" [formGroup]="colorOpacityFormGroup">
  <div class="flex justify-between items-start">
    <div class="flex space-x-2">
      <input
        #colorInput
        class="w-10 h-10 border-none outline-none bg-transparent rounded-md hover:cursor-pointer"
        type="color"
        formControlName="color"
        [id]="id"
        [name]="name"
      >

      <div class="grow flex flex-col space-y-0.5">
        @if (label) {
          <span class="text-xxs text-[#adadad] font-light tracking-wide">
            {{ label }}
          </span>
        }

        <input
          class="p-0.5 -translate-x-0.5 -translate-y-0.5 bg-transparent border-none outline-none focus:ring-cyan hover:ring-cyan hover:ring-1 text-sm text-neutral-100 font-light tracking-wider transition-all"
          [ngClass]="{
            'text-error' : formControl.touched && formControl.invalid
          }"
          type="text"
          formControlName="color"
          [value]="colorOpacityFormGroup.controls.color.value"
          (change)="onColorChange()"
        >
      </div>
    </div>

    <nrc-button
      class="w-7 h-7 relative flex"
      [icon]="'pipette'"
      [buttonType]="buttonType.ICON"
      [role]="actionRole.TRANSPARENT_SECONDARY"
      (clickEvent)="openColorPicker()"
    />
  </div>

  <div class="flex flex-col space-y-2">
    <span class="text-xxs text-[#adadad] font-light tracking-wide">
      {{ 'COMPONENTS.SHARED.INPUTS.COLOR.OPACITY.LABEL' | translate }}
    </span>

    <nrc-input-range
      [formControl]="colorOpacityFormGroup.controls.opacity"
      [min]="0"
      [max]="100"
      [label]="'%'"
    />
  </div>
</form>
