import { Injectable } from '@angular/core';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Observable } from 'rxjs';
import { IFileHydrated, IFileUploadItem } from '@newroom-connect/library/interfaces';

import { BroadcastService } from '../broadcast/broadcast.service';
import { LoggingService } from '../logging/logging.service';

import { FileService } from './file.service';

export enum FileBroadcastEvent  {
  UPLOADED_FILE = 'uploadedFile',
  REPLACED_FILE = 'replacedFile',
  REPLACE_FILE = 'replaceFile',
  UPDATED_FILE = 'updatedFile',
  UPDATE_FILE = 'updateFile'
}

@Injectable({
  providedIn: 'root'
})
export class FileUploadService extends FileService {
  /**
   * @constructor
   *
   * @param loggingService
   * @param broadcastService
   */
  constructor(
    private readonly loggingService: LoggingService,
    private readonly broadcastService: BroadcastService
  ) {
    super();
  }

  /**
   *
   * @param projectId
   * @param file
   *
   * @returns
   */
  public uploadFile(projectId: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();

    formData.append('file', file);

    return this.apiService.postWithReportedProgress<any>(`projects/${projectId}/file-upload`, formData);
  }

  /**
   *
   * @param projectId
   * @param fileId
   * @param file
   *
   * @returns
   */
  public replaceFile(projectId: string, fileId: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData();

    formData.append('file', file);

    return this.apiService.postWithReportedProgress<any>(`projects/${projectId}/file-upload/${fileId}/replace`, formData);
  }

  /**
   *
   * @param fileUploadEvent
   * @param fileUploadItem
   * @param eventType
   * @returns
   */
  public handleFileUploadEvent(fileUploadEvent: HttpEvent<any>, fileUploadItem: IFileUploadItem, eventType: FileBroadcastEvent): IFileHydrated | void {
    switch (fileUploadEvent.type) {
      case HttpEventType.Sent:
        fileUploadItem.status.set('in-progress');

        this.loggingService.debug('File upload request has been made.');

        break;
      case HttpEventType.ResponseHeader:
        this.loggingService.debug('File upload response header has been received.');
        break;
      case HttpEventType.UploadProgress:
        fileUploadItem.progress.set(Math.round(fileUploadEvent.loaded / (fileUploadEvent.total ?? Number.MAX_SAFE_INTEGER) * 100));

        this.loggingService.debug(`Uploaded ${fileUploadItem.progress()}%...`);

        break;
      case HttpEventType.Response:
        this.loggingService.debug('File upload was successful', fileUploadEvent.body, eventType);

        setTimeout(() => {
          fileUploadItem.status.set('success');

          this.broadcastService.emitBroadcast<IFileHydrated>({
            name: eventType,
            state: FileService.hydrateFile(fileUploadEvent.body)
          });
        }, 500);

        return FileService.hydrateFile(fileUploadEvent.body);

        break;
      default:
        break;
    }
  }
}
