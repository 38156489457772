import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@newroom-connect/library/helpers';

@Pipe({
  standalone: true,
  name: 'toString'
})
export class ToStringPipe implements PipeTransform {
  /**
   *
   * @param value
   *
   * @returns
   */
  public transform(value: unknown): string {
    return StringHelper.toString(value);
  }
}
