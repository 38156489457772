<div #searchContainer  class="relative flex flex-col items-end justify-center">
  <div
    class="flex items-center h-10 rounded-full bg-white/10 transition-width"








    [ngClass]="{
      'w-10 justify-center': !searchEnabled() && isCollapsible,
      'p-3 justify-between w-full': searchEnabled(),
      'w-full': !isCollapsible,
    }"

  >
    <form
      class="flex items-center justify-between h-5"
      [ngClass]="{
        'w-0': !searchEnabled(),
        'grow': searchEnabled() && isCollapsible
      }"
      [formGroup]="searchFormGroup"
    >
      <input
        type="text"
        class="w-full h-full p-0 text-sm bg-transparent border-transparent outline-none focus:border-transparent focus:outline-none placeholder:text-white/70 placeholder:text-ellipsis focus:ring-0 transition-width"
        [placeholder]="placeholder"
        formControlName="searchInput"
      />

      @if (searchEnabled() && searchFormGroup.controls.searchInput.value && searchFormGroup.controls.searchInput.value.length > 0) {
        <button
          class="h-full"
          type="button"
          tabindex="-1"
          (click)="null"
        >
          <nrc-icon
            class="w-4 h-4 opacity-50"
            [name]="'clear'"
            (click)="resetSearchInput()"
          />
        </button>
      }
    </form>













    <div class="flex items-center">
      <nrc-icon
        class="w-4 h-4 transition-opacity hover:opacity-100"
        [ngClass]="{
          'opacity-50': !searchEnabled(),
          'opacity-100 ml-3': searchEnabled(),
          'cursor-pointer': isCollapsible
        }"
        [name]="'search'"
        (click)="isCollapsible ? toggleSearchEnabled() : null"
      />

      @if (searchEnabled() && filterEnabled) {
        <nrc-icon
          class="w-4 h-4 ml-2 cursor-pointer"
          [fillColor]="isFilterOpen() ? 'cyan' : 'white'"
          [name]="'filter'"
          (click)="toggleFilter()"
        />
      }
    </div>
  </div>

  @if (searchEnabled() && isFilterOpen()) {
    <div
      class="absolute flex flex-col w-full mt-2 transition-all bg-[#0a0a0a] border rounded-lg top-full border-white/20 z-[101]"
      [@growAnimation]="{
        value: '',
        params: {
          heightStart: '25px',
          heightEnd: '5rem'
        }
      }"
    >
      <!-- Tag Search Input -->
      <div class="flex items-center px-2 py-1 border-b border-white/10">
        <input
          [formControl]="tagSearchControl"
          type="text"
          class="w-full text-xs bg-transparent border-none outline-none focus:ring-0"
          placeholder="Search tags..."
        />
      </div>

      <!-- Selected Tags -->
      @if (selectedTags().length > 0) {
        <div class="flex flex-wrap gap-2 p-3 border-b border-white/10">
          @for (tag of selectedTags(); track tag) {
            <nrc-chip
              @fadeInOutAnimation
              [label]="tag"
              [config]="{ isClosable: true }"
              (closeEvent)="removeTag(tag)"
              class="text-sm"
            />
          }
        </div>
      }

      <!-- Available Tags -->
      <div class="overflow-y-auto max-h-48">
        @for (tag of filteredTags(); track tag) {
          <div
            class="flex items-center px-2 py-1 text-xs cursor-pointer text-white/70 hover:bg-white/5"
            (click)="selectTag(tag)"
            tabindex="0"
            (keyup)="null"
          >
            {{ tag | uppercase }}
          </div>
        }
      </div>
    </div>
  }
</div>
