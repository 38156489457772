import { Injectable, signal } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TitleStrategy, RouterStateSnapshot } from '@angular/router';
import { Observable, of, map, takeUntil, Subject, switchMap } from 'rxjs';
import { IProject, IArea, IFile } from '@newroom-connect/library/interfaces';
import { AreaService, ProjectService, FileService } from '@newroom-connect/library/services';

@Injectable()
export class PageTitleStrategy extends TitleStrategy {
  public currentProject = signal<IProject | null>(null).asReadonly();
  public currentArea = signal<IArea | null>(null);
  public currentFile = signal<IFile | null>(null);

  private destroy$ = new Subject<void>();

  /**
   * @constructor
   *
   * @param titleService
   * @param areaService
   * @param fileService
   * @param projectService
   */
  constructor(
    private readonly titleService: Title,
    private readonly areaService: AreaService,
    private readonly fileService: FileService,
    private readonly projectService: ProjectService
  ) {
    super();

    this.currentProject = this.projectService.watchCurrentProject();
  }

  /**
   *
   * @param routerState
   */
  public override updateTitle(routerState: RouterStateSnapshot): void {
    this.getPageTitle(routerState).pipe(takeUntil(this.destroy$)).subscribe(title => {
      this.titleService.setTitle(title);
    });
  }

  /**
   *
   * @param routerState
   *
   * @returns
   */
  private getPageTitle(routerState: RouterStateSnapshot): Observable<string> {
    const routeTitle = this.buildTitle(routerState);

    if (!routeTitle) {
      return of('');
    }

    const currentProject = this.currentProject();

    const pageTitle = currentProject && Array.isArray(currentProject.translations) && currentProject.translations.length > 0 ? currentProject.translations[0].title : undefined;

    const projectId = currentProject?.id;
    const urlParts = routerState.url.split('/');

    // Replace the project title with an optional title if an area or file is selected
    if (projectId && urlParts.length > 2) {
      const optionalId = urlParts[2];

      const handlers: Record<string, () => Observable<string>> = {
        'areaDetailOutlet': () => this.handleAreaDetailOutlet(projectId, optionalId, pageTitle),
        'fileDetailOutlet': () => this.handleFileDetailOutlet(projectId, optionalId, pageTitle),
        'projectDetailOutlet': () => of(pageTitle ? `${pageTitle} - ${routeTitle}` : routeTitle),
        'projects': () => of(routeTitle)
      };

      for (const key in handlers) {
        if (routerState.url.includes(key)) {
          return handlers[key]();
        }
      }
    }

    return of(pageTitle ? `${routeTitle} - ${pageTitle}` : routeTitle);
  }

  /**
   * Handle the area detail outlet, setting the current area and returning the page title.
   *
   * @param projectId
   * @param entityId
   * @param pageTitle
   *
   * @returns
   */
  private handleAreaDetailOutlet(projectId: string, entityId: string, pageTitle?: string): Observable<string> {
    return of(entityId === this.currentArea()?.id).pipe(
      switchMap(isCurrentArea => isCurrentArea ? of(this.currentArea()) : this.areaService.getArea(projectId, entityId)),
      map(area => {
        this.currentArea.set(area);
        return area ? `${area.translations[0]?.title || ''} - ${pageTitle}` : '';
      })
    );
  }

  /**
   * Handle the file detail outlet, setting the current file and returning the page title.
   *
   * @param projectId
   * @param entityId
   * @param pageTitle
   *
   * @returns
   */
  private handleFileDetailOutlet(projectId: string, entityId: string, pageTitle?: string): Observable<string> {
    return of(entityId === this.currentFile()?.id).pipe(
      switchMap(isCurrentFile => isCurrentFile ? of(this.currentFile()) : this.fileService.getFile(projectId, entityId)),
      map(file => {
        this.currentFile.set(file);
        return file ? `${file.translations[0]?.title || ''} - ${pageTitle}` : '';
      })
    );
  }
}
