import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Level } from '@newroom-connect/library/enums';
import { IToastMessage, ToastService } from '@newroom-connect/library/services';

import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent],
  selector: 'nrc-toast',
  templateUrl: './toast.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToastComponent {
  @Input() public toastMessage?: IToastMessage | null = {
    message: 'Test',
    level: Level.SUCCESS
  };

  public level = Level;

  /**
   * @constructor
   *
   * @param toastService
   */
  constructor(
    private readonly toastService: ToastService
  ) {}

  /**
   *
   */
  public resetToastMessage(): void {
    this.toastService.resetMessage();
  }
}
