import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type CheckboxCheckStatus = 'unchecked' | 'checked' | 'semi-checked';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'nrc-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxComponent {
  @Input() public checkStatus: CheckboxCheckStatus = 'unchecked';

  @Output() public clickEvent = new EventEmitter();
}
