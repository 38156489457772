import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export class FormValidatorHelper {
  /**
   * Check if the form control value matches a given string.
   *
   * @param matchString The string to check.
   *
   * @returns A validator function for matching the string.
   */
  public static matchesString(matchString: string): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return value !== matchString ? { matchesString: true } : null;
    };
  }

  /**
   * Check if the form control value is after or equal the given date.
   *
   * @param date
   *
   * @returns
   */
  public static dateAfterOrEqual(date: Date): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return value < date ? { dateAfterOrEqual: true } : null;
    };
  }

  /**
   * Check if the form control value is before or equal the given date.
   *
   * @param date
   *
   * @returns
   */
  public static dateBeforeOrEqual(date: Date): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      return value > date ? { dateBeforeOrEqual: true } : null;
    };
  }

  /**
   * Validate the password strength of a given form control value.
   *
   * @param minLength An custom minimum length of the password.
   *
   * @returns A validator function for checking the password strength.
   */
  public static passwordStrength(minLength = 6): ValidatorFn {
    return (control: AbstractControl) : ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasMinLength = value.length >= minLength;

      const hasUpperCase = /[A-Z]+/.test(value);

      const hasLowerCase = /[a-z]+/.test(value);

      const hasNumeric = /\d+/.test(value);

      const hasSpecialChars = /[-+_!@#$%^&*.,?]+/.test(value);

      const passwordValid = hasMinLength && hasUpperCase && hasLowerCase && hasNumeric && hasSpecialChars;

      return !passwordValid ? {
        passwordStrength: {
          hasMinLength,
          hasLowerCase,
          hasUpperCase,
          hasNumeric,
          hasSpecialChars
        }
      } : null;
    };
  }
}
