import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'max'
})
export class MaxPipe implements PipeTransform {
  /**
   *
   * @param value
   *
   * @returns
   */
  public transform(value: number[]): number | undefined {
    if (!Array.isArray(value) || value.length === 0) {
      return;
    }

    return Math.max(...value);
  }
}
