import { CommonModule } from '@angular/common';
import { Component, ChangeDetectionStrategy, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslatePipe } from '@newroom-connect/library/pipes';
import { ToastService } from '@newroom-connect/library/services';

import { ButtonComponent } from '../../buttons';
import { InputPasswordComponent, InputTextComponent } from '../../inputs';

@Component({
  selector: 'nrc-sign-in-code-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputPasswordComponent,
    InputTextComponent,
    TranslatePipe
  ],
  templateUrl: './sign-in-code-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInCodeFormComponent {
  @Input() public errorMessage?: string;

  @Output() public codeLoginEvent = new EventEmitter<string>();

  // Codes are alpha-numerical strings with a length of at least 6 characters.
  public codeRegExp = /^[a-zA-Z0-9]{6,}$/;

  public isSubmitted = false;
  public signInCodeLoginForm = new FormGroup({
    code: new FormControl('', { nonNullable: true, validators: [Validators.required] })
  });

  constructor(public readonly toastService: ToastService) {}

  /**
   * Emit continue event with the current sign in code login form value.
   */
  @HostListener('document:keyup.enter')
  public login(): void {
    this.isSubmitted = true;

    if (this.signInCodeLoginForm.valid) {
      this.codeLoginEvent.emit(this.signInCodeLoginForm.controls.code.value);
    }
  }
}
