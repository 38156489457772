// These events need to be activated individually in the Usercentrics backend.
// They get triggered whenever a Consent Status changed. The status of this event is telling if all consents were accepted or not.
export enum UsercentricsEvent {
  CONSENT_ALL_CHANGE = 'all_consents_given_status',
  CONSENT_STATUS = 'consent_status',
  CONSENT_STATUS_CHANGE = 'consent_status_change'
}

export enum UsercentricsCookies {
  GOOGLE_ANALYTICS = 'Google Analytics 4',
}
