<div class="relative w-full h-full">
  @if (!hasLoadedData() && !hasError()) {
    <div class="absolute inset-0 flex items-center justify-center w-full h-full bg-transparent">
      <nrc-loading-spinner />
    </div>
  }

  @if (hasError()) {
    <div class="absolute inset-0 flex flex-col items-center justify-center w-full h-full bg-gray-100 bg-opacity-10">
      <div class="mb-2 text-red-500">
        <nrc-icon class="block w-6 h-7" [name]="'info-outline'"  />
      </div>
      <p class="text-sm text-center">Unable to load video</p>
    </div>
  }

  <video
    #videoPlayer
    class="w-full h-full video-js"
    [ngClass]="{
      'hidden': !hasLoadedData() || hasError(),
      'object-cover': objectFit === 'cover',
      'object-contain': objectFit === 'contain',
      'rounded-md': isRounded
    }"
    >
    @for (source of sources; track source.uri) {
      <source [src]="source.uri" [type]="source.mimetype">
    }
    Your browser does not support the video tag.
  </video>
</div>
