import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAPIListResponse, IProject } from '@newroom-connect/library/interfaces';

import { EntityErrorHandlerService } from '../entity-error-handler.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectErrorHandlerService extends EntityErrorHandlerService {
  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public override handleListError(errorKey = 'ERROR.LIST_PROJECTS_FAILED'): Observable<IAPIListResponse<IProject>> {
    return super.handleListError(errorKey) as Observable<IAPIListResponse<IProject>>;
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public override handleGetError(errorKey = 'ERROR.GET_PROJECT_FAILED'): Observable<null> {
    return super.handleGetError(errorKey);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public override handleCreateError(errorKey = 'ERROR.CREATE_PROJECT_FAILED'): Observable<null> {
    return super.handleCreateError(errorKey);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public override handleUpdateError(errorKey = 'ERROR.UPDATE_PROJECT_FAILED'): Observable<null> {
    return super.handleUpdateError(errorKey);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public override handleDeleteError(errorKey = 'ERROR.DELETE_PROJECT_FAILED'): Observable<null> {
    return super.handleDeleteError(errorKey);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleIsProjectActiveError(errorKey = 'ERROR.PROJECT_NOT_STARTED'): Observable<null> {
    super.displayAlertErrorMessage(errorKey);

    return of(null);
  }
}
