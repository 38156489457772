<div class="w-full flex flex-col">
  <div
    class="flex items-center"
    [ngClass]="{
      'justify-between': label,
      'justify-end': !label
    }"
  >
    @if (icon || label) {
      <div class="flex justify-start items-center space-x-3">
        @if (icon) {
          <nrc-icon
            class="w-4 h-4"
            [name]="icon"
          />
        }

        @if (label) {
          <span class="text-white/70 font-light">
            {{ label }}
          </span>
        }
      </div>
    }

    <nrc-button
      class="w-6 h-6 relative flex transition-transform duration-250"
      [ngClass]="{
        'rotate-90': isCollapsed,
        '-rotate-90': !isCollapsed
      }"
      [icon]="'arrow-back'"
      [buttonType]="buttonType.ICON"
      [role]="actionRole.TRANSPARENT_SECONDARY"
      [disabled]="disabled"
      (clickEvent)="isCollapsed = !isCollapsed"
    />
  </div>

  <div
    class="transition-margin-top duration-250"
    [ngClass]="{
      'mt-0': isCollapsed,
      'mt-3': !isCollapsed
    }"
    [@collapseAnimation]="isCollapsed ? 'closed' : 'open'"
  >
    <ng-content></ng-content>
  </div>
</div>
