import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'nrc-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LanguageComponent {
  @Input({ required: true }) public code!: string;
  @Input() public iconClassList?: string[];
  @Input() public textClassList?: string[];

  @Output() public clickEvent = new EventEmitter<void>();
}
