import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChipComponent, IconComponent, LoadingComponent, TooltipComponent } from '@newroom-connect/library/components';
import { ExtractPropertyPipe } from '@newroom-connect/library/pipes';

import { NavigatorComponent } from './navigator.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    TooltipComponent,
    ChipComponent,
    LoadingComponent,
    ExtractPropertyPipe
  ],
  declarations: [
    NavigatorComponent
  ],
  exports: [
    NavigatorComponent
  ]
})
export class NavigatorComponentModule {}
