import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface IBroadcast<StateT = unknown> {
  name: string;
  state?: StateT;
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  private currentBroadcast$ = new Subject<IBroadcast>();

  /**
   *
   * @returns
   */
  public watchBroadcast<StateT = unknown>(): Observable<IBroadcast<StateT>> {
    return this.currentBroadcast$.asObservable() as Observable<IBroadcast<StateT>>;
  }

  /**
   *
   * @param broadcast
   */
  public emitBroadcast<StateT = unknown>(broadcast: IBroadcast<StateT>): void {
    this.currentBroadcast$.next(broadcast);
  }
}
