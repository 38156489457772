import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, signal } from '@angular/core';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'nrc-progress-circle',
  templateUrl: './progress-circle.component.html',
  styleUrls: ['./progress-circle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCircleComponent implements OnInit, OnChanges {
  public static readonly GRADIENT_COLORS = {
    primaryGradient: {
      startColor: '#00CFFF',
      startOpacity: 1,
      endColor: '#00B0D97A',
      endOpacity: 1,
      strokeColor: '#1B4E59'
    },
    warningGradient: {
      startColor: '#FFCF12',
      startOpacity: 1,
      endColor: '#836800',
      endOpacity: 1,
      strokeColor: '#564A19'
    },
    dangerGradient: {
      startColor: '#FF2020',
      startOpacity: 1,
      endColor: '#6b0000',
      endOpacity: 1,
      strokeColor: '#572020'
    }
  };

  @ViewChild('progressRingCircle', { static: true }) public progressRingRef!: ElementRef<SVGCircleElement>;

  @Input({ required: true }) public progress = 0;
  @Input() public radius = 16;
  @Input() public strokeWidth = 10;

  @Input() public label = '';
  @Input() public subline = '';
  @Input() public animate = false;

  public strokeDashoffset = signal<string>('0');
  public strokeDasharray = signal<string>('0 0');
  public gradientColor = ProgressCircleComponent.GRADIENT_COLORS.primaryGradient;
  public innerRadiusSig = signal<number>(14);

  private circumference = 0;

  private intervalId?: ReturnType<typeof setInterval>;

  /**
   *
   */
  public ngOnInit(): void {
    // Calculate the total circumference (taking into account the stroke width)
    this.circumference = this.calculateCircumference();

    this.strokeDasharray.set(`${this.circumference} ${this.circumference}`);
    this.strokeDashoffset.set(`${this.circumference}`);

    this.updateProgress(this.progress, 0);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['radius']?.currentValue) {
      this.innerRadiusSig.set(changes['radius'].currentValue - (this.strokeWidth * 2));

      this.circumference = this.calculateCircumference();

      this.strokeDasharray.set(`${this.circumference} ${this.circumference}`);
      this.strokeDashoffset.set(`${this.circumference}`);
    }

    if (changes && changes['progress'] && changes['progress'].currentValue !== undefined && changes['progress'].currentValue !== null) {
      this.updateProgress(changes['progress'].currentValue, changes['progress'].previousValue);
    }
  }

  /**
   *
   * @param percent
   */
  private setProgress(percent: number): void {
    const offset = this.circumference - (this.circumference * (percent / 100));

    this.strokeDashoffset.set(`${offset}`);
  }

  /**
   * Set the progress of the usage circle to the given percentage.
   * The progress will be animated.
   *
   * @param currentValue The percentage to set the progress to.
   * @param previousValue The previous percentage.
   */
  private setProgressWithAnimation(currentValue: number, previousValue: number): void {
    // Clear any existing interval
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }

    const targetProgress = Math.ceil(Math.max(0, Math.min(100, currentValue)));
    let currentProgress = previousValue;

    const stepSize = 3;
    const direction = currentProgress < targetProgress ? 1 : -1;
    const steps = Math.abs((targetProgress - currentProgress) / stepSize);
    const intervalDuration = 50;
    const stepValue = direction * stepSize;

    let stepCount = 0;

    this.intervalId = setInterval(() => {
      currentProgress += stepValue;

      // Check if the target progress is reached
      if ((direction === 1 && currentProgress >= targetProgress) || (direction === -1 && currentProgress <= targetProgress)) {
        currentProgress = targetProgress;
        clearInterval(this.intervalId); // Stop the interval
      }

      // Set the gradient color based on progress
      if (currentProgress < 75) {
        this.gradientColor = ProgressCircleComponent.GRADIENT_COLORS.primaryGradient;
      } else if (currentProgress >= 75 && currentProgress < 95) {
        this.gradientColor =  ProgressCircleComponent.GRADIENT_COLORS.warningGradient;
      } else {
        this.gradientColor = ProgressCircleComponent.GRADIENT_COLORS.dangerGradient;
      }

      this.setProgress(currentProgress);

      stepCount++;

      // Stop the interval if it exceeds the expected number of steps
      if (stepCount >= steps) {
        clearInterval(this.intervalId);
      }
    }, intervalDuration);
  }

  /**
   *
   * @param currentValue
   * @param previousValue
   */
  private updateProgress(currentValue: number, previousValue: number): void {
    this.animate ? this.setProgressWithAnimation(currentValue, previousValue) : this.setProgress(currentValue);
  }

  /**
   * Calculate the circumference of the circle based on the radius and the inner radius.
   *
   * @returns
   */
  private calculateCircumference(): number {
    // Calculate the circumference of the outer circle
    const outerCircumference = this.radius * 2 * Math.PI;

    // Calculate the circumference of the inner circle
    const innerCircumference = (this.radius - this.innerRadiusSig()) * 2 * Math.PI;

    return outerCircumference - innerCircumference;
  }
}
