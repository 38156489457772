import { HttpClient } from '@angular/common/http';
import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Observable, map } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'authorize'
})
export class AuthorizePipe implements PipeTransform {
  /**
   *
   * @param http
   * @param sanitizer
   */
  constructor(private http: HttpClient, private sanitizer: DomSanitizer) { }

  /**
   *
   * @param url
   *
   * @returns
   */
  public transform(url: string): Observable<SafeUrl> {
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob: Blob) => {
        const objectUrl = URL.createObjectURL(blob);

        return this.sanitizer.bypassSecurityTrustUrl(objectUrl);
      })
    );
  }
}
