@if (!hasLoadedData()) {
  <div class="w-full h-full flex justify-center items-center">
    <nrc-loading-spinner />
  </div>
}

<video
  #videoPlayer
  class="w-full h-full"
  [ngClass]="{
    'hidden': !hasLoadedData(),
    'object-cover': objectFit === 'cover',
    'object-contain': objectFit === 'contain'
  }"
  [controls]="controls"
  [autoplay]="autoplay"
  [muted]="muted"
  [loop]="loop"
  [playsInline]="playsInline"
  [disablePictureInPicture]="disablePictureInPicture"
  (loadeddata)="hasLoadedData.set(true)"
>
  @for (source of sources; track $index) {
    <source [src]="source.uri" [type]="source.mimetype">
  }
  Your browser does not support the video tag.
</video>