import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IListOptions } from '@newroom-connect/library/interfaces';

import { LoggingService } from '../logging/logging.service';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  public lastListOptions$ = new BehaviorSubject<IListOptions>({
    pagination: { page: 1, pageSize: 10 }
  });

  /**
   * @constructor
   *
   * @param loggingService
   */
  constructor(
    private readonly loggingService: LoggingService
  ) { }

  /**
   * Generate appropriate list options for a search request.
   *
   * @param searchTerm The search term to generate the list options for.
   *
   * @returns The generated list options for the search request.
   */
  public generateListOptionsForSearch(searchTerm: string): IListOptions {
    this.loggingService.debug('Handle search: ', searchTerm);

    const listOptions = {
      ...this.lastListOptions$.getValue(),
      search: searchTerm
    };

    // Delete the filters from the list options, since we don't want to include them from the previous search.
    if (!searchTerm) {
      delete listOptions.filters;
    }

    return listOptions;
  }
}
