
<div class="w-full p-2 bg-white/5 backdrop-blur-md rounded-lg shadow-lg border border-gray-200/10">
  <nrc-collapsible
    class="overflow-visible text-sm"
    [label]="'Document Translations'"
    [isCollapsed]="isCollapsed()"
    (collapsedEvent)="isCollapsed.set($event)"
  >
    @if (!isCollapsed()) {
      <div
        @fadeInOutAnimation
        class="space-y-1 overflow-y-scroll"
      >
        @for (item of activeDocumentTranslation() | orderByStatus; track item.id) {
          <div
            class="relative flex items-center justify-between p-1 rounded-md bg-gray-800/50 border border-gray-700/50 transition-all duration-300 ease-in-out hover:bg-gray-700/50"
            [class.translate-y-0]="item.status === fileTranslationStatus.COMPLETED"
            [class.opacity-100]="item.status === fileTranslationStatus.COMPLETED"
          >
            <!-- Status Icon -->
            <div class="flex items-center gap-1">
              @switch (item.status) {
                @case (fileTranslationStatus.PENDING) {
                  <div class="w-8 h-8 flex items-center justify-center rounded-full bg-yellow-500/20">
                    <nrc-icon [name]="'time'" [fillColor]="'warning'" class="w-4 h-4"/>
                  </div>
                }
                @case (fileTranslationStatus.ACTIVE) {
                  <div class="block rounded-full bg-blue-500/20">
                    <nrc-loading-spinner class="w-4 h-4 text-blue-500" />
                  </div>
                }
                @case (fileTranslationStatus.COMPLETED) {
                  <div class="w-8 h-8 flex items-center justify-center rounded-full bg-green-500/20">
                    <nrc-icon [name]="'multi-check'" [fillColor]="'success'" class="w-4 h-4"/>
                  </div>
                }
              }

              <!-- Job Details -->
              <div class="flex flex-col">
                <span class="text-xs fonrt-normal text-gray-200 max-w-[9rem] text-ellipsis text-nowrap px-1 pt-1 overflow-hidden">
                  {{ item.file?.title ?? item.name }}
                </span>
                <div class="flex items-center text-xxs text-gray-400 pb-1">
                  <nrc-language
                    class="flex h-4"
                    [code]="item.sourceLanguage | toString"
                    [iconClassList]="['h-3 w-3']"
                    [textClassList]="['text-xxs']"
                  />
                  <nrc-icon [name]="'arrow-sharp-down'" class="w-3 h-2 rotate-90 transform" [fillColor]="'semi-transparent'" />
                  <nrc-language
                    class="flex h-4"
                    [code]="item.targetLanguage | toString"
                    [iconClassList]="['h-3 w-3']"
                    [textClassList]="['text-xxs']"
                  />
                </div>
              </div>
            </div>

            <!-- Status Text & Action -->
            <div class="flex items-center select-none hover:scale-105 transition-transform duration-250">
              @switch (item.status) {
                @case (fileTranslationStatus.PENDING) {
                  <span class="px-2 py-1 text-xxs font-medium text-yellow-500 bg-yellow-500/10 rounded-full cursor-progress">
                    Submitted
                  </span>
                }
                @case (fileTranslationStatus.ACTIVE) {
                  <span class="px-2 py-1 text-nowrap text-xxs font-medium text-blue-500 bg-blue-500/10 rounded-full cursor-progress">
                    In Progress
                  </span>
                }
                @case (fileTranslationStatus.COMPLETED) {
                  <button
                    class="px-3 py-1 text-xxs text-nowrap font-medium text-green-500 bg-green-500/10 rounded-full hover:bg-green-500/20 transition-colors duration-200"
                    (click)="handleNavigationEvent(item.fileId, item.newFileId)"
                  >
                    View File
                  </button>
                }
              }
            </div>
          </div>
        }

        @empty {
          <div class="text-center py-6 text-gray-400">
            No active translations
          </div>
        }
      </div>
    }

  </nrc-collapsible>

</div>
