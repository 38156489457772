import { Directive, ElementRef, HostListener, Input, OnInit, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[nrcResizeFitContent]'
})
export class ResizeFitContentDirective implements OnInit {
  private static readonly DEFAULT_WIDTH_PX = 24;

  private readonly el = inject(ElementRef);

  // Input to conditionally activate the directive.
  @Input() public nrcResizeFitContent = false;

  /**
   *
   */
  public ngOnInit(): void {
    // Set initial width, if directive is active.
    if (this.nrcResizeFitContent) {
      this.el.nativeElement.style.width = `${ResizeFitContentDirective.DEFAULT_WIDTH_PX}px`;
    }
  }

  /**
   *
   */
  @HostListener('change')
  public onChange(): void {
    if (this.nrcResizeFitContent) {
      this.resize();
    }
  }

  /**
   *
   */
  private resize(): void {
    const tempOption = document.createElement('option');

    tempOption.textContent = this.el.nativeElement.selectedOptions[0].textContent;

    const tempSelect = document.createElement('select');

    tempSelect.style.visibility = 'hidden';
    tempSelect.style.position = 'fixed';
    tempSelect.appendChild(tempOption);

    this.el.nativeElement.after(tempSelect);
    this.el.nativeElement.style.width = `${+tempSelect.clientWidth - ResizeFitContentDirective.DEFAULT_WIDTH_PX}px`;
    tempSelect.remove();
  }
}
