import { Component, effect, EventEmitter, forwardRef, Input, OnChanges, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { TranslationElement } from '@newroom-connect/library/types';
import { TranslatePipe } from '@newroom-connect/library/pipes';
import { IFile, IFileHydrated } from '@newroom-connect/library/interfaces';

import { InputComponent } from '../input.component';
import { InputFileComponent, InputFileComponentSize } from '../input-file/input-file.component';
import { InputRangeComponent } from '../input-range/input-range.component';
import { InputLanguageSelectorComponent, InputLanguageSelectorTextSize } from '../input-language-selector/input-language-selector.component';

interface ITranslatableFileInputForm {
  file: FormControl<IFileHydrated | null>;
  fileId: FormControl<string>;
  opacity: FormControl<number>;
}

@Component({
  selector: 'nrc-input-translatable-file',
  standalone: true,
  imports: [
    InputFileComponent,
    InputRangeComponent,
    InputLanguageSelectorComponent,
    ReactiveFormsModule,
    TranslatePipe,
    CommonModule
  ],
  templateUrl: './input-translatable-file.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTranslatableFileComponent),
      multi: true
    }
  ]
})
export class InputTranslatableFileComponent extends InputComponent<TranslationElement<{ file?: IFileHydrated | IFile, fileId?: string, fileOpacity?: number }>> implements OnChanges {
  @Input({ required: true }) public languageCodes!: string[];
  @Input({ required: true }) public projectId!: string;
  @Input({ required: true }) public apiBaseUrl!: string;
  @Input({ required: true }) public selectedLanguageCode!: WritableSignal<string>;
  @Input() public size: InputFileComponentSize = InputFileComponentSize.REGULAR;
  @Input() public controlOpacity = false;

  @Output() public languageCodeChangedEvent = new EventEmitter<string>();

  public languageCodeFormControl = new FormControl<string>('', { nonNullable: true });
  public translatableFormGroup: Record<string, FormGroup<ITranslatableFileInputForm>> = {};

  public inputFileComponentSize = InputFileComponentSize;
  public inputFileSelectorTextSize = InputLanguageSelectorTextSize;
  public currentFile = signal<IFileHydrated | null>(null);

  constructor() {
    super();

    // If the selected language changed externally, update the form control to reflect the new language.
    effect(() => {
      const currentLanguageCode = this.selectedLanguageCode();

      if (currentLanguageCode !== this.languageCodeFormControl.value) {
        this.languageCodeFormControl.patchValue(currentLanguageCode);
      }
    });
  }

  /**
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes['formControl'] && changes['formControl'].currentValue.value) {
      this.initializeFormControls();
    }
  }

  /**
   * Initializes the translatable form controls for each language code.
   *
   * Iterates over the language codes and creates a new form control for each one,
   * using the corresponding value from the `formControl` as the initial value, or an empty object if none is provided.
   */
  public initializeFormControls(): void {
    if (!ArrayHelper.isNotEmpty(this.languageCodes)) {
      return;
    }

    const [defaultLanguageCode] = this.languageCodes;

    const currentLanguageCode = this.selectedLanguageCode() ?? defaultLanguageCode;

    this.languageCodeFormControl.patchValue(currentLanguageCode);

    for (const languageCode of this.languageCodes) {
      this.translatableFormGroup[languageCode] = new FormGroup<ITranslatableFileInputForm>({
        file: new FormControl<IFileHydrated>(this.formControl.value[languageCode].file ?? null),
        fileId: new FormControl<string>(this.formControl.value[languageCode].fileId ?? '', { nonNullable: true }),
        opacity: new FormControl<number>(this.formControl.value[languageCode].fileOpacity ?? 100, { nonNullable: true, validators: [Validators.min(0), Validators.max(100)] })
      });
    }
  }

  /**
   *
   * @param languageCode
   */
  public handleLanguageChangeEvent(languageCode: string): void {
    this.currentFile.set(this.formControl.value[languageCode].file);

    this.languageCodeChangedEvent.emit(languageCode);
  }

  /**
   *
   * @param file
   */
  public handleFileChangeEvent(file: IFileHydrated): void {
    this.currentFile.set(file);

    const currentLanguageCode = this.languageCodeFormControl.value;

    const formControlValue = this.formControl.value;

    const fileOpacity = this.controlOpacity && formControlValue[currentLanguageCode] ? Number(formControlValue[currentLanguageCode].fileOpacity) : undefined;

    formControlValue[currentLanguageCode] = {
      file: file,
      fileId: file.id,
      fileOpacity
    };

    this.formControl.patchValue(formControlValue);
  }

  /**
   *
   * @param opacity
   */
  public handleOpacityChangeEvent(opacity: number): void {
    const currentLanguageCode = this.languageCodeFormControl.value;

    const formControlValue = this.formControl.value[currentLanguageCode];

    if (!formControlValue) {
      return;
    }

    formControlValue.fileOpacity = this.controlOpacity ? Number(opacity) : undefined;

    this.formControl.patchValue(this.formControl.value);
  }
}
