<div class="flex flex-col items-center mx-auto overflow-y-auto w-full h-full p-0.5">
  <div class="w-full space-y-4">
    <div class="flex flex-row items-center md:items-end justify-between">
      <p class="text-3xl leading-tight tracking-normal">
        {{ 'PAGES.SIGNIN.PAGE_HEADER' | translate }}
      </p>
    </div>

    @if (signUpRouterLink) {
      <p class="text-sm text-neutral-100 leading-tight tracking-[.01125em]">
        {{ 'PAGES.SIGNIN.NEW_USER' | translate }} <a [routerLink]="signUpRouterLink">{{ 'PAGES.SIGNIN.CREATE_ACCOUNT' | translate }}</a>
      </p>
    }

    @if (errorMessage) {
      <div class="w-full bg-[#9730307f] h-10 border-l-2 border-l-error">
        <span class="text-xs min-h-full inline-flex items-center ml-3">{{ errorMessage }}</span>
      </div>
    }

    <form [formGroup]="signInLoginForm" class="w-full grid gap-6 grid-cols-1 mt-12 md:mt-6 mb-12 md:mb-6" action="#">
      <nrc-input-text
        [formControl]="signInLoginForm.controls.email"
        [type]="'email'"
        [name]="'signin-email'"
        [id]="'signin-email'"
        [label]="'Email'"
        [classList]="['bg-[#454545]', 'md:bg-neutral-900']"
        [invalidHint]="'ERROR.EMAIL_NOT_MATCHING.MESSAGE' | translate"
      />

      <nrc-input-password
        [formControl]="signInLoginForm.controls.password"
        [name]="'signin-password'"
        [id]="'signin-password'"
        [label]="'Password'"
        [classList]="['bg-[#454545]', 'md:bg-neutral-900']"
      />

      <div class="flex flex-row justify-between items-center">
        <nrc-input-checkbox
          [formControl]="signInLoginForm.controls.rememberMe"
          [name]="'signin-remember-me'"
          [id]="'signin-remember-me'"
          [label]="'Remember me'"
          class="ml-1"
        />

        @if (forgotPassswordRouterLink) {
          <a class="text-xs/6 mr-1" [routerLink]="forgotPassswordRouterLink">{{ 'PAGES.SIGNIN.FORGOT_PASSWORD' | translate }}</a>
        }
      </div>
    </form>

    <div class="flex justify-end mt-8">
      <nrc-button
        [label]="'Log in'"
        (clickEvent)="login()"
      />
    </div>
  </div>
</div>
