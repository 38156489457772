<div class="relative flex flex-col w-full">
  <div class="flex flex-row w-full align-middle items-center">
    <div class="flex flex-col w-full items-start">
      <input
        #inputElement
        [type]="type"
        [name]="name"
        [id]="id"
        [tabindex]="tabIndex"
        [autocomplete]="name"
        [readOnly]="readonly"
        class="peer placeholder-transparent block w-full h-10 bg-neutral-900 autofill:bg-neutral-900 pl-4 pt-4 pr-10
          rounded-t text-sm/[16px] border-0 border-b-1 caret-cyan
          focus:border-b-cyan-highlight focus:outline-none focus:ring-0 disabled:opacity-50"
        [ngClass]="{
          'border-b-error focus:border-b-error': formControl.touched && formControl.invalid,
          'border-b-gray-700': formControl.valid,
          classList
        }"
        [placeholder]="placeholder"
        [formControl]="formControl | formControl"
        (input)="handleInputChange($event)"
      />

      <label
        [for]="id"
        class="absolute ml-4 mt-0.5 text-xxs text-[#adadad] peer-focus:text-cyan
          peer-placeholder-shown:text-gray-400
          peer-placeholder-shown:mt-3
          peer-placeholder-shown:text-sm/[16px]
          duration-300"
        [ngClass]="{
          'text-error peer-focus:text-error': formControl.touched && formControl.invalid
        }"
      >{{ label }}</label>
    </div>

    <div class="absolute h-3.5 ps-2 right-4 flex justify-end items-center">
      @if (!formControl.disabled && clearEnabled) {
        <button class="h-full" type="button" tabindex="-1" (click)="resetInput()">
          <nrc-icon [name]="'clear'" />
        </button>
      }

      @if (clipboardEnabled) {
        <nrc-icon
          class="w-4 h-4 cursor-pointer hover:scale-90 transition-transform duration-250"
          [name]="'clipboard'"
          (click)="copyToClipboard(formControl.value)"
        />
      }
    </div>
  </div>

  @if (hint) {
    <p class="text-xs text-white/50 py-1">
      {{ hint }}
    </p>
  }

  @if (invalidHint && formControl.invalid && formControl.touched) {
    <p class="text-xs text-error leading-tight tracking-[.01125em] pt-1">
      {{ invalidHint }}
    </p>
  }
</div>
