<div class="h-6 inline-flex justify-between items-center space-x-2 rounded-11 px-2"
  [ngClass]="{
    'bg-white/10': !config || !config.actionRole || config.actionRole === actionRole.PRIMARY,
    'bg-error/30 text-error': config?.actionRole === actionRole.ERROR,
    'bg-warning/30 text-warning': config?.actionRole === actionRole.WARNING,
    'bg-success/30 text-success': config?.actionRole === actionRole.SUCCESS
  }"
>
  <span class="inline-block text-xs font-semibold tracking-wide whitespace-nowrap px-1">
    {{ label | toString | uppercase }}
  </span>

  <!-- Close button, if chip is closable -->
  @if (config?.isClosable) {
    <button
      class="h-2/3 aspect-square w-auto inline-flex justify-center items-center bg-cyan/70 rounded-full shrink-0 hover:bg-cyan"
      (click)="closeEvent.emit()"
    >
      <span class="text-transparent-dark text-sm font-semibold mb-0.5">x</span>
    </button>
  }
</div>
