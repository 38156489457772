<div class="modal-overlay">
  <div class="modal-backdrop" (click)="closeModal()" (keyup)="null" [tabindex]="null"></div>
  <div
    class="justify-between bg-transparent-dark modal"
    [ngClass]="{
      'modal-small': size === modalSize.SMALL,
      'modal-medium': size === modalSize.MEDIUM,
      'modal-large': size === modalSize.LARGE
    }">
    <div
      class="modal-header px-8 mt-4"
      [ngClass]="{
        'mb-4': paddedContent,
      }"
    >
      <p class="modal-title">{{ title }}</p>
      <button class="modal-close-button" (click)="closeModal()">
        <nrc-icon [classList]="['rotate-45']" [name]="'plus'" />
      </button>
    </div>
    <div
      class="flex flex-col modal-content"
      [ngClass]="{
        'p-8 mt-8': paddedContent
      }">

      @if (isLoadingContent()) {
        <nrc-loading-spinner />
      }

      <ng-container #contentContainer></ng-container>
    </div>
  </div>
</div>
