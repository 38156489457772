import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ActionButtonMenuItemComponent, IconComponent, ImageComponent, TooltipComponent } from '@newroom-connect/library/components';
import { HoverDelayDirective } from '@newroom-connect/library/directives';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { NavigatorComponentModule } from '../navigator/navigator.component.module';

import { HeaderComponent } from './header.component';
import { ProfileTooltipComponent } from './profile-tooltip/profile-tooltip.component';
import { HeaderItemComponent } from './header-item/header-item.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NavigatorComponentModule,
    IconComponent,
    ImageComponent,
    TooltipComponent,
    ActionButtonMenuItemComponent,
    HoverDelayDirective,
    TranslatePipe
  ],
  declarations: [
    HeaderComponent,
    ProfileTooltipComponent,
    HeaderItemComponent
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderComponentModule {}
