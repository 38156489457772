import { ActivatedRouteSnapshot } from '@angular/router';

export class UrlBuilderHelper {
  /**
   * Builds the final URL path by extracting dynamic parameters from the route snapshot.
   * @param routePath The Angular route path (e.g. ':projectSlug/areas/:areaId').
   * @param snapshot The current ActivatedRouteSnapshot containing route params.
   *
   * @returns The final URL path with the route parameters replaced (e.g. 'myProject/areas/123').
   */
  public static buildUrlPath(routePath: string, snapshot: ActivatedRouteSnapshot): string {
    return routePath.replace(/:([a-zA-Z0-9]+)/g, (match, paramName) => {
      const paramValue = snapshot.paramMap.get(paramName);

      if (!paramValue) {
        throw new Error(`Missing route parameter: ${paramName}`);
      }

      return paramValue;
    });
  }
}
