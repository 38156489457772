<div class="w-full flex justify-between items-center space-x-2">
  <input
    #rangeElement
    class="grow h-0.5 bg-white/5 appearance-none outline-none cursor-pointer
      [&::-webkit-slider-thumb]:h-2
      [&::-webkit-slider-thumb]:w-2
      [&::-webkit-slider-thumb]:rounded-full
      [&::-webkit-slider-thumb]:appearance-none
      [&::-webkit-slider-thumb]:bg-[#b3d2d8]"
    type="range"
    [id]="id"
    [name]="name"
    [min]="min"
    [max]="max"
    [formControl]="formControl | formControl"
    (change)="onSliderChange($event)"
    (input)="onSliderInput($event)"
  >

  @if (isLabelEnabled) {
    <div class="flex justify-between items-center">
      <input class="w-12 h-4 p-0 bg-transparent border-none text-white text-sm tracking-wide text-end" type="text" [formControl]="formControl | formControl" (input)="onInputChange($event)">

      @if (label) {
        <span class="text-sm tracking-wide">{{ label }}</span>
      }
    </div>
  }
</div>
