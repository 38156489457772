import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ICountry, IAPIListResponse } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';

@Injectable({
  providedIn: 'root'
})
export class CountryService extends EntityService {
  public searchableProperties = [];

  /**
   *
   * @returns
   */
  public listCountries(): Observable<IAPIListResponse<ICountry>> {
    return this.apiService.list<IAPIListResponse<ICountry>>('countries');
  }
}
