export class JwtError extends Error {
  /**
   * @constructor
   *
   * @param error
   */
  constructor(error: Error) {
    super(error.message);

    this.name = 'JwtError';
  }
}
