import { effect, Injectable, Signal, signal } from '@angular/core';
import { take } from 'rxjs';
import { ITranslationAttempt } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';
import { ListService } from '../list/list.service';
import { ProjectService } from '../project/project.service';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class TranslateDocumentJobService extends EntityService {
  public static readonly SUPPORTED_FILE_TRANSLATION_MIME_TYPES = [
    'text/plain',
    'text/html',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/x-xliff+xml'
  ];

  protected searchableProperties: string[] = ['fileId'];

  public activeDocumentTranslation = signal<ITranslationAttempt[]>([]);
  public currentFileIdSig = signal<string | null>(null);

  private projectId?: string;

  /**
   * @constructor
   *
   * @param projectService
   * @param listService
   */
  constructor(
    private projectService: ProjectService,
    private listService: ListService
  ) {
    super();

    effect(() => {
      const project = this.projectService.watchCurrentProject()();
      const fileId = this.currentFileIdSig();

      if (!project) {
        return;
      }

      this.projectId = project.id;
      this.fetchProjectTranslateDocumentJob(fileId);
    }, { allowSignalWrites: true });
  }

  /**
   *
   * @param job
   */
  public addJob(job: ITranslationAttempt): void {
    const jobs = this.activeDocumentTranslation();

    const newJob = {
      type: 'TRANSLATE_DOCUMENT',
      ...job
    };

    this.activeDocumentTranslation.set([...jobs, newJob]);
  }

  /**
   * Watch the current active document translations.
   *
   * @returns The current active document translations as read-only signal.
   */
  public watchActiveDocumentTranslation(): Signal<ITranslationAttempt[]> {
    return this.activeDocumentTranslation.asReadonly();
  }

  /**
   * Updates the status of a specific translation attempt.
   *
   * @param jobId
   * @param updates
   */
  public updateJob(jobId: string, updates: Partial<ITranslationAttempt>): void {
    const jobs = this.activeDocumentTranslation();

    const index = jobs.findIndex(job => job.id === jobId);

    if (index < 0) {
      jobs.push(updates as ITranslationAttempt);
      this.activeDocumentTranslation.set([...jobs]);

      return;
    }

    jobs[index] = { ...jobs[index], ...updates };

    this.activeDocumentTranslation.set([...jobs]);
  }

  /**
   *
   * @param jobId
   * @returns
   */
  public getJob(jobId: string): ITranslationAttempt | undefined {
    return this.activeDocumentTranslation().find(job => job.id === jobId);
  }

  /**
   * Retrieves stored translation attempts.
   *
   * @param fileId
   */
  public fetchProjectFileTranslateDocumentJob(fileId: string): void {
    if (!this.projectId) {
      return;
    }

    this.apiService.list<ITranslationAttempt[]>(`translate/${this.projectId}/jobs/${fileId}`).pipe(take(1)).subscribe(result => {
      this.activeDocumentTranslation.set(result);
    });
  }

  /**
   * Retrieves stored translation attempts.
   *
   * @param fileId
   *
   */
  private fetchProjectTranslateDocumentJob(fileId: string | null): void {
    this.activeDocumentTranslation.set([]);

    if (!this.projectId) {
      return;
    }

    const options = this.listService.generateListOptionsForSearch(fileId ?? '');

    this.list<ITranslationAttempt>(`translate/${this.projectId}/jobs`, undefined, options).pipe(take(1)).subscribe(result => {
      let jobs: ITranslationAttempt[] = [];

      if (result.data) {
        jobs = result.data.map((job) => {
          if (job.file) {
            job.file = FileService.hydrateFile(job.file);
          }

          return job;
        });
      }

      this.activeDocumentTranslation.set(jobs);
    });
  }
}
