import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnInit, ViewChild, forwardRef, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ToStringPipe } from '@newroom-connect/library/pipes';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';

import { IconComponent } from '../../icon/icon.component';
import { InputComponent } from '../input.component';
import { ButtonComponent } from '../../buttons/button/button.component';

export interface ICalendarEntry {
  date: Date;
  month: number;
}

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, ButtonComponent, ToStringPipe],
  selector: 'nrc-input-datepicker',
  templateUrl: './input-datepicker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDatepickerComponent),
      multi: true
    }
  ]
})
export class InputDatepickerComponent extends InputComponent<string> implements OnInit {
  // Input to keep the time value of the form control. Otherwise, '0:00' is used.
  @Input() public keepTime = true;
  @ViewChild('datepickerContainer') public datepickerContainerRef!: ElementRef<HTMLDivElement>;

  public isDatepickerOpen = false;
  public currentDate = signal(new Date());
  public currentMonth = signal(new Date());
  public calendar: ICalendarEntry[][] = [];
  public daysOfWeek: string[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    const validators = this.formControl.validator ? [this.formControl.validator] : [];

    this.formControl.setValidators(validators);
  }

  /**
   *
   * @param value
   */
  public override writeValue(value: string): void {
    super.writeValue(value);

    this.currentDate.set(new Date(value));

    this.generateCalendar();
  }

  /**
   * Listen to click events on the document and close the datepicker if click is not in the component.
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public clickOutside(event: any): void {
    if (event.target !== this.datepickerContainerRef.nativeElement && !this.datepickerContainerRef.nativeElement.contains(event.target)) {
      this.closeDatepicker();
    }
  }

  /**
   *
   */
  public toggleDatepicker(): void {
    this.isDatepickerOpen = !this.isDatepickerOpen;
  }

  /**
   *
   */
  public closeDatepicker(): void {
    this.isDatepickerOpen = false;
  }

  /**
   *
   */
  public previousMonth(): void {
    this.currentMonth.update(currentMonth => {
      currentMonth.setMonth(this.currentMonth().getMonth() - 1);
      return new Date(currentMonth);
    });

    this.generateCalendar();
  }

  /**
   *
   */
  public nextMonth(): void {
    this.currentMonth.update(currentMonth => {
      currentMonth.setMonth(this.currentMonth().getMonth() + 1);
      return new Date(currentMonth);
    });

    this.generateCalendar();
  }

  /**
   *
   */
  public generateCalendar(): void {
    const year = this.currentMonth().getFullYear();
    const month = this.currentMonth().getMonth();

    const firstDayOfMonth = new Date(year, month, 1);
    const lastDayOfMonth = new Date(year, month + 1, 0);

    let startDate = new Date(firstDayOfMonth);

    startDate.setDate(startDate.getDate() - startDate.getDay());

    if (this.formControl?.value && this.keepTime) {
      startDate = this.setDateHoursFromCurrentFormValueDate(startDate);
    }

    this.calendar = [];

    while (startDate <= lastDayOfMonth) {
      const week = [];

      for (let i = 0; i < 7; i++) {
        week.push({
          date: new Date(startDate),
          month: startDate.getMonth()
        });

        startDate.setDate(startDate.getDate() + 1);
      }

      this.calendar.push(week);
    }
  }

  /**
   *
   * @param dateObj
   */
  public selectDate(dateObj: any): void {
    if (dateObj.month === this.currentMonth().getMonth()) {
      this.formControl.patchValue(dateObj.date);

      this.closeDatepicker();
    }
  }

  /**
   * Set the hours of the given date to the current form value date hours.
   *
   * @param date
   *
   * @returns
   */
  private setDateHoursFromCurrentFormValueDate(date: Date): Date {
    const currentFormValueDate = this.formControl.value as Date;

    date.setHours(currentFormValueDate.getHours());
    date.setMinutes(currentFormValueDate.getMinutes());
    date.setSeconds(currentFormValueDate.getSeconds());
    date.setMilliseconds(currentFormValueDate.getMilliseconds());

    return date;
  }
}
