<div
  [id]="id"
  class="relative inline-flex items-center cursor-pointer gap-2"
  [ngClass]="classList"
>
  <div
    [tabindex]="tabIndex"
    class="w-11 h-6 rounded-full after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:rounded-full after:h-5 after:w-5 after:transition-all"
    [ngClass]="{
      'bg-white/10': !isChecked(),
      'bg-cyan after:translate-x-full': isChecked()
    }"
    (click)="toggleValue()"
    (keydown)="null"
  >
  </div>

  <span class="text-sm font-medium text-white">{{ label }}</span>
</div>
