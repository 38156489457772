import { Injectable } from '@angular/core';
import { StringHelper } from '@newroom-connect/library/helpers';
import { HTMLScriptElementIE, ILoadScriptResult, IScript, ScriptStatus } from '@newroom-connect/library/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  private isScriptLoaded: Record<string, boolean> = {};

  /**
   * Load script
   * http://www.lukasjakob.com/how-to-dynamically-load-external-scripts-in-angular/.
   *
   * @param script
   * @returns
   */
  public async loadScript(script: IScript): Promise<ILoadScriptResult> {
    this.isScriptLoaded[script.src] = false;

    return new Promise((resolve, reject) => {
      // Resolve if already loaded.
      if (this.isScriptLoaded && this.isScriptLoaded[script.src]) {
        resolve({ loaded: this.isScriptLoaded[script.src], status: ScriptStatus.LOADED });
        return;
      }

      const scriptElement = document.createElement('script') as HTMLScriptElementIE;

      scriptElement.src = script.src;

      if (script.attributes) {
        for (const key of Object.keys(script.attributes)) {
          scriptElement.setAttribute(StringHelper.camelToKebab(key), script.attributes[key]);
        }
      }

      if (scriptElement.readyState) {
        // Needed handling for IE browsers.
        scriptElement.onreadystatechange = (): void => {
          if (scriptElement.readyState === 'loaded' || scriptElement.readyState === 'complete') {
            scriptElement.onreadystatechange = undefined;
            this.isScriptLoaded[script.src] = true;

            resolve({ loaded: this.isScriptLoaded[script.src], status: ScriptStatus.LOADED });
          }
        };
      } else {
        // Handling for Non-IE browsers.
        scriptElement.onload = (): void => {
          this.isScriptLoaded[script.src] = true;

          resolve({ loaded: this.isScriptLoaded[script.src], status: ScriptStatus.LOADED });
        };
      }

      // Handle loading script error.
      scriptElement.onerror = (): void => reject({ loaded: this.isScriptLoaded[script.src], status: ScriptStatus.ERROR });

      // Finally append the script tag in the DOM.
      document.getElementsByTagName('head')[0].appendChild(scriptElement);
    });
  }
}
