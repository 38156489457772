@if (tabs) {
  @for (tab of tabs; track $index) {
    <div
      class="w-36 h-20 pt-4 flex flex-col justify-between items-center disabled:opacity-50 transition-opacity"
      [tabindex]="$index"
      [ngClass]="{
        'text-neutral-100': tab.id === activeTabId,
        'text-neutral-100/50': tab.id !== activeTabId,
        'cursor-pointer': !tab.disabled
      }"
      [attr.disabled]="tab.disabled"
      (click)="setActiveTab(tab.id, tab.disabled)"
      (keydown)="handleKeyDown($event)"
    >
      <span
        class="hover:scale-105 pt-3 transition-transform duration-200 w-full h-full text-center text-md font-light"
        [class.pointer-events-none]="tab.disabled"
      >
        {{ tab.title }}
      </span>

      @if (tab.id === activeTabId) {
        <hr
          class="relative bottom-0 border-[#00B0D9] border-1.5 transition-all duration-200 self-stretch"
          [@lineMotionAnimation]="{
            value: '',
            params: {
              widthStart: '0%',
              widthEnd: '50%',
              direction: '50%'
            }
          }"
        />
      }
  </div>
  }
}

<ng-content></ng-content>
