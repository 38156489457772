import { Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, inject } from '@angular/core';

export type DragAndDropEvent = {
  top?: number;
  left?: number;
};

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[nrcDragAndDrop]',
  standalone: true
})
export class DragDropDirective {
  @Input({ required: true }) public targetContainerRef!: ElementRef<HTMLElement>;
  @Input() public isDragAndDropDisabled = false;

  @Output() public dragAndDropEvent = new EventEmitter<DragAndDropEvent>();

  private startX = 0;
  private startY = 0;
  private initialTop = 0;
  private initialLeft = 0;
  private isDraggable = false;

  private readonly elementRef = inject(ElementRef);
  private readonly renderer = inject(Renderer2);

  /**
   * @constructor
   */
  constructor() {
    this.onMousemove = this.onMousemove.bind(this);
    this.onMouseup = this.onMouseup.bind(this);
  }

  /**
   *
   * @param event
   */
  @HostListener('mousedown', ['$event'])
  public onMousedown(event: MouseEvent): void {
    this.startX = event.clientX;
    this.startY = event.clientY;

    this.initialTop = 100 * (this.elementRef.nativeElement.offsetTop / this.targetContainerRef.nativeElement.offsetHeight);
    this.initialLeft = 100 * (this.elementRef.nativeElement.offsetLeft / this.targetContainerRef.nativeElement.offsetWidth);

    this.isDraggable = true;

    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'absolute');
    this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'none');

    document.addEventListener('mousemove', this.onMousemove);
    document.addEventListener('mouseup', this.onMouseup);
  }

  /**
   *
   * @param event
   */
  public onMousemove(event: MouseEvent): void {
    if (!this.isDraggable || this.isDragAndDropDisabled) {
      return;
    }

    const deltaX = event.clientX - this.startX;
    const deltaY = event.clientY - this.startY;

    const newTop = this.initialTop + 100 * (deltaY / this.targetContainerRef.nativeElement.offsetHeight);
    const newLeft = this.initialLeft + 100 * (deltaX / this.targetContainerRef.nativeElement.offsetWidth);

    this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${newTop}%`);
    this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${newLeft}%`);

    this.dragAndDropEvent.emit({ top: newTop, left: newLeft });
  }

  /**
   *
   */
  public onMouseup(): void {
    if (this.isDraggable) {
      this.renderer.setStyle(this.elementRef.nativeElement, 'user-select', 'auto');

      document.removeEventListener('mousemove', this.onMousemove);
      document.removeEventListener('mouseup', this.onMouseup);
    }

    this.isDraggable = false;
  }
}
