import { ActionRole } from '@newroom-connect/library/enums';

import { IActionButton } from './action-button.interface';
import { IObjectProperty } from './object.interface';

export enum ListItemType {
  TEXT = 'TEXT',
  CHIP = 'CHIP',
  DATE = 'DATE',
  ICON = 'ICON',
  BUTTON = 'BUTTON',
  THUMBNAIL = 'THUMBNAIL',
  FILE_SIZE = 'FILE_SIZE',
  CARD = 'CARD'
}

export type ListOptionsSortDirection = 'asc' | 'desc';

export interface IListItemAction<T = any> {
  type: ListItemType;
  action?: T;
}

export interface IListItemClickedEvent<T = any> {
  index: number;
  itemIndex?: number; // The index of an inner item of the list item (e.g. button in a row).
  item?: T
}

export interface IListOptionsSort {
  [key: string]: ListOptionsSortDirection | IListOptionsSort;
}

export interface IListOptionsPagination {
  pageSize?: number;
  page?: number;
}

export interface IListOptions {
  filters?: Record<string, any>;
  pagination?: IListOptionsPagination;
  sort?: IListOptionsSort;
  search?: string;
}

export interface IListConfiguration {
  columns: IListConfigurationColumn[];
  actionButtons?: IActionButton[];
  itemActionButtons?: IActionButton[];
  caption?: string;
  selectMultiple?: boolean;
}

export interface IListConfigurationColumn {
  type: ListItemType;
  key: string;
  label?: string;
  orderBy?: 'asc' | 'desc';
  additionalInfoKeys?: IObjectProperty[];
  thumbnailConfig?: {
    backgroundColorClasses?: string[];
    thumbnailSourceKey?: string;
    fallbackThumbnailSourceKey?: string;
    mimetypeKey?: string;
    fallbackMimetypeKey?: string;
  };
  thumbnailSource?: string;
  mimeType?: string;
  isHighlighted?: boolean;
  showTooltip?: boolean;
  align?: 'left' | 'center' | 'right'; // The position to align the content in.
  [key: string]: any; // Each type has some more differing properties.
}

export interface IListItem<T = unknown> {
  value: T;
  actionRole?: ActionRole;
  isSelected?: boolean;
}

export interface IEmptyListConfiguration {
  headline?: string;
  content?: string;
  actionLabel?: string;
}
