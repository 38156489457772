<div class="relative w-full h-full flex justify-center items-center">
  @if (backgroundFile) {
    @if (isBackgroundFileCubemapSig()) {
      <nrc-area-background-360-degree
        class="absolute w-full h-full top-0 left-0"
        [apiBaseUrl]="apiBaseUrl"
        [backgroundFile]="backgroundFile"
      />
    } @else {
      <nrc-area-background-2d
        class="absolute w-full h-full top-0 left-0"
        [apiBaseUrl]="apiBaseUrl"
        [backgroundFile]="backgroundFile"
        [isEditMode]="isEditMode"
      />
    }
  } @else {
    <nrc-area-background-empty
      (changeBackgroundEvent)="changeBackgroundEvent.emit()"
    />
  }
</div>
