import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter, ViewChild, ViewContainerRef, ChangeDetectionStrategy, AfterViewInit, OnDestroy, TemplateRef, Type, signal } from '@angular/core';
import { ModalSize } from '@newroom-connect/library/enums';

import { IconComponent } from '../icon/icon.component';
import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';

import { ModalService } from './service/modal.service';

export enum ModalTitleSize {
  REGULAR = 'REGULAR',
  LARGE = 'LARGE'
}

@Component({
  standalone: true,
  imports: [CommonModule, IconComponent, LoadingSpinnerComponent],
  selector: 'nrc-modal',
  templateUrl: './modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  @Input({ required: true }) public id!: string;
  @Input() public title?: string;
  @Input() public size: ModalSize = ModalSize.SMALL;
  @Input() public paddedContent = true;

  @Output() public closeEvent = new EventEmitter<void>();

  @ViewChild('contentContainer', { read: ViewContainerRef, static: true }) private contentContainer!: ViewContainerRef;

  public isLoadingContent = signal<boolean>(true);

  public modalSize = ModalSize;
  public modalTitleSizeOption = ModalTitleSize;

  private _content: Type<any> | TemplateRef<any> | null = null;

  constructor(private readonly modalService: ModalService) { }

  public ngAfterViewInit(): void {
    this.loadContent();
  }

  public ngOnDestroy(): void {
    this.contentContainer.clear();
    this.contentContainer.detach();
  }

  /**
   * Sets the content of the modal and triggers content loading.
   *
   * @param value The content to be loaded into the modal.
   */
  @Input() public set content(value: Type<any> | TemplateRef<any> | null) {
    this._content = value;
    this.loadContent();
  }

  /**
   * Gets the current content of the modal.
   *
   * @returns The current content of the modal.
   */
  public get content(): Type<any> | TemplateRef<any> | null {
    return this._content;
  }

  /**
   * Loads the content into the modal.
   * If the content is a TemplateRef, it creates an embedded view.
   * If the content is a Component, it creates the component and sets up event binding.
   *
   * @private
   */
  private loadContent(): void {
    if (!this.contentContainer || !this._content) {
      return;
    }

    this.isLoadingContent.set(true);

    this.contentContainer.clear();

    if (this._content instanceof TemplateRef) {
      this.contentContainer.createEmbeddedView(this._content);
    } else {
      const componentRef = this.contentContainer.createComponent(this._content);

      if (componentRef.instance.closeEvent) {
        componentRef.instance.closeEvent.subscribe(() => this.closeModal());
      }
    }

    this.isLoadingContent.set(false);
  }

  public closeModal(): void {
    this.closeEvent.emit();
  }
}

// Export modal service, to be used to dynamically load the modal.
export * from './service/modal.service';
