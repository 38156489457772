import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, ViewEncapsulation, ElementRef, Input, ViewChild, SimpleChanges, OnChanges, Output, EventEmitter, OnDestroy, signal } from '@angular/core';
import videojs from 'video.js';
import Player from 'video.js/dist/types/player';
import { MediaSource } from '@newroom-connect/library/types';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { LoadingSpinnerComponent } from '../loading/loading-spinner/loading-spinner.component';
import { IconComponent } from '../icon/icon.component';
@Component({
  standalone: true,
  imports: [
    CommonModule,
    LoadingSpinnerComponent,
    IconComponent
  ],
  selector: 'nrc-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class VideoComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input({ required: true }) public sources: MediaSource[] = [];
  @Input() public controls = true;
  @Input() public autoplay = false;
  @Input() public muted = false;
  @Input() public loop = false;
  @Input() public playsInline = false;
  @Input() public fluid = false;
  @Input() public disablePictureInPicture = false;
  @Input() public objectFit: 'cover' | 'contain' = 'contain';
  @Input() public preload: 'auto' | 'metadata' | 'none' = 'auto';
  @Input() public isRounded = false;
  @Output() public loadComplete = new EventEmitter<void>();

  @ViewChild('videoPlayer', { static: false }) public videoPlayerRef!: ElementRef<HTMLVideoElement>;

  public player: Player | null = null;
  public hasLoadedData = signal<boolean>(false);
  public hasError = signal<boolean>(false);

  /**
   * We call the load method of the video element on changing the sources of the component, so that the element is updated
   * with the new sources.
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['sources'] && !changes['sources'].firstChange && this.videoPlayerRef) {
      this.hasLoadedData.set(false);
      this.hasError.set(false);

      if (this.player) {
        this.player.reset();

        for (const source of this.sources) {
          this.player.addSourceElement(source.uri, source.mimetype);
        }

        this.player.load();

        return;
      }

      this.initializeVideo();
    }
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.hasLoadedData.set(false);

    if (this.player) {
      this.player.dispose();
    }
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    if (!this.sources.length || !ArrayHelper.isNotEmpty(this.sources)) {
      return;
    }

    this.initializeVideo();
  }

  /**
   * Initializes the Video.js player with the provided video element.
   */
  private initializeVideo(): void {
    if (!this.videoPlayerRef) {
      return;
    }

    this.hasLoadedData.set(false);
    this.hasError.set(false);

    this.player = videojs(this.videoPlayerRef.nativeElement, {
      controls: this.controls,
      autoplay: this.autoplay,
      muted: this.muted,
      loop: this.loop,
      playsInline: this.playsInline,
      fluid: this.fluid,
      disablePictureInPicture: this.disablePictureInPicture,
      preload: this.preload,
      responsive: true
    });

    // Reset state when a new video loading
    this.player.on('loadstart', () => this.togglePlayButtonVisibility(false));
    this.player.on('playing', () => this.togglePlayButtonVisibility(false));
    this.player.on('pause', () => this.togglePlayButtonVisibility(true));

    // Handle errors
    this.player.on('error', () => {
      this.hasError.set(true);
      this.hasLoadedData.set(false);
    });

    // Video is fully loaded
    this.player.on('loadeddata', () => {
      this.hasLoadedData.set(true);

      this.hasError.set(false);

      this.setPlayerElements();
      this.loadComplete.emit();

      this.togglePlayButtonVisibility(true);
    });
  }

  /**
   * Adjusts the size of the player controls (button and spinner) when the video element is smaller than 200 pixels.
   */
  public setPlayerElements(): void {
    if (!this.videoPlayerRef) {
      return;
    }

    const playerEl = this.videoPlayerRef.nativeElement;

    playerEl.classList.add(this.objectFit === 'cover' ? 'object-cover' : 'object-contain');

    if (playerEl.offsetWidth < 200) {
      playerEl.childNodes.forEach((child) => {
        const childEl = child as HTMLElement;

        if (!childEl || !childEl.classList || !childEl.tagName) {
          return;
        }

        // Make the loading spinner smaller.
        if (childEl.classList.contains('vjs-loading-spinner')) {
          childEl.classList.add('vjs-loading-spinner-small');
        }

        // Hide the control bar.
        if (childEl.classList.contains('vjs-control-bar')) {
          childEl.classList.add('controls-hide');
        }

        // Make the play button smaller.
        if (childEl.classList.contains('vjs-big-play-button')) {
          childEl.classList.add('vjs-big-play-button-small');
        }
      });
    }
  }

  /**
   * Toggles the visibility of the play button based on the player's paused state.
   *
   * @param visible - Whether the play button should be visible.
   */
  private togglePlayButtonVisibility(visible: boolean): void {
    const playButton = this.videoPlayerRef.nativeElement?.parentElement?.querySelector('.vjs-big-play-button') as HTMLElement;

    if (!playButton) {
      return;
    }

    playButton.style.display = visible ? 'grid' : 'none';
  }
}

