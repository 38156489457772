import { Directive, ElementRef, OnInit, Input, Renderer2, OnDestroy, NgZone, inject } from '@angular/core';
import { LoggingService } from '@newroom-connect/library/services';

export type EasingFunction = 'linear' | 'easeIn' | 'easeOut' | 'easeInOut' | 'circIn' | 'circOut' | 'circInOut' | 'backIn' | 'backOut' | 'backInOut' | 'anticipate';

interface GridAnimationConfig {
  duration?: number;
  stagger?: number;
  easing?: EasingFunction;
}

interface Coords {
  translateX: number;
  translateY: number;
  scaleX: number;
  scaleY: number;
}

interface BoundingClientRect {
  top: number;
  left: number;
  width: number;
  height: number;
}

interface ItemPosition {
  rect: BoundingClientRect;
  gridBoundingClientRect: BoundingClientRect;
  stopTween?: () => void;
}

@Directive({
  standalone: true,
  selector: '[nrcGridLayoutAnimation]'
})
export class GridLayoutAnimationDirective implements OnInit, OnDestroy {
  private readonly logger = inject(LoggingService);

  @Input() public config: GridAnimationConfig = {};

  private container: HTMLElement;
  private cachedPositionData: { [key: string]: ItemPosition } = {};
  private mutationObserver?: MutationObserver;
  private resizeListener?: () => void;
  private scrollListener?: () => void;
  private readonly DATASET_KEY = 'animategridid';

  private isFirstRun = true;

  // Track grid changes
  private gridClassChangeTimeout: any | null = null;

  constructor(
    private el: ElementRef,
    private ngZone: NgZone,
    private renderer: Renderer2
  ) {
    this.container = this.el.nativeElement;
  }

  /**
   * Sets up grid positions, mutation observer, and event listeners.
   */
  public ngOnInit(): void {
    this.initializeGridAnimation();
  }

  /**
   * Cleans up event listeners and disconnects the mutation observer.
   */
  public ngOnDestroy(): void {
    this.unwrapGrid();
  }

  /**
   * Initializes the grid animation by setting up listeners and observers.
   */
  private initializeGridAnimation(): void {
    // Set up listeners
    this.setupMutationObserver();
    this.setupResizeListener();
    this.setupScrollListener();

    // Give the grid a chance to settle, then record initial positions
    setTimeout(() => {
      this.logger.info('[GridLayoutAnimation] Initializing with container:', this.container);
      this.logger.info('[GridLayoutAnimation] Container classes:', Array.from(this.container.classList));
      this.recordPositions(Array.from(this.container.children) as HTMLElement[]);
    }, 0);
  }

  /**
   * Records the positions of the given elements.
   * @param elements - The elements to record positions for.
   */
  private recordPositions(elements: HTMLElement[]): void {
    const gridBoundingClientRect = this.container.getBoundingClientRect();

    elements.forEach(el => {
      if (typeof el.getBoundingClientRect !== 'function') {
        return;
      }
      const rect = this.getGridAwareBoundingClientRect(gridBoundingClientRect, el);

      if (!el.dataset[this.DATASET_KEY]) {
        const newId = `${Math.random()}`;

        this.renderer.setAttribute(el, `data-${this.DATASET_KEY}`, newId);
      }

      const id = el.dataset[this.DATASET_KEY] as string;

      this.cachedPositionData[id] = {
        rect,
        gridBoundingClientRect
      };
    });
  }

  /**
   * Sets up the resize listener.
   */
  private setupResizeListener(): void {
    this.resizeListener = this.ngZone.runOutsideAngular(() => {
      return this.renderer.listen('window', 'resize', () => {
        this.recordPositions(Array.from(this.container.children) as HTMLElement[]);
      });
    });
  }

  /**
   * Sets up the scroll listener.
   */
  private setupScrollListener(): void {
    this.scrollListener = this.ngZone.runOutsideAngular(() => {
      return this.renderer.listen(this.container, 'scroll', () => {
        this.recordPositions(Array.from(this.container.children) as HTMLElement[]);
      });
    });
  }

  /**
   * Sets up the mutation observer.
   */
  private setupMutationObserver(): void {
    this.mutationObserver = new MutationObserver(this.mutationCallback.bind(this));

    // Observe the container and its children for ALL possible changes
    this.mutationObserver.observe(this.container, {
      attributes: true,     // Watch for attribute changes
      childList: true,      // Watch for child additions/removals
      characterData: true,  // Watch for text changes
      subtree: true,        // Watch all descendants
      attributeOldValue: true // Get old attribute values
    });
  }

  /**
   * Callback for the mutation observer.
   */
  private mutationCallback(): void {
    // If multiple rapid mutations come in, debounce them
    if (this.gridClassChangeTimeout) {
      clearTimeout(this.gridClassChangeTimeout);
    }

    // Delay checking the final state slightly to let Angular finish its updates
    this.gridClassChangeTimeout = setTimeout(() => {
      // Force animation to happen regardless of what triggered the change
      this.animateGridChanges();

      // Update cached positions
      this.recordPositions(Array.from(this.container.children) as HTMLElement[]);
      this.isFirstRun = false;
    }, 50); // Short delay to let Angular finish updating the DOM
  }

  /**
   * Animates the grid changes.
   */
  private animateGridChanges(): void {
    const gridBoundingClientRect = this.container.getBoundingClientRect();
    const childrenElements = Array.from(this.container.children) as HTMLElement[];

    // Stop current transitions and remove transforms on transitioning elements
    childrenElements
      .filter(el => {
        const itemPosition = this.cachedPositionData[el.dataset[this.DATASET_KEY] as string];

        if (itemPosition && itemPosition.stopTween) {
          itemPosition.stopTween();

          delete itemPosition.stopTween;

          return true;
        }
        return false;
      })
      .forEach(el => {
        el.style.transform = '';
        const firstChild = el.children[0] as HTMLElement;

        if (firstChild) {
          firstChild.style.transform = '';
        }
      });

    const animatedGridChildren: { el: HTMLElement; boundingClientRect: BoundingClientRect; childCoords?: BoundingClientRect }[] = childrenElements
      .map(el => ({
        el,
        boundingClientRect: this.getGridAwareBoundingClientRect(gridBoundingClientRect, el)
      }))
      .filter(({ el, boundingClientRect }) => {
        const itemPosition = this.cachedPositionData[el.dataset[this.DATASET_KEY] as string];

        if (!itemPosition) {
          this.recordPositions([el]);

          return false;
        }

        return this.isFirstRun || this.hasPositionChanged(boundingClientRect, itemPosition.rect);
      });

    if (!animatedGridChildren.length) {
      return;
    }

    animatedGridChildren
      .map(data => {
        const firstChild = data.el.children[0] as HTMLElement;

        if (firstChild) {
          data.childCoords = this.getGridAwareBoundingClientRect(
            gridBoundingClientRect,
            firstChild
          );
        }
        return data;
      })
      .forEach(({ el, boundingClientRect, childCoords }, i) => {
        const firstChild = el.children[0] as HTMLElement;

        const itemPosition = this.cachedPositionData[el.dataset[this.DATASET_KEY] as string];
        const coords: Coords = this.calculateCoords(itemPosition.rect, boundingClientRect);

        this.renderer.setStyle(el, 'transform-origin', '0 0');

        if (firstChild && childCoords?.left === boundingClientRect.left && childCoords?.top === boundingClientRect.top) {
          firstChild.style.transformOrigin = '0 0';
        }

        this.ngZone.runOutsideAngular(() => {
          const timeoutId =  setTimeout(() => {
            this.animateElement(el, coords);
          }, (this.config.stagger || 0) * i);

          itemPosition.stopTween = (): void => clearTimeout(timeoutId);
        });
      });

    // Update cached positions after animation
    this.recordPositions(childrenElements);
    this.isFirstRun = false;
  }

  /**
   * Animates a single element.
   *
   * @param el The element to animate.
   * @param fromCoords The starting coordinates.
   */
  private animateElement(el: HTMLElement, fromCoords: Coords): void {
    const startTime = performance.now();
    const duration = this.config.duration || 250;

    const animate = (currentTime: number): void => {
      const elapsed = currentTime - startTime;
      const progress = Math.min(elapsed / duration, 1);
      const easedProgress = this.getEasingFunction(this.config.easing || 'easeInOut')(progress);

      const currentCoords: Coords = {
        translateX: fromCoords.translateX * (1 - easedProgress),
        translateY: fromCoords.translateY * (1 - easedProgress),
        scaleX: fromCoords.scaleX + (1 - fromCoords.scaleX) * easedProgress,
        scaleY: fromCoords.scaleY + (1 - fromCoords.scaleY) * easedProgress
      };

      this.applyCoordTransform(el, currentCoords, true);

      if (progress < 1) {
        requestAnimationFrame(animate);
      } else {
        this.recordPositions([el]);
      }
    };

    const animationFrame = requestAnimationFrame(animate);
    const itemPosition = this.cachedPositionData[el.dataset[this.DATASET_KEY] as string];

    itemPosition.stopTween = (): void => {
      cancelAnimationFrame(animationFrame);
    };
  }

  /**
   * Applies coordinate transformation to an element.
   * @param el - The element to transform.
   * @param coords - The coordinates to apply.
   * @param immediate - Whether to apply the transform immediately.
   */
  private applyCoordTransform(el: HTMLElement, coords: Coords, immediate = false): void {
    const isFinished = coords.translateX === 0 && coords.translateY === 0 && coords.scaleX === 1 && coords.scaleY === 1;
    const transform = isFinished ? '' : `translateX(${coords.translateX}px) translateY(${coords.translateY}px) scaleX(${coords.scaleX}) scaleY(${coords.scaleY})`;

    const styleElement = (): void => {
      this.renderer.setStyle(el, 'transform', transform);
      const firstChild = el.children[0] as HTMLElement;

      if (firstChild) {
        const childTransform = isFinished ? '' : `scaleX(${1 / coords.scaleX}) scaleY(${1 / coords.scaleY})`;

        this.renderer.setStyle(firstChild, 'transform', childTransform);
      }
    };

    if (immediate) {
      styleElement();
    } else {
      this.ngZone.runOutsideAngular(() => {
        requestAnimationFrame(styleElement);
      });
    }
  }

  /**
   * Gets the bounding client rect relative to the grid.
   *
   * @param gridBoundingClientRect
   * @param el
   *
   * @returns
   */
  private getGridAwareBoundingClientRect(gridBoundingClientRect: BoundingClientRect, el: HTMLElement): BoundingClientRect {
    const { top, left, width, height } = el.getBoundingClientRect();

    const rect = { top, left, width, height };

    rect.top -= gridBoundingClientRect.top;
    rect.left -= gridBoundingClientRect.left;

    rect.top = Math.max(rect.top, 0);
    rect.left = Math.max(rect.left, 0);

    return rect;
  }

  /**
   * Gets the easing function based on the provided name.
   *
   * @param name
   * @returns
   */
  private getEasingFunction(name: EasingFunction): (t: number) => number {
    // Implement easing functions here. For brevity, only a few are shown.
    const easingFunctions: { [key: string]: (t: number) => number } = {
      linear: (t: number) => t,
      easeInOut: (t: number) => t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t
    };

    return easingFunctions[name] || easingFunctions['linear'];
  }

  /**
   * Cleans up the directive by removing listeners and disconnecting the observer.
   */
  private unwrapGrid(): void {
    if (this.resizeListener) {
      this.resizeListener();
    }
    if (this.scrollListener) {
      this.scrollListener();
    }
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }

    // Clear any pending timeouts
    if (this.gridClassChangeTimeout) {
      clearTimeout(this.gridClassChangeTimeout);
      this.gridClassChangeTimeout = null;
    }
  }

  private hasPositionChanged(newRect: BoundingClientRect, oldRect: BoundingClientRect): boolean {
    const threshold = 0.1; // To account for small floating-point differences

    return Math.abs(newRect.top - oldRect.top) > threshold ||
      Math.abs(newRect.left - oldRect.left) > threshold ||
      Math.abs(newRect.width - oldRect.width) > threshold ||
      Math.abs(newRect.height - oldRect.height) > threshold;
  }

  private calculateCoords(oldRect: BoundingClientRect, newRect: BoundingClientRect): Coords {
    return {
      translateX: oldRect.left - newRect.left,
      translateY: oldRect.top - newRect.top,
      scaleX: oldRect.width / newRect.width,
      scaleY: oldRect.height / newRect.height
    };
  }
}
