import { Injectable } from '@angular/core';
import { ObjectPropertyFormat } from '@newroom-connect/library/enums';
import { IListConfigurationColumn, ListItemType } from '@newroom-connect/library/interfaces';
import { FileCubemapStatus } from '@newroom-connect/library/types';

import { FileService } from './file.service';

@Injectable({
  providedIn: 'root'
})
export class FileListService extends FileService {
  /**
   * Generate the list configuration for the grid layout.
   *
   * @returns The list configuration for the grid layout.
   */
  public static generateGridLayoutListConfiguration(): IListConfigurationColumn[] {
    return [
      {
        key: 'title',
        label: 'title',
        additionalInfoKeys: [
          {
            key: 'extension',
            format: ObjectPropertyFormat.TEXT
          },
          {
            key: 'createdAt',
            format: ObjectPropertyFormat.DATETIME
          },
          {
            key: 'size',
            format: ObjectPropertyFormat.FILE_SIZE
          }
        ],
        type: ListItemType.CARD,
        isHighlighted: true,
        thumbnailConfig: {
          fallbackThumbnailSourceKey: 'source',
          thumbnailSourceKey: 'thumbnail',
          fallbackMimetypeKey: 'mimetype'
        },
        buttonConfigs: [
          {
            key: 'cubemapStatus',
            extractorFn: (cubemapStatus: FileCubemapStatus): string => (cubemapStatus === 'generated' ? 'cube-outline' : (cubemapStatus === 'in-progress' ? 'cube-loading-dashed-outline' : (cubemapStatus === 'supported' ? 'cube-dashed-outline' : 'transparent'))),
            visibilityFn: (cubemapStatus: FileCubemapStatus): boolean => !!cubemapStatus,
            tooltipTextFn: (cubemapStatus: FileCubemapStatus): string => (cubemapStatus === 'generated' ? 'This image is converted into a cubemap' : (cubemapStatus === 'in-progress' ? 'Converting image into cubemap...' : (cubemapStatus === 'supported' ? 'You can convert this image into a cubemap' : '')))
          }
        ]
      }
    ];
  }

  /**
   * Generate the list configuration for the row layout.
   *
   * @returns The list configuration for the row layout.
   */
  public static generateRowLayoutListConfiguration(): IListConfigurationColumn[] {
    return [
      {
        key: 'title',
        label: 'title',
        additionalInfoKey: 'extension',
        type: ListItemType.TEXT,
        isHighlighted: true,
        thumbnailConfig: {
          fallbackThumbnailSourceKey: 'source',
          thumbnailSourceKey: 'thumbnail',
          fallbackMimetypeKey: 'mimetype'
        }
      },
      {
        key: 'cubemapStatus',
        label: '',
        type: ListItemType.BUTTON,
        align: 'center',
        extractorFn: (cubemapStatus: FileCubemapStatus): string => (cubemapStatus === 'generated' ? 'cube-outline' : (cubemapStatus === 'in-progress' ? 'cube-loading-dashed-outline' : (cubemapStatus === 'supported' ? 'cube-dashed-outline' : 'transparent'))),
        visibilityFn: (cubemapStatus: FileCubemapStatus): boolean => !!cubemapStatus,
        tooltipTextFn: (cubemapStatus: FileCubemapStatus): string => (cubemapStatus === 'generated' ? 'This image is converted into a cubemap' : (cubemapStatus === 'in-progress' ? 'Converting image into cubemap...' : (cubemapStatus === 'supported' ? 'You can convert this image into a cubemap' : '')))
      },
      {
        key: 'createdAt',
        label: 'uploaded',
        type: ListItemType.DATE,
        userData: {
          idPropertyKey: 'createdBy.id',
          profileColorKey: 'createdBy.profile.profileColor',
          firstNameKey: 'createdBy.profile.firstName',
          lastNameKey: 'createdBy.profile.lastName',
          roleNameKey: 'createdBy.roles.0.name'
        }
      },
      {
        key: 'updatedAt',
        label: 'updated',
        type: ListItemType.DATE,
        userData: {
          idPropertyKey: 'updatedBy.id',
          profileColorKey: 'updatedBy.profile.profileColor',
          firstNameKey: 'updatedBy.profile.firstName',
          lastNameKey: 'updatedBy.profile.lastName',
          roleNameKey: 'updatedBy.roles.0.name'
        }
      },
      {
        key: 'size',
        label: 'size',
        type: ListItemType.FILE_SIZE
      }
    ];
  }
}
