export enum ModalTitleSize {
  REGULAR = 'REGULAR',
  LARGE = 'LARGE'
}

export enum ModalTheme {
  STANDARD = 'STANDARD',
  DARK = 'DARK'
}

export enum ModalSize {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  FULL_SCREEN = 'FULL_SCREEN'
}
