import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { AlertComponent, FileDownloadStatusComponent, FileTranslationStatusComponent, ImageComponent, LoadingComponent, ToastComponent, UnsavedChangesAlertComponent } from '@newroom-connect/library/components';
import { ApiService, AuthService, EntityService, SessionService, TranslationService, WebsocketService } from '@newroom-connect/library/services';
import { HttpAuthInterceptor } from '@newroom-connect/library/interceptors';
import { IsNonEmptyArrayPipe } from '@newroom-connect/library/pipes';

import { environment } from '../../../../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponentModule } from './components/header/header.component.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient): TranslateHttpLoader => new TranslateHttpLoader(http, 'assets/i18n/', '.json'),
        deps: [HttpClient]
      },
      defaultLanguage: 'en'
    }),
    AppRoutingModule,
    HeaderComponentModule,
    AlertComponent,
    UnsavedChangesAlertComponent,
    ImageComponent,
    LoadingComponent,
    IsNonEmptyArrayPipe,
    FileTranslationStatusComponent,
    FileDownloadStatusComponent,
    ToastComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (apiService: ApiService): () => void => () => apiService.setBaseUrl(environment.api.baseUrl),
      deps: [ApiService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService): () => void => () => authService.setBaseUrl(environment.auth.baseUrl),
      deps: [AuthService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (): () => void => () => EntityService.setBaseUrl(environment.api.baseUrl),
      deps: [EntityService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (sessionService: SessionService): () => void => () => sessionService.initialize(),
      deps: [SessionService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (translationService: TranslationService): () => void => () => translationService.initialize(),
      deps: [TranslationService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (websocketService: WebsocketService): () => void => () => websocketService.initialize(environment.websocketServer.baseUrl),
      deps: [WebsocketService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpAuthInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
