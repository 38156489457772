<div tabindex="0" class="flex justify-center items-center h-50 w-full p-1" (click)="clickEvent.emit()" (keydown)="(null)" >
  @switch (code.slice(0, 2)) {
    @case ("ar") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#496e2d" d="M0 0h512v512H0z" />
              <path fill="#eee" d="M379 176v97h-16c-2.8 0-5.3-.2-7.8-.4 3-4.3 5.2-8.9 6.4-13.7a37.7 37.7 0 0 0-4-29.2A31.6 31.6 0 0 0 331 215a31.6 31.6 0 0 0-26.5 14.7c-5.3 8.6-6.7 19-4.1 29.2 1.2 4.8 3.4 9.4 6.4 13.7-2.5.2-5 .4-7.8.4h-16v-58h-24v82a15 15 0 0 1-15 15v24a39 39 0 0 0 39-39h16c12.4 0 23-2.3 32-6 9 3.7 19.6 6 32 6h40V176zm39 0v121h24V176zm-330 0v24h24v-24zm39 0v24h24v-24zm24 39a82 82 0 0 0-82 82h24a58 58 0 0 1 34-52.8V297h102v-82h-24v58h-15v-58h-24v58h-15zm180 24c3.4 0 4.7 1 6.1 3.4a14 14 0 0 1 1.2 10.7c-.9 3.6-3 7.7-7.3 11.2a21.7 21.7 0 0 1-7.3-11.2 14 14 0 0 1 1.2-10.7c1.4-2.4 2.7-3.4 6.1-3.4zm-204 73v24h24v-24zm39 0v24h24v-24zm39 0v24h24v-24z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Arabic </span>
      </div>
    }

    @case ("az") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#d80027" d="m0 166.9 253-31.8 259 31.8v178l-257.5 37.4L0 345z" />
              <path fill="#338af3" d="M0 0h512v166.9H0z" />
              <path fill="#6da544" d="M0 344.9h512V512H0z" />
              <g fill="#eee">
                <path d="M261.6 328.2a72.3 72.3 0 1 1 34.4-136 89 89 0 1 0 0 127.3 72 72 0 0 1-34.4 8.7z" />
                <path d="m317.2 206 9.6 26.8 25.8-12.3-12.2 25.8 26.9 9.6-27 9.6 12.3 25.8-25.8-12.3-9.6 27-9.6-27-25.8 12.3 12.3-25.8-27-9.6 27-9.6-12.3-25.8 25.8 12.3z" />
              </g>
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Azeri </span>
      </div>
    }

    @case ("zh") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#d80027" d="M0 0h512v512H0z" />
              <path fill="#ffda44" d="m140.1 155.8 22.1 68h71.5l-57.8 42.1 22.1 68-57.9-42-57.9 42 22.2-68-57.9-42.1H118zm163.4 240.7-16.9-20.8-25 9.7 14.5-22.5-16.9-20.9 25.9 6.9 14.6-22.5 1.4 26.8 26 6.9-25.1 9.6zm33.6-61 8-25.6-21.9-15.5 26.8-.4 7.9-25.6 8.7 25.4 26.8-.3-21.5 16 8.6 25.4-21.9-15.5zm45.3-147.6L370.6 212l19.2 18.7-26.5-3.8-11.8 24-4.6-26.4-26.6-3.8 23.8-12.5-4.6-26.5 19.2 18.7zm-78.2-73-2 26.7 24.9 10.1-26.1 6.4-1.9 26.8-14.1-22.8-26.1 6.4 17.3-20.5-14.2-22.7 24.9 10.1z"
              />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Chinese </span>
      </div>
    }

    @case ("cs") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M0 0h512v256l-265 45.2z" />
              <path fill="#d80027" d="M210 256h302v256H0z" />
              <path fill="#0052b4" d="M0 0v512l256-256L0 0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Czech </span>
      </div>
    }

    @case ("nl") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="m0 167 253.8-19.3L512 167v178l-254.9 32.3L0 345z" />
              <path fill="#a2001d" d="M0 0h512v167H0z" />
              <path fill="#0052b4" d="M0 345h512v167H0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Dutch </span>
      </div>
    }

    @case ("en") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M256 0h256v64l-32 32 32 32v64l-32 32 32 32v64l-32 32 32 32v64l-256 32L0 448v-64l32-32-32-32v-64z" />
              <path fill="#d80027" d="M224 64h288v64H224Zm0 128h288v64H256ZM0 320h512v64H0Zm0 128h512v64H0Z" />
              <path fill="#0052b4" d="M0 0h256v256H0Z" />
              <path fill="#eee" d="m187 243 57-41h-70l57 41-22-67zm-81 0 57-41H93l57 41-22-67zm-81 0 57-41H12l57 41-22-67zm162-81 57-41h-70l57 41-22-67zm-81 0 57-41H93l57 41-22-67zm-81 0 57-41H12l57 41-22-67Zm162-82 57-41h-70l57 41-22-67Zm-81 0 57-41H93l57 41-22-67zm-81 0 57-41H12l57 41-22-67Z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> English </span>
      </div>
    }

    @case ("fr") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
              <path fill="#0052b4" d="M0 0h167v512H0z" />
              <path fill="#d80027" d="M345 0h167v512H345z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> French </span>
      </div>
    }

    @case ("gd") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
              <path fill="#6da544" d="M0 0h167v512H0z" />
              <path fill="#ff9811" d="M345 0h167v512H345z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Gaelic </span>
      </div>
    }

    @case ("de") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#ffda44" d="m0 345 256.7-25.5L512 345v167H0z" />
              <path fill="#d80027" d="m0 167 255-23 257 23v178H0z" />
              <path fill="#333" d="M0 0h512v167H0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> German </span>
      </div>
    }

    @case ("it") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
              <path fill="#6da544" d="M0 0h167v512H0z" />
              <path fill="#d80027" d="M345 0h167v512H345z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Italian </span>
      </div>
    }

    @case ("ms") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#eee" d="M0 256 256 0h256v55.7l-19.5 33 19.5 33.7v66.8l-22.1 37.7L512 256v66.8l-20.2 38.5 20.2 28.3v66.7l-254.5 28.2L0 456.3v-66.7l26-35.1-26-31.7z" />
              <path fill="#d80027" d="M256 256h256v-66.8H222.9zm-33.1-133.6H512V55.7H222.9zM512 512v-55.7H0V512zM0 389.6h512v-66.8H0z" />
              <path fill="#0052b4" d="M0 0h256v256H0z" />
              <g fill="#ffda44">
                <path d="M170.2 219.1a63.3 63.3 0 1 1 30.1-119 78 78 0 1 0 0 111.4 63 63 0 0 1-30 7.6z" />
                <path d="m188 111.3 11.3 23.5 25.4-5.9-11.4 23.5 20.4 16.2-25.4 5.7.1 26-20.3-16.2-20.4 16.2.1-26-25.4-5.7 20.4-16.2-11.4-23.5 25.4 6z" />
              </g>
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Malay </span>
      </div>
    }

    @case ("no") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#d80027" d="M0 0h100.2l66.1 53.5L233.7 0H512v189.3L466.3 257l45.7 65.8V512H233.7l-68-50.7-65.5 50.7H0V322.8l51.4-68.5-51.4-65z" />
              <path fill="#eee" d="M100.2 0v189.3H0v33.4l24.6 33L0 289.5v33.4h100.2V512h33.4l30.6-26.3 36.1 26.3h33.4V322.8H512v-33.4l-24.6-33.7 24.6-33v-33.4H233.7V0h-33.4l-33.8 25.3L133.6 0z" />
              <path fill="#0052b4" d="M133.6 0v222.7H0v66.7h133.6V512h66.7V289.4H512v-66.7H200.3V0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Norwegian </span>
      </div>
    }

    @case ("pl") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#d80027" d="m0 256 256.4-44.3L512 256v256H0z" />
              <path fill="#eee" d="M0 0h512v256H0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Polish </span>
      </div>
    }

    @case ("pt") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#6da544" d="M0 512h167l37.9-260.3L167 0H0z" />
              <path fill="#d80027" d="M512 0H167v512h345z" />
              <circle cx="167" cy="256" r="89" fill="#ffda44" />
              <path fill="#d80027" d="M116.9 211.5V267a50 50 0 1 0 100.1 0v-55.6H117z" />
              <path fill="#eee" d="M167 283.8c-9.2 0-16.7-7.5-16.7-16.7V245h33.4v22c0 9.2-7.5 16.7-16.7 16.7z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Portuguese </span>
      </div>
    }

    @case ("ro") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#ffda44" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
              <path fill="#0052b4" d="M0 0h167v512H0z" />
              <path fill="#d80027" d="M345 0h167v512H345z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Romanian </span>
      </div>
    }

    @case ("ru") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#0052b4" d="M512 170v172l-256 32L0 342V170l256-32z" />
              <path fill="#eee" d="M512 0v170H0V0Z" />
              <path fill="#d80027" d="M512 342v170H0V342Z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Russian </span>
      </div>
    }

    @case ("es") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#ffda44" d="m0 128 256-32 256 32v256l-256 32L0 384Z" />
              <path fill="#d80027" d="M0 0h512v128H0zm0 384h512v128H0z" />
              <g fill="#eee">
                <path d="M144 304h-16v-80h16zm128 0h16v-80h-16z" />
                <ellipse cx="208" cy="296" rx="48" ry="32" />
              </g>
              <g fill="#d80027">
                <rect width="16" height="24" x="128" y="192" rx="8" />
                <rect width="16" height="24" x="272" y="192" rx="8" />
                <path d="M208 272v24a24 24 0 0 0 24 24 24 24 0 0 0 24-24v-24h-24z" />
              </g>
              <rect width="32" height="16" x="120" y="208" fill="#ff9811" ry="8" />
              <rect width="32" height="16" x="264" y="208" fill="#ff9811" ry="8" />
              <rect width="32" height="16" x="120" y="304" fill="#ff9811" rx="8" />
              <rect width="32" height="16" x="264" y="304" fill="#ff9811" rx="8" />
              <path fill="#ff9811" d="M160 272v24c0 8 4 14 9 19l5-6 5 10a21 21 0 0 0 10 0l5-10 5 6c6-5 9-11 9-19v-24h-9l-5 8-5-8h-10l-5 8-5-8z" />
              <path d="M122 252h172m-172 24h28m116 0h28" />
              <path fill="#d80027" d="M122 248a4 4 0 0 0-4 4 4 4 0 0 0 4 4h172a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm0 24a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4zm144 0a4 4 0 0 0-4 4 4 4 0 0 0 4 4h28a4 4 0 0 0 4-4 4 4 0 0 0-4-4z" />
              <path fill="#eee" d="M196 168c-7 0-13 5-15 11l-5-1c-9 0-16 7-16 16s7 16 16 16c7 0 13-4 15-11a16 16 0 0 0 17-4 16 16 0 0 0 17 4 16 16 0 1 0 10-20 16 16 0 0 0-27-5c-3-4-7-6-12-6zm0 8c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm24 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-8 0-4 4-8 8-8zm-44 10 4 1 4 8c0 4-4 7-8 7s-8-3-8-8c0-4 4-8 8-8zm64 0c5 0 8 4 8 8 0 5-3 8-8 8-4 0-8-3-8-7l4-8z" />
              <path fill="none" d="M220 284v12c0 7 5 12 12 12s12-5 12-12v-12z" />
              <path fill="#ff9811" d="M200 160h16v32h-16z" />
              <path fill="#eee" d="M208 224h48v48h-48z" />
              <path fill="#d80027" d="m248 208-8 8h-64l-8-8c0-13 18-24 40-24s40 11 40 24zm-88 16h48v48h-48z" />
              <rect width="20" height="32" x="222" y="232" fill="#d80027" rx="10" ry="10" />
              <path fill="#ff9811" d="M168 232v8h8v16h-8v8h32v-8h-8v-16h8v-8zm8-16h64v8h-64z" />
              <g fill="#ffda44">
                <circle cx="186" cy="202" r="6" />
                <circle cx="208" cy="202" r="6" />
                <circle cx="230" cy="202" r="6" />
              </g>
              <path fill="#d80027" d="M169 272v43a24 24 0 0 0 10 4v-47h-10zm20 0v47a24 24 0 0 0 10-4v-43h-10z" />
              <g fill="#338af3">
                <circle cx="208" cy="272" r="16" />
                <rect width="32" height="16" x="264" y="320" ry="8" />
                <rect width="32" height="16" x="120" y="320" ry="8" />
              </g>
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Spanish </span>
      </div>
    }

    @case ("sv") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a">
              <circle cx="256" cy="256" r="256" fill="#fff" />
            </mask>
            <g mask="url(#a)">
              <path fill="#0052b4" d="M0 0h133.6l35.3 16.7L200.3 0H512v222.6l-22.6 31.7 22.6 35.1V512H200.3l-32-19.8-34.7 19.8H0V289.4l22.1-33.3L0 222.6z" />
              <path fill="#ffda44" d="M133.6 0v222.6H0v66.8h133.6V512h66.7V289.4H512v-66.8H200.3V0z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Swedish </span>
      </div>
    }

    @case ("uz") {
      <div class="flex items-center space-x-1 w-full h-full">
        <div [ngClass]="iconClassList">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <mask id="a"><circle cx="256" cy="256" r="256" fill="#fff" /></mask>
            <g mask="url(#a)">
              <path fill="#d80027" d="m0 178 254.2-22L512 178v22.3l-40.2 54.1 40.2 57.3V334l-254 23.4L0 334v-22.3l36.7-59.4-36.7-52z" />
              <path fill="#338af3" d="M0 0h512v178H0z" />
              <path fill="#eee" d="M0 200.3h512v111.4H0z" />
              <path fill="#6da544" d="M0 334h512v178H0z" />
              <path fill="#eee" d="M117.2 105.7a50 50 0 0 1 39.3-48.9 50.2 50.2 0 0 0-10.7-1.1 50 50 0 1 0 10.7 99c-22.5-5-39.3-25-39.3-49zm69 22.8 3.3 10.4h11l-9 6.5 3.5 10.4-9-6.4-8.7 6.4 3.4-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.4 10.4-8.8-6.4-9 6.4 3.5-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.4-8.8 6.4 3.4-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.4-8.8 6.4 3.4-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.4-8.8 6.4 3.4-10.4-8.8-6.5h11zm-105-36.4 3.4 10.4h11l-9 6.5 3.4 10.4-8.8-6.5-9 6.5 3.5-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.5-8.8 6.5 3.4-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.5-8.8 6.5 3.4-10.4-9-6.5h11zm35 0 3.4 10.4h11l-9 6.5 3.5 10.4-9-6.5-8.8 6.5 3.4-10.4-8.8-6.5h11zm-70-36.4 3.4 10.4h11l-9 6.4 3.6 10.5-9-6.5-8.8 6.5 3.4-10.5-9-6.4h11zm35 0 3.4 10.4h11l-9 6.4 3.6 10.5-9-6.5-8.8 6.5 3.4-10.5-9-6.4h11zm35 0 3.4 10.4h11l-9 6.4 3.6 10.5-9-6.5-8.8 6.5 3.4-10.5-8.8-6.4h11z" />
            </g>
          </svg>
        </div>
        <span [ngClass]="textClassList" class="text-sm font-light"> Uzbek </span>
      </div>
    }

    @default {
      <p>Unable to load country icon</p>
    }
  }
</div>
