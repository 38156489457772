import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IActionButton } from '@newroom-connect/library/interfaces';
import { HoverDelayDirective } from '@newroom-connect/library/directives';
import { StringHelper } from '@newroom-connect/library/helpers';

import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { TooltipComponent } from '../../tooltip/tooltip.component';
import { ActionButtonMenuItemComponent } from '../../buttons/action-button-menu-item/action-button-menu-item.component';

@Component({
  selector: 'nrc-toolbar-item',
  standalone: true,
  templateUrl: './toolbar-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    IconComponent,
    TooltipComponent,
    ActionButtonMenuItemComponent,
    HoverDelayDirective,
    ButtonComponent
  ]
})
export class ToolbarItemComponent {
  @Input({ required: true }) public icon!: string;
  @Input() public label?: string;
  @Input() public tooltipText?: string;
  @Input() public tooltipTextTopside?: boolean = false;
  @Input() public disabled? = false;
  @Input() public contextMenu?: IActionButton[];

  @Output() public clickEvent = new EventEmitter<void>();
  @Output() public contextMenuActionButtonEvent = new EventEmitter<IActionButton>();

  public uniqueHash = StringHelper.generateRandomString(4);

  /**
   * Handles a click event on the toolbar item.
   * Emits a click event if the item is not disabled and does not have a context menu.
   */
  public handleClickEvent(): void {
    // If the item is disabled or the toolbar item has a context menu, do nothing.
    if (this.disabled || this.contextMenu) {
      return;
    }

    this.clickEvent.emit();
  }

  /**
   * Handles a context menu click event.
   * Emits the `contextMenuActionButtonEvent` with the clicked action button.
   *
   * @param event
   * @param actionButton
   */
  public handleContextMenuClickEvent(event: MouseEvent, actionButton: IActionButton): void {
    event.stopPropagation();
    event.preventDefault();

    this.contextMenuActionButtonEvent.emit(actionButton);
  }
}
