import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AspectRatioDirective, HoverDelayDirective } from '@newroom-connect/library/directives';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IActionButton, IListConfigurationColumn, ListItemType } from '@newroom-connect/library/interfaces';
import { EvaluatePipe, ExtractPropertyPipe, ToStringPipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../../../buttons/button/button.component';
import { ActionButtonMenuItemComponent } from '../../../buttons/action-button-menu-item/action-button-menu-item.component';
import { CheckboxComponent } from '../../../checkbox/checkbox.component';
import { MediaPreviewComponent } from '../../../media-preview/media-preview.component';
import { TooltipComponent } from '../../../tooltip/tooltip.component';

export interface IGridListItemConfiguration {
  type: ListItemType;
  value: unknown;
  originalValue: unknown;
  additionalInfo?: unknown;
  thumbnailConfig?: {
    backgroundColorClasses?: string[];
    thumbnailSourceKey?: string;
    fallbackThumbnailSourceKey?: string;
    mimetypeKey?: string;
    fallbackMimetypeKey?: string;
  };
  thumbnailSource?: string;
  mimetype?: string;
  isHighlighted?: boolean;
  showTooltip?: boolean;
  buttonConfigs?: IListConfigurationColumn[];
  actionButtons?: IActionButton[];
  [key: string]: any; // Each type has some more differing properties.
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ActionButtonMenuItemComponent,
    ButtonComponent,
    CheckboxComponent,
    MediaPreviewComponent,
    TooltipComponent,
    AspectRatioDirective,
    HoverDelayDirective,
    EvaluatePipe,
    ExtractPropertyPipe,
    ToStringPipe
  ],
  selector: 'nrc-grid-list-item',
  templateUrl: './grid-list-item.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GridListItemComponent {
  @Input({ required: true }) public config!: IGridListItemConfiguration;
  @Input({ required: true }) public index!: number;
  @Input() public isSelected?: boolean;
  @Input() public showCheckbox?: boolean;
  @Input() public showContextMenu = true;

  @Output() public configButtonClickEvent = new EventEmitter<{ index: number }>();
  @Output() public itemActionButtonEvent = new EventEmitter<{ index: number, actionButton: IActionButton }>();
  @Output() public itemClickEvent = new EventEmitter<{ index: number }>();

  public listItemType = ListItemType;
  public buttonType = ButtonType;
  public actionRole = ActionRole;

  /**
   *
   * @param actionButton
   * @param index
   */
  public handleItemActionButtonClickEvent(actionButton: IActionButton, index: number): void {
    this.itemActionButtonEvent.emit({ index: index, actionButton });
  }

  /**
   *
   * @param index
   */
  public handleItemClickEvent(index: number): void {
    this.itemClickEvent.emit({ index });
  }
}
