import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'nrc-destroyable',
  template: ''
})
export abstract class DestroyableComponent implements OnDestroy {
  protected destroy$ = new Subject<void>();

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
