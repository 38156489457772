import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'hasSetValue'
})
export class HasSetValuePipe implements PipeTransform {
  /**
   * Check if a set contains a given value.
   *
   * @param value The value to check.
   * @param setToCheck The set to check, if value is inside.
   *
   * @returns Indicator, if set contains value.
   */
  public transform(value: unknown, setToCheck: Set<unknown> | unknown[]): unknown {
    if (setToCheck instanceof Set) {
      return setToCheck.has(value);
    }

    return Array.from(setToCheck).indexOf(value) >= 0;
  }
}
