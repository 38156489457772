import { Component, EventEmitter, Input, OnInit, Output, Signal, WritableSignal, computed, signal } from '@angular/core';
import { IEmptyListConfiguration, IListItem, IPaginationMeta } from '@newroom-connect/library/interfaces';

@Component({ template: '' })
export abstract class BaseListComponent implements OnInit {
  @Input({ required: true }) public items = signal<IListItem[] | null>(null);
  @Input() public meta?: IPaginationMeta;
  @Input() public pageSize = 10;
  @Input() public emptyListConfig?: IEmptyListConfiguration;
  @Input() public isLoading?: boolean | null;

  @Output() public itemSelectionToggleEvent = new EventEmitter<boolean>();

  public itemsDisplayed!: WritableSignal<IListItem[]>;

  public isEveryPageItemSelectedSig: Signal<boolean>;

  public isSomeButNotEveryPageItemSelectedSig: Signal<boolean>;

  private selectAllItems = false;

  /**
   * @constructor
   */
  constructor() {
    this.isEveryPageItemSelectedSig = computed(() => this.items()?.every(item => item.isSelected) ?? false);

    this.isSomeButNotEveryPageItemSelectedSig = computed(() => {
      let someItemSelected = false;
      let everyItemSelected = true;

      const currentItems = this.items();

      if (currentItems) {
        for (let i = 0; i < currentItems.length && (!someItemSelected || everyItemSelected); i++) {
          if (currentItems[i].isSelected) {
            someItemSelected = true;
          } else {
            everyItemSelected = false;
          }
        }
      } else {
        everyItemSelected = false;
      }

      return someItemSelected && !everyItemSelected;
    });
  }

  /**
   *
   */
  public ngOnInit(): void {
    this.itemsDisplayed = signal<IListItem[]>(this.items?.() ?? []);
  }

  /**
   * Toggle the `isSelected` state of all item in the `items` array.
   */
  public toggleAllItemsIsSelected(): void {
    this.selectAllItems = !this.selectAllItems;

    this.items.update(items => {
      items?.forEach(item => item.isSelected = this.selectAllItems);

      return items ? [...items] : null;
    });
  }

  /**
   * Toggle the `isSelected` state of an item in the `items` array at the specified index.
   *
   * @param itemIndex The index of the item in the `items` array to toggle.
   */
  public toggleItemIsSelectedAtIndex(itemIndex: number): void {
    this.items.update(items => {
      if (items) {
        items[itemIndex].isSelected = !items[itemIndex].isSelected;
      }

      return items ? [...items] : null;
    });
  }
}
