import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { IAreaElement } from '@newroom-connect/library/interfaces';
import { FileHelper, ArrayHelper } from '@newroom-connect/library/helpers';

import { LoggingService } from '../logging/logging.service';

import { AbstractCacheService } from './abstract-cache.service';

@Injectable({
  providedIn: 'root'
})
export class AreaElementCacheService extends AbstractCacheService {
  constructor(
    protected override readonly httpClient: HttpClient,
    protected override readonly swUpdate: SwUpdate | null,
    protected override readonly logger: LoggingService
  ) {
    super(httpClient, swUpdate, logger);
    // Custom database name for area element cache
    Object.defineProperty(this, 'DB_NAME', { value: 'areaElementCache' });
  }

  /**
   * Prepares area element files for preloading with priority levels.
   *
   * @param areaElement The area element containing files to preload.
   * @param apiBaseUrl The base URL for API requests.
   *
   * @returns Array of URLs to preload, prioritized backgrounds first.
   */
  public override prepareItemsForPreload(areaElement: IAreaElement, apiBaseUrl: string): { url: string; priority: 'high' | 'normal' }[] {
    if (!areaElement) {
      return [];
    }

    const itemsToPreload: { url: string; priority: 'high' | 'normal' }[] = [];
    const processedUrls = new Set<string>();

    this.processAreaElementBackgroundFiles(areaElement, apiBaseUrl, processedUrls, itemsToPreload);
    this.processAreaElementMediaFiles(areaElement, apiBaseUrl, processedUrls, itemsToPreload);

    // Sort by priority (high priority first)
    return itemsToPreload.sort((a, b) => {
      if (a.priority === 'high' && b.priority !== 'high') {
        return -1;
      }
      if (a.priority !== 'high' && b.priority === 'high') {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Process area element background files for preloading.
   *
   * @param areaElement The area element containing translations with background files.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processAreaElementBackgroundFiles(areaElement: IAreaElement, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    if (!ArrayHelper.isNotEmpty(areaElement.translations)) {
      return;
    }

    for (const translation of areaElement.translations) {
      if (!translation.backgroundFile) {
        continue;
      }

      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.backgroundFile);

      if (!url || processedUrls.has(url)) {
        continue;
      }

      processedUrls.add(url);
      itemsToPreload.push({ url, priority: 'high' });
    }
  }

  /**
   * Process area element media files for preloading.
   *
   * @param areaElement The area element containing translations with media files.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processAreaElementMediaFiles(areaElement: IAreaElement, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    if (!ArrayHelper.isNotEmpty(areaElement.translations)) {
      return;
    }

    for (const translation of areaElement.translations) {
      // Check for media files in the translation
      this.processMediaFiles(translation, apiBaseUrl, processedUrls, itemsToPreload);
    }
  }

  /**
   * Process media files from a translation object.
   *
   * @param translation The translation object to check for media files.
   * @param apiBaseUrl The base URL for API requests.
   * @param processedUrls Set of already processed URLs.
   * @param itemsToPreload Array to store files for preloading.
   */
  private processMediaFiles(translation: any, apiBaseUrl: string, processedUrls: Set<string>, itemsToPreload: { url: string; priority: 'high' | 'normal' }[]): void {
    // Process image files
    if (translation.imageFile) {
      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.imageFile);

      if (url && !processedUrls.has(url)) {
        processedUrls.add(url);
        itemsToPreload.push({ url, priority: 'normal' });
      }
    }

    // Process video files
    if (translation.videoFile) {
      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.videoFile);

      if (url && !processedUrls.has(url)) {
        processedUrls.add(url);
        itemsToPreload.push({ url, priority: 'normal' });
      }
    }

    // Process audio files
    if (translation.audioFile) {
      const url = FileHelper.getFileSourceURI(apiBaseUrl, translation.audioFile);

      if (url && !processedUrls.has(url)) {
        processedUrls.add(url);
        itemsToPreload.push({ url, priority: 'normal' });
      }
    }
  }
}
