import { ButtonView, Editor, Plugin } from 'ckeditor5';

export default class FileUploadPlugin extends Plugin {
  /**
   * @constructor
   *
   * @param editor
   */
  constructor(editor: Editor) {
    super(editor);
  }

  /**
   *
   */
  public init(): void {
    const editor = this.editor;

    editor.ui.componentFactory.add('fileLibrary', () =>
      this.generateFileLibraryButton(editor)
    );

    editor.ui.componentFactory.add('uploadButton', () =>
      this.generateUploadButton(editor)
    );
  }

  /**
   *
   * @param editor
   *
   * @returns
   */
  private generateFileLibraryButton(editor: Editor): ButtonView {
    const button = new ButtonView();

    button.set({
      label: 'File Library',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.066 28.066" ><path id="image" d="M28.066,24.948V3.118A3.128,3.128,0,0,0,24.948,0H3.118A3.128,3.128,0,0,0,0,3.118V24.948a3.128,3.128,0,0,0,3.118,3.118H24.948a3.128,3.128,0,0,0,3.118-3.118M8.576,16.372l3.9,4.693,5.457-7.032,7.017,9.355H3.118Z"/></svg>',
      withText: true
    });

    button.on('execute', () => editor.fire('fileLibraryButtonClickedEvent'));

    return button;
  }

  /**
   *
   * @param editor
   *
   * @returns
   */
  private generateUploadButton(editor: Editor): ButtonView {
    const button = new ButtonView();

    button.set({
      label: 'Upload',
      icon: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.655 15.366"><path id="upload" d="M0,15.366H12.655V13.558H0ZM6.327,0,0,6.327H3.615v5.424H9.039V6.327h3.616Z"/></svg>',
      withText: true
    });

    button.on('execute', () => editor.fire('uploadButtonClickedEvent'));

    return button;
  }
}
