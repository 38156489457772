<div class="flex flex-col w-full">
  <div
    class="flex items-center"
    [ngClass]="{
      'justify-between': label,
      'justify-end': !label
    }"
  >
    @if (icon || label) {
      <div class="flex items-center justify-start space-x-3 grow">
        @if (icon) {
          <nrc-icon
            class="w-4 h-4"
            [name]="icon"
          />
        }

        @if (label) {
          <span class="font-light text-white/70">
            {{ label }}
          </span>
        }
      </div>
    }

    <div class="flex space-x-2">
      @if (actionButtons | isNonEmptyArray) {
        @for (actionButton of actionButtons; track $index) {
          <nrc-button
            class="relative w-6 h-6"
            [icon]="actionButton.icon"
            [buttonType]="buttonType.ICON"
            [role]="actionRole.TRANSPARENT_SECONDARY"
            (clickEvent)="actionButtonClicked.emit(actionButton)"
          />
        }
      }
      <nrc-button
        class="relative flex w-6 h-6 transition-transform duration-250"
        [ngClass]="{
          'rotate-90': isCollapsed,
          '-rotate-90': !isCollapsed
        }"
        [icon]="'arrow-back'"
        [buttonType]="buttonType.ICON"
        [role]="actionRole.TRANSPARENT_SECONDARY"
        [disabled]="disabled"
        (clickEvent)="handleToggleCollapse()"
      />
    </div>
  </div>

  <div
    class="overflow-hidden transition-margin-top duration-250"
    [ngClass]="{
      'mt-0': isCollapsed,
      'mt-3': !isCollapsed && !disabled
    }"
    [@collapseAnimation]="{
      value: isCollapsed || disabled ? 'closed' : 'open',
      params: {
        height: isCollapsed && collapseDirection ===  'vertical' ? '0' : '*',
        width:  isCollapsed && collapseDirection ===  'horizontal' ? '0' : '*'
      }
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
