import { Pipe, PipeTransform } from '@angular/core';
import { FileHelper } from '@newroom-connect/library/helpers';

@Pipe({
  standalone: true,
  name: 'toFileSize'
})
export class ToFileSizePipe implements PipeTransform {
  /**
   * Converts a given number of bytes into a file size string.
   *
   * @param bytes
   * @param decimals
   *
   * @returns
   */
  public transform(bytes = 0, decimals = 2): string {
    return FileHelper.toFileSizeString(bytes, decimals);
  }
}
