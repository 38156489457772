<div class="relative flex flex-col w-full h-full justify-center items-center">
  @if (isLoading) {
    <div class="absolute w-full h-full top-0 left-0 bg-white/10 z-50">
      <nrc-loading />
    </div>
  }

  <!-- Container for an item list -->
  @if ((!isLoading && ((items() | isNonEmptyArray)) || !emptyListConfig)) {
    @switch (layout) {
      @case ('grid') {
        <nrc-grid-list
          class="grow w-full flex flex-col overflow-x-auto"
          [items]="items"
          [config]="config"
          [pageSize]="pageSize"
          (itemActionButtonEvent)="itemActionButtonEvent.emit($event)"
          (itemSelectionToggleEvent)="itemSelectionToggleEvent.emit($event)"
          (gridItemClickedEvent)="listItemClickedEvent.emit($event)"
        />
      }
      @case ('rows') {
        <nrc-row-list
          class="grow w-full flex flex-col shrink-1 overflow-x-auto"
          [items]="items"
          [config]="config"
          [pageSize]="pageSize"
          (sortEvent)="lastSortEvent = $event; emitListOptionsEvent();"
          (rowClickedEvent)="listItemClickedEvent.emit($event)"
          (itemActionButtonEvent)="itemActionButtonEvent.emit($event)"
        />
      }
      @default {
        <div>Unable to display list</div>
      }
    }

    <!--- Footer -->
    <div class="w-full my-2 p-4 inline-flex flex-wrap gap-3 justify-between items-center bottom-0 border-white/10 border-t-1 border-solid">
      <div class="flex items-center space-x-4">
        @for (actionButton of config.actionButtons; track actionButton.id) {
          <nrc-button
            class="relative w-10 h-10"
            [icon]="actionButton?.icon"
            [label]="actionButton?.label"
            [buttonType]="actionButton?.buttonType"
            [role]="actionButton?.role"
            [tooltipText]="actionButton?.tooltipText"
            (clickEvent)="actionButtonEvent.emit({ value: items(), actionButton })"
          />
        }

        @if (config.selectMultiple) {
          <span class="text-xs text-[#898989]">
            {{ itemsSelectedText() }}
          </span>
        }
      </div>

      <nrc-list-paginator
        [items]="items"
        [meta]="meta"
        [overflowParentElement]="listPaginatorOverflowParentElement"
        (paginationEvent)="lastPaginationEvent = $event; emitListOptionsEvent();"
      />
    </div>
  }

  @if (!isLoading && emptyListConfig && (items() | isEmptyArray)) {
    <nrc-empty-list
      [headline]="emptyListConfig.headline"
      [content]="emptyListConfig.content"
      [buttonLabel]="emptyListConfig.actionLabel"
      (buttonClickEvent)="emptyListActionEvent.emit()"
    />
  }
</div>
