import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'startsWith'
})
export class StartsWithPipe implements PipeTransform {
  /**
   *
   * @param value
   * @param searchString
   *
   * @returns
   */
  public transform(value: string, searchString: string): boolean {
    return value.startsWith(searchString);
  }
}
