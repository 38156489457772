import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, EffectRef, ElementRef, EventEmitter, HostListener, Injector, Input, Output, QueryList, ViewChild, ViewChildren, effect, inject, runInInjectionContext, signal } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { IFileUploadItem, IFileUploadItemStatus } from '@newroom-connect/library/interfaces';
import { JoinPipe, TranslatePipe } from '@newroom-connect/library/pipes';

import { DestroyableComponent } from '../../abstract/destroyable/destroyable.component';
import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { LoadingSpinnerComponent } from '../../loading/loading-spinner/loading-spinner.component';
import { ProgressBarComponent } from '../../progress/progress-bar/progress-bar.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    ButtonComponent,
    ProgressBarComponent,
    LoadingSpinnerComponent,
    JoinPipe,
    TranslatePipe
  ],
  selector: 'nrc-file-uploader',
  templateUrl: './file-uploader.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploaderComponent extends DestroyableComponent implements AfterViewInit {
  @ViewChild('fileDropInput') public fileDropInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('fileListContainer') public fileListContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChildren('fileListItems') public fileListItemsRef!: QueryList<ElementRef<HTMLDivElement>>;

  @Input() public fileSizeLimit = 536900000; // Default of 4 GB as the size limit of files to add.
  @Input() public acceptableMimeTypes?: string[];
  @Input() public isMultipleUploadEnabled = true;

  @Output() public filesChangedEvent = new EventEmitter<IFileUploadItem[]>();
  @Output() public fileSizeLimitExceededEvent = new EventEmitter<File>(); // Emit the file which exceeds the size limit.

  // A list of files to upload. Can be inserted via drag-and-drop or file selection from the local device.
  public fileList: IFileUploadItem[] = [];

  public isDraggedOver = false;

  private fileListChanged$ = new Subject<IFileUploadItem[]>();

  private fileListItemStatusEffectRefs: EffectRef[] = [];

  private injector = inject(Injector);

  /**
   *
   * @param event
   */
  @HostListener('dragover', ['$event'])
  public onDragOver(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isDraggedOver = true;
  }

  /**
   *
   * @param event
   */
  @HostListener('dragleave', ['$event'])
  public onDragLeave(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.isDraggedOver = false;
  }

  /**
   *
   * @param event
   */
  @HostListener('drop', ['$event'])
  public onDrop(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.handleFiles(event);

    this.isDraggedOver = false;
  }

  /**
   *
   */
  public ngAfterViewInit(): void {
    this.fileListItemsRef.changes.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.fileList = this.removeFileSizeExceededFilesFromFileList();

      // Reset the file list status effect list before updating it.
      this.fileListItemStatusEffectRefs.forEach(effectRef => effectRef.destroy());
      this.fileListItemStatusEffectRefs = [];

      // Setup the effects for scrolling to the file which status changed to 'in-progress', when no other file is already in progress.
      this.fileList.forEach((file, fileListIndex) => {
        runInInjectionContext(this.injector, () => {
          this.fileListItemStatusEffectRefs.push(effect(() => {
            const isAnotherFileInProgress = this.fileList.some((file, fileListIndexAnotherFile) => file.status() === 'in-progress' && fileListIndexAnotherFile !== fileListIndex);

            if (file.status() === 'in-progress' && !isAnotherFileInProgress) {
              this.fileListItemsRef.toArray()[fileListIndex].nativeElement.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
            }
          }));
        });
      });

      this.filesChangedEvent.emit(this.fileList);
    });
  }

  /**
   * Handle the files inserted into file input via select or drag-and-drop.
   *
   * @param event The file input event to handle.
   */
  public handleFiles(event: Event): void {
    // Extract the inserted files coming from either the browser select or the drag event.
    const files = (event as DragEvent).dataTransfer?.files ?? (event.target as HTMLInputElement)?.files;

    if (files && !ArrayHelper.isNotEmpty(files)) {
      const fileArray = Array.from(files).map(file => ({
        file,
        progress: signal<number>(0),
        status: signal<IFileUploadItemStatus>('not-started'),
        error: signal<string | null>(null)
      }));

      if (!this.isMultipleUploadEnabled) {
        this.fileList = [fileArray[0]];
      } else {
        this.fileList.push(...fileArray);
      }
    }

    this.fileListChanged$.next(this.fileList);
  }

  /**
   * Remove the file with the given index from the current file list.
   *
   * @param fileIndex The index of the file to remove from the current file list.
   */
  public removeFileFromList(fileIndex: number): void {
    this.removeFileFromFileDropInput(fileIndex);

    this.fileList.splice(fileIndex, 1);

    this.filesChangedEvent.emit(this.fileList);
  }

  /**
   *
   * @param fileIndex
   */
  private removeFileFromFileDropInput(fileIndex: number): void {
    const dataTransfer = new DataTransfer();

    if (!this.fileDropInputRef.nativeElement.files) {
      return;
    }

    for (let i = 0; i < this.fileDropInputRef.nativeElement.files.length; i++) {
      if (i !== fileIndex) {
        dataTransfer.items.add(this.fileDropInputRef.nativeElement.files.item(i) as File);
      }
    }

    this.fileDropInputRef.nativeElement.files = dataTransfer.files;
  }

  /**
   * Remove all files from a file list exceeding the specified file size limit.
   *
   * @returns
   */
  private removeFileSizeExceededFilesFromFileList(): IFileUploadItem[] {
    const fileListNew: IFileUploadItem[] = [];

    for (let i = 0; i < this.fileList.length; i++) {
      if (this.fileList[i].file.size <= this.fileSizeLimit) {
        fileListNew.push(this.fileList[i]);
      } else {
        this.removeFileFromFileDropInput(i);

        this.fileSizeLimitExceededEvent.emit(this.fileList[i].file);
      }
    }

    return fileListNew;
  }
}
