import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, forwardRef, signal } from '@angular/core';
import { NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { IsNonEmptyArrayPipe, HasSetValuePipe, ToArrayPipe, FormControlPipe } from '@newroom-connect/library/pipes';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { fadeInOutAnimation, growAnimation } from '@newroom-connect/library/animations';
import { OverflowTargetDirective } from '@newroom-connect/library/directives';

import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { LanguageComponent } from '../../language/language.component';
import { LoadingComponent } from '../../loading/loading.component';
import { InputComponent } from '../input.component';

export enum InputLanguageSelectorComponentType {
  REGULAR = 'REGULAR',
  LABELED = 'LABELED'
}

export enum InputLanguageSelectorTextSize {
  SMALL = 'SMALL',
  REGULAR = 'REGULAR'
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LanguageComponent,
    LoadingComponent,
    ButtonComponent,
    IconComponent,
    IsNonEmptyArrayPipe,
    HasSetValuePipe,
    ToArrayPipe,
    FormControlPipe,
    OverflowTargetDirective
  ],
  selector: 'nrc-input-language-selector',
  templateUrl: './input-language-selector.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [growAnimation, fadeInOutAnimation],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputLanguageSelectorComponent),
      multi: true
    }
  ]
})
export class InputLanguageSelectorComponent extends InputComponent implements OnInit, OnChanges {
  @Input() public override label = '';
  @Input() public options: string[] = [];
  @Input() public invalidItems: string[] = [];
  @Input() public type: InputLanguageSelectorComponentType = InputLanguageSelectorComponentType.REGULAR;
  @Input() public value: string | null = null;
  @Input() public overflowElement?: HTMLElement;
  @Input() public textSize = InputLanguageSelectorTextSize.REGULAR;
  @Input() public borderClass = 'shadow border-b-1';

  @Output() public languageChanged = new EventEmitter<string>();

  public showOptions = signal<boolean>(false);
  public selectedLanguage = signal<string | null>(null);
  public filteredOptions = new Set<string>([]);

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public languageSelectorType = InputLanguageSelectorComponentType;
  public languageSelectorTextSize = InputLanguageSelectorTextSize;

  /**
   * @constructor
   */
  constructor() {
    super();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControl']?.firstChange) {
      // Set the selected options based on the given form control value.
      this.selectLanguage(this.formControl.value);
    }

    if (this.value && changes['value']) {
      this.selectLanguage(changes['value'].currentValue);
    }
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    this.selectedLanguage.set(this.formControl.value);
  }

  /**
   * Toggles the visibility of language options by setting the showOptions signal.
   */
  public toggleOptions(): void {
    this.showOptions.set(!this.showOptions());
  }

  /**
   * Updates the selected language, filters the available options, and optionally collapses the options list.
   *
   * @param option The language option to select.
   * @param collapseOptions Collapses the options list after selection.
   *
   */
  public selectLanguage(option: string, collapseOptions = false): void {
    this.selectedLanguage.set(option);
    this.filteredOptions = new Set(this.options.filter((value) => value !== option));

    // Call the value accessor to update the form control value
    this.writeValue(option);
    this.languageChanged.emit(option);

    if (collapseOptions) {
      this.showOptions.set(false);
    }
  }
}
