<div
  class="w-full max-w-xs h-full flex justify-between items-center p-4 space-x-2 border-0 md:border-2 rounded-[10px] bg-transparent-dark backdrop-blur-md shadow tracking-wide"
  [ngClass]="{
    'border-transparent': !toastMessage || toastMessage.level === level.INFO,
    'border-success': toastMessage?.level === level.SUCCESS,
    'border-error': toastMessage?.level === level.ERROR,
    'border-warning': toastMessage?.level === level.WARN
  }"
>
  <div class="text-sm text-white font-light" [innerHTML]="toastMessage?.message"></div>

  <button class="w-4 h-4" (click)="resetToastMessage()">
    <nrc-icon class="opacity-50 hover:opacity-100" [classList]="['rotate-45']" [name]="'plus'" />
  </button>
</div>
