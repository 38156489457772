import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IActionButtonEvent, IListItemClickedEvent, IListOptionsSort, ListOptionsSortDirection, ListItemType, IListConfiguration, IListConfigurationColumn } from '@newroom-connect/library/interfaces';
import { ExtractPropertyPipe, ToStringPipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../../buttons/button/button.component';
import { IconComponent } from '../../icon/icon.component';
import { BaseListComponent } from '../abstract/base-list.component';

import { RowListItemComponent } from './row-list-item/row-list-item.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    IconComponent,
    RowListItemComponent,
    ExtractPropertyPipe,
    ToStringPipe
  ],
  selector: 'nrc-row-list',
  templateUrl: './row-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RowListComponent extends BaseListComponent {
  @Input({ required: true }) public config!: IListConfiguration;

  @Output() public sortEvent = new EventEmitter<IListOptionsSort>();
  @Output() public rowClickedEvent = new EventEmitter<IListItemClickedEvent>();
  @Output() public itemActionButtonEvent = new EventEmitter<IActionButtonEvent>();

  public listItemType = ListItemType;
  public actionRole = ActionRole;
  public buttonType = ButtonType;

  /**
   * Build a sort event by the given key and direction which can be passed to the API.
   *
   * @param sortEventRaw The raw sort event object.
   * @param sortEventRaw.key The key to sort the data by.
   * @param sortEventRaw.direction The direction to sort the data by.
   *
   * @returns An object for the sort event.
   */
  private static buildSortEvent(sortEventRaw: { key: string; direction: ListOptionsSortDirection; }): IListOptionsSort {
    const sortEvent: IListOptionsSort = {};

    const keyParts = sortEventRaw.key.split('.');

    let sortEventPart: IListOptionsSort = sortEvent;

    for (let i = 0; i < keyParts.length; i++) {
      sortEventPart[keyParts[i]] = i < keyParts.length -1 ? {} : sortEventRaw.direction;

      sortEventPart = i < keyParts.length -1 ? sortEventPart[keyParts[i]] as IListOptionsSort : sortEventPart;
    }

    return sortEvent;
  }

  /**
   * Order the list by the given column and direction.
   *
   * @param column The column to order the list by.
   * @param direction The direction to order the list by.
   */
  public orderByColumn(column: IListConfigurationColumn, direction: ListOptionsSortDirection): void {
    this.resetColumnsOrderByState();

    column.orderBy = column.orderBy === direction ? undefined : direction;

    this.sortEvent.emit(RowListComponent.buildSortEvent({ key: column.key, direction }));
  }

  /**
   * Reset the order by state of all columns in the given config.
   */
  private resetColumnsOrderByState(): void {
    this.config.columns.forEach(column => column.orderBy = undefined);
  }
}
