import { trigger, style, transition, animate } from '@angular/animations';

/**
 * This animation is used to animate the width and position of a line.
 *
 * @param widthStart The initial width of the line.
 * @param widthEnd The final width of the line.
 * @param direction The direction of the line  '0%' (for left), '100%' (for right) or 50% (for center).
 */
export const lineMotionAnimation = trigger('lineMotionAnimation', [
  transition(':enter', [
    style({
      transform: 'scaleX(0)',
      transformOrigin: '{{ direction }}'
    }),
    animate('0.25s ease-out', style({ transform: 'scaleX(1)' }))
  ], { params: { direction: '0%' } }),
  transition(':leave', [
    animate('0.25s ease-in', style({ transform: 'scaleX(0)' }))
  ], { params: { direction: '0%' } })
]);
