import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IAnalyticsProviderPreset, IListOptions, IAPIListResponse } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectAnalyticsService extends EntityService {
  protected searchableProperties: string[] = []; // Empty, since we don't want to search for anything.

  /**
   * Get all supported analytics providers presets.
   *
   * @param options
   * @returns Analytics provider presets.
   */
  public getProjectAnalyticsPresets(options?: IListOptions): Observable<IAPIListResponse<IAnalyticsProviderPreset>> {
    return super.list<IAnalyticsProviderPreset>('presets/projects/analytics-providers', undefined, options);
  }
}
