import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { growAnimation, fadeInOutAnimation } from '@newroom-connect/library/animations';

import { IconComponent } from '../icon/icon.component';
import { ChipComponent } from '../chip/chip.component';

export interface SearchFilter {
  searchTerm?: string;
  tags?: string[];
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent,
    ChipComponent
  ],
  selector: 'nrc-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [growAnimation, fadeInOutAnimation]
})
export class SearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public placeholder = '';
  @Input() public debounceTime = 500;
  @Input() public isCollapsible = true;
  @Input() public availableTags: string[] = [];
  @Input() public filterEnabled = false;

  @Output() public searchEvent = new EventEmitter<string>();
  @Output() public filterChange = new EventEmitter<SearchFilter>();
  public searchFormGroup = new FormGroup({
    searchInput: new FormControl<string>(''),
    tagSelect: new FormControl<string[]>([])
  });

  @ViewChild('searchContainer', { static: false }) public searchContainerRef!: ElementRef<HTMLDivElement>;

  public searchEnabled = signal<boolean>(false);
  public isFilterOpen = signal<boolean>(false);
  public selectedTags = signal<string[]>([]);
  public tagInputControl = new FormControl('');
  public showTagSelect = signal<boolean>(false);

  public readonly buttonType = ButtonType;
  public readonly actionRole = ActionRole;

  private destroy$ = new Subject<void>();

  public filteredTags = signal<string[]>([]);
  public showTagOptions = signal<boolean>(false);
  public tagSearchControl = new FormControl('');

  /**
   * Listen to click events on the document and close the datepicker if click is not in the component.
   *
   * @param event
   */
  @HostListener('document:click', ['$event'])
  public clickOutside(event: any): void {
    if (this.searchContainerRef && event.target !== this.searchContainerRef.nativeElement && !this.searchContainerRef.nativeElement.contains(event.target)) {
      this.isFilterOpen.set(false);
    }
  }
  /**
   *
   */
  public ngOnInit(): void {
    this.searchFormGroup.controls.searchInput.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(searchTerm => this.emitSearch(searchTerm ?? ''));

    this.searchFormGroup.controls.tagSelect.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(tags => {
      if (tags) {
        this.selectedTags.set(tags);
        this.emitFilterChange();
      }
    });

    this.tagSearchControl.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe(searchTerm => {
      if (searchTerm) {
        this.filteredTags.set(
          this.availableTags.filter(tag =>
            tag.toLowerCase().includes(searchTerm.toLowerCase()) &&
            !this.selectedTags().includes(tag)
          )
        );
      } else {
        this.filteredTags.set(
          this.availableTags.filter(tag => !this.selectedTags().includes(tag))
        );
      }
    });
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isCollapsible'] && !changes['isCollapsible'].currentValue) {
      this.searchEnabled.set(true);
    }

    if (changes['availableTags']) {
      this.filteredTags.set(this.availableTags);
    }
  }

  /**
   * Toggle the `enabled` state of the search.
   */
  public toggleSearchEnabled(): void {
    this.searchEnabled.update(searchEnabled => !searchEnabled);
  }

  /**
   * Reset the value of the search input form control.
   */
  public resetSearchInput(): void {
    this.searchFormGroup.controls.searchInput.setValue('');
    this.searchFormGroup.controls.tagSelect.setValue([]);
    this.selectedTags.set([]);
    this.emitFilterChange();
    this.emitSearch('');
  }

  public toggleFilter(): void {
    this.isFilterOpen.update(value => !value);
  }

  public addTag(tag: string | null): void {
    if (!tag?.trim()) {
      return;
    }

    const normalizedTag = tag.trim().toLowerCase();

    this.selectedTags.update(tags => {
      if (!tags.includes(normalizedTag)) {
        const newTags = [...tags, normalizedTag];

        this.emitFilterChange();
        return newTags;
      }
      return tags;
    });

    this.tagInputControl.reset();
  }

  public removeTag(tagToRemove: string): void {
    this.selectedTags.update(tags => {
      const newTags = tags.filter(tag => tag !== tagToRemove);

      this.searchFormGroup.controls.tagSelect.setValue(newTags);
      this.emitFilterChange();
      return newTags;
    });
  }

  private emitSearch(searchTerm: string): void {
    this.searchEvent.emit(searchTerm);
    this.emitFilterChange();
  }

  private emitFilterChange(): void {
    const searchFilter: SearchFilter = {
      searchTerm: this.searchFormGroup.controls.searchInput.value ?? '',
      tags: this.selectedTags()
    };

    this.filterChange.emit(searchFilter);
  }

  public onTagInputKeyup(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ',') {
      event.preventDefault();
      this.addTag(this.tagInputControl.value);
    }
  }

  public toggleTagSelect(): void {
    this.showTagOptions.update(value => {
      if (!value) {
        this.filteredTags.set(
          this.availableTags.filter(tag => !this.selectedTags().includes(tag))
        );
        this.tagSearchControl.setValue('');
      }
      return !value;
    });
  }

  public selectTag(tag: string): void {
    const currentTags = this.selectedTags();

    if (!currentTags.includes(tag)) {
      const newTags = [...currentTags, tag];

      this.selectedTags.set(newTags);
      this.searchFormGroup.controls.tagSelect.setValue(newTags);

      this.filteredTags.set(
        this.availableTags.filter(t =>
          !newTags.includes(t) &&
          (this.tagSearchControl.value ?
            t.toLowerCase().includes(this.tagSearchControl.value.toLowerCase()) :
            true
          )
        )
      );
    }
  }
}
