<ng-template #fileLibraryContent>
  @if (showFileLibrarySig()) {
    <nrc-file-library-modal-search
      class="flex w-full grow overflow-auto"
      [isFileListLoading]="isFileListLoading"
      [fileListItemsSig]="fileListItemsSig"
      [metaSig]="metaSig"
      (fileListEvent)="fileListEvent.emit($event)"
      (fileSearchEvent)="fileSearchEvent.emit($event)"
      (fileSelectedEvent)="currentFile = $event.item?.value"
    />
  } @else {
    <nrc-file-library-modal-selector
      class="w-full grow overflow-auto"
      [currentFile]="currentFile"
      [acceptableMimeTypes]="acceptableMimeTypes"
      (showFileLibraryEvent)="toggleShowFileLibrary()"
      (fileUploadEvent)="fileUploadEvent.emit($event)"
    />
  }

  <hr class="h-[1.5px] my-6 bg-white/10 border-none">

  <!-- The footer - includes button to pass current item on close of the modal -->
  <nrc-file-library-modal-footer
    class="w-full h-10"
    [showFileLibrary]="showFileLibrarySig()"
    [hideSelection]="hideSelection"
    (backEvent)="toggleShowFileLibrary()"
    (closeEvent)="closeModal(currentFile)"
  />
</ng-template>
