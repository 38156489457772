import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { IAPIListResponse } from '@newroom-connect/library/interfaces';
import { Level } from '@newroom-connect/library/enums';

import { AlertService } from '../alert/alert.service';
import { TranslationService } from '../translation/translation.service';

@Injectable({
  providedIn: 'root'
})
export abstract class EntityErrorHandlerService {
  private alertService = inject(AlertService);
  private translationService = inject(TranslationService);

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleListError(errorKey: string): Observable<IAPIListResponse<unknown>> {
    this.displayAlertErrorMessage(errorKey);

    return of({ data: [] });
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleGetError(errorKey: string): Observable<null> {
    this.displayAlertErrorMessage(errorKey);

    return of(null);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleCreateError(errorKey: string): Observable<null> {
    this.displayAlertErrorMessage(errorKey);

    return of(null);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleUpdateError(errorKey: string): Observable<null> {
    this.displayAlertErrorMessage(errorKey);

    return of(null);
  }

  /**
   *
   * @param errorKey
   *
   * @returns
   */
  public handleDeleteError(errorKey: string): Observable<null> {
    this.displayAlertErrorMessage(errorKey);

    return of(null);
  }

  /**
   *
   * @param errorKey
   */
  protected displayAlertErrorMessage(errorKey: string): void {
    this.alertService.setMessage({
      message: this.translationService.translate(`${errorKey}.MESSAGE`),
      headline: this.translationService.translate(`${errorKey}.HEADLINE`),
      level: Level.ERROR
    });
  }
}
