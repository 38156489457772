import { Injectable, Optional } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, map } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { LoggingService } from '../logging/logging.service';

export interface CacheMetadata {
  url: string;
  timestamp: number;
  size: number;
  type: string;
  expiresAt: number;
}

export interface PreloadingStatus {
  total: number;
  loaded: number;
  progress: number;
}

@Injectable()
export abstract class AbstractCacheService {
  protected readonly loadedItems: Set<string> = new Set();
  protected readonly loadingInProgress: Set<string> = new Set();
  protected readonly _preloadingStatus = new BehaviorSubject<PreloadingStatus>({
    total: 0,
    loaded: 0,
    progress: 0
  });

  public readonly preloadingStatus$ = this._preloadingStatus.asObservable();

  // Database configuration
  protected readonly DB_NAME = 'itemCache';
  protected readonly STORE_NAME = 'items';
  protected readonly META_STORE_NAME = 'metadata';
  protected readonly DB_VERSION = 1;
  protected readonly CACHE_EXPIRATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  protected readonly MAX_CACHE_SIZE = 500 * 1024 * 1024; // 500 MB

  protected db: IDBDatabase | null = null;
  protected preloadQueue: Set<string> = new Set();

  constructor(
    protected readonly httpClient: HttpClient,
    @Optional() protected readonly swUpdate: SwUpdate | null,
    protected readonly logger: LoggingService
  ) {
    this.initIndexedDB();
  }

  /**
   * Initializes the IndexedDB for item caching.
   *
   * @protected
   */
  protected initIndexedDB(): void {
    const request = indexedDB.open(this.DB_NAME, this.DB_VERSION);

    request.onerror = (event: Event): void => {
      this.logger.error('Failed to open IndexedDB', event);
    };

    request.onupgradeneeded = (event: Event): void => {
      const db = (event.target as IDBOpenDBRequest).result;

      // Create object store for items
      if (!db.objectStoreNames.contains(this.STORE_NAME)) {
        db.createObjectStore(this.STORE_NAME, { keyPath: 'url' });
      }

      // Create object store for metadata
      if (!db.objectStoreNames.contains(this.META_STORE_NAME)) {
        const metaStore = db.createObjectStore(this.META_STORE_NAME, { keyPath: 'url' });

        metaStore.createIndex('timestamp', 'timestamp', { unique: false });
        metaStore.createIndex('expiresAt', 'expiresAt', { unique: false });
      }
    };

    request.onsuccess = (event: Event): void => {
      this.db = (event.target as IDBOpenDBRequest).result;
      this.logger.debug('IndexedDB initialized successfully');

      // Clean up expired cache entries
      this.cleanupExpiredCache();
    };
  }

  /**
   * Cleans up expired cache entries and ensures cache size is within limits.
   *
   * @protected
   */
  protected cleanupExpiredCache(): void {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction([this.META_STORE_NAME], 'readwrite');
    const metaStore = transaction.objectStore(this.META_STORE_NAME);

    // Get all expired items
    const now = Date.now();
    const expiredIndex = metaStore.index('expiresAt');
    const expiredRange = IDBKeyRange.upperBound(now);
    const expiredRequest = expiredIndex.openCursor(expiredRange);

    const expiredUrls: string[] = [];

    expiredRequest.onsuccess = (event: Event): void => {
      const cursor = (event.target as IDBRequest).result;

      if (cursor) {
        expiredUrls.push(cursor.value.url);
        cursor.continue();
      } else if (expiredUrls.length > 0) {
        // Delete expired files
        this.deleteItemsFromCache(expiredUrls);
      } else {
        // Check total cache size if no expired items found
        this.enforceMaxCacheSize();
      }
    };
  }

  /**
   * Enforces maximum cache size by deleting oldest entries when needed.
   *
   * @protected
   */
  protected enforceMaxCacheSize(): void {
    if (!this.db) {
      return;
    }

    const transaction = this.db.transaction([this.META_STORE_NAME], 'readonly');
    const metaStore = transaction.objectStore(this.META_STORE_NAME);
    const request = metaStore.getAll();

    request.onsuccess = (event: Event): void => {
      const metadata = (event.target as IDBRequest<CacheMetadata[]>).result;
      let totalSize = metadata.reduce((sum, item) => sum + (item.size || 0), 0);

      if (totalSize > this.MAX_CACHE_SIZE) {
        // Sort by timestamp (oldest first)
        metadata.sort((a, b) => a.timestamp - b.timestamp);

        const urlsToDelete: string[] = [];

        // Remove oldest files until we're under the size limit
        for (const item of metadata) {
          if (totalSize <= this.MAX_CACHE_SIZE) {
            break;
          }

          urlsToDelete.push(item.url);
          totalSize -= item.size || 0;
        }

        if (urlsToDelete.length > 0) {
          this.deleteItemsFromCache(urlsToDelete);
        }
      }
    };
  }

  /**
   * Deletes items from the cache.
   *
   * @param urls Array of URLs to delete.
   * @protected
   */
  protected deleteItemsFromCache(urls: string[]): void {
    if (!this.db || !urls.length) {
      return;
    }

    const transaction = this.db.transaction([this.STORE_NAME, this.META_STORE_NAME], 'readwrite');
    const store = transaction.objectStore(this.STORE_NAME);
    const metaStore = transaction.objectStore(this.META_STORE_NAME);

    urls.forEach(url => {
      store.delete(url);
      metaStore.delete(url);
      this.logger.debug(`Deleted cached item: ${url}`);
    });
  }

  /**
   * Saves an item to IndexedDB cache.
   *
   * @param url
   * @param data
   * @param type
   *
   * @returns Observable indicating success.
   */
  protected saveToCache(url: string, data: Blob | ArrayBuffer, type: string): Observable<boolean> {
    if (!this.db) {
      return of(false);
    }

    return new Observable(observer => {
      try {
        const transaction = this.db!.transaction([this.STORE_NAME, this.META_STORE_NAME], 'readwrite');

        transaction.oncomplete = (): void => {
          observer.next(true);
          observer.complete();
        };

        transaction.onerror = (event: Event): void => {
          this.logger.error(`Error caching item: ${url}`, event);
          observer.next(false);
          observer.complete();
        };

        // Store the item data
        const store = transaction.objectStore(this.STORE_NAME);
        const item = { url, data };

        store.put(item);

        // Store the metadata
        const metaStore = transaction.objectStore(this.META_STORE_NAME);
        const now = Date.now();
        const metadata: CacheMetadata = {
          url,
          timestamp: now,
          size: data instanceof Blob ? data.size : data.byteLength,
          type,
          expiresAt: now + this.CACHE_EXPIRATION
        };

        metaStore.put(metadata);
      } catch (error) {
        this.logger.error(`Failed to save item to cache: ${url}`, error);
        observer.next(false);
        observer.complete();
      }
    });
  }

  /**
   * Retrieves an item from the IndexedDB cache.
   *
   * @param url The URL of the item to retrieve.
   *
   * @returns Observable indicating success.
   *
   * @protected
   */
  protected getFromCache(url: string): Observable<Blob | null> {
    if (!this.db) {
      return of(null);
    }

    return new Observable(observer => {
      try {
        const transaction = this.db!.transaction([this.STORE_NAME, this.META_STORE_NAME], 'readonly');
        const store = transaction.objectStore(this.STORE_NAME);
        const metaStore = transaction.objectStore(this.META_STORE_NAME);

        // First check if the item has expired
        const metaRequest = metaStore.get(url);

        metaRequest.onsuccess = (event: Event): void => {
          const metadata = (event.target as IDBRequest<CacheMetadata>).result;

          if (!metadata || metadata.expiresAt < Date.now()) {
            // Item has expired or doesn't exist
            observer.next(null);
            observer.complete();
            return;
          }

          // Then get the item data
          const request = store.get(url);

          request.onsuccess = (event: Event): void => {
            const result = (event.target as IDBRequest).result;

            if (result) {
              // Update access timestamp
              const now = Date.now();
              const updatedMetadata = {
                ...metadata,
                timestamp: now,
                expiresAt: now + this.CACHE_EXPIRATION
              };

              const metaTransaction = this.db!.transaction([this.META_STORE_NAME], 'readwrite');

              metaTransaction.objectStore(this.META_STORE_NAME).put(updatedMetadata);

              // Return the item data
              const data = result.data;

              observer.next(data instanceof Blob ? data : new Blob([data], { type: metadata.type }));
            } else {
              observer.next(null);
            }
            observer.complete();
          };

          request.onerror = (): void => {
            observer.next(null);
            observer.complete();
          };
        };

        metaRequest.onerror = (): void => {
          observer.next(null);
          observer.complete();
        };
      } catch (error) {
        this.logger.error(`Failed to retrieve item from cache: ${url}`, error);
        observer.next(null);
        observer.complete();
      }
    });
  }

  /**
   * Loads a single item and caches it.
   *
   * @param url URL of the item to load.
   *
   * @returns Observable that completes when the item is loaded.
   *
   * @protected
   */
  protected preloadItem(url: string): Observable<boolean> {
    if (this.loadedItems.has(url)) {
      return of(true);
    }

    // First check if the item is already in the cache
    return this.getFromCache(url).pipe(
      switchMap(cachedItem => {
        if (cachedItem) {
          // Item was found in cache
          this.logger.debug(`Item loaded from cache: ${url}`);
          this.markItemAsLoaded(url);
          return of(true);
        }

        // Not in cache, load from network
        // Determine the correct method based on item type
        if (this.isImageUrl(url)) {
          return this.preloadImage(url);
        } else if (this.isVideoUrl(url)) {
          return this.preloadVideo(url);
        } else if (this.isAudioUrl(url)) {
          return this.preloadAudio(url);
        }
        // Use service worker or HttpClient for other item types
        return this.preloadWithServiceWorker(url);
      })
    );
  }

  /**
   * Processes the preload queue.
   *
   * @protected
   */
  protected processQueue(): void {
    if (!this.preloadQueue.size) {
      return;
    }

    // Take first item from queue
    const url = this.preloadQueue.values().next().value;

    this.preloadQueue.delete(url);

    this.preloadItem(url).pipe(
      finalize(() => {
        // Process next item in queue
        this.processQueue();
      })
    ).subscribe();
  }

  /**
   * Preloads an image file.
   *
   * @param url URL of the image to preload.
   *
   * @returns Observable that completes when the image is loaded.
   *
   * @protected
   */
  protected preloadImage(url: string): Observable<boolean> {
    return new Observable(observer => {
      const img = new Image();

      img.onload = (): void => {
        // Create a canvas to get the image data for caching
        const canvas = document.createElement('canvas');

        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
          ctx.drawImage(img, 0, 0);
          canvas.toBlob(blob => {
            if (blob) {
              // Cache the image blob
              this.saveToCache(url, blob, blob.type).subscribe();
            }
            this.markItemAsLoaded(url);
            observer.next(true);
            observer.complete();
          }, this.getMimeTypeFromUrl(url));
        } else {
          // If we can't get a canvas context, just mark as loaded
          this.markItemAsLoaded(url);
          observer.next(true);
          observer.complete();
        }
      };

      img.onerror = (error: string | Event): void => {
        this.logger.error(`Failed to preload image: ${url}`, error);
        this.loadingInProgress.delete(url);
        observer.next(false);
        observer.complete();
      };

      img.crossOrigin = 'anonymous'; // To enable canvas operations on the loaded image
      img.src = url;
    });
  }

  /**
   * Preloads a video file.
   *
   * @param url URL of the video to preload.
   *
   * @returns Observable that completes when the video is loaded.
   *
   * @protected
   */
  protected preloadVideo(url: string): Observable<boolean> {
    // For videos, first try to use fetch to get and cache the file
    return this.fetchAndCacheFile(url).pipe(
      switchMap((success: boolean) => {
        if (success) {
          return of(true);
        }

        // Fallback to video element if fetch fails
        return new Observable<boolean>(observer => {
          const video = document.createElement('video');

          video.preload = 'auto';

          video.onloadeddata = (): void => {
            this.markItemAsLoaded(url);
            observer.next(true);
            observer.complete();
          };

          video.onerror = (error: string | Event): void => {
            this.logger.error(`Failed to preload video: ${url}`, error);
            this.loadingInProgress.delete(url);
            observer.next(false);
            observer.complete();
          };

          video.src = url;
          video.load();
        });
      })
    );
  }

  /**
   * Preloads an audio file.
   *
   * @param url URL of the audio to preload.
   *
   * @returns Observable that completes when the audio is loaded.
   *
   * @protected
   */
  protected preloadAudio(url: string): Observable<boolean> {
    // For audio, first try to use fetch to get and cache the file
    return this.fetchAndCacheFile(url).pipe(
      switchMap((success: boolean) => {
        if (success) {
          return of(true);
        }

        // Fallback to audio element if fetch fails
        return new Observable<boolean>(observer => {
          const audio = new Audio();

          audio.onloadeddata = (): void => {
            this.markItemAsLoaded(url);
            observer.next(true);
            observer.complete();
          };

          audio.onerror = (error: string | Event): void => {
            this.logger.error(`Failed to preload audio: ${url}`, error);
            this.loadingInProgress.delete(url);
            observer.next(false);
            observer.complete();
          };

          audio.src = url;
          audio.load();
        });
      })
    );
  }

  /**
   * Fetches and caches a file using the Fetch API.
   *
   * @param url The URL of the file to fetch and cache.
   * @returns Observable indicating success.
   * @protected
   */
  protected fetchAndCacheFile(url: string): Observable<boolean> {
    return new Observable(observer => {
      fetch(url, { method: 'GET', credentials: 'include' })
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          return response.blob();
        })
        .then(blob => {
          // Save to cache
          this.saveToCache(url, blob, blob.type).subscribe(saved => {
            if (saved) {
              this.markItemAsLoaded(url);
              observer.next(true);
            } else {
              observer.next(false);
            }
            observer.complete();
          });
        })
        .catch(error => {
          this.logger.error(`Failed to fetch and cache file: ${url}`, error);
          observer.next(false);
          observer.complete();
        });
    });
  }

  /**
   * Preloads a file using HttpClient.
   *
   * @param url URL of the file to preload.
   *
   * @returns Observable that completes when the file is loaded.
   *
   * @protected
   */
  protected preloadWithHttpClient(url: string): Observable<boolean> {
    return this.httpClient.get(url, { responseType: 'blob' }).pipe(
      switchMap(blob => {
        // Save to cache
        return this.saveToCache(url, blob, blob.type).pipe(
          map(saved => {
            if (saved) {
              this.markItemAsLoaded(url);
            }
            return saved;
          })
        );
      }),
      catchError(error => {
        this.logger.error(`Failed to preload file: ${url}`, error);
        this.loadingInProgress.delete(url);
        return of(false);
      })
    );
  }

  /**
   * Preloads a file using Service Worker if available, falls back to HttpClient.
   *
   * @param url URL of the file to preload.
   *
   * @returns Observable that completes when the file is loaded.
   *
   * @protected
   */
  protected preloadWithServiceWorker(url: string): Observable<boolean> {
    if (this.swUpdate && this.swUpdate.isEnabled) {
      // Service Worker is available
      // Use fetch() API to add to Service Worker cache
      return this.fetchAndCacheFile(url);
    }

    // Fallback to HttpClient
    return this.preloadWithHttpClient(url);
  }

  /**
   * Gets MIME type from URL.
   *
   * @param url The URL to extract MIME type from.
   * @returns The MIME type string.
   * @protected
   */
  protected getMimeTypeFromUrl(url: string): string {
    const extension = url.split('.').pop()?.toLowerCase();

    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'webp':
        return 'image/webp';
      case 'svg':
        return 'image/svg+xml';
      case 'mp4':
        return 'video/mp4';
      case 'webm':
        return 'video/webm';
      case 'mp3':
        return 'audio/mpeg';
      case 'wav':
        return 'audio/wav';
      default:
        return 'application/octet-stream';
    }
  }

  /**
   * Marks an item as loaded and updates the preloading status.
   *
   * @param url
   *
   * @protected
   */
  protected markItemAsLoaded(url: string): void {
    this.loadedItems.add(url);
    this.loadingInProgress.delete(url);
    this.updatePreloadingStatus();
  }

  /**
   * Updates the preloading status with current progress.
   *
   * @protected
   */
  protected updatePreloadingStatus(): void {
    const total = this.loadedItems.size + this.loadingInProgress.size + this.preloadQueue.size;
    const loaded = this.loadedItems.size;
    const progress = total > 0 ? Math.round((loaded / total) * 100) : 100;

    this._preloadingStatus.next({
      total,
      loaded,
      progress
    });
  }

  /**
   * Checks if a URL points to an image file.
   *
   * @param url URL to check.
   * @returns Boolean indicating if the URL is an image.
   * @protected
   */
  protected isImageUrl(url: string): boolean {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.webp', '.svg', '.bmp'];

    return imageExtensions.some(ext => url.toLowerCase().endsWith(ext));
  }

  /**
   * Checks if a URL points to a video file.
   *
   * @param url URL to check.
   * @returns Boolean indicating if the URL is a video.
   * @protected
   */
  protected isVideoUrl(url: string): boolean {
    const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi'];

    return videoExtensions.some(ext => url.toLowerCase().endsWith(ext));
  }

  /**
   * Checks if a URL points to an audio file.
   *
   * @param url URL to check.
   * @returns Boolean indicating if the URL is an audio file.
   * @protected
   */
  protected isAudioUrl(url: string): boolean {
    const audioExtensions = ['.mp3', '.wav', '.ogg', '.aac'];

    return audioExtensions.some(ext => url.toLowerCase().endsWith(ext));
  }

  /**
   * Processes a list of items for preloading.
   *
   * @param urls Array of URLs to process.
   * @param isPriority Whether these items should be prioritized.
   * @returns Observable that completes when all items are processed.
   */
  protected processItemsForPreload(urls: string[], isPriority = false): Observable<boolean> {
    const itemsToLoad: string[] = [];

    for (const url of urls) {
      if (this.loadedItems.has(url) || this.loadingInProgress.has(url)) {
        continue;
      }

      itemsToLoad.push(url);
      this.loadingInProgress.add(url);
    }

    if (isPriority) {
      // Add to front of queue for priority loading
      for (const url of itemsToLoad.reverse()) {
        this.preloadQueue.delete(url); // Remove if exists
        // We use a temporary Set to maintain insertion order
        const newQueue = new Set<string>([url]);

        for (const existingUrl of this.preloadQueue) {
          newQueue.add(existingUrl);
        }

        this.preloadQueue = newQueue;
      }
    } else {
      // Add to end of queue for normal loading
      for (const url of itemsToLoad) {
        this.preloadQueue.add(url);
      }
    }

    this.updatePreloadingStatus();
    this.processQueue();

    return of(true);
  }

  /**
   * Preloads items from the provided URLs.
   *
   * @param items Array of item URLs or URL+priority objects to preload.
   * @param isPriority Whether these items should be prioritized over existing queue.
   *
   * @returns Observable that completes when all items are loaded.
   */
  public preloadItems(items: (string | { url: string; priority: 'high' | 'normal' })[], isPriority = false): Observable<boolean> {
    if (!items.length) {
      return of(true);
    }

    // Process items with different priorities
    const highPriorityUrls: string[] = [];
    const normalPriorityUrls: string[] = [];

    items.forEach(item => {
      if (typeof item === 'string') {
        normalPriorityUrls.push(item);
      } else {
        if (item.priority === 'high') {
          highPriorityUrls.push(item.url);
        } else {
          normalPriorityUrls.push(item.url);
        }
      }
    });

    // First preload high priority items
    const highPriorityObservable = highPriorityUrls.length > 0
      ? this.processItemsForPreload(highPriorityUrls, true)
      : of(true);

    // Then preload normal priority items
    return highPriorityObservable.pipe(
      switchMap(() => {
        if (normalPriorityUrls.length > 0) {
          return this.processItemsForPreload(normalPriorityUrls, isPriority);
        }
        return of(true);
      })
    );
  }

  /**
   * Clears all loaded items from cache.
   *
   * @returns Boolean indicating success.
   */
  public clearCache(): boolean {
    this.loadedItems.clear();
    this.updatePreloadingStatus();
    return true;
  }

  /**
   * Cancels all pending preload operations.
   *
   * @returns Boolean indicating success.
   */
  public cancelPendingOperations(): boolean {
    this.preloadQueue.clear();
    this.updatePreloadingStatus();
    return true;
  }

  /**
   * Checks if a specific item is already loaded.
   *
   * @param url URL of the item to check.
   *
   * @returns Boolean indicating if the item is loaded.
   */
  public isItemLoaded(url: string): boolean {
    return this.loadedItems.has(url);
  }

  /**
   * Returns the current preloading statistics.
   *
   * @returns Object containing preloading statistics.
   */
  public getPreloadingStats(): PreloadingStatus {
    return {
      total: this.loadedItems.size + this.loadingInProgress.size + this.preloadQueue.size,
      loaded: this.loadedItems.size,
      progress: this._preloadingStatus.value.progress
    };
  }

  /**
   * Updates a cached item when the backend data has changed.
   *
   * @param url The URL of the item to invalidate.
   * @returns Observable that completes when the item is updated.
   */
  public updateCachedItem(url: string): Observable<boolean> {
    if (!this.db) {
      return of(false);
    }

    // First remove from loaded items
    this.loadedItems.delete(url);

    // Then remove from cache
    this.deleteItemsFromCache([url]);

    // Then reload
    return this.preloadItem(url);
  }

  /**
   * Abstract method to be implemented by subclasses to prepare items for preloading.
   *
   * @param data The data object containing items to preload.
   * @param apiBaseUrl The base URL for API requests.
   * @returns Array of URLs to preload with priority.
   */
  public abstract prepareItemsForPreload(
    data: any,
    apiBaseUrl: string
  ): { url: string; priority: 'high' | 'normal' }[];
}
