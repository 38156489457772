import { Subject, Observable } from 'rxjs';
import { Injectable, ComponentRef, ViewContainerRef, Type, TemplateRef } from '@angular/core';

export interface ModalConfig<T> {
  component: Type<T>;
  inputs: ModalConfigInputs;
  content?: Type<any> | TemplateRef<any>;
}

export interface ModalConfigInputs {
  id: string;
  [key: string]: any;
}

interface ModalStackItem {
  id: string;
  componentRef: ComponentRef<any>;
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private modalStack: ModalStackItem[] = [];

  private rootViewContainer: ViewContainerRef | null = null;
  private modalClosed = new Subject<{ id: string, result: any }>();

  public modalClosed$: Observable<{ id: string, result: any }> = this.modalClosed.asObservable();

  /**
   * Sets the root view container ref for the modal service.
   * This should be called once from the app component.
   *
   * @param viewContainerRef The ViewContainerRef to use as the root container.
   */
  public setRootViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.rootViewContainer = viewContainerRef;
  }

  /**
   * Opens a modal with the specified configuration.
   *
   * @param modalConfig
   *
   * @returns
   * @throws Error if the root view container is not set.
   */
  public open<T extends object>(modalConfig: ModalConfig<T>): ComponentRef<T>  {
    if (!this.rootViewContainer) {
      throw new Error('Root view container not set');
    }

    const componentRef = this.rootViewContainer.createComponent(modalConfig.component);

    if (modalConfig.inputs) {
      Object.assign(componentRef.instance, modalConfig.inputs);
    }

    if (modalConfig.content) {
      (componentRef.instance as any).content = modalConfig.content;
    }

    this.modalStack.push({ id: modalConfig.inputs.id, componentRef });

    return componentRef;
  }

  /**
   * Closes the currently open modal.
   *
   * @param modalId
   * @param result
   */
  public close(modalId: string, result?: any): void {
    const closedModal = this.modalStack.find(item => item.id === modalId);

    if (!closedModal) {
      return;
    }

    closedModal.componentRef.destroy();

    this.modalStack = this.modalStack.filter(item => item.id !== modalId);
    this.modalClosed.next({ id: closedModal.id, result });
  }
}
