<div class="flex flex-col justify-center items-center space-y-3">
  <div class="w-24 h-24 bg-white/5 rounded-full p-7">
    <nrc-icon
      class="w-full h-full"
      [name]="'rocket-outline'"
      [fillColor]="'transparent'"
    />
  </div>

  @if (headline) {
    <p class="font-light text-3xl text-neutral-100">{{ headline }}</p>
  }

  @if (content) {
    <p class="font-light tracking-wide text-base text-[#f8f8f8b2] max-w-sm text-center">{{ content }}</p>
  }

  <nrc-button
    [label]="buttonLabel"
    (clickEvent)="buttonClickEvent.emit()"
  />
</div>
