import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, computed } from '@angular/core';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IActionButtonEvent, IListConfiguration, IListItemClickedEvent, IListOptions, IListOptionsSort, ListItemType } from '@newroom-connect/library/interfaces';
import { IsEmptyArrayPipe, IsNonEmptyArrayPipe } from '@newroom-connect/library/pipes';

import { ButtonComponent } from '../buttons/button/button.component';
import { LoadingComponent } from '../loading/loading.component';

import { EmptyListComponent } from './empty-list/empty-list.component';
import { GridListComponent } from './grid-list/grid-list.component';
import { ListPaginatorComponent } from './list-paginator/list-paginator.component';
import { RowListComponent } from './row-list/row-list.component';
import { BaseListComponent } from './abstract/base-list.component';

export type ListLayout = 'grid' | 'rows';

@Component({
  standalone: true,
  imports: [
    ButtonComponent,
    EmptyListComponent,
    GridListComponent,
    ListPaginatorComponent,
    LoadingComponent,
    RowListComponent,
    IsEmptyArrayPipe,
    IsNonEmptyArrayPipe
  ],
  selector: 'nrc-list',
  templateUrl: './list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListComponent extends BaseListComponent {
  @Input({ required: true }) public config!: IListConfiguration;
  @Input() public layout?: ListLayout;
  @Input() public listPaginatorOverflowParentElement?: HTMLElement;

  @Output() public listItemClickedEvent = new EventEmitter<IListItemClickedEvent>();
  @Output() public listOptionsEvent = new EventEmitter<IListOptions>();
  @Output() public actionButtonEvent = new EventEmitter<IActionButtonEvent>();
  @Output() public itemActionButtonEvent = new EventEmitter<IActionButtonEvent>();
  @Output() public emptyListActionEvent = new EventEmitter();

  public listItemType = ListItemType;
  public actionRole = ActionRole;
  public buttonType = ButtonType;

  public lastPaginationEvent = { page: 1, pageSize: 10 };
  public lastSortEvent?: IListOptionsSort;

  public itemsSelectedText = computed(() => {
    const items = this.items();

    if (!items) {
      return '0 selected';
    }

    return `${items.filter(item => item.isSelected).length} selected`;
  });

  /**
   * Emit a list options event based on the stored last events.
   */
  public emitListOptionsEvent(): void {
    this.listOptionsEvent.emit({
      pagination: this.lastPaginationEvent,
      sort: this.lastSortEvent
    });
  }
}
