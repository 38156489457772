import { trigger, transition, style, animate } from '@angular/animations';

export const fadeInOutAnimation = trigger('fadeInOutAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('{{ time }}', style({ opacity: 1 }))
  ], { params: { time: '150ms' } }),
  transition(':leave', [
    animate('{{ time }}', style({ opacity: 0 }))
  ], { params: { time: '150ms' } })
]);
