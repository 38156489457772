<div class="relative w-full h-full text-center">
  @if (mimetype | startsWith: 'image/') {
    <nrc-image
      [source]="{ uri: source }"
      [alt]="alt"
      [objectFit]="'contain'"
    />
  } @else if (mimetype | startsWith: 'video/') {
    <nrc-video
      [sources]="[{ uri: source, mimetype }]"
      [objectFit]="'contain'"
      [autoplay]="true"
      [muted]="true"
      [controls]="false"
      [loop]="true"
      [disablePictureInPicture]="true"
    />
  } @else {
    <p>
      {{ 'COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_2D.BACKGROUND.MIMETYPE_NOT_SUPPORTED_WARNING' | translate }}
    </p>
  }

  <!-- Show a warning icon in the upper-right corner of the image, if the image does not meet the requirements. -->
  @if (!doesBackgroundImageMeetRequirementsSig() && isEditMode) {
    <div class="absolute w-40 h-40 top-4 right-2 flex flex-col items-center">
      <nrc-icon
        class="w-6 h-6 peer"
        [name]="'triangle-warning'"
        [fillColor]="'warning'"
        (clickEvent)="openAspectRatioWarningMessage()"
      />

      <nrc-tooltip
        class="mt-2 w-full opacity-0 peer-hover:opacity-100 scale-0 peer-hover:scale-100 transition-opacity"
        [text]="'COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_2D.TOOLTIP.DOES_NOT_MEET_DIMENSIONS' | translate"
      />
    </div>
  }
</div>
