import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostListener, Input, OnChanges, SimpleChanges, ViewChild, signal } from '@angular/core';
import { Observable, from, map, take } from 'rxjs';
import { CubeTextureLoader, PerspectiveCamera, Scene, WebGLRenderer } from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TranslatePipe } from '@newroom-connect/library/pipes';

export type CubemapFaceMap = {
  px: string;
  py: string;
  pz: string;
  nx: string;
  ny: string;
  nz: string;
}

export type CubemapBackgroundLoadingStatus = 'loading' | 'failed' | 'success';

@Component({
  selector: 'nrc-cubemap',
  standalone: true,
  imports: [TranslatePipe],
  templateUrl: './cubemap.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CubemapComponent implements AfterViewInit, OnChanges {
  private static readonly DEFAULT_CAMERA_FOV = 75;
  private static readonly DEFAULT_CAMERA_FRUSTUM_NEAR = 0.1;
  private static readonly DEFAULT_CAMERA_FRUSTUM_FAR = 1000;

  @ViewChild('cubemapCanvasContainer') private cubemapCanvasContainerRef!: ElementRef<HTMLDivElement>;
  @ViewChild('cubemapCanvas') private cubemapCanvasRef!: ElementRef<HTMLCanvasElement>;

  @Input({ required: true }) public faceMap!: CubemapFaceMap;

  public backgroundLoadingStatusSig = signal<CubemapBackgroundLoadingStatus>('loading');
  public backgroundLoadingProgressSig = signal<number>(0);

  private camera!: PerspectiveCamera;

  private controls!: OrbitControls;

  private renderer!: WebGLRenderer;

  private scene?: Scene;

  private isAfterViewInitFinished = false;

  /**
   * Create the scene and start the rendering loop after the view has been initialized.
   */
  public ngAfterViewInit(): void {
    this.createScene().pipe(take(1)).subscribe(() => {
      this.startRenderingLoop();

      this.isAfterViewInitFinished = true;
    });
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    // If the face map changed, update the scene background and size of the scene.
    if (changes['faceMap']?.currentValue && this.isAfterViewInitFinished) {
      from(this.setSceneBackground()).pipe(take(1)).subscribe(() => {
        this.updateSize();
      });
    }
  }

  /**
   * Update the size of the scene when the window is resized.
   */
  @HostListener('window:resize')
  public onWindowResize(): void {
    this.updateSize();
  }

  /**
   * Create the cubemap scene where the cube texture is loaded and set as background of the scene.
   * The camera is created and positioned afterwards.
   *
   * @returns
   */
  private createScene(): Observable<void> {
    this.scene = new Scene();

    return from(this.setSceneBackground()).pipe(
      map(() => {
        // Create the camera in the scene based on the current aspect ratio of the canvas and some default values.
        this.camera = new PerspectiveCamera(
          CubemapComponent.DEFAULT_CAMERA_FOV,
          this.getAspectRatio(),
          CubemapComponent.DEFAULT_CAMERA_FRUSTUM_NEAR,
          CubemapComponent.DEFAULT_CAMERA_FRUSTUM_FAR
        );

        this.camera.position.z = 1;
      })
    );
  }

  /**
   * Create a new WebGL renderer on the canvas and an instance for the orbit controls and start the rendering loop.
   */
  private startRenderingLoop(): void {
    this.renderer = new WebGLRenderer({ canvas: this.cubemapCanvasRef.nativeElement });
    this.renderer.setSize(this.cubemapCanvasContainerRef.nativeElement.clientWidth, this.cubemapCanvasContainerRef.nativeElement.clientHeight);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);

    this.controls.update();

    // Define the rendering loop.
    const render = (): void => {
      requestAnimationFrame(render);

      if (this.scene) {
        this.renderer.render(this.scene, this.camera);
      }
    };

    // Start the rendering loop.
    render();
  }

  /**
   * Get the current aspect ratio of the component's canvas on the client.
   *
   * @returns The aspect ratio of the component's canvas on the client.
   */
  private getAspectRatio(): number {
    return this.cubemapCanvasContainerRef.nativeElement.clientWidth / this.cubemapCanvasRef.nativeElement.clientHeight;
  }

  /**
   * Update the aspect ratio of the component's camera and the width/height of the renderer based on the size of the canvas container.
   */
  private updateSize(): void {
    this.camera.aspect = this.getAspectRatio();
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(this.cubemapCanvasContainerRef.nativeElement.clientWidth, this.cubemapCanvasContainerRef.nativeElement.clientHeight);
  }

  /**
   * Load the cubemap texture from the provided face map.
   * If loading the cube texture failed, set the appropriate signal to `true` to notify the user.
   *
   * @returns
   */
  private setSceneBackground(): Promise<void> {
    return new Promise<void>(resolve => {
      if (!this.scene) {
        return resolve();
      }

      this.scene.background = new CubeTextureLoader().load(
        [
          this.faceMap.px,
          this.faceMap.nx,
          this.faceMap.py,
          this.faceMap.ny,
          this.faceMap.pz,
          this.faceMap.nz
        ],
        () => {
          this.backgroundLoadingStatusSig.set('success');
          resolve();
        },
        progress => this.backgroundLoadingProgressSig.set(Math.round(progress.loaded / progress.total * 100)),
        () => {
          this.backgroundLoadingStatusSig.set('failed');
          resolve();
        }
      );
    });
  }
}
