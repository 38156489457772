export class MathHelper {
  /**
   * Determine the exponent of the highest power of two that is less than or equal to x.
   *
   * @param x The number to calculate the exponent for.
   * @param base The base to calculate the exponent for. Defaults to 2.
   *
   * @returns The calculated exponent.
   */
  public static exponentOfHighestPowerOfBase(x: number, base = 2): number {
    return Math.floor(Math.log(x) / Math.log(base));
  }

  /**
   * Convert a number from radians to degrees.
   *
   * @param radians The number in radians to convert to degrees.
   *
   * @returns The converted number in degrees.
   */
  public static radToDeg(radians: number): number {
    return radians * (180 / Math.PI);
  }
}
