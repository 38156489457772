import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input, OnDestroy, Signal, ViewChild, computed, forwardRef, signal } from '@angular/core';
import { ReactiveFormsModule, NG_VALUE_ACCESSOR, FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { takeUntil } from 'rxjs';
import { GridLayoutAnimationDirective } from '@newroom-connect/library/directives';
import { IActionButton, MediaItem, IFileHydrated, ICardItem, IFileItem, MediaItemDto, IFileItemDto, ICardItemDto } from '@newroom-connect/library/interfaces';
import { ButtonType, ActionRole, MediaType } from '@newroom-connect/library/enums';
import { collapseAnimation, fadeInOutAnimation } from '@newroom-connect/library/animations';

import { ButtonComponent } from '../buttons';
import { CheckboxComponent } from '../checkbox/checkbox.component';
import { InputCheckboxComponent, InputFileComponent } from '../inputs';
import { InputComponent } from '../inputs/input.component';

import { MediaLibraryService } from './services/media-library.service';
import { MediaLibraryCardItemComponent } from './media-library-card-item/media-library-card-item.component';
import { MediaLibraryEmptyComponent } from './media-library-empty/media-library-empty.component';
import { MediaLibraryHeaderComponent } from './media-library-header/media-library-header.component';
import { MediaLibraryItemPropertiesComponent } from './media-library-item-properties/media-library-item-properties.component';

interface IMediaLibraryOutput {
  data: MediaItemDto[];
  openInFullscreen: boolean;
  gridLayoutStretched: boolean;
}

@Component({
  selector: 'nrc-media-library',
  templateUrl: './media-library.component.html',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ButtonComponent,
    CheckboxComponent,
    InputCheckboxComponent,
    InputFileComponent,
    MediaLibraryEmptyComponent,
    MediaLibraryHeaderComponent,
    MediaLibraryCardItemComponent,
    MediaLibraryItemPropertiesComponent,
    GridLayoutAnimationDirective
  ],
  animations: [collapseAnimation, fadeInOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MediaLibraryComponent),
      multi: true
    }
  ]
})
export class MediaLibraryComponent extends InputComponent<IMediaLibraryOutput> implements AfterViewInit, OnDestroy {
  @Input({ required: true }) public apiBaseUrl!: string;
  @Input({ required: true }) public projectId!: string;

  @Input() public title?: string;
  @Input() public maxCardLevel?: number;
  @Input() public mediaTypes?: string[] = [];
  @Input() public supportLayoutViewSettings = true;

  @ViewChild('gridContainer') public gridContainerElement?: ElementRef<HTMLDivElement>;

  public readonly isFileLibraryVisible = signal<boolean>(false);
  public readonly fileLibraryFormControl = new FormControl<string | null>(null);
  public readonly gridLayoutStretched = new FormControl<boolean>(false, { nonNullable: true });
  public readonly buttonType = ButtonType;
  public readonly actionRole = ActionRole;
  public readonly mediaType = MediaType;

  public readonly gridColumnsClass = computed<string>(() => {
    // Explicitly track dependencies for reactivity
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const cardChangeSignal = this.mediaLibraryService.cardChangeSignal();
    const itemCount = this.mediaLibraryService.openedCard().controls.length;
    const isStretched = this.mediaLibraryService.gridLayoutStretchedSignal();
    const isPropertiesCollapsed = this.mediaLibraryService.isPropertiesCollapsed();

    if (isStretched && this.mediaLibraryService.navigationHistory().length === 1) {
      return 'grid-cols-3';
    }

    if (itemCount === 0) {
      return 'grid-cols-1';
    }

    if (itemCount <= 2) {
      return 'grid-cols-2';
    }

    if (isPropertiesCollapsed && itemCount > 4) {
      return 'grid-cols-4';
    }

    return 'grid-cols-3';
  });

  /**
   * Computed signal for checking if item selection is active.
   */
  public readonly isItemSelectionActive = computed(() => this.mediaLibraryService.isSelectionModeActive());

  /**
   * Computed signal for header action buttons.
   */
  public readonly headerActionButtons: Signal<IActionButton[]> = computed(() => {
    // Force reactivity by explicitly tracking opened card changes
    const openedCard = this.mediaLibraryService.openedCard();
    const isEverySelected = this.mediaLibraryService.isEveryPageItemSelected();
    const isSomeButNotEverySelected = this.mediaLibraryService.isSomeButNotEveryPageItemSelected();

    // Check if any items are selected in the currently opened card
    const hasSelectedItems = openedCard.controls.some(control =>
      control.get('isSelected')?.value === true
    );

    return [
      {
        id: 'delete',
        icon: 'trash',
        buttonType: ButtonType.ICON,
        role: ActionRole.ERROR,
        // Enable delete button if any items are selected
        disabled: !hasSelectedItems &&
                  !isEverySelected &&
                  !isSomeButNotEverySelected
      },
      {
        id: 'createCard',
        icon: 'folder',
        buttonType: ButtonType.ICON,
        role: ActionRole.TRANSPARENT_SECONDARY,
        disabled: !this.canCreateCard()
      },
      {
        id: 'addFile',
        icon: 'upload',
        buttonType: ButtonType.ICON,
        role: ActionRole.TRANSPARENT_SECONDARY
      }
    ];
  });

  public readonly canCreateCard = computed(() =>
    this.maxCardLevel !== undefined && this.mediaLibraryService.navigationHistory().length - 1 < this.maxCardLevel
  );

  /**
   * @constructor
   *
   * @param mediaLibraryService
   */
  constructor(public readonly mediaLibraryService: MediaLibraryService) {
    super();
  }

  /**
   * Initialize component after view initialization.
   */
  public override ngAfterViewInit(): void {
    if (this.formControl) {
      this.mediaLibraryService.initialize(this.formControl.value ?? {
        data: [],
        openInFullscreen: false,
        gridLayoutStretched: false
      });

      // Subscribe to form changes from the service
      this.mediaLibraryService.mediaLibraryForm$
        .pipe(takeUntil(this.destroy$))
        .subscribe(form => {
          if (!form || !form.value) {
            return;
          }

          const processedItemsValue = this.processMediaLibraryActionValue(form.value as MediaItem[]);
          const newValue = {
            data: processedItemsValue,
            openInFullscreen: this.mediaLibraryService.openInFullscreen?.value ?? false,
            gridLayoutStretched: this.mediaLibraryService.gridLayoutStretched?.value ?? false
          };

          this.formControl.patchValue(newValue, { emitEvent: true });
          this.writeValue(newValue);
        });

      if (!this.supportLayoutViewSettings || !this.mediaLibraryService.openInFullscreen || !this.mediaLibraryService.gridLayoutStretched) {
        return;
      }

      const updateFormValues = (updates: Partial<{openInFullscreen: boolean, gridLayoutStretched: boolean}>): void => {
        const newValue = {
          ...this.formControl.value,
          openInFullscreen: this.mediaLibraryService.openInFullscreen?.value ?? false,
          gridLayoutStretched: this.mediaLibraryService.gridLayoutStretched?.value ?? false,
          ...updates
        };

        this.formControl.patchValue(newValue, { emitEvent: true });
        this.writeValue(newValue);
      };

      this.mediaLibraryService.gridLayoutStretched.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => updateFormValues({ gridLayoutStretched: value }));

      this.mediaLibraryService.openInFullscreen.valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe(value => updateFormValues({ openInFullscreen: value }));
    }
  }

  /**
   * Clean up on component destroy.
   */
  public override ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();

    this.mediaLibraryService.clearSelection();
    this.resetInput();
    super.ngOnDestroy();
  }

  /**
   * Handles header action button events.
   * @param actionButton Button that triggered the event.
   */
  public handleHeaderActionButtonEvent(actionButton: IActionButton): void {
    switch (actionButton.id) {
      case 'createCard': {
        this.mediaLibraryService.addItem(MediaType.CARD);
        break;
      }
      case 'delete': {
        const currentCard = this.mediaLibraryService.openedCard();
        const selectedIndices = currentCard.controls
          .map((control, index) => control.get('isSelected')?.value ? index : -1)
          .filter(index => index !== -1);

        this.mediaLibraryService.deleteItems(selectedIndices);
        break;
      }
      case 'addFile': {
        this.toggleFileLibrary();
        break;
      }
      default:
        break;
    }
  }

  /**
   * Handles click event on card items.
   * If item selection is not active, opens the properties bar for the clicked item.
   * If item selection is active, toggles selection for the clicked item.
   *
   * @param index
   */
  public handleCardItemClickEvent(index: number): void {
    this.mediaLibraryService.handleItemSelection(index);
  }

  /**
   * Toggles selection for all items.
   */
  public toggleAllItemsIsSelected(): void {
    const isEverySelected = this.mediaLibraryService.isEveryPageItemSelected();

    this.mediaLibraryService.updateAllItemsSelection(!isEverySelected);
  }

  /**
   * Toggles item selection mode.
   */
  public toggleItemSelection(): void {
    this.mediaLibraryService.toggleSelectionMode();
  }

  /**
   * Handles double click event on card items.
   * @param index Index of the clicked card.
   */
  public handleOpenCardEvent(index: number): void {
    const openedCard = this.mediaLibraryService.openedCard();
    const cardItem = openedCard.at(index);

    if (!cardItem) {
      return;
    }

    this.mediaLibraryService.clearSelection();
    this.mediaLibraryService.navigateToFolder(cardItem);
  }

  /**
   * Handles changes in item properties.
   *
   * @param index
   * @param value
   */
  public handlePropertiesChangeEvent(index: number, value: Partial<MediaItem>): void {
    this.mediaLibraryService.updateItem(index, value);
  }

  /**
   * Handles empty card action event.
   */
  public handleEmptyCardActionEvent(): void {
    if (!this.canCreateCard()) {
      this.toggleFileLibrary();

      return;
    }

    this.mediaLibraryService.addItem(MediaType.CARD);
  }

  /**
   * Handles file library modal close event.
   * @param file Selected file from modal.
   */
  public handleFileLibraryModalCloseEvent(file: IFileHydrated): void {
    if (!this.fileLibraryFormControl) {
      return;
    }

    this.mediaLibraryService.addItem(this.mediaType.FILE, {
      type: MediaType.FILE,
      file,
      fileId: file.id,
      thumbnailId: null,
      fileTags: file.tags,
      label: file.title,
      isSelected: false
    });

    this.toggleFileLibrary();
  }

  /**
   * Reset the input of the form control and the HTML input element.
   */
  private resetInput(): void {
    this.formControl.reset([]);
    this.mediaLibraryService.clearSelection();
    this.mediaLibraryService.isPropertiesCollapsed.set(false);
  }

  /**
   *
   */
  private toggleFileLibrary(): void {
    this.isFileLibraryVisible.update(isVisible => !isVisible);
  }

  /**
   * Processes media library internal format to the API's MediaItemDto format for submission.
   *
   * @param value Array of media items to process.
   * @returns Processed media items with only ID references for form control.
   */
  private processMediaLibraryActionValue(value: MediaItem[]): MediaItemDto[] {
    const processItem = (item: MediaItem): MediaItemDto => {
      const { type, label, thumbnailId } = item;
      const base = { type, label, thumbnailId };

      if (item.type === MediaType.FILE) {
        return {
          ...base,
          fileId: (item as IFileItem).fileId,
          fileTags: (item as IFileItem).fileTags
        } as IFileItemDto;
      }

      return {
        ...base,
        isThumbnailAsDetailImage: (item as ICardItem).isThumbnailAsDetailImage,
        items: (item as ICardItem).items?.map(processItem) ?? null
      } as ICardItemDto;
    };

    return value.map(processItem);
  }
}
