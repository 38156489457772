import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { ActionRole, ButtonType, Level, ObjectPropertyFormat } from '@newroom-connect/library/enums';
import { IAPIListResponseMeta, IFileHydrated, IFileUploadItem, IListConfigurationColumn, IListItem, IListItemClickedEvent, IListOptions, ListItemType } from '@newroom-connect/library/interfaces';
import { FileListService } from '@newroom-connect/library/services';
import { IsNonEmptyArrayPipe, IsEmptyArrayPipe } from '@newroom-connect/library/pipes';

import { FileExplorerComponent } from '../../file-explorer/file-explorer.component';
import { SearchComponent } from '../../../search/search.component';
import { ListComponent } from '../../../list/list.component';

@Component({
  selector: 'nrc-file-library-modal-search',
  templateUrl: './file-library-modal-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SearchComponent,
    ListComponent,
    FileExplorerComponent,
    IsNonEmptyArrayPipe,
    IsEmptyArrayPipe
  ]
})
export class FileLibraryModalSearchComponent {
  @Input() public fileListItemsSig = signal<IListItem<IFileHydrated>[]>([]);
  @Input() public metaSig = signal<IAPIListResponseMeta | undefined>(undefined);
  @Input() public isFileListLoading?: boolean;

  @Output() public showFileLibraryEvent = new EventEmitter<void>();
  @Output() public uploaderFilesChanged = new EventEmitter<IFileUploadItem[]>();
  @Output() public fileListEvent = new EventEmitter<IListOptions>();
  @Output() public fileSelectedEvent = new EventEmitter<IListItemClickedEvent<IListItem<IFileHydrated>>>();
  @Output() public fileSearchEvent = new EventEmitter<string>();

  public columnsSig = signal<IListConfigurationColumn[]>(FileListService.generateGridLayoutListConfiguration());

  public buttonType = ButtonType;
  public actionRole = ActionRole;
  public level = Level;
  public objectPropertyFormat = ObjectPropertyFormat;
  public listItemType = ListItemType;
}
