import { IFileHydrated } from './file.interface';

export interface ITranslationAttempt {
  id?: string;
  type?: string;
  fileId?: string;
  file?: IFileHydrated;
  name?: string;
  status: TranslationJobStatus;
  progress?: number;
  projectId?: string;
  sourceLanguage?: string;
  targetLanguage?: string;
  outputFilename?: string;
  outputDirectory?: string;
  newFileId?: string;
  timestamp: number;
}

export enum TranslationJobStatus {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED'
}
