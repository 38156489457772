import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toNumber'
})
export class ToNumberPipe implements PipeTransform {
  /**
   * Transform a given value to a string.
   *
   * If the value is `typeof object`, the object will be stringified.
   * If the value is `typeof undefined`, an empty string will be returned.
   * Otherwise the value will just be wrapped into a string.
   *
   * @param value The value to transform to a string.
   *
   * @returns The stringified value.
   */
  public transform(value: unknown): number {
    switch (typeof value) {
      case 'object':
        return NaN;
      case 'undefined':
        return NaN;
      default:
        return Number(value);
    }
  }
}
