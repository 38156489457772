<div class="flex flex-col justify-center items-center space-y-3">
  <div class="w-24 h-24 bg-white/5 rounded-full p-7">
    <nrc-icon
      class="w-full h-full"
      [name]="'rocket-outline'"
      [fillColor]="'transparent'"
    />
  </div>

  <p class="font-light text-3xl text-neutral-100">
    {{ 'COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_EMPTY.HEADLINE' | translate }}
  </p>

  <p class="font-light tracking-wide text-base text-[#f8f8f8b2] max-w-sm text-center">
    {{ 'COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_EMPTY.CONTENT' | translate }}
  </p>

  <nrc-button
    [label]="'COMPONENTS.AREAS.AREA_BACKGROUND.AREA_BACKGROUND_EMPTY.BUTTON.LABEL' | translate"
    (clickEvent)="changeBackgroundEvent.emit()"
  />
</div>
