@if (downloadProgress) {
  <div
    class="relative flex h-14 items-center justify-between gap-2 p-2 rounded-md bg-gray-800 border border-gray-700/50 transition-all duration-300 ease-in-out"
    [class.translate-y-0]="downloadProgress.status === downloadStatus.COMPLETED"
    [class.opacity-100]="downloadProgress.status === downloadStatus.COMPLETED"
  >

    <div class="flex items-center gap-1">
      @switch (downloadProgress.status) {
        @case (downloadStatus.COMPLETED) {
          <div class="w-8 h-8 flex items-center justify-center rounded-full bg-green-500/20">
            <nrc-icon [name]="'multi-check'" [fillColor]="'success'" class="w-4 h-4" />
          </div>
        }

        @case (downloadStatus.FAILED) {
          <div class="w-8 h-8 flex items-center justify-center rounded-full bg-red-500/20">
            <nrc-icon [name]="'error'" [fillColor]="'error'" class="w-4 h-4" />
          </div>
        }

        @default {
          <div class="block rounded-full bg-blue-500/20">
            <nrc-loading-spinner class="w-4 h-4 text-blue-500" />
          </div>
        }
      }

      <div class="flex flex-col">
        <span
          class="text-xs font-semibold text-gray-200 max-w-[14rem] px-1 overflow-hidden">
          {{ downloadProgress.filename }}
        </span>
      </div>
    </div>

    <div class="flex items-center select-none">
      @switch (downloadProgress.status) {
        @case (downloadStatus.PENDING) {
          <span class="px-2 py-1 text-xs font-medium text-yellow-500 bg-yellow-500/10 rounded-full cursor-progress">
            Preparing files download
          </span>
        }

        @case (downloadStatus.ZIPPING) {
          <span
            class="inline-block w-44 text-center whitespace-nowrap px-2 py-1 text-xs font-medium text-blue-500 bg-blue-500/10 rounded-full cursor-progress">
            Compressing files {{ downloadProgress.processed }}/{{ downloadProgress.total }}
          </span>
        }

        @case (downloadStatus.DOWNLOADING) {
          <span
            class="px-2 py-1 text-xs font-medium text-blue-500 bg-blue-500/10 rounded-full cursor-progress">
            Downloading..
          </span>
        }

        @case (downloadStatus.COMPLETED) {
          <span class="px-2 py-1 text-xs font-medium text-green-500 bg-green-500/10 rounded-full">
            Downloaded
          </span>
        }

        @case (downloadStatus.FAILED) {
          <span class="px-2 py-1 text-xs font-medium text-red-500 bg-red-500/10 rounded-full">
            Failed
          </span>
        }
      }
    </div>
  </div>
}