import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { ISystemMessage } from '@newroom-connect/library/interfaces';
import { TranslatePipe } from '@newroom-connect/library/pipes';

import { AlertComponent } from '../alert/alert.component';
import { DestroyableComponent } from '../abstract/destroyable/destroyable.component';
import { ModalService } from '../modal/modal.component';

export interface ChangesComponent {
  hasUnsavedChanges: () => boolean;
  collectionName: string;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    TranslatePipe
  ],
  selector: 'nrc-unsaved-changes-alert',
  templateUrl: './unsaved-changes-alert.component.html',
  animations: [
    fadeInOutAnimation
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsavedChangesAlertComponent extends DestroyableComponent {
  private modalService = inject(ModalService);

  @Input({ required: true }) public alertMessage?: ISystemMessage | null;
  @Input({ required: true }) public collectionName!: string;
  @Input({ required: true }) public id!: string;

  /**
   *
   * @param hasConfirmed
   */
  public handleUserChoiceEvent(hasConfirmed: boolean): void {
    this.modalService.close(this.id, hasConfirmed);
  }
}

export * from './service/unsaved-changes.service';
