import { Injectable } from '@angular/core';
import { Subject, PartialObserver, Subscription } from 'rxjs';
import { ArrayHelper } from '@newroom-connect/library/helpers';
import { CookieChoiceEvent, IScript, UsercentricsCookieEventDetail } from '@newroom-connect/library/interfaces';
import { UsercentricsCookies, UsercentricsEvent } from '@newroom-connect/library/enums';

import { ScriptService } from '../script/script.service';
import { ProjectService } from '../project';
import { LoggingService } from '../logging/logging.service';

// Extended Window interface to include Usercentrics event listener
declare const window: any;

/**
 * Service for managing cookie consent using Usercentrics.
 */
@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private static readonly USERCENTRICS_PRESET_ID = '1eb51d8b-c01d-4fc5-a5d2-67a9e451a9c7';

  // Emits an event when something changes regarding the cookie choices.
  private events$ = new Subject<CookieChoiceEvent>();

  // Hold the current cookies choices (SPOT).
  private choices: Record<string, boolean> = {};

  /**
   *
   * @param scriptService
   * @param projectService
   * @param logger
   */
  constructor(
    private scriptService: ScriptService,
    private projectService: ProjectService,
    private logger: LoggingService
  ) { }

  /**
   * Initialize the cookies service if Usercentrics analytics provider is enabled in the project.
   */
  public async initialize(): Promise<void> {
    const usercentricsKeyId = this.determineUsercentricsKeyId();

    if (!usercentricsKeyId) {
      return;
    }

    await this.loadUsercentricsScript(usercentricsKeyId);

    // Register cookies choice change listener.
    this.registerListener();

    this.logger.debug('CookiesService: Initialized');
  }

  /**
   * Subscribe to cookie choice events.
   *
   * @param observer - The observer to subscribe to events.
   * @returns A subscription object.
   */
  public subscribe(observer: PartialObserver<CookieChoiceEvent>): Subscription {
    return this.events$.subscribe(observer);
  }

  /**
   * Get cookie choice for a given key.
   * @param key - One of the implemented Usercentrics cookies keys.
   * @returns Value of the provided key or false if key doesn't exist.
   */
  public getChoice(key: UsercentricsCookies): boolean {
    return this.choices[key] ?? false;
  }

  /**
   * Determine and return the Usercentrics Key ID from the project analytics providers list if it is enabled.
   *
   * @returns The Usercentrics Key ID if enabled or undefined if not enabled.
   */
  private determineUsercentricsKeyId(): string | undefined {
    const currentSignInConfig = this.projectService.getCurrentSignInConfig();

    if (!currentSignInConfig || !ArrayHelper.isNotEmpty(currentSignInConfig.analytics.providers)) {
      return undefined;
    }

    const googleAnalyticsProvider = currentSignInConfig.analytics.providers
      .find(provider =>
        provider.preset.id === CookiesService.USERCENTRICS_PRESET_ID && provider.enabled
      );

    return googleAnalyticsProvider?.analyticsKeyId;
  }

  /**
   *
   * @param usercentricsKeyId
   */
  private async loadUsercentricsScript(usercentricsKeyId: string): Promise<void> {
    const usercentricsScript: IScript = {
      src: 'https://app.usercentrics.eu/browser-ui/latest/loader.js',
      attributes: {
        id: 'usercentrics-cmp',
        dataSettingsId: usercentricsKeyId,
        type: 'text/javascript'
      }
    };

    await this.scriptService.loadScript(usercentricsScript);
  }

  /**
   * Register a listener for Usercentrics events.
   */
  private registerListener(): void {
    window.addEventListener(UsercentricsEvent.CONSENT_STATUS_CHANGE, this.consentStatusChangeHandler.bind(this));
  }

  /**
   * Handle Usercentrics consent status change events.
   * @param customEvent - The custom event from Usercentrics.
   */
  private consentStatusChangeHandler(customEvent: CustomEvent<UsercentricsCookieEventDetail>): void {
    const { event, type, ...consentChoices } = customEvent.detail;

    switch (event) {
      case UsercentricsEvent.CONSENT_STATUS:
        this.handleChoices(consentChoices as Record<string, boolean>);
        break;
      case UsercentricsEvent.CONSENT_ALL_CHANGE:
        // Handle all consents change if needed
        break;
      default:
        this.logger.warn('CookiesService: Unknown event type', type, event);
        break;
    }
  }

  /**
   * Handle changes in cookie choices.
   *
   * @param consentChoices - The new consent choices.
   */
  private handleChoices(consentChoices: Record<string, boolean>): void {
    this.logger.debug('CookiesService: Consent choices changed', consentChoices);

    for (const [key, value] of Object.entries(consentChoices)) {
      if (typeof value === 'boolean') {
        this.choices[key] = value;
      }
    }

    this.events$.next({
      event: UsercentricsEvent.CONSENT_STATUS_CHANGE,
      payload: this.choices
    });
  }
}
