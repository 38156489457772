import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private static readonly LOCAL_STORAGE_PREFIX = 'NRC';

  /**
   * Get an item from the local storage with the given key.
   *
   * @param key The key of the item to get.
   *
   * @returns The item from the local storage or `null`, if no item with the given key is present.
   */
  public get(key: string): string | undefined {
    return localStorage.getItem(this.buildKey(key)) ?? undefined;
  }

  /**
   * Set an item in the local storage with the given key and value.
   *
   * @param key The key of the item to set.
   * @param value The value of the item to set.
   */
  public set(key: string, value: string): void {
    localStorage.setItem(this.buildKey(key), value);
  }

  /**
   * Remove an item from the local storage with the given key.
   *
   * @param key The key of the item to remove.
   */
  public remove(key: string): void {
    localStorage.removeItem(this.buildKey(key));
  }

  /**
   * Build the whole local storage key with the given key.
   *
   * @param key The key to build the whole key from.
   *
   * @returns The whole, prefixed key.
   */
  private buildKey(key: string): string {
    return `${LocalStorageService.LOCAL_STORAGE_PREFIX}.${key}`;
  }
}
