<div class="w-full h-full flex justify-between items-center">
  @if (label) {
    <span
      class="w-4 h-full text-sm font-semibold tracking-wide flex justify-center items-center"
      [style.width]="(items ? (100 / items.length) : 100) + '%'"
    >
      {{ label }}
    </span>
  }

  @if (items) {
    @for (item of items; track item.id) {
      <nrc-toolbar-item
        class="h-full"
        [icon]="item.iconName"
        [label]="item.label"
        [tooltipText]="item.tooltipText"
        [tooltipTextTopside]="item.tooltipTextTopside"
        [contextMenu]="item.contextMenu"
        [style.width]="(100 / items.length) + '%'"
        [disabled]="item.disabled? item.disabled : false"
        (clickEvent)="handleClickEvent(item)"
        (contextMenuActionButtonEvent)="handleContextMenuActionButtonEvent($event)"
      />
    }
  }
</div>
