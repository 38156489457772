import { Injectable, computed, signal } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { MediaType } from '@newroom-connect/library/enums';
import {
  IFileItemForm,
  ICardItemForm,
  ICardNavigationHistory,
  IFileHydrated,
  MediaItem,
  MediaItemForm,
  MediaLibraryData
} from '@newroom-connect/library/interfaces';

@Injectable({
  providedIn: 'root'
})
export class MediaLibraryService {
  public readonly navigationHistory = signal<ICardNavigationHistory[]>([]);
  public readonly isSelectionModeActive = signal<boolean>(false);
  public readonly selectedCardItem = signal<{ index: number, value: Partial<MediaItem> } | null>(null);
  public readonly selectedItems = signal<Set<number>>(new Set());

  public readonly openedCard = signal<FormArray<FormGroup<MediaItemForm>>>(
    new FormArray<FormGroup<MediaItemForm>>([])
  );

  public readonly rootCardFolder = signal<FormArray<FormGroup<MediaItemForm>>>(
    new FormArray<FormGroup<MediaItemForm>>([])
  );

  public openInFullscreen?: FormControl<boolean>;
  public gridLayoutStretched?: FormControl<boolean>;

  public readonly isPropertiesCollapsed = signal<boolean>(false);

  /**
   * Computed signal for checking if every item is selected.
   */
  public readonly isEveryPageItemSelected = signal<boolean>(false);

  /**
   * Computed signal for checking if some but not all items are selected.
   */
  public readonly isSomeButNotEveryPageItemSelected = signal<boolean>(false);

  private readonly mediaLibraryFormSubject = new BehaviorSubject<FormArray<FormGroup<MediaItemForm>> | null>(null);
  public readonly mediaLibraryForm$ = this.mediaLibraryFormSubject.asObservable();
  public mediaLibraryForm?: FormArray<FormGroup<MediaItemForm>>;

  public readonly canNavigateBack = computed(() =>
    this.navigationHistory().length > 1
  );

  public readonly headerNavigationHistory = computed(() => {
    return this.navigationHistory().map((nav, index) => ({
      label: nav.label,
      index
    }));
  });

  public readonly cardChangeSignal = signal(0);
  public readonly gridLayoutStretchedSignal = signal<boolean>(false);

  /**
   * @constructor
   *
   * @param formBuilder Angular form builder service.
   */
  constructor(private readonly formBuilder: FormBuilder) {}

  /**
   * Initialize the media library with items.
   * @param mediaLibraryData
   */
  public initialize(mediaLibraryData: MediaLibraryData): void {
    const items = mediaLibraryData.data ?? [];

    const controls = items.map(item => this.createMediaItemFormGroup(item));

    this.mediaLibraryForm = new FormArray<FormGroup<MediaItemForm>>(controls);
    this.openInFullscreen = new FormControl<boolean>(mediaLibraryData.openInFullscreen ?? false, { nonNullable: true });
    this.gridLayoutStretched = new FormControl<boolean>(mediaLibraryData.gridLayoutStretched ?? false, { nonNullable: true });

    // Initialize the signal with the current value
    this.gridLayoutStretchedSignal.set(this.gridLayoutStretched.value);

    // Subscribe to value changes and update the signal
    this.gridLayoutStretched.valueChanges.subscribe(value => {
      this.gridLayoutStretchedSignal.set(value);
    });

    this.mediaLibraryFormSubject.next(this.mediaLibraryForm);
    this.rootCardFolder.set(this.mediaLibraryForm);
    this.openedCard.set(this.mediaLibraryForm);

    this.navigationHistory.set([{
      card: this.mediaLibraryForm,
      label: '',
      isCurrentOpenCard: true
    }]);
  }

  /**
   * Update form value and notify subscribers.
   */
  private updateFormValue(): void {
    if (this.mediaLibraryForm) {
      this.mediaLibraryFormSubject.next(this.mediaLibraryForm);

      // Update computed signals for item selection.
      const isEveryItemSelected = this.mediaLibraryForm.controls.every(item => item.value.isSelected);
      const isSomeItemSelected = this.mediaLibraryForm.controls.some(item => item.value.isSelected);

      this.isEveryPageItemSelected.set(isEveryItemSelected);
      this.isSomeButNotEveryPageItemSelected.set(isSomeItemSelected && !isEveryItemSelected);
    }
  }

  /**
   * Creates a form group for a media item and its nested items recursively.
   *
   * @param item The media item to create a form group for.
   *
   * @returns
   */
  private createMediaItemFormGroup(item: MediaItem): FormGroup<MediaItemForm> {
    // Create the base form group with common properties
    const formGroup = this.formBuilder.group<MediaItemForm>({
      type: new FormControl<MediaType>(item.type, { nonNullable: true }),
      label: new FormControl<string>(item.label, { nonNullable: true }),
      isSelected: new FormControl<boolean>(false, { nonNullable: true }),
      thumbnailId: new FormControl<string | null>(item.thumbnailId ?? null),
      thumbnail: new FormControl<IFileHydrated | null>(item.thumbnail ?? null),
      isThumbnailAsDetailImage: new FormControl<boolean | null>('isThumbnailAsDetailImage' in item ? item.isThumbnailAsDetailImage : null),
      items: new FormArray<FormGroup<MediaItemForm>>([])
    });

    // If this is a card type and has nested items, recursively create form groups for them
    if (item.type === MediaType.CARD && this.isCardItemForm(formGroup.controls) && 'items' in item) {
      (formGroup as FormGroup<ICardItemForm>).addControl('isThumbnailAsDetailImage', new FormControl<boolean | null>(item.isThumbnailAsDetailImage ?? null));
      (formGroup as FormGroup<ICardItemForm>).addControl('items', new FormArray<FormGroup<MediaItemForm>>([]));

      const itemsFormArray = formGroup.controls.items;

      if (itemsFormArray && item.items) {
        for (const nestedItem of item.items) {
          const nestedFormGroup = this.createMediaItemFormGroup(nestedItem);

          itemsFormArray.push(nestedFormGroup);
        }
      }
    }

    // If this is a file type, add file-specific controls
    if (item.type === MediaType.FILE && 'fileId' in item) {
      (formGroup as FormGroup<IFileItemForm>).addControl('fileId', new FormControl<string | null>(item.fileId ?? null));
      (formGroup as FormGroup<IFileItemForm>).addControl('file', new FormControl<IFileHydrated | null>(item.file ?? null));
      (formGroup as FormGroup<IFileItemForm>).addControl('fileTags', new FormControl<string[]>(item.fileTags ?? [], { nonNullable: true }));
    }

    return formGroup;
  }

  /**
   * Adds a new item to the current folder.
   *
   * @param type MediaType of the item to add.
   * @param initialValue Optional initial values for the item.
   *
   */
  public addItem(type: MediaType, initialValue?: Partial<MediaItem>): void {
    const currentCard = this.openedCard();
    const newItem = type === MediaType.CARD
      ? this.createNewCardItem(initialValue)
      : this.createNewFileItem(initialValue);

    currentCard.push(newItem as FormGroup<MediaItemForm>);
    this.openedCard.set(currentCard);
    this.cardChangeSignal.update(val => val + 1);
    this.updateFormValue();
  }

  /**
   * Deletes selected items.
   * @param indices Array of indices to delete.
   */
  public deleteItems(indices: number[]): void {
    const currentCard = this.openedCard();

    // Store the original items reference for parent lookup
    const originalCardReference = currentCard;

    // Create updated controls (without deleted items)
    const updatedControls = currentCard.controls.filter((_, index) => !indices.includes(index));
    const newFormArray = new FormArray(updatedControls);

    // Update the navigation history
    this.navigationHistory.update(history => {
      const lastEntry = history[history.length - 1];

      if (lastEntry) {
        lastEntry.card = newFormArray;
      }
      return history;
    });

    // Update the opened card view
    this.openedCard.set(newFormArray);

    // Update the root form if we're at the root level
    if (this.navigationHistory().length === 1) {
      this.rootCardFolder.set(newFormArray);
      this.mediaLibraryForm = newFormArray;
      this.mediaLibraryFormSubject.next(newFormArray);
    } else {
      // If we're in a nested folder, update the parent's items FormArray
      const parentHistory = this.navigationHistory()[this.navigationHistory().length - 2];

      if (parentHistory && parentHistory.card) {
        const parentCard = parentHistory.card;

        // Find the parent using the original reference, not the new one
        const parentIndex = parentCard.controls.findIndex(control => {
          return this.isCardItemForm(control.controls) && control.controls.items === originalCardReference;
        });

        if (parentIndex !== -1) {
          const parentControl = parentCard.at(parentIndex);

          if (parentControl && this.isCardItemForm(parentControl.controls)) {
            // Update the parent's items with the new array content
            parentControl.controls.items.clear();

            updatedControls.forEach(control => {
              const controls = parentControl.controls;

              if (!this.isCardItemForm(controls)) {
                return;
              }

              controls.items.push(control);
            });

            // Ensure form value is updated
            this.updateFormValue();
          }
        }
      }
    }

    // Clear selection state
    this.selectedCardItem.set(null);
    this.cardChangeSignal.update(val => val + 1);
    this.updateFormValue();
  }

  /**
   * Updates an item.
   *
   * @param index Index of the item to update.
   *
   * @param value New values for the item.
   */
  public updateItem(index: number, value: Partial<MediaItem>): void {
    const currentCard = this.openedCard();
    const item = currentCard.at(index);

    if (item) {
      item.patchValue(value);

      this.updateFormValue();
    }
  }

  /**
   * Updates selection state for all items.
   *
   * @param isSelected New selection state.
   */
  public updateAllItemsSelection(isSelected: boolean): void {
    const currentCard = this.openedCard();

    // Update form controls
    currentCard.controls.forEach(item => {
      item.patchValue({ isSelected });
    });

    // In single select mode, also update selected card item
    if (!this.isSelectionModeActive()) {
      // If selecting all in single mode, select the first item
      // If deselecting all, clear the selection
      this.selectedCardItem.set(isSelected && currentCard.length > 0 ? {
        index: 0,
        value: currentCard.at(0)?.value as MediaItem
      } : null);
    }

    this.updateFormValue();
  }

  /**
   * Handles item selection based on the current selection mode.
   *
   * @param index Index of the item to select/deselect.
   */
  public handleItemSelection(index: number): void {
    const currentCard = this.openedCard();
    const item = currentCard.at(index);

    if (!item) {
      return;
    }

    const isCurrentlySelected = item.get('isSelected')?.value;

    if (!this.isSelectionModeActive()) {
      // Single select mode - update selected card item
      // Clear all selections first
      currentCard.controls.forEach(control => {
        control.patchValue({ isSelected: false });
      });

      // Toggle selection for clicked item
      item.patchValue({ isSelected: !isCurrentlySelected });

      if (!isCurrentlySelected) {
        this.isSomeButNotEveryPageItemSelected.set(true);
      }

      // Update selected card item
      this.selectedCardItem.set(!isCurrentlySelected ? {
        index,
        value: item.value as MediaItem
      } : null);
    } else {
      // Multi-select mode - toggle selection
      item.patchValue({ isSelected: !isCurrentlySelected });
    }

    if (this.isPropertiesCollapsed() && !this.isSelectionModeActive()) {
      this.isPropertiesCollapsed.set(false);
    }

    this.updateFormValue();
  }

  /**
   * Toggles selection mode.
   */
  public toggleSelectionMode(): void {
    const newState = !this.isSelectionModeActive();

    this.isSelectionModeActive.set(newState);

    if (!newState) {
      // When exiting selection mode, clear all selections
      this.clearSelection();
      return;
    }
  }

  /**
   * Clears all selections and resets selection state.
   */
  public clearSelection(): void {
    const currentCard = this.openedCard();

    currentCard.controls.forEach(item => {
      item.patchValue({ isSelected: false });
    });

    this.selectedItems.set(new Set());
    this.selectedCardItem.set(null);
    this.updateFormValue();
  }

  /**
   * Navigates to a folder.
   *
   * @param folder FormGroup to navigate to.
   */
  public navigateToFolder(folder: FormGroup<MediaItemForm>): void {
    this.navigationHistory.update(history => {
      const controls = folder.controls;

      if (!this.isCardItemForm(controls) || !controls.items) {
        return history;
      }

      this.openedCard.set(controls.items);
      this.clearSelection();

      return [
        ...history,
        {
          card: controls.items,
          label: controls.label.value,
          isCurrentOpenCard: true
        }
      ];
    });
  }

  /**
   * Navigates back to the previous folder.
   */
  public navigateBack(): void {
    if (!this.canNavigateBack()) {
      return;
    }

    this.navigationHistory.update(history => {
      const newHistory = history.slice(0, -1);
      const lastFolder = newHistory[newHistory.length - 1];

      this.openedCard.set(lastFolder.card);
      this.clearSelection();

      return newHistory;
    });
  }

  /**
   * Navigates to a specific level.
   *
   * @param level
   */
  public navigateToLevel(level: number): void {
    const history = this.navigationHistory();

    if (level >= history.length) {
      return;
    }

    this.navigationHistory.update(history => {
      const newHistory = history.slice(0, level + 1);
      const targetFolder = newHistory[newHistory.length - 1];

      this.openedCard.set(targetFolder.card);
      this.clearSelection();

      return newHistory;
    });
  }

  /**
   * Toggles properties panel collapsed state.
   */
  public togglePropertiesCollapsed(): void {
    this.isPropertiesCollapsed.update(state => !state);
  }

  /**
   * Creates a new file item form group.
   *
   * @param initialValue Optional initial values for the file item.
   *
   * @returns
   */
  private createNewFileItem(initialValue?: Partial<MediaItem>): FormGroup<IFileItemForm> {
    return this.formBuilder.group<IFileItemForm>({
      type: new FormControl(MediaType.FILE, { nonNullable: true }),
      label: new FormControl(initialValue?.label || '', { nonNullable: true }),
      isSelected: new FormControl(initialValue?.isSelected || false, { nonNullable: true }),
      thumbnailId: new FormControl(initialValue?.thumbnail?.id || null),
      thumbnail: new FormControl(initialValue?.thumbnail || null),
      file: new FormControl(initialValue && 'file' in initialValue ? initialValue.file ?? null : null),
      fileId: new FormControl(initialValue && 'fileId' in initialValue && initialValue.fileId ? initialValue.fileId : null),
      fileTags: new FormControl(initialValue && 'file' in initialValue && initialValue.file ? initialValue.file.tags : [], { nonNullable: true })
    });
  }

  /**
   * Creates a new card item form group.
   *
   * @param initialValue Optional initial values for the card item.
   *
   * @returns
   */
  public createNewCardItem(initialValue?: Partial<MediaItem>): FormGroup<MediaItemForm> {
    return this.formBuilder.group<MediaItemForm>({
      type: new FormControl(MediaType.CARD, { nonNullable: true }),
      label: new FormControl(initialValue?.label || 'New Card', { nonNullable: true }),
      isSelected: new FormControl(initialValue?.isSelected || false, { nonNullable: true }),
      isThumbnailAsDetailImage: new FormControl(initialValue && 'isThumbnailAsDetailImage' in initialValue ? initialValue.isThumbnailAsDetailImage ?? false : false, { nonNullable: true }),
      thumbnailId: new FormControl(initialValue?.thumbnail?.id || null),
      thumbnail: new FormControl(initialValue?.thumbnail || null),
      items: new FormArray<FormGroup<MediaItemForm>>([])
    });
  }

  /**
   * Type guard for card item form.
   *
   * @param controls Form controls to check.
   *
   * @returns Boolean indicating if form controls are from a card item form.
   */
  private isCardItemForm(controls: MediaItemForm): controls is ICardItemForm {
    return controls.type.value === MediaType.CARD && 'items' in controls && controls.items instanceof FormArray;
  }
}
