import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MediaSource } from '@newroom-connect/library/types';

@Component({
  standalone: true,
  selector: 'nrc-audio',
  templateUrl: './audio.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AudioComponent {
  @Input({ required: true }) public sources: MediaSource[] = [];
}
