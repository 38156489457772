import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, signal } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject, debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs';

import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IconComponent
  ],
  selector: 'nrc-search',
  templateUrl: './search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public placeholder = '';
  @Input() public debounceTime = 500;
  @Input() public isCollapsible = true;

  @Output() public searchEvent = new EventEmitter<string>();

  public searchFormGroup = new FormGroup({
    searchInput: new FormControl<string>('')
  });

  public searchEnabled = signal<boolean>(false);

  private destroy$ = new Subject<void>();

  /**
   *
   */
  public ngOnInit(): void {
    this.searchFormGroup.controls.searchInput.valueChanges.pipe(
      takeUntil(this.destroy$),
      filter(searchTerm => typeof searchTerm === 'string' && (searchTerm.length === 0 || searchTerm.length > 2)),
      debounceTime(this.debounceTime),
      distinctUntilChanged()
    ).subscribe(searchTerm => this.searchEvent.emit(searchTerm ?? ''));
  }

  /**
   *
   */
  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isCollapsible'] && !changes['isCollapsible'].currentValue) {
      this.searchEnabled.set(true);
    }
  }

  /**
   * Toggle the `enabled` state of the search.
   */
  public toggleSearchEnabled(): void {
    this.searchEnabled.update(searchEnabled => !searchEnabled);
  }

  /**
   * Reset the value of the search input form control.
   */
  public resetSearchInput(): void {
    this.searchFormGroup.controls.searchInput.setValue('');
  }
}
