import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { JwtHelper } from '@newroom-connect/library/helpers';
import { IAPIListResponse, IUserLicense } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';
import { SessionService } from '../session/session.service';

export interface IListUserLicensesOptions {
  onlyUnassigned?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserService extends EntityService {
  public searchableProperties = [];

  /**
   * @constructor
   *
   * @param sessionService
   */
  constructor(
    private readonly sessionService: SessionService
  ) {
    super();
  }

  /**
   * List all licenses of a specified user.
   *
   * @param userId The ID of the user to list licenses for.
   * @param options
   *
   * @returns The list of licenses for the user.
   */
  public listUserLicenses(userId: string, options?: IListUserLicensesOptions): Observable<IAPIListResponse<IUserLicense>> {
    return this.apiService.list<IAPIListResponse<IUserLicense>>(`users/${userId}/licenses`, undefined, {
      filters: options?.onlyUnassigned ? { project: null } : undefined
    });
  }

  /**
   * List the available licenses for the current user.
   *
   * @param options
   *
   * @returns
   *
   * @throws
   */
  public listUserLicensesForCurrentUser(options?: IListUserLicensesOptions): Observable<IAPIListResponse<IUserLicense>> {
    const idToken = this.sessionService.getUserSession()?.getIdToken();

    if (!idToken) {
      throw new Error('Unable to list licenses for current user: No ID token is available');
    }

    const idTokenDecoded = JwtHelper.decodeToken<Record<string, string>>(idToken);

    return this.listUserLicenses(idTokenDecoded['profile:userId'], options);
  }
}
