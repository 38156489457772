import { Injectable, signal } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import { lastValueFrom } from 'rxjs';
import { IAreaElement, IAreaElementActionPreset, IAPIListResponse, IListOptions, ActionName, ActionValueType } from '@newroom-connect/library/interfaces';
import { AreaElementEvent } from '@newroom-connect/library/types';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';

export interface IUpdateAreaElementInput extends Omit<IAreaElement, 'translations'> {
  translations?: IUpdateAreaElementTranslationInput[];
}

export interface IUpdateAreaElementTranslationInput {
  code: string;
  action?: IAreaElementActionInput;
  description?: string;
  backgroundFileId?: string;
  fileOpacity?: number;
}

export interface IAreaElementActionInput<T extends ActionName = ActionName> {
  name: T;
  presetId: string;
  value: ActionValueType<T>;
}

export interface IGenerateAreaElementOptions {
  areaId: string;
  preset: IAreaElementActionPreset[];
  languageCodes: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AreaElementService extends EntityService {
  public readonly areaElementSelectedSig = signal<IAreaElement | null>(null);
  public readonly searchableProperties = ['name'];
  public static readonly DEFAULT_AREA_ELEMENT_NAME = 'Untitled Element';

  private static readonly DEFAULT_AREA_ELEMENT_WIDTH = 10;
  private static readonly DEFAULT_AREA_ELEMENT_HEIGHT = 10;
  private static readonly DEFAULT_AREA_ELEMENT_RADIUS = 9999;
  private static readonly DEFAULT_AREA_ELEMENT_EVENT: AreaElementEvent = { name: 'NONE' };
  private static readonly DEFAULT_AREA_ELEMENT_BASE_COLOR = '#000000ff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON_COLOR = '#ffffffff';
  private static readonly DEFAULT_AREA_ELEMENT_ICON = 'trigger';

  private cachedAreaElementActionPresets?: IAPIListResponse<IAreaElementActionPreset>;

  /**
   * Generate a new area element based on the given input and some default parameters
   *
   * @param input - The input options for generating the area element
   *
   * @returns The generated area element
   */
  public generateAreaElement(input: IGenerateAreaElementOptions): IAreaElement {
    const [defaultActionPreset] = input.preset;

    const areaElement: IAreaElement = {
      id: uuidv4(),
      areaId: input.areaId,
      name: AreaElementService.DEFAULT_AREA_ELEMENT_NAME,
      event: AreaElementService.DEFAULT_AREA_ELEMENT_EVENT,
      boundingBox: {
        width: AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH,
        height: AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT,
        top: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_WIDTH / 2),
        left: 50 - (AreaElementService.DEFAULT_AREA_ELEMENT_HEIGHT / 2)
      },
      radius: AreaElementService.DEFAULT_AREA_ELEMENT_RADIUS,
      isEnabled: true,
      isLocked: false,
      baseColor: AreaElementService.DEFAULT_AREA_ELEMENT_BASE_COLOR,
      icon: AreaElementService.DEFAULT_AREA_ELEMENT_ICON,
      iconColor: AreaElementService.DEFAULT_AREA_ELEMENT_ICON_COLOR,
      translations: input.languageCodes.map(code => ({
        languageCode: code,
        action: {
          name: defaultActionPreset.name,
          value: {} as ActionValueType<typeof defaultActionPreset.name>,
          preset: defaultActionPreset
        },
        areaElementId: input.areaId,
        description: ''
      }))
    };

    return areaElement;
  }

  /**
   * Generate update input for area elements.
   *
   * @param areaElements The area elements to generate update input for.
   *
   * @returns The generated update input.
   */
  public generateAreaElementsUpdateInput(areaElements?: IAreaElement[]): IUpdateAreaElementInput[] {
    if (!areaElements || !ArrayHelper.isNotEmpty(areaElements)) {
      return [];
    }

    return areaElements.map((areaElement) => ({
      id: areaElement.id,
      areaId: areaElement.areaId,
      name: areaElement.name,
      event: areaElement.event,
      boundingBox: areaElement.boundingBox,
      radius: areaElement.radius,
      isEnabled: areaElement.isEnabled,
      isLocked: areaElement.isLocked,
      baseColor: areaElement.baseColor ?? null,
      icon: areaElement.icon,
      iconColor: areaElement.iconColor,
      translations: areaElement.translations.map((translation) => ({
        code: translation.languageCode,
        action: translation.action ? {
          name: translation.action.name as ActionName,
          value: translation.action.value as ActionValueType<typeof translation.action.name>,
          presetId: translation.action.preset.id,
          actionId: translation.action.id
        } : undefined,
        description: translation.description,
        backgroundFileId: translation.backgroundFileId,
        fileOpacity: translation.fileOpacity
      }))
    }));
  }

  /**
   * Fetch and cache list of area element action presets
   *
   * @param options Optional list options
   *
   * @returns List of area element action presets
   */
  public async listAreaElementActionPreset(options?: IListOptions): Promise<IAPIListResponse<IAreaElementActionPreset>> {
    if (this.cachedAreaElementActionPresets) {
      return this.cachedAreaElementActionPresets;
    }

    const result = await lastValueFrom(super.list<IAreaElementActionPreset>('presets/areas/actions', undefined, options));

    this.cachedAreaElementActionPresets = result;

    return result;
  }
}
