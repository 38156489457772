import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'firstCharOfWords'
})
export class FirstCharOfWordsPipe implements PipeTransform {
  /**
   * Extract the first character of each word inside the input string and join them together.
   * If only one word is present, extract the first two characters of the word.
   *
   * @param value The input string to extract the first character of each word from.
   * @param maxCharacters The maximum number of characters to extract.
   *
   * @returns The extracted and joined first character string.
   *
   * @example value: "Product Information Platform" -> "PIP"
   * @example value: "Vechta Campus" -> "VC"
   * @example value: "Newroom" -> "Ne"
   */
  public transform(value: string, maxCharacters = 3): unknown {
    const words = value.split(' ');

    if (words.length === 1) {
      return words[0].slice(0, 2);
    }

    return words.map(word => word.slice(0, 1)).join('').slice(0, maxCharacters);
  }
}
