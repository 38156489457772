@if (cubemapFaceMapSig()) {
  <nrc-cubemap class="w-full h-full"
    [faceMap]="{
      px: cubemapFaceMapSig()!.px,
      py: cubemapFaceMapSig()!.py,
      pz: cubemapFaceMapSig()!.pz,
      nx: cubemapFaceMapSig()!.nx,
      ny: cubemapFaceMapSig()!.ny,
      nz: cubemapFaceMapSig()!.nz
    }"
  />
}
