import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'toArray'
})
export class ToArrayPipe implements PipeTransform {
  /**
   * Transform a value to an array.
   *
   * @param input The value to be converted to an array.
   *
   * @returns The transformed value as an array.
   */
  public transform(input: unknown): unknown[] {
    if (Array.isArray(input)) {
      return input;
    } else if (input instanceof Set) {
      return Array.from(input);
    } else if (input instanceof Map) {
      return Array.from(input.entries());
    } else if (input instanceof Object) {
      return Object.entries(input);
    }

    return [];
  }
}
