import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { EntityService } from '../entity/entity.service';

export interface ITranslateInputOutput {
  from: string;
  to: string;
  payload: { [key: string]: string }
}

@Injectable({
  providedIn: 'root'
})
export class AutoTranslateService extends EntityService {
  protected searchableProperties = [];

  /**
   * Translates the values of the keys in the provided JSON object.
   *
   * @param autoTranslateParams
   * @returns
   */
  public translateAreaElementActionInputs(autoTranslateParams: ITranslateInputOutput): Observable<ITranslateInputOutput> {
    const translationParams = {
      from: autoTranslateParams.from,
      to: autoTranslateParams.to,
      payload: autoTranslateParams.payload
    };

    return this.apiService.post<ITranslateInputOutput>('translate/json', translationParams);
  }
}
