<div
  class="group relative flex items-center text-xs/[14px] text-[#f8f8f8] transition-[color] duration-250 ease-in-out px-4 py-2"
  tabindex="0"
  aria-hidden="true"
  (click)="$event.stopPropagation(); clickEvent.emit();"
>
  <span
    class="flex justify-center items-center mr-2 w-5 h-5 bg-transparent
      border-solid rounded-sm cursor-pointer
      transition-all duration-250 ease-in-out
      group-[]:bg-transparent"
    [ngClass]="{
      'border-1 border-gray-400': checkStatus === 'unchecked',
      'border-10 animate-shrink-bounce before:content-[\'\'] before:absolute before:border-b-1.5 before:border-b-solid': checkStatus === 'checked' || checkStatus === 'semi-checked',
      'border-[#454545] before:top-3 before:w-2 before:h-2 before:border-b-[#fefefe]': checkStatus === 'semi-checked',
      'border-cyan before:top-5 before:left-5 before:border-r-1.5 before:border-r-solid before:border-r-transparent before:border-b-transparent before:rotate-45 before:origin-bottom-left before:animate-checkbox-check': checkStatus === 'checked'
    }"
  ></span>
</div>
