<div
  class="h-10 rounded-full bg-white/10 flex items-center transition-width"
  [ngClass]="{
    'w-10 justify-center': !searchEnabled() && isCollapsible,
    'p-5 justify-between': searchEnabled(),
    'w-64': searchEnabled() && isCollapsible,
    'w-full': !isCollapsible,
  }"
>
  <form
    class="h-5 flex justify-between items-center"
    [ngClass]="{
      'w-0': !searchEnabled(),
      'grow': searchEnabled() && isCollapsible
    }"
    [formGroup]="searchFormGroup"
  >
    <input
      type="text"
      class="w-full h-full text-sm bg-transparent border-transparent focus:border-transparent outline-none focus:outline-none p-0 placeholder:text-white/70 placeholder:text-ellipsis focus:ring-0 transition-width"
      [placeholder]="placeholder"
      formControlName="searchInput"
    />

    @if (searchEnabled() && searchFormGroup.controls.searchInput.value && searchFormGroup.controls.searchInput.value.length > 0) {
      <button
        class="h-full"
        type="button"
        tabindex="-1"
        (click)="null"
      >
        <nrc-icon
          class="w-4 h-4 opacity-50"
          [name]="'clear'"
          (click)="resetSearchInput()"
        />
      </button>
    }
  </form>

  <nrc-icon
    class="w-4 h-4 hover:opacity-100 transition-opacity"
    [ngClass]="{
      'opacity-50': !searchEnabled(),
      'opacity-100 ml-3': searchEnabled(),
      'cursor-pointer': isCollapsible
    }"
    [name]="'search'"
    (click)="isCollapsible ? toggleSearchEnabled() : null"
  />
</div>
