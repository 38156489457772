import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  standalone: true,
  selector: 'nrc-youtube',
  templateUrl: './youtube.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class YouTubeComponent implements OnChanges {
  @Input({ required: true }) public source = '';
  @Input() public title = 'YouTube Video Player';

  public sourceSanitized = '';

  constructor(
    private readonly sanitizer: DomSanitizer
  ) {}

  /**
   *
   */
  public ngOnChanges(): void {
    this.sourceSanitized = this.source ? this.sanitizer.bypassSecurityTrustResourceUrl(this.source) as string : '';
  }
}
