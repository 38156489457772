export class ApiError extends Error {
  public statusCode: number;

  /**
   * @constructor
   *
   * @param message
   * @param name
   * @param statusCode
   */
  constructor(message: string, name?: string, statusCode?: number) {
    super(message);

    this.name = name ?? 'ApiError';
    this.statusCode = statusCode ?? 500;
  }
}
