import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, Signal, SimpleChanges, WritableSignal, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';
import { IListItem, IPaginationEvent, IPaginationMeta } from '@newroom-connect/library/interfaces';

import { ButtonComponent } from '../../buttons/button/button.component';
import { InputSelectComponent } from '../../inputs/input-select/input-select.component';

@Component({
  standalone: true,
  imports: [
    ReactiveFormsModule,
    ButtonComponent,
    InputSelectComponent
  ],
  selector: 'nrc-list-paginator',
  templateUrl: './list-paginator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListPaginatorComponent implements OnChanges {
  @Input({ required: true }) public items?: Signal<IListItem[] | null>;
  @Input({ required: true }) public meta?: IPaginationMeta;
  @Input() public pageSizeOptions = [{ value: 10 }, { value: 25 }, { value: 100 }];
  @Input() public overflowParentElement?: HTMLElement;

  @Output() public paginationEvent = new EventEmitter<IPaginationEvent>();

  public actionRole = ActionRole;
  public buttonType = ButtonType;

  public paginationForm = new FormGroup({
    pageSize: new FormControl(10, { nonNullable: true, validators: [Validators.required] }),
    page: new FormControl(1, { nonNullable: true, validators: [Validators.required, Validators.min(1)] })
  });

  public itemStartIndex = signal<number>(0);
  public itemEndIndex = signal<number>(0);
  public lastPage: WritableSignal<number>;

  /**
   * @constructor
   */
  constructor() {
    this.paginationForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(paginationFormValue => {
      const pageClipped = Math.min(this.calculateLastPage(), this.paginationForm.controls.page.value);

      if (pageClipped !== this.paginationForm.controls.page.value) {
        this.paginationForm.controls.page.patchValue(pageClipped);
      } else {
        this.paginationEvent.emit(paginationFormValue as IPaginationEvent);

        this.setItemIndexes();
      }

      this.lastPage.set(this.calculateLastPage());
    });

    this.lastPage = signal<number>(0);
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['meta'].currentValue) {
      const currentMeta = changes['meta'].currentValue as IPaginationMeta;

      this.paginationForm.patchValue({
        page: currentMeta.page,
        pageSize: currentMeta.pageSize
      }, { emitEvent: false });
    }

    this.setItemIndexes();

    this.lastPage.set(this.calculateLastPage());
  }

  /**
   * Paginate the given pages.
   * To go back, use a negative page number.
   *
   * Emitting the pagination event will be done inside the value changes listener of the pagination form.
   *
   * @param pages The number of pages to go forward or back.
   */
  public paginate(pages: number): void {
    this.paginationForm.controls.page.setValue(this.clipPage(this.paginationForm.controls.page.value + pages));
  }

  /**
   * Clip the page into a correct page value, if the given page value is out of bounds.
   *
   * @param page The page value to clip.
   *
   * @returns The clipped page value.
   */
  private clipPage(page: number): number {
    return Math.max(1, Math.min(page, this.lastPage()));
  }

  /**
   * Set the item start and end index based on the pagination form values and the current items.
   */
  private setItemIndexes(): void {
    this.itemStartIndex.set(Math.max(0, this.paginationForm.controls.pageSize.value * (this.paginationForm.controls.page.value - 1)));

    this.itemEndIndex.set(Math.min(
      this.paginationForm.controls.pageSize.value * this.paginationForm.controls.page.value - 1,
      this.meta ? this.meta.total - 1 : this.items?.()?.length ?? 0
    ));
  }

  /**
   * Calculate the last page based on the total number of items and the current page size.
   *
   * @returns The last page number.
   */
  private calculateLastPage(): number {
    return Math.ceil((this.meta?.total ?? 0) / this.paginationForm.controls.pageSize.value);
  }
}
