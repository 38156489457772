<div #wysiwygEditor></div>

<ng-template #WYSIWYGEditorUploadModalContent>
  @if (project) {
    <nrc-file-uploader
      (filesChangedEvent)="uploadFiles($event, project)"
      (fileSizeLimitExceededEvent)="handleFileSizeLimitExceededEvent($event.name)"
    />

    <hr class="w-full h-[2px] my-6 bg-white/5 border-none">

    <div class="w-full flex justify-end items-center">
      <nrc-button
        class="relative"
        [label]="'PAGES.FILE_LIST.UPLOAD_FILES_MODAL.BUTTONS.DONE.LABEL' | translate"
        (clickEvent)="toggleUploadModal()"
      />
    </div>
  }
</ng-template>

@if (isFileLibraryModalVisible()) {
  <nrc-file-library-modal
    class="absolute h-[50rem] w-full right-0 -top-[25rem]"
    [currentFile]=""
    [fileListItemsSig]="fileListInputSig"
    [metaSig]="fileListMetaSig"
    [isFileListLoading]="isFileListLoading"
    [hideSelection]="false"
    (uploaderFilesChanged)="null"
    (initEvent)="isFileLibraryModalVisible() ? handleFileListOptions() : null"
    (showFileLibraryEvent)="handleFileListOptions()"
    (fileUploadEvent)="handleFileLibraryModalFileUploadEvent($event)"
    (fileListEvent)="handleFileListOptions($event)"
    (fileSearchEvent)="handleFileSearchEvent($event)"
    (closeEvent)="handleFileLibraryModalCloseEvent($event)"
  />
}

