<div class="w-full flex justify-between space-x-4 px-5 py-3.5" tabindex="0">
  <!-- User avatar -->
  <div class="w-14 h-14">
    <div class="bg-violet-500 w-full h-full rounded-full flex justify-center items-center">
      <span>{{ firstName() | slice:0:1 }}{{ lastName() | slice:0:1 }}</span>
    </div>
  </div>

  <div class="flex flex-col grow space-y-1">
    <p class="font-bold">{{ fullName() }}</p>
    <p class="text-white/50">{{ email() }}</p>
    <a href="#">View Account</a>
  </div>
</div>

<hr>

<div class="flex justify-between items-center px-6 py-3.5">
  <span>Logout</span>

  <button class="h-full w-4" type="button" (click)="logoutEvent.emit()">
    <nrc-icon
      class="w-full h-full opacity-50 transition-opacity duration-300 hover:opacity-100"
      [name]="'logout'"
      [fillColor]="'white'"
    />
  </button>
</div>
