import { AfterViewInit, Component, ElementRef, forwardRef, OnChanges, signal, SimpleChanges, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { HttpEventType } from '@angular/common/http';
import { catchError, takeUntil, tap } from 'rxjs';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { NGXLogger } from 'ngx-logger';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ClassicEditor } from 'ckeditor5';
import { TranslatePipe } from '@newroom-connect/library/pipes';
import { fadeInOutAnimation } from '@newroom-connect/library/animations';
import { IAPIListResponseMeta, IFileHydrated, IFileUploadItem, IListItem, IListOptions, IProject } from '@newroom-connect/library/interfaces';
import { ActionRole, Level, ModalSize } from '@newroom-connect/library/enums';
import { FileBroadcastEvent, FileErrorHandlerService, FileService, FileUploadService, ListService, MediaService, ProjectService, ToastService, TranslationService } from '@newroom-connect/library/services';

import { ModalComponent, ModalService } from '../modal/modal.component';
import { FileLibraryModalComponent, FileUploaderComponent } from '../files';
import { ButtonComponent } from '../buttons';
import { InputComponent } from '../inputs/input.component';

import { editorConfig } from './configuration/editor.configuration';
import { editorPlugins } from './configuration/plugins.configuration';

@Component({
  selector: 'nrc-wysiwyg-editor',
  standalone: true,
  imports: [
    CommonModule,
    CKEditorModule,
    ModalComponent,
    FileUploaderComponent,
    ButtonComponent,
    TranslatePipe,
    FileLibraryModalComponent
  ],
  templateUrl: './wysiwyg-editor.component.html',
  styleUrls: ['./wysiwyg-editor.component.css'],
  animations: [fadeInOutAnimation],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => WYSIWYGEditorComponent),
      multi: true
    }
  ]
})
export class WYSIWYGEditorComponent extends InputComponent<string> implements AfterViewInit, OnChanges {
  @ViewChild('wysiwygEditor') public editorElement!: ElementRef<HTMLDivElement>;
  @ViewChild('WYSIWYGEditorUploadModalContent') public WYSIWYGEditorUploadModalContent!: TemplateRef<any>;

  public fileListInputSig = signal<IListItem<IFileHydrated>[]>([]);
  public fileListMetaSig = signal<IAPIListResponseMeta | undefined>(undefined);
  public editorData = signal<string>('');

  public isFileUploadModalVisible = false;
  public isFileLibraryModalVisible = signal<boolean>(false);
  public project: IProject|null;
  public editor!: ClassicEditor;
  public uploadedFile!: IFileHydrated | void;
  public currentFile?: IFileHydrated;
  public isFileListLoading = false;
  public mediaType?: string[];
  public ActionRole = ActionRole;

  public readonly config = editorConfig;

  public isLayoutReady = false;

  constructor(
    private readonly logger: NGXLogger,
    private readonly projectService: ProjectService,
    private readonly toastService: ToastService,
    private readonly translationService: TranslationService,
    private readonly fileUploadService: FileUploadService,
    private readonly listService: ListService,
    private readonly mediaService: MediaService,
    private readonly fileErrorHandlerService: FileErrorHandlerService,
    private readonly modalService: ModalService
  ) {
    super();
    this.project = this.projectService.watchCurrentProject()();

    this.modalService.modalClosed$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      if (result && result.id === 'WYSIWYGFileUpload') {
        this.isFileUploadModalVisible = false;
      }
    });
  }

  /**
   *
   */
  public override async ngAfterViewInit(): Promise<void> {
    if (!this.editorElement) {
      return;
    }

    this.editor = await ClassicEditor.create(this.editorElement.nativeElement, { ...this.config, plugins: editorPlugins });

    if (this.formControl) {
      this.editor.setData(this.formControl.getRawValue() ?? '');
    }

    // Listen to the upload button click event
    this.editor.listenTo(this.editor, 'uploadButtonClickedEvent', () => {
      this.toggleUploadModal();
    });

    // Listen to the File library button click event
    this.editor.listenTo(this.editor, 'fileLibraryButtonClickedEvent', () => {
      this.isFileLibraryModalVisible.set(true); // Show the div when the upload button is clicked
    });

    // Listens to changes inside the editor => update the formControl onChanges.
    this.editor.model.document.on('change:data', () => {
      this.formControl.patchValue(this.editor.getData());

      this.writeValue(this.editor.getData());
    });

    // Work around to fix the PoweredBy being displayed after the modal is closed.
    // Source: https://github.com/ckeditor/ckeditor5/issues/727
    const body = this.editor.ui.view.body.bodyCollectionContainer;

    if (body && this.editor.ui.view.element) {
      body.remove();

      this.editor.ui.view.element.appendChild(body);
    }

    this.isLayoutReady = true;
  }

  /**
   *
   * @param changes
   */
  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['formControl'] && this.isLayoutReady) {
      this.editor.setData(this.formControl.value ?? '');
    }
  }

  /**
   *
   * @param filename
   */
  public handleFileSizeLimitExceededEvent(filename: string): void {
    this.toastService.setMessage({
      message: this.translationService.translate('ERROR.FILE_TOO_LARGE.MESSAGE', { fileName: filename }),
      level: Level.ERROR
    }, 5000);
  }

  /**
   * Upload all files in the passed file list which have not been uploaded yet.
   *
   * @param event
   * @param project
   */
  public uploadFiles(event: IFileUploadItem[], project: IProject): void {
    const filesToUpload = event.filter(fileListItem => fileListItem.status() === 'not-started');

    for (const fileListItem of filesToUpload) {
      this.uploadFile(fileListItem, project);
    }
  }

  /**
   * Upload a single file from a file list item.
   *
   * @param fileListItem A single item from the file list.
   * @param project The project to upload the file to.
   */
  public uploadFile(fileListItem: IFileUploadItem, project: IProject): void {
    this.fileUploadService.uploadFile(project.id, fileListItem.file).pipe().subscribe(fileUploadEvent => {
      if (!fileUploadEvent) {
        return;
      }

      this.uploadedFile = this.fileUploadService.handleFileUploadEvent(fileUploadEvent, fileListItem, FileBroadcastEvent.UPLOADED_FILE);

      if (this.uploadedFile) {
        this.insertFileInEditor(this.uploadedFile as IFileHydrated);
      }
    });
  }

  /**
   *
   * @param file
   */
  private insertFileInEditor(file: IFileHydrated): void {
    if (file) {
      if (!this.editor) {
        return;
      }
      this.editor.execute('insertImage', {
        source: [
          {
            src: file.source,
            alt: file.title
          }
        ]
      });
    }
  }

  /**
   * Handle a search event from the file library modal where a new file list request is made based on the given search term.
   *
   * @param searchTerm The search term to use for the file list request.
   */
  public handleFileSearchEvent(searchTerm: string): void {
    this.handleFileListOptions(this.listService.generateListOptionsForSearch(searchTerm));
  }

  /**
   * Handle a pagination event from the file library modal.
   *
   * @param event An optional pagination event to handle. Otherwise, the last list options are used.
   */
  public handleFileListOptions(event?: IListOptions): void {
    if (event) {
      this.listService.lastListOptions$.next(event);
    }

    this.isFileListLoading = true;

    if (this.project && this.project.id) {
      this.mediaService.listSources(this.project.id, this.listService.lastListOptions$.getValue(), this.mediaType).pipe()
        .subscribe(fileListResponse => {
          this.fileListInputSig.set(fileListResponse.data.map((file: IFileHydrated) => {
            return {
              value: file,
              actionRole: ActionRole.PRIMARY
            };
          }));

          this.fileListMetaSig = signal(fileListResponse.meta);

          this.isFileListLoading = false;
        });
    }
  }

  /**
   *
   * @param searchTerm
   */
  public handleFileLibraryModalSearchEvent(searchTerm: string): void {
    this.handleFileListOptions(this.listService.generateListOptionsForSearch(searchTerm));
  }

  /**
   * Upload a single file received from a file upload event.
   *
   * @param event The file upload event containing the file to upload in a list.
   */
  public handleFileLibraryModalFileUploadEvent(event: IFileUploadItem[]): void {
    if (event.length !== 1) {
      return;
    }

    const fileUploadItem = event[0];

    if (this.project && this.project.id) {
      this.fileUploadService.uploadFile(this.project.id, fileUploadItem.file).pipe(
        catchError(() => this.fileErrorHandlerService.handleUpdateError()),
        tap(fileUploadEvent => fileUploadEvent ? this.fileUploadService.handleFileUploadEvent(fileUploadEvent, fileUploadItem, FileBroadcastEvent.UPLOADED_FILE) : undefined)
      ).subscribe(fileUploadEvent => {
        if (fileUploadEvent?.type !== HttpEventType.Response) {
          return;
        }

        this.currentFile = FileService.hydrateFile(fileUploadEvent.body);
      });
    }
  }

  /**
   * On the close event of the file library modal, toggle its visibility and update the background file form controls appropriately.
   *
   * @param file
   */
  public handleFileLibraryModalCloseEvent(file: IFileHydrated): void {
    if (!file) {
      this.isFileLibraryModalVisible.set(false);
      return;
    }

    this.isFileLibraryModalVisible.set(false);
    this.insertFileInEditor(file);
  }

  /**
   *
   */
  public toggleUploadModal(): void {
    if (this.isFileUploadModalVisible) {
      this.modalService.close('WYSIWYGFileUpload');
      return;
    }

    const modalRef = this.modalService.open({
      component: ModalComponent,
      inputs: {
        id: 'WYSIWYGFileUpload',
        size: ModalSize.SMALL,
        title: this.translationService.translate('PAGES.FILE_LIST.UPLOAD_FILES_MODAL.TITLE'),
        paddedContent: true
      },
      content: this.WYSIWYGEditorUploadModalContent
    });

    modalRef.instance.closeEvent.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.modalService.close('WYSIWYGFileUpload');
    });

    this.isFileUploadModalVisible = true;
  }
}
