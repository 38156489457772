import { Injectable, Signal, signal } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, forkJoin, of, switchMap, throwError } from 'rxjs';
import { IProject, IAPIListResponse, IListOptions, IProjectSigninConfig } from '@newroom-connect/library/interfaces';
import { ArrayHelper } from '@newroom-connect/library/helpers';

import { EntityService } from '../entity/entity.service';
import { LocalStorageService } from '../local-storage/local-storage.service';

export enum ProjectBroadcastEvent {
  UPDATED_PROJECT = 'updatedProject'
}

export interface ICreateProjectTranslationInput {
  languageCode: string;
  title: string;
}

export interface ICreateProjectInput {
  title: string; // Will be distributed across all translations
  translations: { code: string; }[]; // The language codes which are used for i81n properties of the project
  licenseId: string;
  templateId?: string; // The optional ID of the template to pre-populate the project data with
}

export interface IUpdateProjectTranslationInput {
  code: string;
  title: string;
}

export interface IUpdateProjectAnalyticsInput {
  collectData: boolean;
  providers: IUpdateAnalyticsProviderInput[]
}

export interface IUpdateAnalyticsProviderInput {
  id: string;
  enabled: boolean;
  analyticsKeyId: string;
  presetId: string;
}

export interface IUpdateProjectInput {
  translations?: IUpdateProjectTranslationInput[];
  startArea?: string; // The ID of the start area to set for the project.
  slug?: string;
  startDate?: string;
  endDate?: string;
  analytics?: IUpdateProjectAnalyticsInput
}

@Injectable({
  providedIn: 'root'
})
export class ProjectService extends EntityService {
  public searchableProperties = ['translations[].title', 'userLicense.license.title'];

  private currentProjectSig = signal<IProject | null>(null);
  private currentProjectSigninConfigSig = signal<IProjectSigninConfig | null>(null);

  /**
   * @constructor
   *
   * @param localStorageService
   */
  constructor(
    private readonly localStorageService: LocalStorageService
  ) {
    super();
  }

  /**
   *
   * @returns
   */
  public getCurrentProject(): IProject | null {
    return this.currentProjectSig();
  }

  /**
   *
   * @returns
   */
  public getCurrentSignInConfig(): IProjectSigninConfig | null {
    return this.currentProjectSigninConfigSig();
  }

  /**
   * Set the current project signal with the given project.
   *
   * Also set the ID of the current project in the local storage.
   * If the current project is `null`, remove the key from the local storage.
   *
   * @param project The project to set in the signal and the local storage.
   */
  public setCurrentProject(project: IProject | null): void {
    this.currentProjectSig.set(project);

    if (project) {
      this.localStorageService.set('currentProjectId', project.id);
    } else {
      this.localStorageService.remove('currentProjectId');
    }
  }

  /**
   * Set the current project signal with the given project.
   *
   * Also set the ID of the current project in the local storage.
   * If the current project is `null`, remove the key from the local storage.
   *
   * @param signInConfig The project to set in the signal and the local storage.
   */
  public setCurrentSignInConfig(signInConfig: IProjectSigninConfig | null): void {
    this.currentProjectSigninConfigSig.set(signInConfig);
  }

  /**
   *
   * @returns
   */
  public watchCurrentSignInConfig(): Signal<IProjectSigninConfig | null> {
    return this.currentProjectSigninConfigSig.asReadonly();
  }

  /**
   *
   * @returns
   */
  public watchCurrentProject(): Signal<IProject | null> {
    return this.currentProjectSig.asReadonly();
  }

  /**
   *
   * @param options
   *
   * @returns
   */
  public listProjects(options?: IListOptions): Observable<IAPIListResponse<IProject>> {
    return super.list<IProject>('projects', undefined, options);
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public getProject(projectId: string): Observable<IProject> {
    return this.apiService.get<IProject>(`projects/${projectId}`);
  }

  /**
   *
   * @param projectSlug
   *
   * @returns
   */
  public getProjectBySlug(projectSlug: string): Observable<IProject> {
    return this.listProjects({ filters: { slug: projectSlug } }).pipe(
      switchMap(response => {
        if (ArrayHelper.isNotEmpty(response.data)) {
          return of(response.data[0]);
        }

        return throwError(() => new HttpErrorResponse({
          error: 'Project not found',
          status: 404
        }));
      })
    );
  }

  /**
   *
   * @param input
   *
   * @returns
   */
  public createProject(input: ICreateProjectInput): Observable<IProject> {
    return this.apiService.post<IProject>('projects', input);
  }

  /**
   *
   * @param projectId
   * @param input
   *
   * @returns
   */
  public updateProject(projectId: string, input: IUpdateProjectInput): Observable<IProject> {
    return this.apiService.patch<IProject>(`projects/${projectId}`, input);
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public deleteProject(projectId: string): Observable<IProject> {
    return this.apiService.delete<IProject>(`projects/${projectId}`);
  }

  /**
   *
   * @param projectIds
   *
   * @returns
   */
  public deleteProjects(projectIds: string[]): Observable<IProject[]> {
    return forkJoin(projectIds.map(projectId => this.apiService.delete<IProject>(`projects/${projectId}`)));
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public getProjectSigninConfig(projectId: string): Observable<IProjectSigninConfig> {
    return this.apiService.get<IProjectSigninConfig>(`projects/${projectId}/signin-config`);
  }

  /**
   * @param project
   * @returns
   */
  public isProjectActive(project: IProject): boolean {
    return this.isProjectStarted(project) && !this.isProjectEnded(project);
  }

  /**
   * @param project
   * @returns
   */
  public isProjectEnded(project: IProject): boolean {
    const currentDate = new Date();

    return currentDate > new Date(project.endDate);
  }

  /**
   * @param project
   * @returns
   */
  public isProjectStarted(project: IProject): boolean {
    const currentDate = new Date();

    return currentDate >= new Date(project.startDate);
  }
}
