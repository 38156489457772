import { trigger, transition, style, animate } from '@angular/animations';

export const slideInOutAnimation = trigger('slideInOutAnimation', [
  transition(':enter', [
    style({ transform: 'translateY({{ translateYStart }})' }),
    animate('350ms ease-out', style({ transform: 'translateY({{ translateYEnd }})' }))
  ], { params: { translateYStart: '100%', translateYEnd: '50%' } }),
  transition(':leave', [
    animate('350ms ease-in', style({ transform: 'translateY({{ translateYStart }})' }))
  ], { params: { translateYStart: '100%' } })
]);
