import { Injectable } from '@angular/core';
import { ActionRole } from '@newroom-connect/library/enums';
import { ILicense } from '@newroom-connect/library/interfaces';

@Injectable({
  providedIn: 'root'
})
export class LicenseService {
  public searchableProperties = [];

  /**
   * Get the action role to the corresponding license.
   *
   * The action role is determined by the title of the license.
   * If the action role cannot be determined by the license title, the action role `PRIMARY` will be returned.
   *
   * @param license The license object to get the action role for.
   *
   * @returns The determined action role for the given license.
   */
  public static getActionRoleForLicense(license: ILicense): ActionRole {
    switch (license.title) {
      case 'Starter':
        return ActionRole.SUCCESS;
      case 'Professional':
        return ActionRole.WARNING;
      case 'Free':
        return ActionRole.PRIMARY;
      default:
        return ActionRole.PRIMARY;
    }
  }
}
