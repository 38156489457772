@if (firstName && lastName && userRoleName && profileColor) {
  <div class="group/profileTooltip flex flex-row py-1 pr-2">
    <div class="absolute hidden group-hover/profileTooltip:inline-block" @fadeInOutAnimation>
      <div class="relative bottom-24 right-32 w-80 max-w-full h-auto flex space-x-4 px-7 py-3.5 z-10 bg-[#2d2d2d] rounded-[10px]">
        <div class="w-14 h-14 rounded-full flex justify-center items-center text-xl" [style]="{'background-color': profileColor}">
          <span>{{ firstName | slice:0:1 }}{{ lastName | slice:0:1 }}</span>
        </div>

        <div class="flex flex-col grow space-y-1">
          <p class="font-bold">{{ fullName }}</p>
          <p class="text-white/50">{{ userRoleName }}</p>
        </div>
      </div>
    </div>

    <button class="w-9 h-9" type="button">
      <div class="transition ease-in-out delay-200 relative w-10 h-10 rounded-full text-l flex justify-center items-center bg-white/10 hover:bg-white/25">
        <span>{{ firstName | slice:0:1 }}{{ lastName | slice:0:1 }}</span>
      </div>
    </button>
  </div>
}
