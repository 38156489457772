import { Directive, ElementRef, HostBinding, Input, OnInit, Renderer2, inject } from '@angular/core';

@Directive({
  selector: '[nrcReOrderableItem]',
  standalone: true
})
export class ReOrderableItemDirective implements OnInit {
  @Input() public grabIcon = false;

  private readonly renderer = inject(Renderer2);

  constructor(public elementRef: ElementRef<HTMLElement>) {}

  @HostBinding('class') public get hostClass(): string {
    return 'hover:cursor-grab active:cursor-grabbing';
  }

  /**
   * Appends the re-order icon to the center top of the directive element.
   *
   */
  public ngOnInit(): void {
    if (!this.grabIcon) {
      return;
    }

    const wrapper = this.renderer.createElement('div');

    wrapper.innerHTML = `
      <div class="absolute top-3 left-1/2 transform -translate-x-1/2 flex justify-center items-center w-full transition duration-150 hover:scale-150 hover:cursor-grab active:cursor-grabbing">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10" class="w-3 h-3">
          <path id="drag_handle" d="M4,14a2,2,0,1,1-2-2,2.006,2.006,0,0,1,2,2M2,6A2,2,0,1,0,4,8,2.006,2.006,0,0,0,2,6M2,0A2,2,0,1,0,4,2,2.006,2.006,0,0,0,2,0M8,4A2,2,0,1,0,6,2,2.006,2.006,0,0,0,8,4M8,6a2,2,0,1,0,2,2A2.006,2.006,0,0,0,8,6m0,6a2,2,0,1,0,2,2,2.006,2.006,0,0,0-2-2" transform="translate(0 10) rotate(-90)" fill="#fff"/>
        </svg>
      </div>
    `;

    this.renderer.appendChild(this.elementRef.nativeElement, wrapper);
    this.renderer.setStyle(this.elementRef.nativeElement, 'position', 'relative');
  }
}
