import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormValidatorHelper } from '@newroom-connect/library/helpers';
import { TranslatePipe } from '@newroom-connect/library/pipes';
import { ILoginEvent } from '@newroom-connect/library/interfaces';

import { ButtonComponent } from '../../buttons/button/button.component';
import { InputCheckboxComponent, InputPasswordComponent, InputTextComponent } from '../../inputs';

@Component({
  selector: 'nrc-sign-in-form',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputCheckboxComponent,
    InputPasswordComponent,
    InputTextComponent,
    TranslatePipe
  ],
  templateUrl: './sign-in-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SignInFormComponent {
  @Input() public errorMessage?: string;
  @Input() public signUpRouterLink?: string[];
  @Input() public forgotPassswordRouterLink?: string[];

  @Output() public loginEvent = new EventEmitter<ILoginEvent>();

  public isSubmitted = false;
  public signInLoginForm = new FormGroup({
    email: new FormControl('', { nonNullable: true, validators: [Validators.required, Validators.email] }),
    password: new FormControl('', { nonNullable: true, validators: [Validators.required, FormValidatorHelper.passwordStrength()] }),
    rememberMe: new FormControl(false, { nonNullable: true })
  });

  /**
   * Emit continue event with the current sign up login form value.
   */
  @HostListener('document:keyup.enter')
  public login(): void {
    this.isSubmitted = true;

    if (this.signInLoginForm.valid) {
      this.loginEvent.emit(this.signInLoginForm.getRawValue());
    }
  }
}
