<div
  class="w-full p-2 space-x-2 flex border-l-2 border-solid"
  [ngClass]="{
    'border-transparent bg-transparent-dark': !message || message.level === level.INFO,
    'border-l-error bg-error/30': message?.level === level.ERROR,
    'border-l-warning bg-warning/30': message?.level === level.WARN,
    'border-l-success bg-success/30': message?.level === level.SUCCESS,
  }"
>
  @if (icon) {
    <nrc-icon
      class="w-4 h-4"
      [name]="icon"
      [fillColor]="iconFillColor"
    />
  }

  <span class="text-xs text-white tracking-wide">{{ message?.message }}</span>
</div>
