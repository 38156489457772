import { Directive, ElementRef, HostListener, Input, OnInit, inject } from '@angular/core';

export type AspectProperty = 'width' | 'height';

@Directive({
  standalone: true,
  selector: '[nrcAspectRatio]'
})
export class AspectRatioDirective implements OnInit {
  private readonly el = inject(ElementRef);

  // Input to distinguish, if width or height should be used.
  @Input({ required: true }) public nrcAspectRatio: AspectProperty = 'width';

  /**
   *
   */
  public ngOnInit(): void {
    this.resize();
  }

  /**
   *
   */
  @HostListener('window:resize')
  public onChange(): void {
    this.resize();
  }

  /**
   *
   */
  private resize(): void {
    // Set initial width, if directive is active.
    if (this.nrcAspectRatio === 'width') {
      this.el.nativeElement.style.height = `${this.el.nativeElement.offsetWidth}px`;
    } else {
      this.el.nativeElement.style.width = `${this.el.nativeElement.offsetHeight}px`;
    }
  }
}
