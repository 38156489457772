import { Component, Input, ContentChildren, QueryList, AfterContentInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { lineMotionAnimation } from '@newroom-connect/library/animations';

import { TabComponent } from './tab/tab.component';

// Keyboard keys that are used to switch tabs
enum KeyboardKey {
  ArrowRight = 'ArrowRight',
  ArrowLeft = 'ArrowLeft',
  Tab = 'Tab'
}

@Component({
  selector: 'nrc-tabs',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tabs.component.html',
  animations: [lineMotionAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TabsComponent implements AfterContentInit {
  @Input() public activeTabId = '';

  @Output() public activeTabChanged = new EventEmitter<string>();

  @ContentChildren(TabComponent) public tabs?: QueryList<TabComponent>;

  /**
   * After the content has been initialized, set the active tab to the first tab if no tab is active.
   * If there is an active tab, emit the active tab id.
   */
  public ngAfterContentInit(): void {
    if (!!this.activeTabId && this.tabs?.first) {
      const activeTab = this.tabs.find(tab => tab.id === this.activeTabId);

      this.setActiveTab(this.activeTabId, activeTab?.disabled);
    }
  }

  /**
   * Sets the active tab id.
   *
   * @param id
   * @param isDisabled
   */
  public setActiveTab(id: string, isDisabled = false): void {
    // If the tab is disabled, do nothing.
    if (isDisabled) {
      return;
    }

    this.activeTabId = id;
    this.activeTabChanged.emit(id);
  }

  /**
   * Handles keyboard navigation between tabs, using arrow keys and tab KeyboardKey.
   * The navigation is circular, meaning that if the user is on the first tab and presses the left arrow key, the last tab will be selected.
   * Supported keys: ArrowLeft, ArrowRight, Tab.
   *
   * @param event
   */
  public handleKeyDown(event: KeyboardEvent): void {
    const supportedKeys = [KeyboardKey.ArrowLeft, KeyboardKey.ArrowRight, KeyboardKey.Tab];

    const { tabs, activeTabId } = this;

    // If the key is not supported or there are no tabs, do nothing.
    if (!supportedKeys.includes(event.key as KeyboardKey) || !tabs) {
      return;
    }

    // Prevent the default tab behavior when the user presses the Tab KeyboardKey.
    if (event.key === KeyboardKey.Tab) {
      event.preventDefault();
    }

    // Find the index of the current tab and the next tab to be selected.
    const currentTabIndex = tabs.toArray().findIndex(tab => tab.id === activeTabId);

    let nextTabIndex = event.key === KeyboardKey.ArrowLeft || (event.key === KeyboardKey.Tab && event.shiftKey)
      ? currentTabIndex - 1
      : currentTabIndex + 1;

    // Adjust nextTabIndex for circular navigation
    if (nextTabIndex < 0) {
      // Wrap around to the last tab
      nextTabIndex = tabs.length - 1;
    } else if (nextTabIndex >= tabs.length) {
      // Wrap around to the first tab
      nextTabIndex = 0;
    }

    const nextTab = tabs.toArray()[nextTabIndex];

    // Set the next tab as active
    this.setActiveTab(nextTab.id, nextTab.disabled);
  }
}
