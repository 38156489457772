import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { collapseAnimation } from '@newroom-connect/library/animations';
import { ActionRole, ButtonType } from '@newroom-connect/library/enums';

import { ButtonComponent } from '../buttons/button/button.component';
import { IconComponent } from '../icon/icon.component';

@Component({
  standalone: true,
  imports: [CommonModule, ButtonComponent, IconComponent],
  selector: 'nrc-collapsible',
  templateUrl: './collapsible.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [collapseAnimation]
})
export class CollapsibleComponent {
  @Input() public icon?: string;
  @Input() public label?: string;
  @Input() public isCollapsed?: boolean;
  @Input() public disabled?: boolean;

  public buttonType = ButtonType;
  public actionRole = ActionRole;
}
