import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs';
import { TranslatePipe } from '@newroom-connect/library/pipes';
import { ButtonType, ActionRole } from '@newroom-connect/library/enums';
import { ColorHelper } from '@newroom-connect/library/helpers';

import { InputComponent } from '../../inputs/input.component';
import { InputRangeComponent } from '../../inputs/input-range/input-range.component';
import { ButtonComponent } from '../../buttons/button/button.component';

@Component({
  selector: 'nrc-input-color',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    InputRangeComponent,
    ButtonComponent,
    TranslatePipe
  ],
  templateUrl: './input-color.component.html',
  styleUrls: ['./input-color.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputColorComponent),
      multi: true
    }
  ]
})
export class InputColorComponent extends InputComponent<string> implements OnInit, AfterViewInit {
  private static readonly DEFAULT_COLOR_HEX = '#000000';

  @ViewChild('colorInput') public colorInputRef!: ElementRef<HTMLInputElement>;

  public colorOpacityFormGroup = new FormGroup({
    color: new FormControl<string>('#000000'),
    opacity: new FormControl<number>(0)
  });

  public buttonType = ButtonType;
  public actionRole = ActionRole;

  /**
   * @constructor
   */
  constructor() {
    super();

    this.registerColorOpacityFormGroupListener();
  }

  /**
   *
   */
  public override ngOnInit(): void {
    super.ngOnInit();

    // Add a validator to the form control of the input to match the color format of HEX color with alpha channel.
    const validators = this.formControl.validator
      ? [this.formControl.validator, Validators.pattern(ColorHelper.HEX_ALPHA_REGEXP)]
      : [Validators.pattern(ColorHelper.HEX_ALPHA_REGEXP)]
      ;

    this.formControl.setValidators(validators);
    this.initializeColorOpacityFormGroup(this.formControl.value);
  }

  /**
   *
   */
  public override ngAfterViewInit(): void {
    super.ngAfterViewInit();

    this.formControl.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(colorHexAlphaValue => this.initializeColorOpacityFormGroup(colorHexAlphaValue));
  }

  /**
   * Set the initial values of the internal color and opacity form control from the passed form control value of the input.
   *
   * @param colorHexAlphaValue String containing the colorhex and alpha values for the colorOpacity formgroup.
   */
  public initializeColorOpacityFormGroup(colorHexAlphaValue: string): void {
    if (!colorHexAlphaValue) {
      return;
    }

    try {
      const { color, alpha } = ColorHelper.hexAlphaToColorWithAlpha(colorHexAlphaValue);

      this.colorOpacityFormGroup.controls.color.patchValue(color);
      this.colorOpacityFormGroup.controls.opacity.patchValue(alpha > 0 ? Math.round(alpha / 255 * 100) : 0);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to initialize color and opacity form control values: ', error);
    }
  }

  /**
   * Handle the change of the color value made via the text input.
   */
  public onColorChange(): void {
    // Set the value explicitly again to refresh the from control from changing the "static" value.
    this.colorOpacityFormGroup.controls.color.setValue(this.colorOpacityFormGroup.controls.color.value);

    // Check, if the form control of the color input is invalid after changing the color via text input and reset the color, if the form control is invalid.
    if (this.formControl.invalid) {
      this.colorOpacityFormGroup.controls.color.setValue(InputColorComponent.DEFAULT_COLOR_HEX);
    }
  }

  /**
   * Open the color picker menu from the color input element.
   * For that the color input HTML is focused first and then clicked to update the color picker menu.
   */
  public openColorPicker(): void {
    this.colorInputRef.nativeElement.focus();
    this.colorInputRef.nativeElement.click();
  }

  /**
   * Register a listener for the color and opacity form group where the form control value of the color input is updated based on the updated form control values.
   */
  private registerColorOpacityFormGroupListener(): void {
    this.colorOpacityFormGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe(({ color, opacity }) => {
      if (!color || opacity === null || opacity === undefined) {
        return;
      }

      const currentColorHexAlphaValue = ColorHelper.colorWithAlphaToHexAlpha({ color, alpha: opacity });

      if (currentColorHexAlphaValue !== this.formControl.value) {
        this.formControl.patchValue(currentColorHexAlphaValue);
      }
    });
  }
}
