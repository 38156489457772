import { AreaElementEvent, BoundingBox } from '@newroom-connect/library/types';
import { ModalSize } from '@newroom-connect/library/enums';

import { IBaseEntity } from './base-entity.interface';
import { IFile, IFileHydrated } from './file.interface';
import { ActionName, ActionValueType } from './area-element-action-value.interface';

export enum AreaPerspective {
  TWO_DIMENSIONAL = 'TWO_DIMENSIONAL',
  THREE_SIXTY_DEGREE = 'THREE_SIXTY_DEGREE'
}

export interface IAreaTranslation {
  areaId: string;
  languageCode: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  backgroundFile?: IFile;
  backgroundFileId?: string;
}

export interface IArea extends IBaseEntity {
  projectId: string;
  slug: string;
  enabled: boolean;
  password?: string;
  translations: IAreaTranslation[];
  areaElements: IAreaElement[];
  perspective: AreaPerspective;
}

export interface IAreaElement extends IBaseEntity {
  areaId: string;
  event: AreaElementEvent;
  boundingBox: BoundingBox;
  name?: string;
  icon?: string;
  baseColor?: string | null;
  iconColor?: string;
  radius: number;
  isEnabled: boolean;
  isLocked: boolean;
  translations: IAreaElementTranslation[];
}

export interface IAreaElementTranslation {
  areaElementId: string;
  languageCode: string;
  createdAt?: string;
  updatedAt?: string;
  label?: string;
  description?: string;
  backgroundFileId?: string;
  backgroundFile?: IFile | IFileHydrated;
  fileOpacity?: number;
  action?: IAreaElementAction;
  actionId?: string;
}

export interface IAreaElementAction<T extends ActionName = ActionName> {
  id?: string;
  name: T;
  preset: IAreaElementActionPreset;
  value: ActionValueType<T>;
}

export interface IAreaElementActionPreset extends IBaseEntity {
  name: ActionName;
  label: string;
  description: string;
  icon: string;
  modalSize: ModalSize;
  inputs: IAreaElementActionPresetInput[];
}

export interface IAreaElementActionPresetInputBase {
  name: string;
  label: string;
  placeholder: string;
  type: string;
  required?: boolean;
  pattern?: string;
  default?: unknown;
  options?: IAreaElementActionInputOptions[];
  collection?: string;
  show?: IAreaElementActionInputShowOptions;
  separator?: boolean;
  fileType?: string[];
  isTranslatable: string;
  width?: string;
}

interface IAreaElementActionPresetInputLayoutGroup {
  layout: {
    type: 'row' | 'column';
    items: IAreaElementActionPresetInputBase[];
  };
}

export type IAreaElementActionPresetInput = IAreaElementActionPresetInputBase | IAreaElementActionPresetInputLayoutGroup;

export const isLayoutGroup = (input: IAreaElementActionPresetInput): input is IAreaElementActionPresetInputLayoutGroup  => {
  return 'layout' in input;
};

interface IAreaElementActionInputOptions {
  label: string;
  value: string;
}

interface IAreaElementActionInputShowOptions {
  is: string;
  when: string;
}
