import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IListOptions, IAPIListResponse, IFileHydrated } from '@newroom-connect/library/interfaces';

import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  /**
   * @constructor
   *
   * @param fileService
   */
  constructor(
    private readonly fileService: FileService
  ) {}

  /**
   * List all files which mimetype group is "image" or "video" and type is "SOURCE".
   * This will return all source images and videos from the API.
   *
   * @param projectId The ID of the project to list source images from.
   * @param options Optional list options to pass to the list source images request.
   * @param mediaType Specifies which media should be fetched. If not provided defaults to ['image', 'video'].
   *
   * @returns Source images and videos requested with the given options.
   */
  public listSources(projectId: string, options?: IListOptions, mediaType = ['image', 'video']): Observable<IAPIListResponse<IFileHydrated>> {
    const requestOptions: IListOptions = options ?? {};

    if (!requestOptions.filters) {
      requestOptions.filters = {};
    }

    if (!requestOptions.filters['AND']) {
      requestOptions.filters['AND'] = [];
    }

    if (mediaType.length > 0) {
      requestOptions.filters['AND'].push(
        {
          OR: mediaType.map(type => {
            return { mimetype: { startsWith: type } };
          })
        }
      );
    }

    return this.fileService.listSourceFiles(projectId, requestOptions);
  }
}
