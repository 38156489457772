import { jwtDecode, JwtPayload } from 'jwt-decode';
import { JwtError } from '@newroom-connect/library/errors';

import { DateHelper } from './date.helper';

export class JwtHelper {
  /**
   * Decode a given JWT token.
   *
   * @param token The token to decode.
   *
   * @returns The payload of the decoded token.
   *
   * @throws `JwtError` if the token is unable to decode.
   */
  public static decodeToken<T>(token: string): JwtPayload & T {
    try {
      return jwtDecode<JwtPayload & T>(token);
    } catch (error) {
      throw new JwtError(error as Error);
    }
  }

  /**
   * Get a specific value from an encoded JWT token specified by a key.
   *
   * @param key The key of the key/value pair to retrieve the value from.
   * @param token The JWT token to get the value from.
   *
   * @returns The value of the key/value pair or `null`, if the value is not found.
   */
  public static getValueFromToken<T>(key: string, token: string | null | undefined): T | null {
    if (!token) {
      return null;
    }

    const tokenDecoded = JwtHelper.decodeToken<Record<string, string>>(token);

    return tokenDecoded[key] as T;
  }

  /**
   *
   * @param token
   *
   * @returns
   */
  public static getExpiryTime(token: string): number | null {
    const decodedToken = JwtHelper.decodeToken(token);

    return decodedToken?.exp ?? null;
  }

  /**
   * Check if a token is expired.
   *
   * @param token The token to check.
   * @param expirationThreshold A threshold value to check expiration for in milliseconds.
   *
   * @returns Boolean indicator, if the token is expired.
   */
  public static isTokenExpired(token: string, expirationThreshold = 5000): boolean {
    const expiryTime = JwtHelper.getExpiryTime(token);
    const expiryTimeInMs = expiryTime !== null ? expiryTime * 1000 : null;
    const now = DateHelper.getCurrentTimestamp();

    if (expiryTimeInMs) {
      return (expiryTimeInMs - now) < expirationThreshold;
    }

    return false;
  }
}
