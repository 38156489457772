<div class="grow overflow-hidden flex flex-col lg:flex-row justify-between space-x-2 bg-white/10 rounded-10">
  <!-- The side menu of the file library view shown on the left side -->
  <div class="w-full lg:w-64 shrink-0 flex flex-col border-r-1.5 border-solid border-white/10">
    <div class="w-full p-5 border-b-1.5 border-solid border-white/10">
      <nrc-search
        [placeholder]="'Name, type, ...'"
        [isCollapsible]="false"
        (searchEvent)="fileSearchEvent.emit($event)"
      />
    </div>

    <div class="w-full grow bg-black/30 px-2 py-4">
      <nrc-file-explorer
        class="w-full"
        [headline]="'All files'"
        [fileExplorer]="{
          folders: []
        }"
      />
    </div>
  </div>

  <!-- The file library list shown on the right side -->
  <div #fileLibraryModalSearchListContainer class="h-full grow">
    <nrc-list
      class="h-full pr-2 overflow-auto flex flex-col justify-center items-center"
      [ngClass]="{
        'justify-start':  (fileListItemsSig() | isNonEmptyArray),
        'justify-center': (fileListItemsSig() | isEmptyArray)
      }"
      [layout]="'grid'"
      [items]="fileListItemsSig"
      [meta]="metaSig()?.pagination"
      [config]="{
        columns: [
        {
            key: 'title',
            label: 'title',
            additionalInfoKeys: [
              {
                key: 'extension',
                format: objectPropertyFormat.TEXT
              },
              {
                key: 'createdAt',
                format: objectPropertyFormat.DATE
              }
            ],
            type: listItemType.CARD,
            isHighlighted: true,
            showTooltip: true,
            thumbnailConfig: {
              fallbackThumbnailSourceKey: 'source',
              thumbnailSourceKey: 'thumbnail',
              fallbackMimetypeKey: 'mimetype'
            }
          }
        ]
      }"
      [listPaginatorOverflowParentElement]="fileLibraryModalSearchListContainer"
      (listOptionsEvent)="fileListEvent.emit($event)"
      (listItemClickedEvent)="fileSelectedEvent.emit($event)"
    />
  </div>
</div>
