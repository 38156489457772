import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IProjectLicenseQuotaUsage, IWebsocketMessage } from '@newroom-connect/library/interfaces';

import { EntityService } from '../entity/entity.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectLicenseQuotaService extends EntityService {
  protected searchableProperties: string[] = []; // Empty, since we don't want to search for anything.

  /**
   * Get license quota usage for a project.
   *
   * @param projectId The ID of the project to get the license quota usage for.
   *
   * @returns The license quota usage for the given project.
   */
  public getProjectLicenseQuotaUsage(projectId: string): Observable<IProjectLicenseQuotaUsage> {
    return this.apiService.get<IProjectLicenseQuotaUsage>(`projects/${projectId}/license-quota-usage`);
  }

  /**
   *
   * @param projectId
   *
   * @returns
   */
  public watchReachedProjectStorageSize(projectId: string): Observable<IWebsocketMessage<number>> {
    return this.websocketService.watchTopic<number>(`projects/${projectId}/storage-size`);
  }
}
